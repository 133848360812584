import { NativeDispatch } from './webViewEvents/nativeDispatch';

declare global {
  interface Window {
    _console: typeof window['console'];
  }
}

window._console = {} as any;
window._console.log = console.log.bind(console);
window._console.warn = console.warn.bind(console);
window._console.error = console.error.bind(console);
window._console.info = console.info.bind(console);
window._console.trace = console.trace.bind(console);

console.log = function (...args: any[]) {
  window._console.log(...args);
  NativeDispatch.dispatch({ type: 'LOG', payload: { level: 'log', data: args } });
};

console.warn = function (...args: any[]) {
  window._console.warn(...args);
  NativeDispatch.dispatch({ type: 'LOG', payload: { level: 'warn', data: args } });
};

console.error = function (...args: any[]) {
  window._console.error(...args);
  NativeDispatch.dispatch({ type: 'LOG', payload: { level: 'error', data: args } });
};

console.info = function (...args: any[]) {
  window._console.info(...args);
  NativeDispatch.dispatch({ type: 'LOG', payload: { level: 'info', data: args } });
};

console.trace = function (...args: any[]) {
  window._console.trace(...args);
  NativeDispatch.dispatch({ type: 'LOG', payload: { level: 'trace', data: args } });
};

export {};
