import { all, call, put, takeLatest } from 'redux-saga/effects';
import { createCheckoutSessionSuccess, createCheckoutSessionFailure } from './actions';
import { CREATE_CHECKOUT_SESSION_ATTEMPT } from './actionTypes';
import { postDataToService } from '../../services/apiGatewayClient';
import { TypedIterableIterator } from '../../modules/helpers';
import { SelectState } from '../../modules/helpers';
import { getPrevPathSelector } from '../../containers/PathName/selectors';
import { getDevicesAddedSelector } from '../../containers/ManageDevices/selectors';
import { getSelectedPlanSelector } from '../../containers/PlanListPage/selectors';
import { refreshToken } from '../../containers/LoginPage/actions';

function createCreateCheckoutSessionRequest(planId: string, devicesAdded: any, successUrl: string, cancelUrl: string) {
  return {
    pepperPlanId: planId,
    devices: devicesAdded,
    successUrl: successUrl,
    cancelUrl: cancelUrl,
  };
}

function* createCheckoutSession(): TypedIterableIterator<any> {
  try {
    const selectedPlan: any = yield SelectState<any>(getSelectedPlanSelector);
    const prevPath: any = yield SelectState<any>(getPrevPathSelector);
    const devicesAdded: any = yield SelectState<any>(getDevicesAddedSelector);

    const createCheckoutSessionRequest = createCreateCheckoutSessionRequest(
      selectedPlan.id,
      devicesAdded,
      window.location.origin + '/checkout-success',
      window.location.origin + prevPath,
    );

    const response = yield call(postDataToService, '/checkoutDelegate', createCheckoutSessionRequest, 'subscriptions');

    // If the external customer did not exist, we want to add the external account to the current profile
    if (response.token) {
      yield put(refreshToken(response.token));
    }

    yield put(createCheckoutSessionSuccess(response.checkoutUrl));
  } catch (err) {
    yield put(createCheckoutSessionFailure(err));
  }
}

function* CheckoutSessionSaga() {
  yield all([takeLatest(CREATE_CHECKOUT_SESSION_ATTEMPT, createCheckoutSession)]);
}

export default CheckoutSessionSaga;
