import { TypedIterableIterator } from '../../modules/helpers';

//redux
import { all, put, takeLatest } from 'redux-saga/effects';
import {
  FETCH_APP_URI,
  INVALID_TOKEN,
  LINK_ALREADY_USED,
  PASSWORDS_TOO_SIMILAR,
  RESET_PASSWORD_ATTEMPT,
} from './actionTypes';

import {
  appUriFailureAction,
  appUriSuccessAction,
  initiateFetchUri,
  resetPasswordFailureAction,
  resetPasswordSuccessAction,
} from './actions';

//third party lib
import { get } from 'lodash';

//pepper sdk
import PepperSdk from 'pepper-js-sdk';

export function* attemptResetPassword(action: any): TypedIterableIterator<any> {
  const { brand, resetToken, password, email } = action;

  yield put(initiateFetchUri(brand));

  if (!resetToken) {
    yield put(resetPasswordFailureAction(INVALID_TOKEN));
  } else {
    const authService = PepperSdk.Authentication();

    const resetPasswordPromise = new Promise(resolve => {
      authService.resetPassword(brand, resetToken, password, email, (err, result) => {
        if (err) {
          resolve({ err });
        }
        resolve({ result });
      });
    });
    const { err, result } = yield resetPasswordPromise;

    if (result) {
      yield put(resetPasswordSuccessAction());
    } else {
      const errorDescription = get(err, ['response', 'data', 'description'], null);
      const errorMapper: any = {
        'Verification token (vtoken) is invalid': INVALID_TOKEN,
        'The email verification link has already been used': LINK_ALREADY_USED,
        'The new password is invalid': PASSWORDS_TOO_SIMILAR,
      };
      const errorString = errorMapper[errorDescription] || INVALID_TOKEN;
      yield put(resetPasswordFailureAction(errorString));
    }
  }
}

export function* fetchAppUri(action: any): TypedIterableIterator<any> {
  const { brand } = action;
  const configService = PepperSdk.Configuration();

  const appUriPromise = new Promise((resolve, reject) => {
    configService.appUriByBrand(brand, (err, result) => {
      if (err) {
        return reject({ err });
      }
      resolve({ result });
    });
  });
  const { result } = yield appUriPromise;

  if (result && result.brand) {
    yield put(appUriSuccessAction(result));
  } else {
    yield put(appUriFailureAction());
  }
}
function* ResetPasswordSaga() {
  yield all([takeLatest(RESET_PASSWORD_ATTEMPT, attemptResetPassword)]);
  yield all([takeLatest(FETCH_APP_URI, fetchAppUri)]);
}

export default ResetPasswordSaga;
