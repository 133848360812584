import { ManageDevicesActionTypes, IManageDevices } from './types';
import {
  GET_MANAGE_DEVICES_ATTEMPT,
  GET_ASSIGN_DEVICES_SUCCESS,
  GET_DEVICES_MANAGED_SUCCESS,
  GET_MANAGE_DEVICES_FAILURE,
  GET_ASSIGN_DEVICES_FAILURE,
  CLEAR_MANAGE_DEVICES_STATE,
  SET_DEVICES_MANAGED,
  SET_DEVICES_ASSIGNED,
  CLEAR_ERROR_STATE,
  CLEAR_STATE,
} from './actionTypes';

export const initialState: IManageDevices = {
  devices: [],
  attempting: false,
  error: null,
  saving: false,
  saveError: null,
  saveSuccess: null,
  devicesAdded: [],
  devicesRemoved: [],
};

export const ManageDevicesReducer = (state = initialState, action: ManageDevicesActionTypes): IManageDevices => {
  switch (action.type) {
    case GET_MANAGE_DEVICES_ATTEMPT:
      return {
        ...state,
        attempting: true,
      };
    case GET_ASSIGN_DEVICES_SUCCESS:
      return {
        ...state,
        saving: false,
        devices: action.devices,
        error: null,
        saveError: null,
        attempting: false,
      };
    case GET_DEVICES_MANAGED_SUCCESS:
      return {
        ...state,
        saving: false,
        error: null,
        saveError: null,
        saveSuccess: true,
      };
    case GET_ASSIGN_DEVICES_FAILURE:
      return {
        ...state,
        saving: false,
        error: action.error,
        attempting: false,
      };
    case GET_MANAGE_DEVICES_FAILURE:
      return {
        ...state,
        saving: false,
        saveError: action.error,
        saveSuccess: false,
        attempting: false,
      };
    case SET_DEVICES_MANAGED:
      return {
        ...state,
        saving: true,
        saveError: null,
        saveSuccess: null,
        devicesAdded: action.devicesAdded,
        devicesRemoved: action.devicesRemoved,
      };
    case SET_DEVICES_ASSIGNED:
      return {
        ...state,
        saving: false,
        saveError: null,
        saveSuccess: null,
        devicesAdded: action.devicesAdded,
        devicesRemoved: action.devicesRemoved,
      };
    case CLEAR_ERROR_STATE:
      return {
        ...state,
        saving: false,
        saveError: null,
      };
    case CLEAR_MANAGE_DEVICES_STATE:
    case CLEAR_STATE:
      return initialState;
    default:
      return state;
  }
};
