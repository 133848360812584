/**
 * "Original" style errors were text strings. This is retained for brands that still use them
 */
enum PepperErrorKey {
  EMAIL_NOT_VERIFIED = 'email.not.verified',
  MISSING_PARAMETERS = 'missing_parameters',
}

/**
 * Documents the error codes that Login Radius may send with authentication requests
 * "New" Login Radius errors are error code numbers
 * 
 * Account lockout Ref: https://adminconsole.loginradius.com/platform-security/account-protection/auth-security/brute-force-lockout
 * Error Codes: https://docs.loginradius.com/api/v2/getting-started/response-codes/customer-identity-api-codes/
 */
enum LRErrorCodes {
    TOO_MANY_REQUESTS = 429, // Not observed in Login testing
    ACCOUNT_LOCKED = 1198, // Your account has been locked
    SMS_LIMIT_EXCEEDED = 1123, // You have reached a limit for sending SMS
    EMAIL_LIMIT_EXCEEDED = 1122, // Too many SMS (in docs but not observed in testing)
    INVALID_TOKEN = 1104, // TOKEN IS WRONG, resend may not help if coding issue
    EXPIRED_CODE = 1070, // Code expired (using a previously received code)
    CODE_INVALID = 1067, // Code invalid
    EMAIL_NOT_VERIFIED = 970, 
}

function _isOneOf(errorCode: string | number | undefined, stringCodes: string[], numericCodes: number[]) {
  if (!errorCode) return false;
  if (typeof errorCode === 'number') {
    return numericCodes.includes(errorCode);
  }
  return stringCodes.includes(errorCode);
}

/**
 * Whether the user has submitted the form too many times incorrectly
 * or the user has requested to resend the verification code too many times
 * @param errorCode
 */
export function isLimitExceededError(errorCode: string | number | undefined): boolean {
  const stringCodes: string[] = [];
  const numericCodes = [LRErrorCodes.EMAIL_LIMIT_EXCEEDED, LRErrorCodes.SMS_LIMIT_EXCEEDED, LRErrorCodes.TOO_MANY_REQUESTS];
  return _isOneOf(errorCode, stringCodes, numericCodes);
}

/**
 * Whether the error code returned is a result of an account lockout
 * @param errorCode
 */
export function isAccountLockedError(errorCode: string | number | undefined): boolean {
  const stringCodes: string[] = [];
  const numericCodes = [LRErrorCodes.ACCOUNT_LOCKED];
  return _isOneOf(errorCode, stringCodes, numericCodes);
}

/**
 * Whether the MFA error code returned is from an expired token
 * @param errorCode
 */
export function isMFAExpiredError(errorCode: string | number | undefined): boolean {
  const stringCodes: string[] = [];
  const numericCodes = [LRErrorCodes.INVALID_TOKEN, LRErrorCodes.EXPIRED_CODE];
  return _isOneOf(errorCode, stringCodes, numericCodes);
}

/**
 * Whether the error code indicates the user is ineligible for new MFA codes and may be unable to sign in
 * @param errorCode
 */
export function isRequestLimitError(errorCode: string | number | undefined): boolean {
  return isAccountLockedError(errorCode) || isLimitExceededError(errorCode);
}

/**
 * Whether the user is unverified
 * @param errorCode
 */
export function isUnverifiedError(errorCode: string | number | undefined): boolean {
  const stringCodes = [PepperErrorKey.EMAIL_NOT_VERIFIED];
  const numericCodes = [LRErrorCodes.EMAIL_NOT_VERIFIED];
  return _isOneOf(errorCode, stringCodes, numericCodes);
}

/**
 * Whether the required parameters are missing for oauth
 * @param errorCode
 */
export function isOAuthMissingParamsError(errorCode: string | number | undefined): boolean {
  return errorCode === PepperErrorKey.MISSING_PARAMETERS;
}