import React from 'react';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import messages from "./messages";

interface SignInProps {
  brand: string;
}

/**
 * Sign in link for case when the user has legitimately arrived at a page without being signed in.
 * 
 * @param props
 * @constructor
 */
const SignIn = (props: SignInProps) => {
  const { brand } = props;

  return (
    <Link id="signIn" className={`${brand}-header-log-in-link`} to="/">
      <FormattedMessage {...messages.signIn}/>
    </Link>
  );
};

export default SignIn;