import React from 'react';

//redux
import { useDispatch } from 'react-redux';
import { clearPromoCodeState } from '../PromoCode/actions';
import { clearSubscriptionPreviewState, subscriptionAttempt } from './actions';

//third party lib
import { FormattedMessage } from 'react-intl';
import history from '../../history';
import messages from './messages';

//material ui
import { Button, Paper } from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';

interface Props {
  brand: string;
  submitDisabled: boolean;
}

const OrderActions = (props: Props) => {
  const { brand, submitDisabled } = props;
  const [tooltipIsOpen, setTooltipIsOpen] = React.useState(false);

  const dispatch = useDispatch();

  const handleSubmit = () => {
    submitSubscription();
  };

  const submitSubscription = () => {
    dispatch(subscriptionAttempt());
  };

  const pageBack = () => {
    dispatch(clearPromoCodeState());
    dispatch(clearSubscriptionPreviewState());

    history.goBack();
  };

  return (
    <Paper variant="outlined" className="summary-order-footer" style={{ width: '100%' }}>
      <div className="summary-order-footer-action-buttons">
        <Button
          className={`${brand}-btn ${brand}-btn-text`}
          style={{
            textTransform: 'none',
            fontWeight: 'bolder',
          }}
          id={`${brand}-back-btn`}
          onClick={pageBack}>
          <FormattedMessage {...messages.back} />
        </Button>
        <Tooltip
          open={tooltipIsOpen}
          onClick={() => setTooltipIsOpen(true)}
          onClose={() => setTooltipIsOpen(false)}
          onOpen={() => setTooltipIsOpen(true)}
          title={
            submitDisabled ? (
              <span style={{ fontSize: '0.8rem' }}>
                <FormattedMessage {...messages.confirmTermsStatement} />
              </span>
            ) : (
              ''
            )
          }
          placement="top">
          <div>
            <Button
              className={
                submitDisabled ? `${brand}-btn ${brand}-btn-action-disabled` : `${brand}-btn ${brand}-btn-action`
              }
              style={{
                height: 42,
                width: 224,
                textTransform: 'none',
                fontWeight: 'bolder',
              }}
              onClick={() => handleSubmit()}
              id="submit-btn"
              disabled={submitDisabled}>
              <FormattedMessage {...messages.submit} />
            </Button>
          </div>
        </Tooltip>
      </div>
    </Paper>
  );
};

export default OrderActions;
