import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getBrandImagesSelector, getLongBrandNameSelector, getBrandNameSelector } from '../../BrandProvider/selectors';
import {
  getIsAttemptingSelector,
  getIsRequestLimitExceeded,
  getMfaContactDisplay,
  getResendErrorCodeSelector,
  getResendingSelector,
  getResendMessageSelector,
  getResendSuccessSelector,
} from '../selectors';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import { FormattedMessage } from 'react-intl';
import messages from './messages';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import { clearMfaState, mfaResendVerificationAttempt, mfaVerificationAttempt } from '../actions';
import { MfaMessages } from './mfaMessages';
import Alert from '@material-ui/lab/Alert';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Collapse from '@material-ui/core/Collapse';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import Container from '@material-ui/core/Container';
import CssBaseline from '@material-ui/core/CssBaseline';
import history from '../../../history';

const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  form: {
    width: '90%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  messageWrapper: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  resendButton: {
    // FIXME: these may not match another brands styles
    borderRadius: '30px', // match login button
    padding: '10px', // match login button
  },
}));

interface MultiFactorAuthenticationProps extends RouteComponentProps {}

function MultiFactorAuthentication(props: MultiFactorAuthenticationProps) {
  const dispatch = useDispatch();
  const classes = useStyles();
  const brand = useSelector(getBrandNameSelector);
  const brandName = useSelector(getLongBrandNameSelector);
  const images = useSelector(getBrandImagesSelector);
  const loading = useSelector(getIsAttemptingSelector);
  const isLimitExceeded = useSelector(getIsRequestLimitExceeded);
  const contact = useSelector(getMfaContactDisplay);

  //Resend Data
  const [resendOpen, setResendOpen] = React.useState(false);
  const resending = useSelector(getResendingSelector);
  const resent = useSelector(getResendSuccessSelector);
  const resendError = useSelector(getResendErrorCodeSelector);
  const resendMessage = useSelector(getResendMessageSelector);
  const [messageStyle, setMessageStyle] = React.useState<'success' | 'error'>('success');

  const redirectUri = React.useMemo(() => {
    const searchParams = new URLSearchParams(props.location.search.substring(1));
    const uri = searchParams.get('redirectUri');
    return uri ? window.decodeURIComponent(uri) : undefined;
  }, [props.location.search]);

  // update the color of the collapse message
  useEffect(() => {
    if (!!resendError) {
      setMessageStyle('error');
    }
  }, [resendError]);

  useEffect(() => {
    if (!!resent) {
      setMessageStyle('success');
    }
  }, [resent]);

  useEffect(() => {
    if (!!resendMessage) {
      setResendOpen(true);
    }
  }, [resendMessage]);

  const [code, setCode] = useState('');
  const disableSubmit = useMemo(() => {
    return !(code.length > 0);
  }, [code]);

  const handleSubmit = (e: any) => {
    e.preventDefault();
    setResendOpen(false);
    dispatch(mfaVerificationAttempt(code, redirectUri));
  };

  const handleResend = (e: any) => {
    setResendOpen(false);
    dispatch(mfaResendVerificationAttempt());
    setCode('');
  };

  const handleOkay = (e: any) => {
    e.preventDefault();
    dispatch(clearMfaState());
    history.push('/login');
  };

  const loadingWrapper = (
    // Brand logo styles added for padding to match
    <div className={`${brand}-login-logo progress-wrapper`}>
      <CircularProgress size={34} className={`${brand}-button-progress`} />
      <Typography align="center" variant="body1">
        {resending ? <FormattedMessage {...messages.resending} /> : <FormattedMessage {...messages.verifying} />}
      </Typography>
    </div>
  );

  const buttonStyles: React.CSSProperties = {
    textTransform: 'none',
    fontWeight: 'bolder',
    fontSize: 'larger',
  };

  const mfaForm = (
    <form className={classes.form} noValidate onSubmit={handleSubmit} onReset={handleResend}>
      <TextField
        className={`${brand}-text-field`}
        variant="outlined"
        margin="normal"
        required
        fullWidth
        id="code"
        label={<FormattedMessage {...messages.code} />}
        name="code"
        autoComplete="code"
        value={code}
        onChange={e => {
          setCode(e.target.value);
        }}
        autoFocus
        disabled={loading || resending}
      />
      <div style={{ margin: 35 }}></div>
      <Button
        type="reset"
        fullWidth
        variant="text"
        color="inherit"
        id="mfaResendCode"
        style={buttonStyles}
        disabled={resending || loading}>
        <FormattedMessage {...messages.resendCode} />
      </Button>
      <Button
        type="submit"
        fullWidth
        variant="contained"
        color="primary"
        className={disableSubmit || loading ? `${brand}-login-button-disabled` : `${brand}-login-button`}
        id="mfaCodeSubmit"
        style={buttonStyles}
        disabled={disableSubmit || resending || loading}>
        <FormattedMessage {...messages.verify} />
      </Button>
      <div style={{ paddingBottom: 50 }}></div>
    </form>
  );

  const limitErrorAction = (
    <>
      <div style={{ margin: 35 }}></div>
      <Button
        fullWidth
        variant="contained"
        color="primary"
        className={`${brand}-login-button`}
        id="mfaOkayButton"
        style={{
          textTransform: 'none',
          fontWeight: 'bolder',
          fontSize: 'larger',
        }}
        onClick={handleOkay}>
        <FormattedMessage {...messages.okayButton} />
      </Button>
      <div style={{ paddingBottom: 50 }}></div>
    </>
  );

  return (
    <Container className={`${brand}-screen-bg`}>
      <Container className={`${brand}-bg login_container`} component="main" maxWidth="xs">
        <CssBaseline />
        <div className={`${classes.paper} ${brand}-login-paper`} style={{ width: '100%' }}>
          <Collapse in={resendOpen && !!resendMessage} style={{ width: '100%', marginTop: 10 }}>
            <Alert
              severity={messageStyle}
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={() => {
                    setResendOpen(false);
                  }}
                  id="error-alert">
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }>
              {resendOpen ? <FormattedMessage {...resendMessage} /> : ''}
            </Alert>
          </Collapse>
          {loading || resending ? (
            loadingWrapper
          ) : (
            <>
              <div>
                <img src={images['logo-error']} alt="Logo" className={`${brand}-login-logo`} />
              </div>
              <MfaMessages brand={brandName} contact={contact} wrapperClass={classes.messageWrapper} />
            </>
          )}
          {!isLimitExceeded ? mfaForm : limitErrorAction}
        </div>
      </Container>
    </Container>
  );
}

export default withRouter(MultiFactorAuthentication);
