import { VerifyEmailTypes } from './types';

import {
  APP_URI_FAILURE,
  APP_URI_SUCCESS,
  FETCH_APP_URI,
  VERIFY_EMAIL_ATTEMPT,
  VERIFY_EMAIL_FAILURE,
  VERIFY_EMAIL_SUCCESS,
} from './actionTypes';

export const appUriFailureAction = (): VerifyEmailTypes => ({
  type: APP_URI_FAILURE,
});

export const appUriSuccessAction = (appUriObj: any): VerifyEmailTypes => ({
  type: APP_URI_SUCCESS,
  appUriObj: appUriObj,
});

export const initiateFetchUri = (brand: string): VerifyEmailTypes => ({
  type: FETCH_APP_URI,
  brand: brand,
});

export const verifyEmailAttemptAction = (brand: string, verificationToken: any, email: string): VerifyEmailTypes => ({
  type: VERIFY_EMAIL_ATTEMPT,
  brand: brand,
  verificationToken: verificationToken,
  email: email,
});

export const verifyEmailFailureAction = (error: any): VerifyEmailTypes => ({
  type: VERIFY_EMAIL_FAILURE,
  error: error,
});

export const verifyEmailSuccessAction = (): VerifyEmailTypes => ({
  type: VERIFY_EMAIL_SUCCESS,
});
