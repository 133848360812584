import { createSelector } from 'reselect';

import { AppState } from '../../store/rootReducer';

const getAppUriObj = (state: AppState) => state.resetPassword.appUriObj;
const getResetPasswordAttempting = (state: AppState) => state.resetPassword.attempting;
const getResetPasswordError = (state: AppState) => state.resetPassword.error;
const getResetPasswordFailed = (state: AppState) => state.resetPassword.failed;
const getResetPasswordSuccess = (state: AppState) => state.resetPassword.success;

export const getAppUriObjSelector = createSelector(getAppUriObj, appUriObj => appUriObj);

export const getResetPasswordAttemptingSelector = createSelector(getResetPasswordAttempting, attempting => attempting);

export const getResetPasswordErrorSelector = createSelector(getResetPasswordError, error => error);

export const getResetPasswordFailedSelector = createSelector(getResetPasswordFailed, failed => failed);

export const getResetPasswordSuccessSelector = createSelector(getResetPasswordSuccess, success => success);
