const planFeatures: any = {
  es_1month_1cam: [
    {
      id: 'accountManagement.components.cancel.plan.description.otis.es_1month_4cam',
      defaultMessage: '{cancelIcon} 7-day video history',
    },
  ],
  pr_1month_allcam: [
    {
      id: 'accountManagement.components.cancel.plan.description.otis.pr_1month_4cam',
      defaultMessage: '{cancelIcon} 30-day video history',
    },
  ],

  es_1year_1cam: [
    {
      id: 'accountManagement.components.cancel.plan.description.otis.es_1year_4cam',
      defaultMessage: '{cancelIcon} 7-day video history',
    },
  ],

  pr_1year_allcam: [
    {
      id: 'accountManagement.components.cancel.plan.description.otis.pr_1year_4cam',
      defaultMessage: '{cancelIcon} 30-day video history',
    },
  ],

  //visi

  ba_1month_4cam: [
    {
      id: 'accountManagement.components.cancel.plan.description.visi.ba_1month_4cam_1',
      defaultMessage: '{cancelIcon} Watch & download activity',
    },
    {
      id: 'accountManagement.components.cancel.plan.description.visi.ba_1month_4cam_2',
      defaultMessage: '{cancelIcon} 24-hour video history',
    },
  ],

  pr_1month_4cam: [
    {
      id: 'accountManagement.components.cancel.plan.description.visi.pr_1month_4cam_1',
      defaultMessage: '{cancelIcon} Watch & download activity',
    },
    {
      id: 'accountManagement.components.cancel.plan.description.visi.pr_1month_4cam_2',
      defaultMessage: '{cancelIcon} 30-day video history',
    },
    {
      id: 'accountManagement.components.cancel.plan.description.visi.pr_1month_4cam_3',
      defaultMessage: '{cancelIcon} Smart Detection',
    },
    {
      id: 'accountManagement.components.cancel.plan.description.visi.pr_1month_4cam_4',
      defaultMessage: '{cancelIcon} Send Help Smart Security',
    },
  ],

  ba_1year_4cam: [
    {
      id: 'accountManagement.components.cancel.plan.description.visi.ba_1year_4cam_1',
      defaultMessage: '{cancelIcon} Watch & download activity',
    },
    {
      id: 'accountManagement.components.cancel.plan.description.visi.ba_1year_4cam_2',
      defaultMessage: '{cancelIcon} 24-hour video history',
    },
  ],

  pr_1year_4cam: [
    {
      id: 'accountManagement.components.cancel.plan.description.visi.pr_1year_4cam_1',
      defaultMessage: '{cancelIcon} Watch & download activity',
    },
    {
      id: 'accountManagement.components.cancel.plan.description.visi.pr_1year_4cam_2',
      defaultMessage: '{cancelIcon} 30-day video history',
    },
    {
      id: 'accountManagement.components.cancel.plan.description.visi.pr_1year_4cam_3',
      defaultMessage: '{cancelIcon} Smart Detection',
    },
    {
      id: 'accountManagement.components.cancel.plan.description.visi.pr_1year_4cam_4',
      defaultMessage: '{cancelIcon} Send Help Smart Security',
    },
  ],

  ba_2year_4cam: [
    {
      id: 'accountManagement.components.cancel.plan.description.visi.ba_2year_4cam_1',
      defaultMessage: '{cancelIcon} Watch & download activity',
    },
    {
      id: 'accountManagement.components.cancel.plan.description.visi.ba_2year_4cam_2',
      defaultMessage: '{cancelIcon} 24-hour video history',
    },
  ],

  pr_2year_4cam: [
    {
      id: 'accountManagement.components.cancel.plan.description.visi.pr_2year_4cam_1',
      defaultMessage: '{cancelIcon} Watch & download activity',
    },
    {
      id: 'accountManagement.components.cancel.plan.description.visi.pr_2year_4cam_2',
      defaultMessage: '{cancelIcon} 30-day video history',
    },
    {
      id: 'accountManagement.components.cancel.plan.description.visi.pr_2year_4cam_3',
      defaultMessage: '{cancelIcon} Smart Detection',
    },
    {
      id: 'accountManagement.components.cancel.plan.description.visi.pr_2year_4cam_4',
      defaultMessage: '{cancelIcon} Send Help Smart Security',
    },
  ],

  /**
   * Geeni plans
   */
  pr_1month_basic: [
    {
      id: 'accountManagement.components.subscription.plan.description.geeni.pr_1month_basic_1',
      defaultMessage: '{cancelIcon} Watch & download activity',
    },
    {
      id: 'accountManagement.components.subscription.plan.description.geeni.pr_1month_basic_2',
      defaultMessage: '{cancelIcon} 14-day video history',
    },
    {
      id: 'accountManagement.components.subscription.plan.description.geeni.pr_1month_basic_3',
      defaultMessage: '{cancelIcon} 1 camera',
    },
    {
      id: 'accountManagement.components.subscription.plan.description.geeni.pr_1month_basic_4',
      defaultMessage: '{cancelIcon} Snooze notifications',
    },
    {
      id: 'accountManagement.components.subscription.plan.description.geeni.pr_1month_basic_5',
      defaultMessage: '{cancelIcon} Smart Detection',
    },
  ],
  pr_1year_basic: [
    {
      id: 'accountManagement.components.subscription.plan.description.geeni.pr_1year_basic_1',
      defaultMessage: '{cancelIcon} Watch & download activity',
    },
    {
      id: 'accountManagement.components.subscription.plan.description.geeni.pr_1year_basic_2',
      defaultMessage: '{cancelIcon} 14-day video history',
    },
    {
      id: 'accountManagement.components.subscription.plan.description.geeni.pr_1year_basic_3',
      defaultMessage: '{cancelIcon} 1 camera',
    },
    {
      id: 'accountManagement.components.subscription.plan.description.geeni.pr_1year_basic_4',
      defaultMessage: '{cancelIcon} Snooze notifications',
    },
    {
      id: 'accountManagement.components.subscription.plan.description.geeni.pr_1year_basic_5',
      defaultMessage: '{cancelIcon} Smart Detection',
    },
  ],
  pr_1month_plus: [
    {
      id: 'accountManagement.components.subscription.plan.description.geeni.pr_1month_plus_1',
      defaultMessage: '{cancelIcon} Watch & download activity',
    },
    {
      id: 'accountManagement.components.subscription.plan.description.geeni.pr_1month_plus_2',
      defaultMessage: '{cancelIcon} 14-day video history',
    },
    {
      id: 'accountManagement.components.subscription.plan.description.geeni.pr_1month_plus_3',
      defaultMessage: '{cancelIcon} Up to 2 Cameras',
    },
    {
      id: 'accountManagement.components.subscription.plan.description.geeni.pr_1month_plus_4',
      defaultMessage: '{cancelIcon} Snooze notifications',
    },
    {
      id: 'accountManagement.components.subscription.plan.description.geeni.pr_1month_plus_5',
      defaultMessage: '{cancelIcon} Smart Detection',
    },
  ],
  pr_1year_plus: [
    {
      id: 'accountManagement.components.subscription.plan.description.geeni.pr_1year_plus_1',
      defaultMessage: '{cancelIcon} Watch & download activity',
    },
    {
      id: 'accountManagement.components.subscription.plan.description.geeni.pr_1year_plus_2',
      defaultMessage: '{cancelIcon} 14-day video history',
    },
    {
      id: 'accountManagement.components.subscription.plan.description.geeni.pr_1year_plus_3',
      defaultMessage: '{cancelIcon} Up to 2 Cameras',
    },
    {
      id: 'accountManagement.components.subscription.plan.description.geeni.pr_1year_plus_4',
      defaultMessage: '{cancelIcon} Snooze notifications',
    },
    {
      id: 'accountManagement.components.subscription.plan.description.geeni.pr_1year_plus_5',
      defaultMessage: '{cancelIcon} Smart Detection',
    },
  ],
  pr_1month_premium: [
    {
      id: 'accountManagement.components.subscription.plan.description.geeni.pr_1month_premium_1',
      defaultMessage: '{cancelIcon} Watch & download activity',
    },
    {
      id: 'accountManagement.components.subscription.plan.description.geeni.pr_1month_premium_2',
      defaultMessage: '{cancelIcon} 30-day video history',
    },
    {
      id: 'accountManagement.components.subscription.plan.description.geeni.pr_1month_premium_3',
      defaultMessage: '{cancelIcon} Up to 10 Cameras',
    },
    {
      id: 'accountManagement.components.subscription.plan.description.geeni.pr_1month_premium_4',
      defaultMessage: '{cancelIcon} Snooze notifications',
    },
    {
      id: 'accountManagement.components.subscription.plan.description.geeni.pr_1month_premium_5',
      defaultMessage: '{cancelIcon} Smart Detection',
    },
  ],
  pr_1year_premium: [
    {
      id: 'accountManagement.components.subscription.plan.description.geeni.pr_1year_premium_1',
      defaultMessage: '{cancelIcon} Watch & download activity',
    },
    {
      id: 'accountManagement.components.subscription.plan.description.geeni.pr_1year_premium_2',
      defaultMessage: '{cancelIcon} 30-day video history',
    },
    {
      id: 'accountManagement.components.subscription.plan.description.geeni.pr_1year_premium_3',
      defaultMessage: '{cancelIcon} Up to 10 Cameras',
    },
    {
      id: 'accountManagement.components.subscription.plan.description.geeni.pr_1year_premium_4',
      defaultMessage: '{cancelIcon} Snooze notifications',
    },
    {
      id: 'accountManagement.components.subscription.plan.description.geeni.pr_1year_premium_5',
      defaultMessage: '{cancelIcon} Smart Detection',
    },
  ],
};

export default planFeatures;
