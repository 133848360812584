import React, { useState } from 'react';

// Material UI
import Container from '@material-ui/core/Container';
import Dialog from '@material-ui/core/Dialog';

// Controls
import PaymentDetailsActions from './PaymentDetailsActions';
import messages from '../BillingInformationView/messages';

//Redux
import { useDispatch } from 'react-redux';

import { loadingCountry, setThreeDToken, storeBillingInfo, verifyBillingInfo } from '../BillingInformationView/actions';
import history from '../../history';
import BillingInformationView from '../BillingInformationView';
import { IBillingFormError, IBillingUserInfo } from './types';
import { injectIntl } from 'react-intl';

import { getRecurlyPublicKey, getRecurlyUrl } from '../../env/config_util';

const $script = require('scriptjs');

interface Props {
  intl: any;
  lastPath: string;
  brand: string;
  userInfo: IBillingUserInfo;
  handleInputChangeCountry: Function;
  handleInputChange: Function;
  updateBillingInfo: Function;
  updateErrorMapping: Function;
}

declare var recurly: any;

const PaymentDetailsRecurly = ({
  intl,
  lastPath,
  brand,
  userInfo,
  handleInputChangeCountry,
  handleInputChange,
  updateBillingInfo,
  updateErrorMapping,
}: Props) => {
  const [formErrors, setFormErrors] = useState<IBillingFormError>({
    first_name: '',
    last_name: '',
    address1: '',
    city: '',
    state: '',
    postal_code: '',
    number: '',
    year: '',
    month: '',
    cvv: '',
  });

  const [recurlyErrors, setRecurlyErrors] = useState<IBillingFormError>({
    first_name: '',
    last_name: '',
    address1: '',
    city: '',
    state: '',
    postal_code: '',
    number: '',
    year: '',
    month: '',
    cvv: '',
  });
  const [recurlyEvent, setRecurlyEvent] = useState<any>({});
  const [showModel, setShowModel] = useState<boolean>(false);

  const dispatch = useDispatch();

  const submitDisabled = false;

  const loadRecurly = () => {
    const { formatMessage } = intl;
    $script(getRecurlyUrl(brand), () => {
      recurly.configure({
        publicKey: getRecurlyPublicKey(brand),
        fields: {
          all: {
            style: {
              fontColor: brand === 'nightowl' ? 'white' : 'black',
              placeholder: {
                color: '#9e9e9e',
              },
              fontSize: '1rem',
            },
          },
          month: {
            style: {
              placeholder: {
                content: `${formatMessage(messages.monthPlaceHolder)}`,
              },
            },
          },
          year: {
            style: {
              placeholder: {
                content: `${formatMessage(messages.yearPlaceHolder)}`,
              },
            },
          },
          cvv: {
            style: {
              placeholder: {
                content: `${formatMessage(messages.cvvPlaceHolder)}`,
              },
            },
          },
        },
      });
      recurly.on('change', handleRecurlyChange);
    });
  };

  // eslint-disable-next-line
  React.useEffect(loadRecurly, []);

  const handleRecurlyChange = (event: any) => {
    handleRecurlyEvent(event);
  };

  const handleRecurlySubmit = (key: string, callback: any) => {
    let isValid = validateForm();
    let userInfoClone = { ...userInfo };

    if (isValid) {
      recurly.token(userInfoClone, (err: any, token: any) => {
        if (err) {
          handleRecurlyError(err);
          if (callback) {
            callback(err);
          }
          return;
        }

        userInfoClone.token = token.id;
        userInfoClone.cardNumberLast4 = recurlyEvent.fields.number.lastFour;

        updateBillingInfo(userInfoClone);

        const errorHandler = (error: any, action: any) => {
          if (error) {
            loadRecurly();
          } // reload recurly hosted fields
          if (
            error &&
            error.response.data.description &&
            error.response.data.description === 'error.subscription.card.3d.security'
          ) {
            const { errorData } = error.response.data;
            setShowModel(true);
            loadRecurlyThreeDSecure(errorData, callback, action, userInfoClone);
          } else if (callback) {
            callback(error);
          }
        };

        if (key === 'paymentDetails') {
          saveBillingInfoInStore(userInfoClone, (error: any) => errorHandler(error, saveBillingInfoInStore));
        } else if (key === 'editPayment') {
          storeBillingInfoFunc(userInfoClone, (error: any) => errorHandler(error, storeBillingInfoFunc));
        }
      });
    }
  };

  const saveBillingInfoInStore = (state: any, callback: any) => {
    if (state.actionResultTokenId) {
      dispatch(setThreeDToken(state.actionResultTokenId));
    }
    dispatch(verifyBillingInfo(state, callback));
    dispatch(loadingCountry());
  };

  const storeBillingInfoFunc = (state: any, callback: any) => {
    dispatch(storeBillingInfo(state, callback));
    dispatch(loadingCountry());
  };

  const loadRecurlyThreeDSecure = (actionTokenId: any, callback: any, retryFn: any, state: any) => {
    const risk = recurly.Risk();
    const threeDSecure = risk.ThreeDSecure({ actionTokenId });

    threeDSecure.on('error', (err: any) => {
      setShowModel(false);
      callback(err);
    });
    threeDSecure.on('token', (token: any) => {
      setShowModel(false);
      let newState = { ...state };
      newState.actionResultTokenId = token.id;
      retryFn({ ...newState }, (err: any) => {
        callback(err);
      });
    });

    threeDSecure.attach(document.querySelector('#fingerprint-container'));
  };

  const handleRecurlyError = (err: any) => {
    let errorFormClone: any = { ...formErrors };
    let errorRecurlyClone: any = { ...recurlyErrors };

    for (let field of err.fields) {
      errorFormClone[field] = 'is-invalid';
      errorRecurlyClone[field] = err.message;
    }

    setFormErrors(errorFormClone);
    setRecurlyErrors(errorRecurlyClone);
  };

  const handleSubmit = () => {
    updateErrorMapping(null);

    if (lastPath === '/payment') {
      handleRecurlySubmit('editPayment', (err: any) => {
        if (err) {
          let errorMapping = 'error.subscription.card.declined';
          if (err.response && err.response.data && err.response.data.error) {
            errorMapping = err.response.data.error;
          }
          updateErrorMapping(errorMapping);
          return;
        }
        history.push('/payment');
      });
    } else {
      handleRecurlySubmit('paymentDetails', (err: any) => {
        if (err) {
          let errorMapping = 'error.subscription.card.declined';
          if (err.response && err.response.data && err.response.data.error) {
            errorMapping = err.response.data.error;
          }
          updateErrorMapping(errorMapping);
          return;
        }
        history.push('/order-summary');
      });
    }
  };

  const validateForm = (): boolean => {
    let errorClone: any = { ...formErrors };

    for (const key of Object.keys(userInfo)) {
      if (!userInfo[key] || userInfo[key] === '') {
        if (key in errorClone) {
          errorClone[key] = 'in-valid';
        }
      } else if (key === 'state') {
        errorClone[key] = userInfo[key] !== 'select' ? '' : 'is-invalid';
      } else if (key === 'postal_code') {
        errorClone[key] = userInfo[key].length >= 1 ? '' : 'is-invalid';
      } else {
        if (key in errorClone) {
          errorClone[key] = '';
        }
      }
    }

    for (let field in recurlyEvent.fields) {
      let isValid = recurlyEvent.fields[field].valid;
      errorClone[field] = isValid ? '' : 'is-invalid';
    }

    setFormErrors(errorClone);

    return isFormValid(errorClone);
  };

  const handleRecurlyEvent = (e: any) => {
    setRecurlyEvent(e);
  };

  const isFormValid = (errorObject: any): boolean => {
    for (const key of Object.keys(errorObject)) {
      if (errorObject[key] !== '') {
        return false;
      }
    }
    return true;
  };

  return (
    <>
      <BillingInformationView
        handleInputChange={handleInputChange}
        handleInputChangeCountry={handleInputChangeCountry}
        userInfo={userInfo}
        formErrors={formErrors}
        handleRecurlyEvent={handleRecurlyEvent}
        showPromoSection={lastPath !== '/payment'}
      ></BillingInformationView>
      <PaymentDetailsActions
        brand={brand}
        lastPath={lastPath}
        handleSubmit={handleSubmit}
        submitDisabled={submitDisabled}
      ></PaymentDetailsActions>

      <Dialog open={showModel}>
        <Container maxWidth="sm" style={{ display: 'flex' }}>
          <div id="fingerprint-container" style={{ minHeight: 400, padding: 15 }}></div>
        </Container>
      </Dialog>
    </>
  );
};

export default injectIntl(PaymentDetailsRecurly);
