import '../../themes/common/unifiedCheckout.scss';

import { Button, Container, Paper, Typography, makeStyles } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import classNames from 'classnames';
import _ from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { NativeDispatch } from '../../utils/webViewEvents/nativeDispatch';
import { getBrandNameSelector } from '../BrandProvider/selectors';
import { getAccountIdSelector } from '../LoginPage/selectors';
import { getPlansAttempt } from '../PlanListPage/actions';
import { getPlansListSelector } from '../PlanListPage/selectors';
import ApplePayButton from './ApplePayButton';
import PaymentDetails from './PaymentDetails';
import PaymentMethodSelector from './PaymentMethodSelector';
import {
  unifiedCheckoutGetPaymentMethodsAttempt,
  unifiedCheckoutInitializeProviderAttempt,
  unifiedCheckoutListAccountSubscriptionsAttempt,
  unifiedCheckoutSubscriptionConfirmAttempt,
  unifiedCheckoutSubscriptionPreviewAttempt,
} from './actions';
import messages from './messages';
import {
  createUnifiedCheckoutTrialEligibilitySelector,
  getUnifiedCheckoutActiveSubscriptionSelector,
  getUnifiedCheckoutLoadingSelector,
  getUnifiedCheckoutPaymentMethodsSelector,
  getUnifiedCheckoutSubscriptionDeviceIdsSelector,
  getUnifiedCheckoutSubscriptionPreviewSelector,
} from './selectors';
import { PaymentErrorType, PaymentMethod } from './types';
import { useApplePayCheckout } from './applePayCheckout';
import { useGooglePayCheckout } from './googlePayCheckout';

const PLAN_ID_QUERY_PARAM = 'planId';

const useStyles = makeStyles(theme => ({
  header: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
  },
  section: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    borderBottom: `1px solid ${theme.palette.grey[300]}`,
  },
  main: {
    padding: 0,
  },
}));

const UnifiedCheckout = () => {
  const styles = useStyles();
  const location = useLocation();
  const dispatch = useDispatch();
  const intl = useIntl();
  const isLoading = useSelector(getUnifiedCheckoutLoadingSelector);
  const accountId = useSelector(getAccountIdSelector);
  const plans = useSelector(getPlansListSelector);
  const brand = useSelector(getBrandNameSelector);
  const subPreview = useSelector(getUnifiedCheckoutSubscriptionPreviewSelector);
  const paymentMethods = useSelector(getUnifiedCheckoutPaymentMethodsSelector);
  const subscription = useSelector(getUnifiedCheckoutActiveSubscriptionSelector);
  const allSubscriptionDevices = useSelector(getUnifiedCheckoutSubscriptionDeviceIdsSelector);

  const selectedPlanId = useMemo(() => {
    return new URLSearchParams(location.search).get(PLAN_ID_QUERY_PARAM) ?? undefined;
  }, [location.search]);
  const isTrialEligible = useSelector(
    useMemo(() => createUnifiedCheckoutTrialEligibilitySelector(selectedPlanId), [selectedPlanId]),
  );

  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<PaymentMethod | undefined>();

  // Initializes the selected payment method
  useEffect(() => {
    if (!selectedPaymentMethod && paymentMethods[0]) {
      setSelectedPaymentMethod(paymentMethods[0]);
    } else if (!paymentMethods.some(i => _.isEqual(i, selectedPaymentMethod))) {
      setSelectedPaymentMethod(paymentMethods[0]);
    }
  }, [paymentMethods, selectedPaymentMethod]);

  // Gets everything required for the page on page load.
  useEffect(() => {
    if (!brand || !accountId) return; // not ready
    dispatch(getPlansAttempt(brand));
    dispatch(unifiedCheckoutGetPaymentMethodsAttempt(brand));
    dispatch(unifiedCheckoutInitializeProviderAttempt(brand));
    dispatch(unifiedCheckoutListAccountSubscriptionsAttempt(accountId));
  }, [dispatch, brand, accountId]);

  /** The currently selected plan details. */
  const selectedPlan = useMemo(() => {
    return plans.find(plan => plan.id === selectedPlanId);
  }, [plans, selectedPlanId]);

  const subscriptionDevices = useMemo<string[]>(() => {
    if (!selectedPlan?.device_quantity) return [];
    return allSubscriptionDevices.slice(0, selectedPlan.device_quantity);
  }, [allSubscriptionDevices, selectedPlan?.device_quantity]);

  /** Information about what the selected plan costs, from the pepper backend (_not_ the authorative source on what the user will pay). */
  const selectedPlanCost = selectedPlan?.cost[0];

  /**
   * Called when the user completes payment.
   * @param token The payment token from recurly.
   */
  const onPaymentComplete = useCallback(
    (token: string) => {
      if (!selectedPlan?.id || !selectedPlan?.external_plan_code)
        throw new Error('No selected plan after user just paid. Something is very wrong.');
      if (!subPreview?.currency) throw new Error('No selected currency after user just paid. Something is very wrong.');
      dispatch(
        unifiedCheckoutSubscriptionConfirmAttempt(
          selectedPlan.id,
          selectedPlan.external_plan_code,
          subPreview.currency,
          token,
          subscription?.id,
          subscription?.external_subscription_id,
          subscriptionDevices,
        ),
      );
    },
    [
      selectedPlan?.id,
      selectedPlan?.external_plan_code,
      subPreview?.currency,
      dispatch,
      subscription?.id,
      subscription?.external_subscription_id,
      subscriptionDevices,
    ],
  );

  // We pass undefined for the preview if the Mobile payment method is not selected in order to prevent activating the mobile payment method.
  const { isApplePayReady, onApplePayCheckout, applePayError } = useApplePayCheckout(
    selectedPlan,
    selectedPaymentMethod?.type === 'applePay' ? subPreview : undefined,
    onPaymentComplete,
  );
  // Handle errors from Recurly Apple Pay
  useEffect(() => {
    if (applePayError) {
      console.log('Apple Pay error: %o', applePayError);
      NativeDispatch.dispatch({
        type: 'SHOW_ALERT',
        payload: {
          subtype: applePayError.type,
          errorMessage: applePayError.message ? intl.formatMessage(applePayError.message) : '',
        },
      });
    }
  }, [applePayError, intl]);

  /** Google Pay renders a more dynamic button, so we let it handle the rendering and activation itself */
  const googleButtonRef = React.useRef<HTMLDivElement | null>(null);
  const { isGooglePayReady, googlePayError } = useGooglePayCheckout(
    brand,
    selectedPlan,
    selectedPaymentMethod?.type === 'googlePay' ? subPreview : undefined,
    onPaymentComplete,
    googleButtonRef.current,
  );
  // Handle errors from Recurly Google Pay
  useEffect(() => {
    if (googlePayError) {
      console.log('Google Pay error: %o', googlePayError);
      NativeDispatch.dispatch({
        type: 'SHOW_ALERT',
        payload: {
          subtype: googlePayError.type,
          errorMessage: googlePayError.message ? intl.formatMessage(googlePayError.message) : '',
        },
      });
    }
  }, [googlePayError, intl]);

  // When the selected plan changes, update the selected plan in the store and re-fetch the subscription preview.
  useEffect(() => {
    if (selectedPlanCost?.currency && selectedPlan?.external_plan_code) {
      dispatch(unifiedCheckoutSubscriptionPreviewAttempt(selectedPlan.external_plan_code, selectedPlanCost.currency));
    }
  }, [dispatch, selectedPlan?.external_plan_code, selectedPlanCost?.currency]);

  /** The formatted price of the selected plan. */
  const planPrice = useMemo(() => {
    if (!selectedPlan) {
      return '';
    }

    const costInDollars = selectedPlan?.cost[0]?.amount / 100;

    return Intl.NumberFormat(window.navigator.language, {
      style: 'currency',
      currency: selectedPlan?.cost[0]?.currency,
    }).format(costInDollars);
  }, [selectedPlan]);

  /** The localized period of the selected plan. */
  const planPeriod = useMemo(() => {
    if (!selectedPlan) {
      return '';
    }

    const period = (() => {
      switch (selectedPlan?.billing_cycle) {
        case 1:
          return 'month';
        case 12:
          return 'year';
        default:
          console.warn(`Unexpected billing cycle: ${selectedPlan?.billing_cycle}`);
          return '';
      }
    })();

    return intl.formatMessage(
      { id: `accountManagement.unifiedCheckout.${period}`, defaultMessage: period },
      { count: 1 },
    );
  }, [selectedPlan, intl]);

  /** Finalizes the checkout flow for the default checkout button (not used by mobile pay). */
  const confirmCheckout = useCallback(async () => {
    try {
      if (!selectedPlan || !subPreview) {
        throw new Error('Invalid state, cannot initiate checkout!');
      }

      switch (selectedPaymentMethod?.type) {
        case 'creditCard':
          console.log('TODO: PLAY-15305');
          break;
        default:
          throw new Error(`Unhandled payment method: ${selectedPaymentMethod?.type}`);
      }
    } catch (e) {
      console.error('Checkout failed:', e);
      NativeDispatch.dispatch({ type: 'SHOW_ALERT', payload: { subtype: PaymentErrorType.CHECKOUT_FAILURE } });
    }
  }, [selectedPaymentMethod?.type, selectedPlan, subPreview]);

  return (
    <div>
      {isLoading ? (
        <div className="progress-wrapper-center">
          <CircularProgress size={55} className={`${brand}-button-progress`} />
        </div>
      ) : (
        <>
          <Container maxWidth="md" className={styles.main}>
            <div className={styles.section}>
              <Typography variant="h2" className={styles.header}>
                <FormattedMessage {...messages.checkoutSummary} />
              </Typography>
            </div>
            {/* Plan details */}
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
              className={styles.section}>
              <div>
                <Typography style={{ fontWeight: 'bold' }}>
                  <FormattedMessage {...messages.plan} values={{ plan: selectedPlan?.name }} />
                </Typography>
                <Typography>
                  <FormattedMessage {...messages.price} values={{ price: planPrice, period: planPeriod }} />
                </Typography>
                {selectedPlan?.trial && subPreview?.trial && (
                  <>
                    <Typography>
                      <FormattedMessage
                        {...messages.trialInfo}
                        values={{
                          trialDuration: selectedPlan?.trial?.duration,
                          trialUnit: intl.formatMessage(messages[selectedPlan?.trial?.unit], {
                            count: selectedPlan.trial.duration,
                          }),
                          billingDuration: selectedPlan.billing_cycle,
                          billingUnit: intl.formatMessage(messages.month, { count: selectedPlan.billing_cycle }),
                        }}
                      />
                    </Typography>
                    <Typography>
                      <FormattedMessage
                        {...messages.trialEnd}
                        values={{ trialEndDate: new Date(subPreview.trial.trialEndDate) }}
                      />
                    </Typography>
                  </>
                )}
              </div>
              {/* <div> 
                <Button TODO: Users cannot change plan in current iteration.
                  id="change-plan-btn"
                  disabled={true} 
                  variant="text">
                  <FormattedMessage {...messages.change} />
                </Button>
              </div> */}
            </div>
            {/* Payment method */}
            <div className={styles.section}>
              <Typography variant="h3" className={styles.header}>
                <FormattedMessage {...messages.paymentMethod} />
              </Typography>
            </div>
            <div className={styles.section}>
              <PaymentMethodSelector selectedPaymentMethod={selectedPaymentMethod} />
            </div>
            {/* Payment details */}
            <div className={styles.section}>
              <Typography variant="h3" className={styles.header}>
                <FormattedMessage {...messages.paymentDetails} />
              </Typography>
              <PaymentDetails
                planName={selectedPlan?.name}
                subTotal={isTrialEligible ? 0 : subPreview?.subTotal}
                currency={subPreview?.currency}
                planTax={isTrialEligible ? 0 : subPreview?.tax}
                total={isTrialEligible ? 0 : subPreview?.total}
              />
            </div>
          </Container>
          <Paper variant="outlined" className="checkout-action-footer" style={{ width: '100%' }}>
            <div className="checkout-action-footer-action-buttons">
              {selectedPaymentMethod?.type === 'applePay' ? (
                <ApplePayButton onClick={onApplePayCheckout} disabled={!isApplePayReady} />
              ) : selectedPaymentMethod?.type === 'googlePay' ? (
                <div ref={googleButtonRef} hidden={!isGooglePayReady}></div>
              ) : (
                <Button
                  className={classNames(`${brand}-btn`, `${brand}-btn-action-disabled`)}
                  style={{
                    textTransform: 'none',
                    fontWeight: 'bolder',
                  }}
                  disabled={true}
                  id="submit-btn"
                  onClick={confirmCheckout}>
                  <FormattedMessage {...messages.pay} />
                </Button>
              )}
            </div>
          </Paper>
        </>
      )}
    </div>
  );
};

export default UnifiedCheckout;
