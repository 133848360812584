import { select } from 'redux-saga/effects';

export function SelectState<T>(selector: (s: any) => T): any {
  return select(selector);
}

export interface TypedIterableIterator<T, N = any> {
  next(value: N): T;
}

export interface Dictionary<T> {
  [Key: string]: T;
}

/**
 * Async Sleep
 */
export function sleep(seconds = 1) {
  return new Promise(resolve => setTimeout(resolve, seconds * 1000));
}

/**
 * Get unix timestamp in seconds
 */
export function getUnixtime(): number {
  return Math.floor(Date.now() / 1000);
}

/**
 * Check if cache is valid
 */
export function hasValidCache(lastUpdated: number, max = 10): boolean {
  if (!navigator.onLine) {
    return true;
  }

  return lastUpdated + max * 60 > getUnixtime();
}
