import React from 'react';

import './ApplePayButton.scss';
import classNames from 'classnames';

interface ApplePayButtonProps {
  /**
   * The function that opens the Apple Pay dialog.
   * @returns {void}
   */
  onClick: () => void;

  /**
   * Whether the button should be disabled. This will prevent the onClick from being called.
   */
  disabled?: boolean;
}

const ApplePayButton: React.FC<ApplePayButtonProps> = ({ onClick, disabled }) => {
  return (
    <div
      className={classNames('apple-pay-button-with-text', 'apple-pay-button-black-with-text', disabled && 'disabled')}
      onClick={onClick}>
      <span className="text">Subscribe with</span>
      <span className="logo"></span>
    </div>
  );
};

export default ApplePayButton;
