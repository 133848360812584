//redux
import { useDispatch, useSelector } from 'react-redux';
import { clearManageDevicesState } from '../ManageDevices/actions';
import { getBrandNameSelector } from '../BrandProvider/selectors';

//models and components
import PlanSubscriptionSuccessModal from '../PlanSubscriptionSuccessModal';
import history from '../../history';

//global style
import '../../themes/common/orderSummary.scss';
import { getAttemptingInvoiceDetailsSelector } from './selectors';
import CircularProgress from '@material-ui/core/CircularProgress';
import React, { useEffect } from 'react';
import { invoiceDetailsAttempt, invoiceDetailsClearState } from './actions';

const CheckoutSuccess = () => {
  const brand = useSelector(getBrandNameSelector);
  const loading = useSelector(getAttemptingInvoiceDetailsSelector);
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchInvoiceDetailsPreview = () => {
      dispatch(invoiceDetailsClearState());
      dispatch(invoiceDetailsAttempt());
    };
    fetchInvoiceDetailsPreview();
  }, [dispatch]);

  const handleModelClose = () => {
    dispatch(clearManageDevicesState());

    history.push('/subscriptions');
  };

  return (
    <>
      <div>
        {loading ? (
          <div className="progress-wrapper-center">
            <CircularProgress size={55} className={`${brand}-button-progress`} />
          </div>
        ) : (
          <PlanSubscriptionSuccessModal
            open={true}
            handleClose={handleModelClose}
            brand={brand}></PlanSubscriptionSuccessModal>
        )}
      </div>
    </>
  );
};

export default CheckoutSuccess;
