const enFormats = {
  number: {
    LOCALE_CURRENCY: {
      style: 'currency',
      currency: 'USD',
    },
  },
  date: {
    LOCALE_DATE: {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    },
  },
};

export { enFormats };
