import {
  UNIFIED_CHECKOUT_GET_PAYMENT_METHODS_ATTEMPT,
  UNIFIED_CHECKOUT_GET_PAYMENT_METHODS_FAILURE,
  UNIFIED_CHECKOUT_GET_PAYMENT_METHODS_SUCCESS,
  UNIFIED_CHECKOUT_INITIALIZE_PROVIDER_ATTEMPT,
  UNIFIED_CHECKOUT_INITIALIZE_PROVIDER_FAILURE,
  UNIFIED_CHECKOUT_INITIALIZE_PROVIDER_SUCCESS,
  UNIFIED_CHECKOUT_SUBSCRIPTION_CONFIRM_ATTEMPT,
  UNIFIED_CHECKOUT_SUBSCRIPTION_CONFIRM_SUCCESS,
  UNIFIED_CHECKOUT_SUBSCRIPTION_CONFIRM_FAILURE,
  UNIFIED_CHECKOUT_SUBSCRIPTION_PREVIEW_ATTEMPT,
  UNIFIED_CHECKOUT_SUBSCRIPTION_PREVIEW_FAILURE,
  UNIFIED_CHECKOUT_SUBSCRIPTION_PREVIEW_SUCCESS,
  UNIFIED_CHECKOUT_PAYMENT_METHOD_FAILURE,
  UNIFIED_CHECKOUT_LIST_ACCOUNT_SUBSCRIPTIONS_ATTEMPT,
  UNIFIED_CHECKOUT_LIST_ACCOUNT_SUBSCRIPTIONS_FAILURE,
  UNIFIED_CHECKOUT_LIST_ACCOUNT_SUBSCRIPTIONS_SUCCESS,
} from './actionTypes';
import { PaymentMethod, Subscription, UnifiedCheckoutSubscriptionPreview } from './types';

export const unifiedCheckoutInitializeProviderAttempt = (brand: string) =>
  ({
    type: UNIFIED_CHECKOUT_INITIALIZE_PROVIDER_ATTEMPT,
    payload: {
      brand,
    },
  } as const);

export const unifiedCheckoutInitializeProviderSuccess = () =>
  ({
    type: UNIFIED_CHECKOUT_INITIALIZE_PROVIDER_SUCCESS,
  } as const);

export const unifiedCheckoutInitializeProviderFailure = () =>
  ({
    type: UNIFIED_CHECKOUT_INITIALIZE_PROVIDER_FAILURE,
  } as const);

export const unifiedCheckoutGetPaymentMethodsAttempt = (brand: string) =>
  ({
    type: UNIFIED_CHECKOUT_GET_PAYMENT_METHODS_ATTEMPT,
    payload: {
      brand,
    },
  } as const);

export const unifiedCheckoutGetPaymentMethodsSuccess = (paymentMethods: PaymentMethod[]) =>
  ({
    type: UNIFIED_CHECKOUT_GET_PAYMENT_METHODS_SUCCESS,
    payload: {
      paymentMethods,
    },
  } as const);

export const unifiedCheckoutGetPaymentMethodsFailure = () =>
  ({
    type: UNIFIED_CHECKOUT_GET_PAYMENT_METHODS_FAILURE,
  } as const);

export const unifiedCheckoutListAccountSubscriptionsAttempt = (accountId: string) =>
  ({
    type: UNIFIED_CHECKOUT_LIST_ACCOUNT_SUBSCRIPTIONS_ATTEMPT,
    payload: {
      accountId,
    },
  } as const);

export const unifiedCheckoutListAccountSubscriptionsSuccess = (
  subscriptions: Subscription[],
  trialPlanCodes: string[],
) =>
  ({
    type: UNIFIED_CHECKOUT_LIST_ACCOUNT_SUBSCRIPTIONS_SUCCESS,
    payload: { subscriptions, trialPlanCodes },
  } as const);

export const unifiedCheckoutListAccountSubscriptionsFailure = () =>
  ({
    type: UNIFIED_CHECKOUT_LIST_ACCOUNT_SUBSCRIPTIONS_FAILURE,
  } as const);

export const unifiedCheckoutSubscriptionPreviewAttempt = (externalPlanCode: string, currency: string) =>
  ({
    type: UNIFIED_CHECKOUT_SUBSCRIPTION_PREVIEW_ATTEMPT,
    payload: {
      externalPlanCode,
      currency,
    },
  } as const);

export const unifiedCheckoutSubscriptionPreviewSuccess = (preview: UnifiedCheckoutSubscriptionPreview) =>
  ({
    type: UNIFIED_CHECKOUT_SUBSCRIPTION_PREVIEW_SUCCESS,
    payload: {
      subscriptionPreview: preview,
    },
  } as const);

export const unifiedCheckoutSubscriptionPreviewFailure = () =>
  ({
    type: UNIFIED_CHECKOUT_SUBSCRIPTION_PREVIEW_FAILURE,
  } as const);

export const unifiedCheckoutSubscriptionConfirmAttempt = (
  planId: string,
  externalPlanCode: string,
  currency: string,
  token: string,
  subscriptionId: string | undefined,
  externalSubscriptionId: string | undefined,
  deviceIds: string[],
) =>
  ({
    type: UNIFIED_CHECKOUT_SUBSCRIPTION_CONFIRM_ATTEMPT,
    payload: {
      planId,
      externalPlanCode,
      currency,
      token,
      subscriptionId,
      externalSubscriptionId,
      deviceIds,
    },
  } as const);

export const unifiedCheckoutSubscriptionConfirmSuccess = () =>
  ({
    type: UNIFIED_CHECKOUT_SUBSCRIPTION_CONFIRM_SUCCESS,
    payload: {},
  } as const);

export const unifiedCheckoutSubscriptionConfirmFailure = () =>
  ({
    type: UNIFIED_CHECKOUT_SUBSCRIPTION_CONFIRM_FAILURE,
  } as const);

export const unifiedCheckoutPaymentMethodFailure = (method: PaymentMethod) =>
  ({
    type: UNIFIED_CHECKOUT_PAYMENT_METHOD_FAILURE,
    payload: {
      paymentMethod: method,
    },
  } as const);

export type UnifiedCheckoutActionTypes =
  | ReturnType<typeof unifiedCheckoutInitializeProviderAttempt>
  | ReturnType<typeof unifiedCheckoutInitializeProviderSuccess>
  | ReturnType<typeof unifiedCheckoutInitializeProviderFailure>
  | ReturnType<typeof unifiedCheckoutSubscriptionPreviewAttempt>
  | ReturnType<typeof unifiedCheckoutSubscriptionPreviewSuccess>
  | ReturnType<typeof unifiedCheckoutSubscriptionPreviewFailure>
  | ReturnType<typeof unifiedCheckoutGetPaymentMethodsAttempt>
  | ReturnType<typeof unifiedCheckoutGetPaymentMethodsSuccess>
  | ReturnType<typeof unifiedCheckoutGetPaymentMethodsFailure>
  | ReturnType<typeof unifiedCheckoutListAccountSubscriptionsAttempt>
  | ReturnType<typeof unifiedCheckoutListAccountSubscriptionsSuccess>
  | ReturnType<typeof unifiedCheckoutListAccountSubscriptionsFailure>
  | ReturnType<typeof unifiedCheckoutSubscriptionConfirmAttempt>
  | ReturnType<typeof unifiedCheckoutSubscriptionConfirmSuccess>
  | ReturnType<typeof unifiedCheckoutSubscriptionConfirmFailure>
  | ReturnType<typeof unifiedCheckoutPaymentMethodFailure>;
