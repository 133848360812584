import { Device, DeviceModel } from 'pepper-js-sdk/types';

// Device Images
import DeviceImage from '../themes/momentum/images/momentum-device.svg';
import AriaDeviceImage from '../themes/momentum/images/Aria.png';
import AxelDeviceImage from '../themes/momentum/images/axel.png';
import NiroDeviceImage from '../themes/momentum/images/Niro.png';
import CoriDeviceImage from '../themes/momentum/images/Cori.png';
import KnokDeviceImage from '../themes/momentum/images/Momentum_Knok_Camera.png';
import RobbiDeviceImage from '../themes/momentum/images/Momentum_Robbi_Camera.png';
import ViewCameraDeviceImage from '../themes/otis/images/View_Camera.png';
import VisiCamera from '../themes/visi/images/leedarson-a215.svg';

export const getDeviceImage = (device: Device, deviceModels?: DeviceModel[]) => {
  const deviceModel = device.model || '';
  if (deviceModels) {
    let found = deviceModels?.find(x => x.model[0] === deviceModel);
    return found?.icon || DeviceImage;
  }

  if (['MOCAM-720-01', 'MOCAM-720-02'].indexOf(deviceModel) !== -1) {
    return AxelDeviceImage;
  } else if (['MO-SE-01', 'MO-SE-SAC01'].indexOf(deviceModel) !== -1) {
    return AriaDeviceImage;
  } else if (['MOGA-001'].indexOf(deviceModel) !== -1) {
    return NiroDeviceImage;
  } else if (['MOCAM-1080-01', 'ROBBI'].indexOf(deviceModel) !== -1) {
    return RobbiDeviceImage;
  } else if (['MOCAM-SIG02'].indexOf(deviceModel) !== -1) {
    return CoriDeviceImage;
  } else if (['MOBELL-1080NB01'].indexOf(deviceModel) !== -1) {
    return KnokDeviceImage;
  } else if (['SPRCAM'].indexOf(deviceModel) !== -1) {
    return ViewCameraDeviceImage;
  } else if (['PEC-33'].indexOf(deviceModel) !== -1) {
    return VisiCamera;
  } else {
    return DeviceImage;
  }
};
