import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import history from '../../history';
import { FormattedMessage } from 'react-intl';
import { getBrandNameSelector } from '../../containers/BrandProvider/selectors';
import { useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';

function TabPanel(props: any) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box boxShadow={0} p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index: any) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
}));

const TabContainer = (props: any) => {
  const classes = useStyles();
  const brand = useSelector(getBrandNameSelector);
  const route = '/' + history.location.pathname.split('/')[1];
  const isMobile = useMediaQuery({ query: `(max-width: 600px)` });

  const handleChange = (event: any, newValue: any) => {
    history.push(newValue);
  };

  return (
    <div className={classes.root}>
      <AppBar className={`${brand}-app-bar`} position="static" elevation={0}>
        <Tabs
          value={route}
          onChange={handleChange}
          variant="fullWidth"
          aria-label="full width tabs example"
          className={`${brand}-tabs`}
          TabIndicatorProps={{
            style: {
              backgroundColor: '#fff',
              paddingTop: 3,
            },
          }}
        >
          <Tab
            className={`${brand}-tab`}
            label={<FormattedMessage id={'common_first_tab'} />}
            value="/subscriptions"
            {...a11yProps(0)}
            id="sub-tab"
          />
          <Tab
            className={`${brand}-tab`}
            value="/payment"
            label={<FormattedMessage id={'common_second_tab'} />}
            {...a11yProps(1)}
            id="payment-tab"
          />
          <Tab
            className={`${brand}-tab`}
            value="/account-history"
            label={
              isMobile ? (
                <FormattedMessage id={'common_third_tab_mobile'} />
              ) : (
                <FormattedMessage id={'common_third_tab'} />
              )
            }
            {...a11yProps(2)}
            id="acct-history-tab"
          />
        </Tabs>
      </AppBar>
    </div>
  );
};

export default TabContainer;
