export const APP_URI_FAILURE = 'APP_URI_FAILURE';
export const APP_URI_SUCCESS = 'APP_URI_SUCCESS';
export const FETCH_APP_URI = 'FETCH_APP_URI';
export const INVALID_TOKEN = 'INVALID_TOKEN';
export const LINK_ALREADY_USED = 'LINK_ALREADY_USED';
export const PASSWORDS_TOO_SIMILAR = 'PASSWORDS_TOO_SIMILAR';
export const RESET_PASSWORD_ATTEMPT = 'RESET_PASSWORD_ATTEMPT';
export const RESET_PASSWORD_FAILURE = 'RESET_PASSWORD_FAILURE';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const CLEAR_STATE = 'CLEAR_STATE';
