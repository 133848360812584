import { createSelector } from 'reselect';

import { AppState } from '../../store/rootReducer';

const getPromoCode = (state: AppState) => state.promoCode.code;
const getIsValidating = (state: AppState) => state.promoCode.attempting;
const getPromoCodeValid = (state: AppState) => state.promoCode.valid;
const getPromoCodeValidationRes = (state: AppState) => state.promoCode.validationResponse;
const getPromoCodeErrorCode = (state: AppState) => state.promoCode.errorCode;
const getPromoCodesData = (state: AppState) => state.promoCode.codesData;
const getPromoCodes = (state: AppState) => state.promoCode.codes;

export const getPromoCodeSelector = createSelector(getPromoCode, code => code);

export const getPromoCodesSelector = createSelector(getPromoCodes, codes => codes);

export const getPromoCodesStatusSelector = createSelector([getPromoCodes, getPromoCodesData], (codes, codesData) =>
  codes.map(code => {
    return {
      ...codesData[code],
      promoCode: code,
    };
  }),
);

export const getIsValidatingSelector = createSelector(getIsValidating, attempting => attempting);

export const getPromoCodeValidSelector = createSelector(getPromoCodeValid, valid => valid);

export const getPromoCodeValidationResSelector = createSelector(getPromoCodeValidationRes, res => res);

export const getPromoCodeErrorCodeSelector = createSelector(getPromoCodeErrorCode, error => error);
