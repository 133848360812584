import React from 'react';
import { injectIntl } from 'react-intl';

interface Props {
  brand: string;
  message: any;
  className: string;
  intl: any;
}

const BrandMessage = (props: Props) => {
  return <span className={props.className}>{getBrandString(undefined, props)}</span>;
};

function getBrandString(message: any, props: Props) {
  const { formatMessage } = props.intl;
  const messageId = message && message.id ? message.id : props.message.id;
  const brandedMessageId = `${messageId}.${props.brand}`;

  let messageToFormat = props.intl.messages[brandedMessageId] ? { id: brandedMessageId } : props.message;

  if (!messageToFormat) {
    messageToFormat = message;
  }

  return formatMessage(messageToFormat);
}

function getBrandErrorString(message: any = 'generic_error', props: any) {
  const { formatMessage } = props.intl;
  const brandedMessageId = `${message}.${props.brand}`;

  let messageToFormat: any = '';

  if (props.intl.messages[brandedMessageId]) {
    messageToFormat = { id: brandedMessageId };
  } else if (props.intl.messages[message]) {
    messageToFormat = { id: message };
  } else {
    messageToFormat = { id: 'generic_error' };
  }
  return formatMessage(messageToFormat);
}

export default injectIntl(BrandMessage);
export { getBrandString, getBrandErrorString };
