import { AccountHistoryActionTypes } from './types';

import { GET_ACCT_HISTORY_ATTEMPT, GET_ACCT_HISTORY_SUCCESS, GET_ACCT_HISTORY_FAILURE } from './actionTypes';

export const getAcctHistoryAttempt = (): AccountHistoryActionTypes => ({
  type: GET_ACCT_HISTORY_ATTEMPT,
});

export const getAcctHistorySuccess = (acctHistory: any): AccountHistoryActionTypes => ({
  type: GET_ACCT_HISTORY_SUCCESS,
  accountHistoryList: acctHistory,
});

export const getAcctHistoryFailure = (err: any): AccountHistoryActionTypes => ({
  type: GET_ACCT_HISTORY_FAILURE,
  errorCode: err,
});
