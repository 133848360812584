import { createSelector } from 'reselect';

import { AppState } from '../../store/rootReducer';

const getAttemptingInvoiceDetails = (state: AppState) => state.checkoutSuccess.invoiceDetailsAttempting;

const getInvoiceDetailsSuccess = (state: AppState) => state.checkoutSuccess.invoiceDetailsSuccess;

const getInvoiceDetailsResponse = (state: AppState) => state.checkoutSuccess.invoiceDetailsResponse;

const getInvoiceDetailsFailure = (state: AppState) => state.checkoutSuccess.invoiceDetailsFailure;

const getInvoiceDetailsErrorCode = (state: AppState) => state.checkoutSuccess.invoiceDetailsError;

export const getAttemptingInvoiceDetailsSelector = createSelector(
  getAttemptingInvoiceDetails,
  attempting => attempting,
);

export const getInvoiceDetailsSuccessSelector = createSelector(getInvoiceDetailsSuccess, success => success);

export const getInvoiceDetailsResponseSelector = createSelector(getInvoiceDetailsResponse, res => res);

export const getInvoiceDetailsFailureSelector = createSelector(getInvoiceDetailsFailure, err => err);

export const getInvoiceDetailsErrorCodeSelector = createSelector(getInvoiceDetailsErrorCode, errCode => errCode);
