import React, { useState, useEffect, useCallback } from 'react';

//material ui
import CircularProgress from '@material-ui/core/CircularProgress';

//controls
import OrderActions from './OrderActions';
import OrderBody from './OrderBody';
import OrderHeader from './OrderHeader';
import LoadingModel from '../LoadingModel';

//third party libs
import { FormattedMessage } from 'react-intl';
import messages from './messages';

//redux
import { subscriptionPreviewAttempt, subscriptionClearState, clearSubscriptionPreviewState } from './actions';
import { useSelector, useDispatch } from 'react-redux';
import { clearManageDevicesState } from '../ManageDevices/actions';
import { getBrandNameSelector } from '../BrandProvider/selectors';
import { clearPromoCodeState } from '../PromoCode/actions';
import {
  getAttemptingSubmitSubscriptionSelector,
  getSubscriptionPreviewSelector,
  getSubscriptionSuccessSelector,
  getSubscriptionErrorCodeSelector,
  getSubscriptionFailureSelector,
  getSubscriptionPreviewErrorSelector,
} from './selectors';

//models and components
import PlanSubscriptionSuccessModal from '../PlanSubscriptionSuccessModal';
import history from '../../history';

//global style
import '../../themes/common/orderSummary.scss';
import { loadingBillingInfoView } from '../BillingInformationView/actions';

interface Props {}
declare global {
  interface Window {
    nsWebViewInterface?: any;
  }
}

const OrderSummary = (props: Props) => {
  const brand = useSelector(getBrandNameSelector);
  const loading = useSelector(getAttemptingSubmitSubscriptionSelector);
  const subPreview = useSelector(getSubscriptionPreviewSelector);
  const subSuccess = useSelector(getSubscriptionSuccessSelector);
  const subFailure = useSelector(getSubscriptionFailureSelector);
  const error = useSelector(getSubscriptionErrorCodeSelector);
  const previewError = useSelector(getSubscriptionPreviewErrorSelector);

  const [submitDisabled, setSubmitDisabled] = useState(true);
  const [subSuccessModel, setSubSuccessModel] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    const fetchSubscriptionPreview = () => {
      dispatch(subscriptionClearState());
      dispatch(subscriptionPreviewAttempt());
      dispatch(loadingBillingInfoView());
    };
    fetchSubscriptionPreview();
  }, [dispatch]);

  useEffect(() => {
    const subscriptionSuccess = () => {
      if (subSuccess) {
        setSubSuccessModel(true);
      }
    };
    subscriptionSuccess();
  }, [subSuccess]);

  useEffect(() => {
    const subscriptionFailure = () => {
      if (subFailure) {
        if (error) {
          const billingErrorCodes = [
            'error.subscription.card.declined',
            'error.subscription.card.expired',
            'error.subscription.card.type',
            'error.subscription.card.payment',
            'error.subscription.card.cvv.declined',
            'error.subscription.card.number.declined',
            'error.subscription.card.address.declined',
            'error.subscription.token.invalid',
          ];

          dispatch(clearPromoCodeState());

          if (error.message && billingErrorCodes.indexOf(error.message) !== -1) {
            dispatch(subscriptionClearState());
            history.push(`/billing?error_code=${error.message}`);
          } else {
            dispatch(subscriptionClearState());
            history.push('/error');
          }
        }

        const oWebViewInterface: any = window.nsWebViewInterface;
        // emit event to native app
        if (oWebViewInterface) {
          oWebViewInterface.emit('subscriptionsFlowError', error);
        }
      }
    };
    subscriptionFailure();
  }, [subFailure, error, dispatch, previewError]);

  useEffect(() => {
    const subscriptionPreviewFailure = () => {
      if (previewError) {
        dispatch(clearSubscriptionPreviewState());
        dispatch(subscriptionClearState());
        dispatch(clearManageDevicesState());
        if (previewError.message === 'error.subscription.preview.already_subscribed') {
          history.push('/error?error_code=already_subscribed');
        } else {
          history.push('/error');
        }
      }
    };
    subscriptionPreviewFailure();
  }, [dispatch, previewError]);

  const checkboxChangeHandle = useCallback((val: boolean) => {
    setSubmitDisabled(val);
  }, []);

  const handleModelClose = () => {
    setSubSuccessModel(false);
    dispatch(subscriptionClearState());
    dispatch(clearManageDevicesState());

    history.push('/subscriptions');
  };

  return (
    <>
      {!subPreview ? (
        <div className="progress-wrapper-center">
          <CircularProgress size={55} className={`${brand}-button-progress`} />
        </div>
      ) : (
        <div>
          <OrderHeader currency={subPreview.currency} brand={brand}></OrderHeader>
          <OrderBody brand={brand} checkboxChangeHandle={checkboxChangeHandle} subPreview={subPreview}></OrderBody>
          <OrderActions brand={brand} submitDisabled={submitDisabled}></OrderActions>
          <PlanSubscriptionSuccessModal
            open={subSuccessModel}
            handleClose={handleModelClose}
            brand={brand}
            tax={subPreview.tax}></PlanSubscriptionSuccessModal>
          <LoadingModel
            open={loading}
            brand={brand}
            title={<FormattedMessage {...messages.savingSubscription} />}
            subtitle={<FormattedMessage {...messages.savingSubscriptionStatement} />}></LoadingModel>
        </div>
      )}
    </>
  );
};

export default OrderSummary;
