/*
 * LoginPage Messages
 *
 * This contains all the text for the LoginPage component.
 */
import { defineMessages } from 'react-intl';
import {EMAIL_VERIFICATION_REQUEST_DISABLED_MINUTES} from '../../../constants/authentication';

export default defineMessages({
  code: {
    id: 'accountManagement.components.multiFactorAuthentication.fields.code.text',
    defaultMessage: '6-digit-code',
  },
  resending: {
    id: 'accountManagement.components.multiFactorAuthentication.message.resending.text',
    defaultMessage: 'Resending Code...',
  },
  verifying: {
    id: 'accountManagement.components.multiFactorAuthentication.message.verifying.text',
    defaultMessage: 'Verifying Code...',
  },
  resendCode: {
    id: 'accountManagement.components.multiFactorAuthentication.button.resendCode.text',
    defaultMessage: 'Resend verification code',
  },
  resendCodeSuccess: {
    id: 'accountManagement.components.multiFactorAuthentication.message.resendCode.success',
    defaultMessage: 'Verification code sent.',
  },
  resendCodeError: {
    id: 'accountManagement.components.multiFactorAuthentication.message.resendCode.error',
    defaultMessage: 'Sorry, there was an issue sending your verification code. Please try again later.',
  },
  verify: {
    id: 'accountManagement.components.multiFactorAuthentication.button.verify',
    defaultMessage: 'Verify',
  },
  okayButton: {
    id: 'accountManagement.components.multiFactorAuthentication.button.okay',
    defaultMessage: 'Okay',
  },
  defaultMessageTitle: {
    id: 'accountManagement.components.multiFactorAuthentication.default.title',
    defaultMessage: 'Verify your identity',
  },
  defaultMessageBody: {
    id: 'accountManagement.components.multiFactorAuthentication.default.text',
    defaultMessage: "This device isn't recognized. For your security, {brand} wants to make sure it's really you. Please enter the code we sent to {contact}",
  },
  errorInvalidLimitExceededTitle: {
    id: 'accountManagement.components.multiFactorAuthentication.error.invalidLimitExceeded.title',
    defaultMessage: 'You have requested too many codes',
  },
  errorInvalidLimitExceededBody: {
    id: 'accountManagement.components.multiFactorAuthentication.error.invalidLimitExceeded.text',
    defaultMessage: 'For security purposes, you will need to wait {EMAIL_VERIFICATION_REQUEST_DISABLED_MINUTES} {EMAIL_VERIFICATION_REQUEST_DISABLED_MINUTES, plural, one {minute} other {minutes}} before trying to sign in again.',
    values: {EMAIL_VERIFICATION_REQUEST_DISABLED_MINUTES}
  },
  errorCodeLimitExceededTitle: {
    id: 'accountManagement.components.multiFactorAuthentication.error.codeLimitExceeded.title',
    defaultMessage: 'You have entered too many invalid codes',
  },
  errorCodeLimitExceededBody: {
    id: 'accountManagement.components.multiFactorAuthentication.error.codeLimitExceeded.text',
    defaultMessage: 'For security purposes, you will need to wait {EMAIL_VERIFICATION_REQUEST_DISABLED_MINUTES} {EMAIL_VERIFICATION_REQUEST_DISABLED_MINUTES, plural, one {minute} other {minutes}} before trying to sign in again.',
    values: {EMAIL_VERIFICATION_REQUEST_DISABLED_MINUTES}
  },
  errorInvalidTokenTitle: {
    id: 'accountManagement.components.multiFactorAuthentication.error.invalidToken.title',
    defaultMessage: 'Wrong Verification Code',
  },
  errorInvalidTokenBody: {
    id: 'accountManagement.components.multiFactorAuthentication.error.invalidToken.text',
    defaultMessage: 'Please confirm the code sent to {contact}. If needed, you can choose to resend a verification code.',
  },
  errorExpiredTokenTitle: {
    id: 'accountManagement.components.multiFactorAuthentication.error.expiredToken.title',
    defaultMessage: 'Expired Verification Code',
  },
  errorExpiredTokenBody: {
    id: 'accountManagement.components.multiFactorAuthentication.error.expiredToken.text',
    defaultMessage: 'Verification codes are only valid for {EMAIL_VERIFICATION_REQUEST_DISABLED_MINUTES} {EMAIL_VERIFICATION_REQUEST_DISABLED_MINUTES, plural, one {minute} other {minutes}} and also expire when you request a new one. If needed, you can choose to resend a verification code.',
    values: {EMAIL_VERIFICATION_REQUEST_DISABLED_MINUTES}
  },
});
