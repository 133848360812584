import { makeStyles } from '@material-ui/styles';
import React from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { getSocialConfig } from '../../../env/config_util';
import { getBrandNameSelector } from '../../BrandProvider/selectors';
import loginMessages from '../messages';
import { getOAuthParametersSelector, getIsOAuthLoginSelector } from '../selectors';

const useStyles = makeStyles(theme => ({
  buttonDivOuter: {
    fontSynthesis: 'none',
    '-moz-font-feature-settings': 'kern',
    '-webkit-font-smoothing': 'antialiased',
    '-moz-osx-font-smoothing': 'grayscale',
    width: '200px',
    height: '40px',
    minWidth: '130px',
    maxWidth: '375px',
    minHeight: '30px',
    maxHeight: '64px',
    position: 'relative',
    letterSpacing: 'initial',
  },
  buttonDivInner: {
    paddingRight: '8%',
    paddingLeft: '8%',
    position: 'absolute',
    boxSizing: 'border-box',
    width: '100%',
    height: '100%',
  },
  buttonSvgOuter: {
    overflow: 'visible',
    pointerEvents: 'none',
  },
  buttonBorder: {
    padding: '1px',
    width: '100%',
    height: '100%',
    boxSizing: 'border-box',
  },
  overflowVisible: {
    overflow: 'visible',
  },
}));

const validLocales = [
  'ar_SA',
  'ca_ES',
  'cs_CZ',
  'da_DK',
  'de_DE',
  'el_GR',
  'en_GB',
  'en_US',
  'es_ES',
  'es_MX',
  'fi_FI',
  'fr_CA',
  'fr_FR',
  'hr_HR',
  'hu_HU',
  'id_ID',
  'it_IT',
  'iw_IL',
  'ja_JP',
  'ko_KR',
  'ms_MY',
  'nl_NL',
  'no_NO',
  'pl_PL',
  'pt_BR',
  'pt_PT',
  'ro_RO',
  'ru_RU',
  'sk_SK',
  'sv_SE',
  'th_TH',
  'tr_TR',
  'uk_UA',
  'vi_VI',
  'zh_CN',
  'zh_HK',
  'zh_TW',
];

interface LoginAppleButtonProps {
  redirectUri?: string;
}

export const LoginAppleButton: React.FC<LoginAppleButtonProps> = props => {
  const styles = useStyles();
  const brand = useSelector(getBrandNameSelector);
  const isOAuthLogin = useSelector(getIsOAuthLoginSelector);
  const oauthParameters = useSelector(getOAuthParametersSelector);
  const [showFallbackButton, setShowFallbackButton] = React.useState(false);
  const { formatMessage, locale } = useIntl();

  const socialConfig = React.useMemo(() => {
    const { apple } = getSocialConfig(brand);
    const appleRedirectUriBase = apple?.redirectUriBase; // note: This is could potentially redirect you to a different domain than the one you are currently using
    const appleRedirectUri = new URL(appleRedirectUriBase);
    if (isOAuthLogin && appleRedirectUri && oauthParameters) {
      appleRedirectUri.pathname = `/oauth`;
      appleRedirectUri.searchParams.append('client_id', oauthParameters.clientId);
      appleRedirectUri.searchParams.append('redirect_uri', oauthParameters.redirectUri);
      appleRedirectUri.searchParams.append('state', oauthParameters.state);
    } else {
      appleRedirectUri.pathname = `/login`;
      if (props.redirectUri) {
        appleRedirectUri.searchParams.append('redirectUri', props.redirectUri);
      }
    }

    if (
      typeof appleRedirectUriBase !== 'string' ||
      typeof apple?.lrUri !== 'string' ||
      typeof apple?.lrApiKey !== 'string'
    ) {
      // Crash hard on configuration errors, this is static config data bundled with the app so it should never be invalid
      throw new Error(
        `Apple sign in button rendered, but configuration is invalid. redirectUri, lrUri, and lrApiKey must be defined as part of the apple social config. Got ${JSON.stringify(
          apple,
        )}`,
      );
    }

    return {
      redirectUri: appleRedirectUri.toString(),
      lrUri: apple.lrUri as string,
      lrApiKey: apple.lrApiKey as string,
    };
  }, [brand, isOAuthLogin, oauthParameters, props.redirectUri]);

  /** The locale to use in the image request. Apple only supports displaying the sign in image with certain locales, and will return a 404 if others are requested. */
  const resolvedLocale = React.useMemo(() => {
    // This cast is not necessary for typescript, but for the webpack build via CRA (which appears to ignore our tsconfig).
    const intlLocale = new (Intl as any).Locale(locale);
    const appleLocale = `${intlLocale.language}_${intlLocale.region?.toUpperCase()}`;
    if (validLocales.some(l => l === appleLocale)) return appleLocale;
    const fallbackLocale = validLocales.find(l => l.substring(0, 2) === intlLocale.language);
    if (!fallbackLocale) {
      console.warn(`Language ${intlLocale.language} cannot be used to load apple sign in image, falling back to en-us`);
      return 'en_US';
    }
    console.warn(`Locale ${appleLocale} cannot be used to load apple sign in image, falling back to ${fallbackLocale}`);
    return fallbackLocale;
  }, [locale]);

  /** The url where the apple sign in button image is located. */
  const imageUrl = React.useMemo(() => {
    const url = new URL('https://appleid.cdn-apple.com/appleid/button');
    url.searchParams.append('height', '40');
    url.searchParams.append('width', '200');
    url.searchParams.append('color', 'white');
    url.searchParams.append('border', 'true');
    url.searchParams.append('type', 'sign-in');
    url.searchParams.append('border_radius', '50');
    url.searchParams.append('scale', '3');
    url.searchParams.append('locale', resolvedLocale);
    return url.toString();
  }, [resolvedLocale]);

  const loginUrl = React.useMemo(() => {
    const url = new URL(socialConfig.lrUri);
    url.searchParams.append('apikey', socialConfig.lrApiKey);
    url.searchParams.append('provider', 'apple');
    url.searchParams.append('callback', socialConfig.redirectUri);
    url.searchParams.append('same_window', 'true');
    url.searchParams.append('is_access_token', 'true');
    url.searchParams.append('callbacktype', 'Hash');
    return url.toString();
  }, [socialConfig.lrApiKey, socialConfig.lrUri, socialConfig.redirectUri]);

  if (!showFallbackButton) {
    return (
      <a href={loginUrl}>
        <img
          src={imageUrl}
          height={40}
          width={200}
          alt={formatMessage(loginMessages.signInWithApple)}
          onError={() => setShowFallbackButton(true)}
        />
      </a>
    );
  }

  return (
    <a href={loginUrl} aria-label={formatMessage(loginMessages.signInWithApple)}>
      <div className={styles.buttonDivOuter}>
        <div className={styles.buttonDivInner}>
          <svg xmlns="http://www.w3.org/2000/svg" className={styles.buttonSvgOuter} width="100%" height="100%">
            <g>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className={styles.overflowVisible}
                width="100%"
                height="50%"
                y="25%"
                viewBox="0 -11 111.046875 14"
                fill="#000">
                <text fontSize="12px" textLength="111.046875" fontFamily="applied-button-font-0" direction="ltr">
                   {formatMessage(loginMessages.signInWithApple)}
                </text>
              </svg>
            </g>
          </svg>
        </div>
        <div className={styles.buttonBorder}>
          <svg xmlns="http://www.w3.org/2000/svg" className={styles.overflowVisible} width="100%" height="100%">
            <rect
              width="100%"
              height="100%"
              ry="50%"
              fill="#fff"
              stroke="black"
              strokeWidth="0.5"
              strokeLinecap="round"></rect>
          </svg>
        </div>
      </div>
    </a>
  );
};
