import React from 'react';

//material ui
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';

//redux
import { getBrandImagesSelector } from '../BrandProvider/selectors';
import { useSelector } from 'react-redux';

//third party lib
import { FormattedMessage, injectIntl } from 'react-intl';
import planFeatures from './planFeatures';
import messages from './messages';

//constants
import { brandNames } from '../../constants/brandNames';
import { isFreePlan } from '../../constants/freePlans';

interface Props {
  open: boolean;
  handleClose: Function;
  confirmChangeSubscription: Function;
  brand: string;
  plan: any;
  selectedPlan: any;
  external_plan_code: string;
  expireDate: any;
  devices: any;
  intl: any;
}

const ChangeSubscriptionConfirmation = (props: Props) => {
  const { open, brand, devices } = props;
  const images = useSelector(getBrandImagesSelector);

  const handleClose = () => {
    props.handleClose();
  };

  const confirmChangeSubscription = () => {
    props.confirmChangeSubscription();
  };

  const getHoursText = (): string => {
    if (
      brand === brandNames.otis &&
      props.selectedPlan &&
      props.selectedPlan.plan_config &&
      props.selectedPlan.plan_config.billing_cycle === 0 &&
      isFreePlan(props.selectedPlan.plan_config_id)
    ) {
      return '2';
    }

    return '24';
  };

  const getStorageRetention = () => {
    const retention = props.selectedPlan && props.selectedPlan.features ? props.selectedPlan.features.retention : null;

    if (retention) {
      if (retention === 0) {
        return <FormattedMessage id={'noStorage'} />;
      } else if (retention === 1) {
        const hours: string = getHoursText();
        return <FormattedMessage id={'hourStorage'} values={{ hours: hours }} />;
      } else {
        return <FormattedMessage id={'dayStorage'} values={{ days: retention }} />;
      }
    }
    return <FormattedMessage id={'noStorage'} />;
  };

  return (
    <div style={{ maxWidth: 600 }}>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="data-loss-dialog-title"
        aria-describedby="data-loss-dialog-description"
      >
        <DialogTitle id="data-loss-dialog-title" disableTypography={true} style={{ fontWeight: 'bold' }}>
          <Typography variant="h5">
            <FormattedMessage {...messages.question} />
          </Typography>
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            style={{
              marginBottom: 20,
            }}
            id="data-loss-dialog-description"
          >
            <FormattedMessage {...messages.statementDowngrade} />
          </DialogContentText>
          <DialogContentText
            id="data-loss-dialog-description"
            style={{
              textTransform: 'uppercase',
              fontWeight: 'bolder',
              color: '#5C687C',
            }}
          >
            <FormattedMessage {...messages.dataLossFeatures} />
          </DialogContentText>
          <div style={{ marginLeft: 10 }}>
            {planFeatures[props.external_plan_code] &&
              planFeatures[props.external_plan_code].map((feature: any, index: number) => (
                <div
                  key={index}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    marginBottom: 10,
                  }}
                >
                  <Typography
                    align="left"
                    style={{
                      display: 'flex',
                    }}
                  >
                    <FormattedMessage
                      {...feature}
                      values={{
                        cancelIcon: (
                          <img
                            style={{ marginRight: 7 }}
                            src={images['cancel-icon'] ? images['cancel-icon'] : null}
                            alt="cancel"
                          />
                        ),
                      }}
                    />
                  </Typography>
                </div>
              ))}
          </div>
          <div
            style={{
              marginTop: 15,
              marginBottom: 15,
            }}
          >
            <Typography variant="h5">
              <FormattedMessage {...messages.backUp} />
            </Typography>
          </div>
          <Typography variant="body2">
            <FormattedMessage
              {...messages.backUpStatement}
              values={{
                device: devices.length === 1 ? 'device' : 'devices',
                storage: getStorageRetention(), //getStorageRetention(),
                // monthName: getMonthName(),
                expireDate: props.expireDate,
              }}
            />
          </Typography>
        </DialogContent>
        <DialogActions id="data-loss-dialog-actions">
          <Button
            onClick={handleClose}
            className={`${brand}-btn-text`}
            style={{ textTransform: 'none', marginBottom: 5 }}
            id="not-now-btn"
          >
            <FormattedMessage {...messages.cancel} />
          </Button>
          <Button
            onClick={confirmChangeSubscription}
            className={`${brand}-btn ${brand}-btn-action `}
            style={{
              width: 265,
              textTransform: 'none',
              height: 42,
              fontWeight: 'bolder',
            }}
            autoFocus
            id="confirm-btn"
          >
            <FormattedMessage {...messages.confirm} />
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default injectIntl(ChangeSubscriptionConfirmation);
