import { PlanListActionTypes } from './types';

import {
  GET_PLANS_ATTEMPT,
  GET_PLANS_SUCCESS,
  GET_PLANS_FAILURE,
  SET_PLANS_SELECTED_PLAN,
  SET_PLANS_CURRENT_PLAN,
  SET_CURRENT_SELECTED_PLANS,
} from './actionTypes';

export const getPlansAttempt = (brand: string): PlanListActionTypes => ({
  type: GET_PLANS_ATTEMPT,
  brand: brand,
});

export const getPlansSuccess = (plans: any[]): PlanListActionTypes => ({
  type: GET_PLANS_SUCCESS,
  plans: plans,
});

export const getPlansFailure = (errorCode: any): PlanListActionTypes => ({
  type: GET_PLANS_FAILURE,
  errorCode: errorCode,
});

export const setSelectedPlan = (plan: any): PlanListActionTypes => ({
  type: SET_PLANS_SELECTED_PLAN,
  selectedPlan: plan,
});

export const setCurrentPlan = (plan: any): PlanListActionTypes => ({
  type: SET_PLANS_CURRENT_PLAN,
  currentPlan: plan,
});

export const setCurrentAndSelectedPlan = (currentPlan: any, selectedPlan: any): PlanListActionTypes => ({
  type: SET_CURRENT_SELECTED_PLANS,
  currentPlan,
  selectedPlan,
});
