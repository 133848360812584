import React from 'react';

//material ui
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';

//third party lib
import { FormattedMessage } from 'react-intl';
import { useMediaQuery } from 'react-responsive';
import { Dictionary } from '../../modules/helpers';
import messages from './messages';
import history from '../../history';

//redux
import { useSelector } from 'react-redux';
import { getBrandNameSelector, getBrandImagesSelector } from '../BrandProvider/selectors';
import queryString from 'query-string';
const brandEmail: Dictionary<string> = {
  otis: 'info@otishome.com',
  visi: 'information@getvisi.com',
  momentum: 'help@momentumcam.com',
};

interface Props {
  noSupportButton?: boolean;
}

const ErrorStatusPage = (props: Props) => {
  const brand = useSelector(getBrandNameSelector);
  const images = useSelector(getBrandImagesSelector);
  const query = queryString.parse(window.location.search);
  const isMobile = useMediaQuery({ query: `(max-width: 600px)` });

  const onSupportClick = () => {
    window.location.href = 'mailto:' + brandEmail[brand];
  };

  const onBackClick = () => {
    history.push('/subscriptions');
  };

  return (
    <div>
      <Container maxWidth="sm" className={`${brand}-screen-bg`} style={{ display: 'flex', justifyContent: 'center' }}>
        <div style={isMobile ? { marginTop: 25 } : { marginTop: 45 }}>
          <div className={`${brand}-error-status-page`}>
            <div>
              {' '}
              <img
                src={images['logo-error'] ? images['logo-error'] : null}
                alt="error-status-icon"
                className={`${brand}-confirm-modal-icon`}
              />
            </div>
            <div style={{ marginBottom: 20 }}>
              <Typography align="center" variant="h5">
                <FormattedMessage {...messages.somethingWentWrong} />
              </Typography>
            </div>
            <div style={{ marginLeft: 50, marginRight: 50 }}>
              <Typography align="center" variant="body1" style={{ maxWidth: 280 }}>
                {query && query.error_code === 'already_subscribed' ? (
                  <FormattedMessage {...messages.alreadySubscribed} />
                ) : props.noSupportButton === true ? (
                  <FormattedMessage {...messages.sorryGenericMessage} />
                ) : (
                  <FormattedMessage {...messages.sorryMessage} />
                )}
              </Typography>
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                marginTop: 100,
              }}>
              <Button
                className={`${brand}-btn ${brand}-btn-action `}
                onClick={onBackClick}
                style={{
                  textTransform: 'none',
                  fontWeight: 'bold',
                  minWidth: 220,
                }}>
                {props.noSupportButton === true ? (
                  <FormattedMessage {...messages.goToMyPlan} />
                ) : (
                  <FormattedMessage {...messages.goToMainPage} />
                )}
              </Button>
              {props.noSupportButton === true ? null : (
                <Button className={`${brand}-btn-text`} onClick={onSupportClick} style={{ textTransform: 'none' }}>
                  <FormattedMessage {...messages.contactSupport} />
                </Button>
              )}
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default ErrorStatusPage;
