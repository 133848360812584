import { getBrandNameSelector } from '../containers/BrandProvider/selectors';
import { getProfileSelector } from '../containers/LoginPage/selectors';

import { loadConfig } from '../env/config_util';
import { SelectState, TypedIterableIterator } from '../modules/helpers';

const apigClientFactory = require('aws-api-gateway-client').default;

const config = loadConfig();

export function createApiGatewayClient(awsCredentials: any, brand: any, context = config.config_module[brand].context) {
  return apigClientFactory.newClient({
    invokeUrl: config.config_module[brand].base_url + '/' + context,
    accessKey: awsCredentials.AccessKeyId,
    secretKey: awsCredentials.SecretAccessKey,
    sessionToken: awsCredentials.SessionToken, //OPTIONAL: If you are using temporary credentials you must include the session token
    region: awsCredentials.region, // OPTIONAL: The region where the API is deployed, by default this parameter is set to us-east-1
  });
}

export function createUnauthorizedApiGatewayClient(brand: string, context: string) {
  return apigClientFactory.newClient({
    invokeUrl: `${config?.config_module[brand]?.base_url}/${context}`
  })
}

export function* postDataToService(servicePath: any, body: any, context: any): TypedIterableIterator<any> {
  const brand: string = yield SelectState<string>(getBrandNameSelector);
  const profile: any = yield SelectState<any>(getProfileSelector);

  try {
    const apiGatewayClient = createApiGatewayClient(profile.pepperUser.awsUserCredentials, brand, context);
    const pathTemplate = servicePath;
    const method = 'POST';
    const params = {};
    const additionalParams = {
      headers: {
        peppertoken: profile.token,
      },
      queryParams: {},
    };
    return yield apiGatewayClient
      .invokeApi(params, pathTemplate, method, additionalParams, body)
      .then(function (result: any) {
        return result.data;
      })
      .catch(function (err: any) {
        throw err;
      });
  } catch (err) {
    throw err;
  }
}

export function* putDataToService(servicePath: any, body: any, context: any): TypedIterableIterator<any> {
  const brand: string = yield SelectState<string>(getBrandNameSelector);
  const profile: any = yield SelectState<any>(getProfileSelector);

  try {
    const apiGatewayClient = createApiGatewayClient(profile.pepperUser.awsUserCredentials, brand, context);
    const pathTemplate = servicePath;
    const method = 'PUT';
    const params = {};
    const additionalParams = {
      headers: {
        peppertoken: profile.token,
      },
      queryParams: {},
    };
    return yield apiGatewayClient
      .invokeApi(params, pathTemplate, method, additionalParams, body)
      .then(function (result: any) {
        return result.data;
      })
      .catch(function (err: any) {
        throw err;
      });
  } catch (err) {
    throw err;
  }
}

export function* getDataFromService(servicePath: any, context: any, queryParams = {}, isUserSpecificRequest= true): TypedIterableIterator<any> {
  const brand: string = yield SelectState<string>(getBrandNameSelector);
  const profile: any = yield SelectState<any>(getProfileSelector);

  try {
    const apiGatewayClient = isUserSpecificRequest ?
      createApiGatewayClient(profile?.pepperUser?.awsUserCredentials, brand, context)
      : createUnauthorizedApiGatewayClient(brand, context);

    const pathTemplate = servicePath;
    const params = {};
    const body = {};
    let additionalParams: any = { queryParams };
    if (isUserSpecificRequest) {
      additionalParams['headers'] = { peppertoken: profile.token }
    }

    return yield apiGatewayClient
      .invokeApi(params, pathTemplate, 'GET', additionalParams, body)
      .then(function (result: any) {
        return result.data;
      })
      .catch(function (err: any) {
        throw err;
      });
  } catch (err) {
    throw err;
  }
}
export function* getDataArrayFromService(servicePath: any, context: any, queryParams = {}, isUserSpecificRequest= true): TypedIterableIterator<any> {
  const brand: string = yield SelectState<string>(getBrandNameSelector);
  const profile: any = yield SelectState<any>(getProfileSelector);

  try {
    const apiGatewayClient = createApiGatewayClient(profile?.pepperUser?.awsUserCredentials, brand, context);

    const pathTemplate = servicePath;
    const params = {};
    const body = {};
    let additionalParams: any = { queryParams };
    if (isUserSpecificRequest) {
      additionalParams['headers'] = { peppertoken: profile.token }
    }

    return yield apiGatewayClient
      .invokeApi(params, pathTemplate, 'GET', additionalParams, body)
      .then(function (result: any) {
        return result.data.docs;
      })
      .catch(function (err: any) {
        throw err;
      });
  } catch (err) {
    throw err;
  }
}
