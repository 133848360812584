import React, { FC, useEffect } from 'react';

//redux
import { isolatedSsoAuthAttempt } from './actions';
import { useDispatch, useSelector } from 'react-redux';

//third party lib
import { RouteComponentProps, withRouter } from 'react-router-dom';
import CircularProgress from '@material-ui/core/CircularProgress';
import { getBrandNameSelector } from '../BrandProvider/selectors';

interface IsolatedSsoRedirectProps extends RouteComponentProps {}

const IsolatedSsoRedirect: FC<IsolatedSsoRedirectProps> = ({ location }) => {
  const dispatch = useDispatch();
  const brand = useSelector(getBrandNameSelector);

  useEffect(() => {
    dispatch(isolatedSsoAuthAttempt(location));
  }, [dispatch, location]);

  return (
    <div className="progress-wrapper-center">
      <CircularProgress size={55} className={`${brand}-button-progress`} />
    </div>
  );
};

export default withRouter(IsolatedSsoRedirect);
