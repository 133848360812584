import React, { useEffect } from 'react';

//material ui
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';

//redux
import { getBrandImagesSelector } from '../BrandProvider/selectors';
import { useDispatch, useSelector } from 'react-redux';

//third party lib
import { FormattedMessage, injectIntl } from 'react-intl';
import messages from './messages';
import {
  getDeleteAccountAttemptingSelector,
  getDeleteAccountErrorSelector,
  getDeleteAccountSuccessSelector,
} from './selectors';
import { getDeleteAttempt, getDeleteFailure } from './actions';
import CircularProgress from '@material-ui/core/CircularProgress';
import history from '../../history';

interface Props {
  open: boolean;
  handleClose: Function;
  brand: string;
  intl: any;
  brandName: string;
}

const DeleteAccountModalRequest = (props: Props) => {
  const { open, brand, handleClose } = props;
  const images = useSelector(getBrandImagesSelector);
  const loading = useSelector(getDeleteAccountAttemptingSelector);
  const deleteSuccess = useSelector(getDeleteAccountSuccessSelector);
  const error = useSelector(getDeleteAccountErrorSelector);
  const dispatch = useDispatch();

  useEffect(() => {
    if (deleteSuccess) {
      handleClose();
      history.push('/');
    }

    if (error) {
      dispatch(getDeleteFailure(null));
      history.push('/error');
    }
  }, [deleteSuccess, error, handleClose, dispatch]);

  const handleCloseModal = () => {
    handleClose();
  };

  const confirmDelete = () => {
    dispatch(getDeleteAttempt());
  };

  return (
    <div style={{ maxWidth: 600 }}>
      <Dialog
        open={open}
        onClose={handleCloseModal}
        aria-labelledby="delete-account-request-dialog-title"
        aria-describedby="delete-account-request-dialog-description">
        <DialogTitle
          id="delete-account-request-dialog-title"
          disableTypography={true}
          style={{
            fontWeight: 'bold',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
          }}>
          <div>
            <img src={images['logo-login']} alt="Logo" className={`${brand}-login-logo`} />
          </div>
          <Typography variant="h5">
            <FormattedMessage {...messages.modalTitle} />
          </Typography>
        </DialogTitle>
        <DialogContent>
          <div
            style={{
              marginTop: 15,
              marginBottom: 15,
              textAlign: 'center',
            }}>
            <Typography variant="body2">
              <FormattedMessage {...messages.deleteAccountStatement} values={{ partnerName: props.brandName }} />
            </Typography>
          </div>
          <ol>
            <li>
              <Typography variant="body2">
                <FormattedMessage {...messages.deleteAccountBullet1} />
              </Typography>
            </li>
            <li>
              <Typography variant="body2">
                <FormattedMessage {...messages.deleteAccountBullet2} />
              </Typography>
            </li>
            <li>
              <Typography variant="body2">
                <FormattedMessage {...messages.deleteAccountBullet3} />
              </Typography>
            </li>
            <li>
              <Typography variant="body2">
                <FormattedMessage {...messages.deleteAccountBullet4} />
              </Typography>
            </li>
          </ol>
        </DialogContent>
        <DialogActions id="delete-account-dialog-actions">
          {loading ? (
            <CircularProgress
              size={35}
              className={`${brand}-small-loading-progress`}
              style={{
                top: 10,
                position: 'relative',
                marginRight: 25,
                marginBottom: 25,
              }}
            />
          ) : (
            <>
              <Button
                onClick={handleCloseModal}
                className={`${brand}-btn-text`}
                style={{ textTransform: 'none', height: 42 }}
                id="delete-account-btn">
                <FormattedMessage {...messages.cancelDeleteAccountButton} />
              </Button>
              <Button
                onClick={confirmDelete}
                className={`${brand}-btn ${brand}-btn-action `}
                style={{
                  width: 175,
                  textTransform: 'none',
                  height: 42,
                  fontWeight: 'bolder',
                }}
                autoFocus
                id="yes-im-sure-btn">
                <FormattedMessage {...messages.deleteAccountButton} />
              </Button>
            </>
          )}
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default injectIntl(DeleteAccountModalRequest);
