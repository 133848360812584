import React, { ReactElement } from 'react';
import Paper from '@material-ui/core/Paper';
import {getDeviceImage} from '../../utils/deviceImageUtils';

interface Props {
  numOfDevices: number;
  brand: string;
  device: any;
  deviceModels?: any[]; 
}

function DeviceControl(props: Props): ReactElement {
  return (
    <div className={`${props.brand}-device-control`}>
      <div>
        <Paper variant="outlined" className={`${props.brand}-plan-device-card`}>
          <div style={{ width: 100, display: 'flex', justifyContent: 'center' }}>
            <img className={`${props.brand}-device-image`} src={getDeviceImage(props.device, props.deviceModels)} alt="device-img" />
          </div>
          <p style={{ fontSize: 16 }}>{props.device.name}</p>
        </Paper>
      </div>
    </div>
  );
}

export default DeviceControl;
