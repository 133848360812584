/*
 * PlanLimitReachedModal Messages
 *
 * This contains all the text for the PlanLimitReachedModal component.
 */
import { defineMessages } from 'react-intl';

export default defineMessages({
  modalTitle: {
    id: 'app.containers.PlanLimitReachedModal.modalTitle.text',
    defaultMessage: 'Plan Limit Reached',
  },
  planLimitStatement: {
    id: 'app.containers.PlanLimitReachedModal.planLimitStatement.text',
    defaultMessage:
      'The selected plan includes coverage up to {maxDevices} devices. Please select a new plan or uncheck a device to open up a new slot.',
  },
  okayButton: {
    id: 'app.containers.PlanLimitReachedModal.OkayButton.text',
    defaultMessage: 'OK',
  },
  changePlanButton: {
    id: 'app.containers.PlanLimitReachedModal.ChangePlanButton.text',
    defaultMessage: 'Change Plan',
  },
  warningAltText: {
    id: 'app.containers.PlanLimitReachedModal.warningAltText.text',
    defaultMessage: 'Warning: Plan Limit Reached',
  },
});
