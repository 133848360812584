import { all, call, put, takeLatest } from 'redux-saga/effects';
import { getDeviceListFailure, getDeviceListSuccess } from './actions';
import { GET_DEVICE_LIST_ATTEMPT } from './actionTypes';
import { getDataFromService } from '../../services/apiGatewayClient';
import { TypedIterableIterator } from '../../modules/helpers';
import { push } from 'react-router-redux';

export function* getDeviceList(): TypedIterableIterator<any> {
  try {
    const devices = yield call(getDataFromService, '/devices', 'account') ?? [];

    const subscribableDevices = devices.filter((device: any) => device.subscribable !== false)

    yield put(getDeviceListSuccess(subscribableDevices));
  } catch (err) {
    yield put(getDeviceListFailure(err));
    yield put(push('/error'));
  }
}

function* deviceListSaga() {
  yield all([takeLatest(GET_DEVICE_LIST_ATTEMPT, getDeviceList)]);
}

export default deviceListSaga;
