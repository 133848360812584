import React from 'react';

//material ui
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import CircularProgress from '@material-ui/core/CircularProgress';

interface Props {
  open: boolean;
  brand: string;
  title?: any;
  subtitle?: any;
}

const LoadingModel = (props: Props) => {
  const { open, brand } = props;

  return (
    <div>
      <Dialog open={open} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title" style={{ display: 'flex', justifyContent: 'center' }}>
          <CircularProgress size={55} className={`${brand}-loading-progress`} />
        </DialogTitle>
        <DialogTitle id="alert-dialog-title" style={{ fontWeight: 'bold' }}>
          {props.title ? props.title : ''}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">{props.subtitle ? props.subtitle : ''}</DialogContentText>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default LoadingModel;
