import { ReactNode } from 'react';
import Button from '@material-ui/core/Button';

type StepButton = {
  copy: string;
  callback: any;
}
interface StepProps {
  brand: string;
  imgSrc: string;
  header: string;
  body: ReactNode;
  primaryButton?: StepButton;
  secondaryButton?: StepButton;
}
const Step = ({ brand, imgSrc, header, body, primaryButton, secondaryButton}: StepProps) => {
  return (
    <div>
      <img src={imgSrc} alt="" />
      <h4>{header}</h4>
      {body}
      {primaryButton?.copy ? (
        <Button
          onClick={primaryButton.callback}
          fullWidth
          variant="contained"
          className={`${brand}-login-button`}
          style={{
            textTransform: 'none',
            fontWeight: 'bolder',
            fontSize: 'larger',
            marginTop: 24,
          }}
          color="primary">
          {primaryButton.copy}
        </Button>
      ) : null}
      {secondaryButton ? (
        <Button
          onClick={secondaryButton.callback}
          fullWidth
          variant="contained"
          className={`${brand}-login-button-inverse`}
          style={{
            textTransform: 'none',
            fontWeight: 'bolder',
            fontSize: 'larger',
            marginTop: 24,
          }}
          color="primary">
          {secondaryButton.copy}
        </Button>
      ) : null}
    </div>
  )
}

export default Step;