import { createSelector } from 'reselect';

import { AppState } from '../../store/rootReducer';

const getBrandName = (state: AppState) => state.brand.name;
const getLongBrandName = (state: AppState) => state.brand.brandName;
const getLocale = (state: AppState) => state.brand.locale;
const getLocation = (state: AppState) => state.brand.location;
const getImages = (state: AppState) => state.brand.images;
const getConfig = (state: AppState) => state.brand.config;
const getFlow = (state: AppState) => state.brand.flow;
const getBillingProvider = (state: AppState) => state.brand.billingProvider;

export const getBrandNameSelector = createSelector(getBrandName, brandState => brandState);

export const getLongBrandNameSelector = createSelector(getLongBrandName, brandName => brandName);

export const getBrandImagesSelector = createSelector(getImages, images => images);

export const getLocaleSelector = createSelector(getLocale, locale => locale);

export const getLocationSelector = createSelector(getLocation, location => location);

export const getConfigSelector = createSelector(getConfig, config => config);

export const getFlowSelector = createSelector(getFlow, flow => flow);

export const getBillingProviderSelector = createSelector(getBillingProvider, billingProvider => billingProvider);
