import React, { useState } from 'react';

//third party lib
import { injectIntl, FormattedMessage } from 'react-intl';
import { withRouter } from 'react-router';
import messages from './messages';
import queryString from 'query-string';
import clsx from 'clsx';

//helpers
import { parseLocationForEmail } from '../../utils/pepperUtils';
import { Dictionary } from '../../modules/helpers';

//material ui
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormHelperText from '@material-ui/core/FormHelperText';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import IconButton from '@material-ui/core/IconButton';
import Visibility from '@material-ui/icons/Visibility';
import Typography from '@material-ui/core/Typography';
import FormGroup from '@material-ui/core/FormGroup';
import CloseIcon from '@material-ui/icons/Close';
import Container from '@material-ui/core/Container';
import Collapse from '@material-ui/core/Collapse';
import Alert from '@material-ui/lab/Alert';
import Button from '@material-ui/core/Button';

//redux
import { useSelector, useDispatch } from 'react-redux';

import {
  getAppUriObjSelector,
  getResetPasswordAttemptingSelector,
  getResetPasswordErrorSelector,
  getResetPasswordFailedSelector,
  getResetPasswordSuccessSelector,
} from './selectors';

import { getBrandNameSelector, getLongBrandNameSelector, getBrandImagesSelector } from '../BrandProvider/selectors';
import { resetPasswordAttemptAction } from './actions';
import { INVALID_TOKEN, LINK_ALREADY_USED, PASSWORDS_TOO_SIMILAR } from './actionTypes';

// Images
import redFailureImage from '../../themes/common/images/alert-circle-red.svg';

interface Props {
  location: any;
  history: any;
  match: any;
  intl: any;
}

const brandCapFirst = (brand: string) => {
  return brand.charAt(0).toUpperCase() + brand.slice(1);
};

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    alignItems: 'center',
  },
  margin: {
    marginTop: theme.spacing(3),
  },
  withoutLabel: {
    marginTop: theme.spacing(3),
  },
  textField: {
    width: '100%',
    maxWidth: 400,
  },
}));

export interface IResetPasswordState extends Dictionary<any> {
  bannerDismissed: boolean;
  confirmPasswordMask: boolean;
  password: any;
  passwordConfirmation: string | null;
  passwordMask: boolean;
  submitDisabled: boolean;
  validate: {
    touched: boolean;
    password: {
      minLengthError: boolean;
      uppercaseError: boolean;
      lowercaseError: boolean;
      numberError: boolean;
      specialCharError: boolean;
    };
    passwordConfirmation: {
      matches: boolean;
    };
  };
}

const ResetPassword = (props: Props) => {
  const dispatch = useDispatch();
  const classes = useStyles();

  const [resetPasswordState, setResetPasswordState] = useState<IResetPasswordState>({
    bannerDismissed: false,
    confirmPasswordMask: true,
    password: '',
    passwordConfirmation: '',
    passwordMask: true,
    submitDisabled: true,
    validate: {
      touched: false,
      password: {
        minLengthError: false,
        uppercaseError: false,
        lowercaseError: false,
        numberError: false,
        specialCharError: false,
      },
      passwordConfirmation: {
        matches: false,
      },
    },
  });

  const [showPassword, setShowPassword] = React.useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = React.useState(false);

  const appUriObj = useSelector(getAppUriObjSelector);
  const brand = useSelector(getBrandNameSelector);
  const brandName = useSelector(getLongBrandNameSelector);
  const images = useSelector(getBrandImagesSelector);

  const resetPasswordFailed = useSelector(getResetPasswordFailedSelector);
  const resetPasswordError = useSelector(getResetPasswordErrorSelector);
  const resetPasswordSuccess = useSelector(getResetPasswordSuccessSelector);
  const resetPasswordAttempting = useSelector(getResetPasswordAttemptingSelector);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleClickShowConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const handleMouseDownPassword = (event: any) => {
    event.preventDefault();
  };

  const handleMouseDownPasswordConfirm = (event: any) => {
    event.preventDefault();
  };

  const onBannerDismiss = () => {
    let state = { ...resetPasswordState };

    state.bannerDismissed = true;
    setResetPasswordState(state);
  };

  const confirmOpenApp = () => {
    if (appUriObj) {
      const appUri = appUriObj.appUri;
      const uriScheme = appUriObj.uriScheme;
      if (isiOS()) {
        window.location.href = `${uriScheme}://`;
      } else {
        window.location.href = `intent:${appUri}/#Intent;scheme=${uriScheme};package=${appUri};launchFlags=268435456;end;`;
      }
    }
  };

  const handleChangePassword = (e: any) => {
    const { validate } = resetPasswordState;
    const { password } = validate;
    let state = { ...resetPasswordState };
    password.minLengthError = !(e.target.value.length >= 8 && e.target.value.length <= 40);
    password.uppercaseError = !e.target.value.match(/[A-Z]/);
    password.lowercaseError = !e.target.value.match(/[a-z]/);
    password.numberError = !e.target.value.match(/\d/);

    // Momentum, Pepper are the only brands that do not require a special character
    if (brand !== 'momentum' && brand !== 'pepper') {
      password.specialCharError = !e.target.value.match(/[\^$*.[\]{}()?\-"!@#%&/\\,><':;|_`~]/);
    }

    if (Object.values(password).indexOf(true) !== -1) {
      state.submitDisabled = true;
    }

    state.password = e.target.value;
    state.validate.touched = true;
    state.validate.password = password;

    setResetPasswordState(state);
  };

  const isiOS = (): boolean => {
    return (
      window.navigator.userAgent.includes('iOS') ||
      window.navigator.userAgent.includes('iPhone') ||
      window.navigator.userAgent.includes('iPad')
    );
  };

  const handleChangePasswordConfirm = (e: any) => {
    const { validate } = resetPasswordState;
    let state = { ...resetPasswordState };
    validate.passwordConfirmation.matches = e.target.value === resetPasswordState.password;

    state.submitDisabled = !validate.passwordConfirmation.matches;
    state.passwordConfirmation = e.target.value;
    state.validate = validate;
    setResetPasswordState(state);
  };

  const handleUserSubmitReset = (event: any) => {
    event.preventDefault();
    const { password, validate } = resetPasswordState;
    let state = { ...resetPasswordState };

    if (!isPasswordValid() || !validate.passwordConfirmation.matches) {
      return;
    }

    const resetToken = queryString.parse(props.location.search).vtoken;
    const maybeEmail = parseLocationForEmail(props.location.search);
    dispatch(resetPasswordAttemptAction(brand, resetToken, password, maybeEmail));

    state.bannerDismissed = false;
    state.submitDisabled = true;

    setResetPasswordState(state);
  };

  const isAndroid = () => {
    return window.navigator.userAgent.includes('Android');
  };

  const isAttempting = () => {
    if (!resetPasswordAttempting) {
      return false;
    }
    return (
      <div className="progress-wrapper">
        <CircularProgress size={55} className={`${brand}-button-progress`} />
      </div>
    );
  };

  const isPasswordValid = () => {
    return (
      resetPasswordState.validate.touched && Object.values(resetPasswordState.validate.password).indexOf(true) === -1
    );
  };

  const isInvalidTokenFailure = () => {
    let src = redFailureImage;

    return resetPasswordFailed && resetPasswordError === INVALID_TOKEN ? (
      <div>
        <img className="mb-5" src={src} alt="exclamation-point" />
        <p className="primary-h1-bold-text mb-3">
          {<FormattedMessage {...messages.resetPasswordErrorInvalidTokenTitle} />}
        </p>
        <p className="mb-2">
          {
            <FormattedMessage
              {...messages.resetPasswordErrorInvalidTokenSubtitle}
              values={{ brand: brandCapFirst(brandName) }}
            />
          }
        </p>
      </div>
    ) : null;
  };

  const isLinkAlreadyUsedFailure = () => {
    let src = redFailureImage;

    return resetPasswordFailed && resetPasswordError === LINK_ALREADY_USED ? (
      <div>
        <img className="mb-5" src={src} alt="exclamation-point" />
        <p className="primary-h1-bold-text mb-3">
          {<FormattedMessage {...messages.resetPasswordErrorLinkAlreadyUsedTitle} />}
        </p>
        <p className="mb-2">
          {
            <FormattedMessage
              {...messages.resetPasswordErrorLinkAlreadyUsedSubtitle}
              values={{ brand: brandCapFirst(brandName) }}
            />
          }
        </p>
      </div>
    ) : null;
  };

  const isMobile = () => {
    return isAndroid() || isiOS();
  };

  const isSuccess = () => {
    return resetPasswordSuccess ? (
      <div>
        <img className="mb-5" src={images['complete']} alt="check mark" />
        <Typography variant="h5" style={{ margin: 10 }}>
          {<FormattedMessage {...messages.resetPasswordSuccessTitle} />}
        </Typography>
        <Typography variant="body1">{<FormattedMessage {...messages.resetPasswordSuccessSubtitle} />}</Typography>
      </div>
    ) : null;
  };

  const passwordKeyIcon = () => {
    return <img className="mb-5" src={images['password_key']} alt="key-icon" />;
  };

  const getErrorMessage = () => {
    if (resetPasswordState.validate.password.minLengthError) {
      return <FormHelperText>{<FormattedMessage {...messages.resetPasswordInputErrorMinLength} />}</FormHelperText>;
    }

    if (resetPasswordState.validate.password.uppercaseError) {
      return (
        <FormHelperText>{<FormattedMessage {...messages.resetPasswordInputErrorMissingUppercase} />}</FormHelperText>
      );
    }

    if (resetPasswordState.validate.password.lowercaseError) {
      return (
        <FormHelperText>{<FormattedMessage {...messages.resetPasswordInputErrorMissingLowercase} />}</FormHelperText>
      );
    }

    if (resetPasswordState.validate.password.numberError) {
      return <FormHelperText>{<FormattedMessage {...messages.resetPasswordInputErrorMissingNumber} />}</FormHelperText>;
    }

    if (brand !== 'momentum' && brand !== 'pepper' && resetPasswordState.validate.password.specialCharError) {
      return (
        <FormHelperText>{<FormattedMessage {...messages.resetPasswordInputErrorMissingSpecialChar} />}</FormHelperText>
      );
    }

    return null;
  };

  return (
    <Container
      maxWidth="sm"
      style={{
        marginTop: 70,
      }}
    >
      <Collapse in={resetPasswordError === PASSWORDS_TOO_SIMILAR && !resetPasswordState.bannerDismissed}>
        <Alert
          variant="filled"
          severity="error"
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                onBannerDismiss();
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
        >
          {<FormattedMessage {...messages.resetPasswordErrorPasswordsTooSimilar} />}
        </Alert>
      </Collapse>
      <div style={{ textAlign: 'center', width: '100%' }}>
        <div style={{ justifyContent: 'center', alignItems: 'center' }}>
          {!isAttempting() && !isLinkAlreadyUsedFailure() && !isInvalidTokenFailure() && !isSuccess() && (
            <div>
              {passwordKeyIcon()}
              <Typography variant="h5" gutterBottom style={{ marginTop: 10, fontWeight: 'bolder' }}>
                {<FormattedMessage {...messages.resetPasswordPageTitle} />}
              </Typography>
              <Typography variant="body1" gutterBottom>
                {<FormattedMessage {...messages.resetPasswordPageSubtitle} />}
              </Typography>
            </div>
          )}
          {isAttempting()}
          {isLinkAlreadyUsedFailure()}
          {isInvalidTokenFailure()}
          {isSuccess()}
        </div>
      </div>
      <div style={{ marginTop: 20 }}>
        {!isAttempting() && !isLinkAlreadyUsedFailure() && !isInvalidTokenFailure() && !isSuccess() && (
          <div style={{ maxWidth: '350px', width: '100%', margin: 'auto' }}>
            <form onSubmit={handleUserSubmitReset}>
              <FormGroup className={`${brand}-text-field`}>
                <div>
                  <FormControl
                    className={clsx(classes.margin, classes.textField)}
                    variant="outlined"
                    style={{ width: '100%' }}
                  >
                    <InputLabel htmlFor="password">
                      {props.intl.formatMessage({
                        ...messages.resetPasswordInputPlaceholder,
                      })}
                    </InputLabel>
                    <OutlinedInput
                      id="password"
                      error={!isPasswordValid() && resetPasswordState.validate.touched}
                      fullWidth
                      label={props.intl.formatMessage({
                        ...messages.resetPasswordInputPlaceholder,
                      })}
                      type={showPassword ? 'text' : 'password'}
                      value={resetPasswordState.password}
                      onChange={(e: any) => handleChangePassword(e)}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      }
                      labelWidth={70}
                    />
                    {getErrorMessage()}
                  </FormControl>
                </div>
              </FormGroup>
              <FormGroup className={`${brand}-text-field`}>
                <div>
                  <FormControl className={clsx(classes.margin, classes.textField)} variant="outlined">
                    <InputLabel htmlFor="passwordConfirmation">
                      {props.intl.formatMessage({
                        ...messages.resetPasswordConfirmInputPlaceholder,
                      })}
                    </InputLabel>
                    <OutlinedInput
                      id="passwordConfirmation"
                      error={
                        resetPasswordState.validate.touched && !resetPasswordState.validate.passwordConfirmation.matches
                      }
                      fullWidth
                      label={props.intl.formatMessage({
                        ...messages.resetPasswordConfirmInputPlaceholder,
                      })}
                      type={showConfirmPassword ? 'text' : 'password'}
                      value={resetPasswordState.passwordConfirmation}
                      onChange={(e: any) => handleChangePasswordConfirm(e)}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowConfirmPassword}
                            onMouseDown={handleMouseDownPasswordConfirm}
                            edge="end"
                          >
                            {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      }
                      labelWidth={70}
                    />
                    {resetPasswordState.validate.touched &&
                    !resetPasswordState.validate.passwordConfirmation.matches ? (
                      <FormHelperText>
                        {<FormattedMessage {...messages.resetPasswordInputErrorPasswordsMatch} />}
                      </FormHelperText>
                    ) : null}
                  </FormControl>
                </div>
              </FormGroup>

              <Button
                type="submit"
                className={
                  resetPasswordState.submitDisabled
                    ? `${brand}-btn-reset-password-disabled`
                    : `${brand}-btn-reset-password`
                }
                style={{
                  marginTop: 40,
                  width: '100%',
                  textTransform: 'none',
                  height: 42,
                  fontWeight: 'bolder',
                }}
                disabled={resetPasswordState.submitDisabled}
                autoFocus
                id="reset-password"
              >
                <FormattedMessage {...messages.resetPasswordPageTitle} />
              </Button>
            </form>
            {isMobile() && (resetPasswordFailed || resetPasswordSuccess) && (
              <Button
                id="open-app"
                className={`${brand}-btn-reset-password`}
                style={{
                  marginTop: 40,
                  width: '100%',
                  textTransform: 'none',
                  height: 42,
                  fontWeight: 'bolder',
                }}
                onClick={() => confirmOpenApp()}
              >
                {<FormattedMessage {...messages.openAppBrand} values={{ brand: brandCapFirst(brandName) }} />}
              </Button>
            )}
          </div>
        )}
      </div>
    </Container>
  );
};

export default injectIntl(withRouter(ResetPassword));
