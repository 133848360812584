import { PathTypes, IPath } from './types';
import { SET_PREV_PATH, CLEAR_STATE } from './actionTypes';

export const initialState: IPath = {
  prevPathName: '',
};

export const PathReducer = (state = initialState, action: PathTypes): IPath => {
  switch (action.type) {
    case SET_PREV_PATH:
      return {
        ...state,
        prevPathName: action.prevPathName,
      };
    case CLEAR_STATE:
      return initialState;
    default:
      return state;
  }
};
