import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import messages from './messages';

import { useSelector } from 'react-redux';
import { getSelectedPlanSelector } from '../PlanListPage/selectors';

//material ui
import Paper from '@material-ui/core/Paper';
import CurrencyFormatter from '../../components/Formatters/CurrencyFormatter';

interface Props {
  currency: string;
  brand: string;
  intl: any;
}

const OrderHeader = (props: Props) => {
  const selectedPlan = useSelector(getSelectedPlanSelector);
  const { currency, brand } = props;
  const { formatMessage } = props.intl;

  const getSelectedPlanName = () => {
    let retVal: any = '';

    if (selectedPlan) {
      if (selectedPlan.name) {
        retVal += selectedPlan.name + ' Plan | ';
      }
    }
    return retVal;
  };

  const getSelectedPlanPrice = () => {
    if (selectedPlan) {
      if (selectedPlan.amountInCents && selectedPlan.amountInCents[currency]?._) {
        return <CurrencyFormatter value={selectedPlan.amountInCents[currency]?._} />;
      }
    }
  };

  const getSelectedPlanBillingCycle = () => {
    if (selectedPlan) {
      if (selectedPlan.billing_cycle && selectedPlan.billing_cycle) {
        return formatMessage(messages['billingCycle' + selectedPlan.billing_cycle]);
      }
    }
  };

  return (
    <Paper
      className={`${brand}-order-summary-header`}
      variant="outlined"
      square
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        height: 80,
      }}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          lineHeight: 1.8,
        }}
        className="order-summary-header-text">
        <div
          style={{
            fontWeight: 'bolder',
            fontSize: '1.4em',
          }}>
          <FormattedMessage {...messages.subtitle} />
        </div>
        <div
          style={{
            fontSize: '1.2em',
          }}>
          {getSelectedPlanName()}
          {getSelectedPlanPrice()} {getSelectedPlanBillingCycle()}
        </div>
      </div>
    </Paper>
  );
};

export default injectIntl(OrderHeader);
