import React from 'react';
import Paper from '@material-ui/core/Paper';
import { FormattedMessage, injectIntl } from 'react-intl';
import messages from './messages';
import CurrencyFormatter from '../../components/Formatters/CurrencyFormatter';

interface Props {
  selectedPlan: any;
  currency: string;
  brand: string;
  intl: any;
}

const PaymentDetailsHeader = (props: Props) => {
  const { selectedPlan, currency, brand } = props;
  const { formatMessage } = props.intl;

  return (
    <Paper
      variant="outlined"
      className={`${brand}-payment-details-header`}
      square
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        height: 80,
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          lineHeight: 1.8,
        }}
        className="order-summary-header-text"
      >
        <div
          style={{
            fontWeight: 'bolder',
            fontSize: '1.4em',
          }}
        >
          <FormattedMessage {...messages.summary} />
        </div>
        <div
          style={{
            fontSize: '1.2em',
          }}
        >
          {selectedPlan.name} Plan | {'  '}
          <CurrencyFormatter value={selectedPlan.amountInCents[currency]._} />
          {formatMessage(messages['billingCycle' + selectedPlan.billing_cycle])}
        </div>
      </div>
    </Paper>
  );
};

export default injectIntl(PaymentDetailsHeader);
