import React from 'react';

//material ui
import { Button, Paper } from '@material-ui/core';

//third party lib
import { FormattedMessage } from 'react-intl';
import messages from './messages';
import history from '../../history';
import { clearPromoCodeState } from '../PromoCode/actions';
import { useDispatch, useSelector } from 'react-redux';
import { setPrevPathName } from '../PathName/actions';
import { getProfileSelector } from '../LoginPage/selectors';
import { logEvent } from '../../utils/analytics/analyticsLogger';
import { AnalyticsEvent } from '../../utils/analytics/events';

interface Props {
  brand: string;
  submitDisabled: boolean;
  handleSubmit: Function;
  lastPath: string;
}

const PaymentDetailsActions = (props: Props) => {
  const { brand, lastPath, submitDisabled } = props;
  const dispatch = useDispatch();

  const userProfile = useSelector(getProfileSelector);

  const goBack = () => {
    let goToPage = lastPath;
    if (!lastPath || lastPath === '/sso-redirect') {
      goToPage = '/plans';
    }
    if (lastPath === '/payment') {
      logEvent(AnalyticsEvent.ACCTDASH_EXIT_PAYMENT, userProfile);
    }
    dispatch(setPrevPathName('/billing'));
    dispatch(clearPromoCodeState());
    history.push(goToPage);
  };

  const handleSubmit = () => {
    props.handleSubmit();
  };

  const getRegretButton = () => {
    return (
      <Button
        id={`${brand}-back-btn`}
        className={`${brand}-btn ${brand}-btn-text`}
        style={{
          textTransform: 'none',
          fontWeight: 'bolder',
        }}
        onClick={goBack}>
        {lastPath !== '/payment' ? (
          <FormattedMessage {...messages.backButton} />
        ) : (
          <FormattedMessage {...messages.cancelButton} />
        )}
      </Button>
    );
  };

  const getSubmitButton = () => {
    return (
      <Button
        className={submitDisabled ? `${brand}-btn ${brand}-btn-action-disabled` : `${brand}-btn ${brand}-btn-action`}
        style={{
          height: 42,
          width: 224,
          textTransform: 'none',
          fontWeight: 'bolder',
        }}
        onClick={() => handleSubmit()}
        id={`${brand}-next-btn`}
        disabled={submitDisabled}>
        {lastPath !== '/payment' ? (
          <FormattedMessage {...messages.nextButton} />
        ) : (
          <FormattedMessage {...messages.submitButton} />
        )}
      </Button>
    );
  };

  return (
    <Paper variant="outlined" className="summary-order-footer" style={{ width: '100%' }}>
      <div className="summary-order-footer-action-buttons">
        {getRegretButton()}

        <div>{getSubmitButton()}</div>
      </div>
    </Paper>
  );
};

export default PaymentDetailsActions;
