import React from 'react';

//material ui
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';

//redux
import { getBrandImagesSelector } from '../BrandProvider/selectors';
import { useSelector } from 'react-redux';

//third party lib
import { FormattedMessage, injectIntl } from 'react-intl';
import planFeatures from './dataLossPlanFeatures';
import messages from './messages';
import { brandNames } from '../../constants/brandNames';

interface Props {
  open: boolean;
  handleClose: Function;
  confirmDataLoss: Function;
  brand: string;
  devices: any;
  plan: string;
  external_plan_code: string;
  intl: any;
}

const DataLossModal = (props: Props) => {
  const { open, brand, devices } = props;
  const images = useSelector(getBrandImagesSelector);

  const handleClose = () => {
    props.handleClose();
  };

  const confirmDataLoss = () => {
    props.confirmDataLoss();
  };

  const getDevicesNames = (): string => {
    let names: string = '';

    if (devices.length > 0) {
      if (devices.length > 1) {
        for (let i = 0; i < devices.length; ++i) {
          if (i === devices.length - 1) {
            names += ' and ' + devices[i].name;
          } else if (i === devices.length - 2) {
            names += devices[i].name;
          } else {
            names += devices[i].name + ', ';
          }
        }

        names += ' devices';
      } else {
        names = devices[0].name + ' device';
      }
    }

    return names;
  };

  const getDataLossSubText = () => {
    if (props.brand === brandNames.pepper) {
      return messages.pepperDataLossQuestion;
    }

    return messages.dataLossQuestion;
  };

  const getDataLossStatement = () => {
    if (props.brand === brandNames.pepper) {
      return messages.pepperDataLossStatement;
    }

    return messages.dataLossStatement;
  };

  return (
    <div style={{ maxWidth: 600 }}>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="data-loss-dialog-title"
        aria-describedby="data-loss-dialog-description"
      >
        <DialogTitle id="data-loss-dialog-title" disableTypography={true} style={{ fontWeight: 'bold' }}>
          <Typography variant="h5">
            <FormattedMessage
              {...messages.modalTitle}
              values={{
                device: devices.length === 1 ? 'a device' : 'devices',
                plan: props.plan,
              }}
            />
          </Typography>
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            style={{
              marginBottom: 20,
            }}
            id="data-loss-dialog-description"
          >
            <FormattedMessage
              {...getDataLossSubText()}
              values={{
                devicesNames: getDevicesNames(),
              }}
            />
          </DialogContentText>
          <DialogContentText
            id="data-loss-dialog-description"
            style={{
              textTransform: 'uppercase',
              fontWeight: 'bolder',
              color: '#5C687C',
            }}
          >
            <FormattedMessage {...messages.dataLossFeatures} />
          </DialogContentText>
          <div style={{ marginLeft: 10 }}>
            {planFeatures[props.external_plan_code] &&
              planFeatures[props.external_plan_code].map((feature: any, index: number) => (
                <div
                  key={index}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    marginBottom: 10,
                  }}
                >
                  <Typography
                    align="left"
                    style={{
                      display: 'flex',
                    }}
                  >
                    <FormattedMessage
                      {...feature}
                      values={{
                        cancelIcon: (
                          <img
                            style={{ marginRight: 7 }}
                            src={images['cancel-icon'] ? images['cancel-icon'] : null}
                            alt="cancel"
                          />
                        ),
                      }}
                    />
                  </Typography>
                </div>
              ))}
          </div>
          <div
            style={{
              marginTop: 15,
              marginBottom: 15,
            }}
          >
            <Typography variant="h5">
              <FormattedMessage {...messages.backUp} />
            </Typography>
          </div>
          <Typography variant="body2">
            <FormattedMessage
              {...getDataLossStatement()}
              values={
                props.brand === brandNames.pepper
                  ? {
                      device: devices.length === 1 ? 'device' : 'devices',
                      camera: devices.length === 1 ? 'camera' : 'cameras',
                    }
                  : {
                      device: devices.length === 1 ? 'device' : 'devices',
                    }
              }
            />
          </Typography>
        </DialogContent>
        <DialogActions id="data-loss-dialog-actions">
          <Button
            onClick={handleClose}
            className={`${brand}-btn-text`}
            style={{ textTransform: 'none', marginBottom: 5 }}
            id="not-now-btn"
          >
            <FormattedMessage {...messages.notNowButton} />
          </Button>
          <Button
            onClick={confirmDataLoss}
            className={`${brand}-btn ${brand}-btn-action `}
            style={{
              width: 265,
              textTransform: 'none',
              height: 42,
              fontWeight: 'bolder',
            }}
            autoFocus
            id="yes-im-sure-btn"
          >
            <FormattedMessage {...messages.yesImSureButton} />
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default injectIntl(DataLossModal);
