export const GET_SUBSCRIPTION_PLANS_ATTEMPT = 'GET_SUBSCRIPTION_PLANS_ATTEMPT';
export const GET_SUBSCRIPTION_PLANS_SUCCESS = 'GET_SUBSCRIPTION_PLANS_SUCCESS';
export const GET_SUBSCRIPTION_PLANS_FAILURE = 'GET_SUBSCRIPTION_PLANS_FAILURE';
export const GET_DEVICE_MODELS_ATTEMPT = 'GET_DEVICE_MODELS_ATTEMPT';
export const GET_DEVICE_MODELS_SUCCESS = 'GET_DEVICE_MODELS_SUCCESS';
export const GET_DEVICE_MODELS_FAILURE = 'GET_DEVICE_MODELS_FAILURE';
export const CHECK_MIGRATION_REQUIRED = 'CHECK_MIGRATION_REQUIRED';
export const CHECK_MIGRATION_COMPLETE = 'CHECK_MIGRATION_COMPLETE';
export const MIGRATION_ATTEMPT = 'MIGRATION_ATTEMPT';
export const MIGRATION_FAILURE = 'MIGRATION_FAILURE';
export const MIGRATION_COMPLETE = 'MIGRATION_COMPLETE';
export const CLEAR_STATE = 'CLEAR_STATE';
export const CLEAR_MIGRATION_STATE = 'CLEAR_MIGRATION_STATE';

