import axios from 'axios';

import { loadConfig } from '../env/config_util';
const config = loadConfig();

export function createHttpClient(brand, context = config.config_module[brand].context) {
  const baseUrl = config.config_module[brand].base_url + '/' + context;
  return axios.create({
    baseURL: baseUrl,
    withCredentials: false,
  });
}

export function createOAuthHttpClient(brand) {
  const baseUrl = config.config_module[brand].oauth_url;
  return axios.create({
    baseURL: baseUrl,
    withCredentials: false,
  });
}