import { get as _get } from 'lodash';

import { getAnalyticsInfo } from '../../env/config_util';
import { CleverTapWrapper } from './wrappers/clevertapWrapper';

/**
 * Initialize analytics provider based on given brand.
 *
 * @param brand
 */
export const initAnalytics = (brand: string) => {
  const wrapper = _getWrapper(brand);
  if (wrapper) {
    try {
      wrapper.init();
    } catch (error) {
      console.error(`CleverTap init error. No analytics will be reported: ${error}`);
    }
  } else {
    console.log('failed to get analytics wrapper, cannot initialize analytics.  No analytics will be reported');
  }
};

/**
 * Invoke analytics provider to log given event with the given data.
 *
 * @param eventName
 * @param profile user's profile as returned by authentication call.
 * @param data additional data besides what is in the user's profile that needs
 *   to be logged. Only needed if the given event requires it.
 */
export const logEvent = (eventName: string, profile: any, data: any = {}) => {
  const eventData = _buildAnalyticsEventData(profile, data);
  const wrapper = _getWrapper(profile?.brand);
  if (wrapper) {
    try {
      wrapper.logEvent(eventName, eventData);
    } catch (err) {
      console.log(`error encountered logging event: eventName ${eventName},\neventData: ${eventData},\nerr: ${err}`);
    }
  } else {
    console.log(`failed to get analytics wrapper, cannot log event: eventName ${eventName},\neventData: ${eventData}`);
  }
};

/**
 * Invoke analytics provider to identify the logged-in user.
 *
 * @param profile user's profile as returned by authentication call.
 */
export const identifyUser = (profile: any) => {
  const eventData = _buildAnalyticsEventData(profile, undefined);
  const wrapper = _getWrapper(profile?.brand);

  if (wrapper) {
    try {
      wrapper.identifyUser(eventData.account_id, eventData.email);
    } catch (err) {
      console.log(`error encountered identifying the current user,\nerr: ${err}`);
    }
  } else {
    console.log(`failed to get analytics wrapper, cannot identify current user,\neventData: ${eventData}`);
  }
};

/**
 * Package the data to be sent with the event.
 *
 * @param profile
 * @param data
 */
const _buildAnalyticsEventData = (profile: any, data: any) => {
  return {
    account_id: _get(profile, 'pepperUser.account_id'),
    email: _getEmailAddress(profile),
    ...data,
  };
};

/**
 * Extract the user's email address from the profile.
 *
 * @param event
 */
const _getEmailAddress = (event: any) => {
  let emailAddress;
  emailAddress = _get(event, 'email');
  if (emailAddress) return emailAddress;

  // If Cognito
  emailAddress = _get(event, 'externalAccounts.cognito.email');
  if (emailAddress) return emailAddress;

  // If LoginRadius
  emailAddress = _get(event, 'externalAccounts.loginRadius.email');
  if (emailAddress) return emailAddress;
};

/**
 * Returns the analytics provider API wrapper for the given brand.
 *
 * All analytics provider APIs are wrapped so that they are not explicitly referenced by business logic.  This allows
 * different brands to use different analytics providers.
 *
 * @param brand - Name of analytics provider as used in the *_config files im the root of this project.
 */
const _getWrapper = (brand: string) => {
  const analyticsInfo = getAnalyticsInfo(brand);
  const providerName = analyticsInfo?.provider;
  if (providerName === undefined) {
    console.log(`WrapperFactory: No analytics provider defined for ${brand}.  Analytics will not be reported.`);
  }
  switch (providerName) {
    case 'clevertap': {
      return new CleverTapWrapper(analyticsInfo);
    }
    default: {
      console.log(`${providerName} is not a recognized analytics provider.  Analytics will not be reported.`);
      return undefined;
    }
  }
};
