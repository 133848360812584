/*
 * LoginPage Messages
 *
 * This contains all the text for the LoginPage component.
 */
import { defineMessages } from 'react-intl';
import { EMAIL_VERIFICATION_REQUEST_DISABLED_MINUTES } from '../../constants/authentication';

export default defineMessages({
  dividerOr: {
    id: 'accountManagement.components.loginPage.divider.or',
    defaultMessage: 'OR',
  },
  forgotPassword: {
    id: 'accountManagement.components.loginPage.Links.ForgotPassword.message',
    defaultMessage: 'Forgot Password?',
  },
  username: {
    id: 'accountManagement.components.loginPage.fields.username.text',
    defaultMessage: 'Email Address',
  },
  password: {
    id: 'accountManagement.components.loginPage.fields.password.text',
    defaultMessage: 'Password',
  },
  rememberMe: {
    id: 'accountManagement.components.loginPage.fields.rememberMe.text',
    defaultMessage: 'Remember Me',
  },
  signIn: {
    id: 'accountManagement.components.loginPage.button.signIn.text',
    defaultMessage: 'Sign In',
  },
  signInWithApple: {
    id: 'accountManagement.components.loginPage.button.appleSignIn.alt',
    defaultMessage: 'Sign in with Apple',
  },
  signInError: {
    id: 'accountManagement.components.loginPage.error.signInError.text',
    defaultMessage: 'Sorry there was an error while logging in.',
  },
  signInVerifyEmailError: {
    id: 'accountManagement.components.loginPage.error.signInVerifyEmailError.text',
    defaultMessage: 'Email is not verified. Please verify and try again.',
  },
  signInTooManyResendsError: {
    id: 'accountManagement.components.loginPage.error.signInTooManyResendsError.text',
    defaultMessage:
      'You requested too many verification codes. For security purposes, please wait {EMAIL_VERIFICATION_REQUEST_DISABLED_MINUTES} {EMAIL_VERIFICATION_REQUEST_DISABLED_MINUTES, plural, one {minute} other {minutes}} and try again.',
    values: { EMAIL_VERIFICATION_REQUEST_DISABLED_MINUTES },
  },
  oauthMissingParametersError: {
    id: 'accountManagement.components.oauth.error.oauthMissingParametersError.text',
    defaultMessage: 'Missing URL parameters for OAuth2 authentication.',
  },
  googleAuthMessage: {
    id: 'accountManagement.components.loginPage.googleAuthMessage.text',
    defaultMessage: 'By signing in, you are authorizing Google to access your devices',
  },
  alexaAuthMessage: {
    id: 'accountManagement.components.loginPage.alexaAuthMessage.text',
    defaultMessage: 'By signing in, you are authorizing Alexa to access your devices',
  },
  getAppPrompt: {
    id: 'accountManagement.components.loginPage.getAppPrompt.text',
    defaultMessage: "Don't have the app?",
  },
  getAppAndroid: {
    id: 'accountManagement.components.loginPage.getAppAndroid.text',
    defaultMessage: 'Get it on Google Play',
  },
  getAppIOS: {
    id: 'accountManagement.components.loginPage.getAppIOS.text',
    defaultMessage: 'Download on the App Store',
  },
});
