import { ResetPasswordTypes, IResetPassword } from './types';

import {
  APP_URI_FAILURE,
  APP_URI_SUCCESS,
  RESET_PASSWORD_ATTEMPT,
  RESET_PASSWORD_FAILURE,
  RESET_PASSWORD_SUCCESS,
  CLEAR_STATE,
} from './actionTypes';

export const initialState: IResetPassword = {
  appUriObj: null,
  attempting: false,
  error: null,
  failed: false,
  success: false,
};

export const ResetPasswordReducer = (state = initialState, action: ResetPasswordTypes): IResetPassword => {
  switch (action.type) {
    case APP_URI_FAILURE:
      return {
        ...state,
        appUriObj: null,
      };
    case APP_URI_SUCCESS:
      return {
        ...state,
        appUriObj: action.appUriObj,
      };
    case RESET_PASSWORD_ATTEMPT:
      return {
        ...state,
        error: null,
        failed: false,
        success: false,
        attempting: true,
      };
    case RESET_PASSWORD_FAILURE:
      return {
        ...state,
        error: action.error,
        failed: true,
        success: false,
        attempting: false,
      };
    case RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        error: null,
        failed: false,
        success: true,
        attempting: false,
      };
    case CLEAR_STATE:
      return initialState;
    default:
      return state;
  }
};
