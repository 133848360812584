import React, { useEffect } from 'react';

//controls
import AccountHistoryTable from './AccountHistoryTable';
import messages from './messages';
import { FormattedMessage } from 'react-intl';

//redux
import { getSubscriptionPlansSelector } from '../SubscriptionsList/selectors';
import { useSelector, useDispatch } from 'react-redux';
import { getAcctHistoryAttempt } from './actions';
import { getBrandNameSelector } from '../BrandProvider/selectors';
import {
  getAccountHistoryAttemptSelector,
  getAccountHistorySelector,
  getAccountHistoryErrorSelector,
} from './selectors';

//material ui
import CircularProgress from '@material-ui/core/CircularProgress';
import { Alert, AlertTitle } from '@material-ui/lab';
import Typography from '@material-ui/core/Typography';
import { AnalyticsEvent } from '../../utils/analytics/events';
import { logEvent } from '../../utils/analytics/analyticsLogger';
import { getProfileSelector } from '../LoginPage/selectors';

interface Props {}

const AccountHistory = (props: Props) => {
  const accountHistory = useSelector(getAccountHistorySelector);
  const subscriptions = useSelector(getSubscriptionPlansSelector);
  const loading = useSelector(getAccountHistoryAttemptSelector);
  const error = useSelector(getAccountHistoryErrorSelector);
  const brand = useSelector(getBrandNameSelector);
  const userProfile = useSelector(getProfileSelector);

  const dispatch = useDispatch();

  useEffect(() => {
    const fetchPlans = () => {
      dispatch(getAcctHistoryAttempt());
    };

    fetchPlans();
    logEvent(AnalyticsEvent.ACCTDASH_HISTORY, userProfile);
  }, [dispatch, userProfile]);

  const containsAppleSubscription = () => {
    let appleSubscriptionExists = subscriptions.find((subscription: any) => {
      if (subscription.plan_config && subscription.plan_config.provider) {
        return subscription.plan_config.provider.toLowerCase() === 'apple';
      }
      return false;
    });

    return appleSubscriptionExists ? true : false;
  };

  const getAccountHistoryData = () => {
    if (accountHistory.length > 0 && !error) {
      return (
        <AccountHistoryTable
          accountHistory={accountHistory}
          containsAppleSub={containsAppleSubscription()}
          brand={brand}></AccountHistoryTable>
      );
    }

    if (containsAppleSubscription() && accountHistory.length === 0 && !error) {
      return (
        <Typography variant="h6" align="center" style={{ margin: 12 }}>
          <FormattedMessage {...messages.appleSubscriptions} />
        </Typography>
      );
    }

    if (!containsAppleSubscription() && accountHistory.length === 0 && !error) {
      return (
        <Typography variant="h6" align="center" style={{ margin: 12 }}>
          <FormattedMessage {...messages.noAcctHistory} />
        </Typography>
      );
    }

    if (error) {
      return (
        <Alert severity="error">
          <AlertTitle>Error</AlertTitle>
          <FormattedMessage {...messages.networkError} />
        </Alert>
      );
    }
  };

  return (
    <>
      {loading || accountHistory === null ? (
        <div className="progress-wrapper-center">
          <CircularProgress size={55} className={`${brand}-button-progress`} />
        </div>
      ) : (
        <div>{getAccountHistoryData()}</div>
      )}
    </>
  );
};

export default AccountHistory;
