import { PaymentInfoActionTypes, IPaymentInfo } from './types';
import {
  CLEAR_BILLING_INFORMATION_STATE,
  GET_PAYMENT_INFORMATION_ATTEMPT,
  GET_PAYMENT_INFORMATION_SUCCESS,
  GET_PAYMENT_INFORMATION_FAILURE,
  CREATE_PAYMENT_INFORMATION_UPDATE_DELEGATE_ATTEMPT,
  CREATE_PAYMENT_INFORMATION_UPDATE_DELEGATE_SUCCESS,
  CREATE_BILLING_INFORMATION_UPDATE_DELEGATE_FAILURE,
} from './actionTypes';

export const initialState: IPaymentInfo = {
  billingInfo: null,
  error: null,
  attempting: false,
  updateBillingInfoSession: undefined,
};

export const PaymentInfoReducer = (state = initialState, action: PaymentInfoActionTypes): IPaymentInfo => {
  switch (action.type) {
    case GET_PAYMENT_INFORMATION_ATTEMPT:
      return {
        ...state,
        attempting: true,
      };
    case GET_PAYMENT_INFORMATION_SUCCESS:
      return {
        ...state,
        billingInfo: action.billingInfo,
        error: null,
        attempting: false,
      };
    case GET_PAYMENT_INFORMATION_FAILURE:
      return {
        ...state,
        error: action.error,
        attempting: false,
      };
    case CREATE_PAYMENT_INFORMATION_UPDATE_DELEGATE_ATTEMPT:
      return {
        ...state,
        updateBillingInfoSession: {
          updateBillingInfoUrl: '',
          attempting: true,
        },
      };
    case CREATE_PAYMENT_INFORMATION_UPDATE_DELEGATE_SUCCESS:
      return {
        ...state,
        updateBillingInfoSession: {
          updateBillingInfoUrl: action.updateBillingInfoSessionUrl,
          attempting: false,
        },
      };
    case CREATE_BILLING_INFORMATION_UPDATE_DELEGATE_FAILURE:
      return {
        ...state,
        updateBillingInfoSession: {
          updateBillingInfoUrl: '',
          error: action.error,
          attempting: false,
        },
      };
    case CLEAR_BILLING_INFORMATION_STATE:
      return initialState;
    default:
      return state;
  }
};
