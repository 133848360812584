import { PromoCodeTypes, IPromoCode, PromoCodeData } from './types';

import {
  PROMO_CODE_VALIDATION_ATTEMPT,
  PROMO_CODE_VALIDATION_SUCCESS,
  PROMO_CODE_VALIDATION_FAILURE,
  PROMO_CODE_INVALID,
  PROMO_CODE_VALIDATION_CLEAR_STATE,
  CLEAR_STATE,
  PROMO_CODE_PREDEFINED_CODES,
  PROMO_CODE_REFRESH_ATTEMPT,
  PROMO_CODE_REMOVE_CODE,
} from './actionTypes';

export const initialState: IPromoCode = {
  code: '',
  errorCode: null,
  attempting: false,
  valid: true,
  validationResponse: null,
  message: '',
  codesData: {},
  codes: [],
};

const getValidCode = (codes: string[], codeData: Record<string, PromoCodeData>) => {
  return codes.reverse().filter(code => codeData[code].attempting === 'fulfilled')?.[0];
};

export const PromoCodeReducer = (state = initialState, action: PromoCodeTypes): IPromoCode => {
  let codes = state.codes ? [...state.codes] : [];
  let codesData = state.codesData ? { ...state.codesData } : {};
  let validCode = null;
  switch (action.type) {
    case PROMO_CODE_VALIDATION_ATTEMPT:
      codesData[action.code] = {
        attempting: 'pending',
        message: '',
        errorCode: undefined,
      };
      // We want to make sure we don't include duplicates
      if (!codes.includes(action.code)) {
        codes = [...codes, action.code];
      }
      return {
        ...state,
        valid: true,
        attempting: true,
        code: action.code,
        errorCode: null,
        codesData,
        codes,
      };
    case PROMO_CODE_VALIDATION_SUCCESS:
      codesData[action.code] = {
        attempting: 'fulfilled',
        message: action.message,
        errorCode: undefined,
      };
      return {
        ...state,
        code: action.code,
        attempting: false,
        valid: true,
        validationResponse: action.message,
        errorCode: null,
        codesData: codesData,
      };
    case PROMO_CODE_VALIDATION_FAILURE:
      codesData[action.code] = {
        attempting: 'rejected',
        message: '',
        errorCode: action.errorCode,
      };
      validCode = getValidCode(codes, codesData);
      return {
        ...state,
        code: action.code !== state.code ? state.code : validCode ?? '',
        errorCode: action.errorCode,
        valid: false,
        attempting: false,
        validationResponse: validCode ? codesData[validCode].message : null,
        codesData: codesData,
      };
    case PROMO_CODE_INVALID:
      delete codesData[action.code];
      codes = codes.filter(code => code !== action.code);
      validCode = getValidCode(codes, codesData);
      return {
        ...state,
        code: action.code !== state.code ? state.code : validCode ?? '',
        validationResponse: action.message,
        valid: false,
        attempting: false,
        errorCode: undefined,
        codesData: codesData,
        codes: codes,
      };
    case PROMO_CODE_PREDEFINED_CODES:
      action.codes.forEach((code: string) => {
        codesData[code] = {
          attempting: 'pending',
          message: '',
        };
      });
      return {
        ...state,
        codesData: codesData,
        codes: action.codes,
      };
    case PROMO_CODE_REFRESH_ATTEMPT:
      codes.forEach((code: string) => {
        codesData[code] = {
          attempting: 'pending',
          message: '',
          errorCode: undefined,
        };
      });
      return {
        ...state,
        codesData: codesData,
      };
    case PROMO_CODE_REMOVE_CODE:
      delete codesData[action.code];
      codes = codes.filter(code => code !== action.code);
      validCode = getValidCode(codes, codesData);
      return {
        ...state,
        code: action.code !== state.code ? state.code : validCode ?? '',
        validationResponse: validCode ? codesData[validCode].message : null,
        attempting: false,
        errorCode: undefined,
        valid: true,
        codesData: codesData,
        codes: codes,
      };
    case PROMO_CODE_VALIDATION_CLEAR_STATE:
      return {
        ...initialState,
        codesData: { ...state.codesData },
        codes: [...state.codes],
      };
    case CLEAR_STATE:
      return initialState;
    default:
      return state;
  }
};
