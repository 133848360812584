import React from 'react';
import Select from '@material-ui/core/Select';

const Dropdown = (props: any) => {
  return (
    <div className={props.className}>
      <Select
        native
        id={props.id || 'drop-down'}
        value={props.value}
        onChange={e => props.onChange(e, props.id)}
        style={{ marginTop: 16, width: '100%', fontSize: 16 }}
      >
        {Object.keys(props.values).map((value, index) => (
          <option key={index} value={value}>
            {props.values[value]}
          </option>
        ))}
      </Select>
    </div>
  );
};

export default Dropdown;
