/*
 * MigrationModal Messages
 *
 * This contains all the text for the migration modal component.
 */
import { defineMessages } from 'react-intl';

export default defineMessages({
  attemptingModalHeader: {
    id: 'accountManagement.components.label.migrationModal.attemptingModalHeader.text',
    defaultMessage: 'Great things are about to happen!',
  },
  migrationStatement: {
    id: 'accountManagement.components.label.migrationModal.migrationStatement.text',
    defaultMessage:
      'You are eligible for a {freeText} service upgrade. Please wait a moment while we update your plan.',
  },
  freeText: {
    id: 'accountManagement.components.label.migrationModal.freeText.text',
    defaultMessage: 'free',
  },
  warningAltText: {
    id: 'accountManagement.components.label.migrationModal.warningAltText.text',
    defaultMessage: 'Migration Gift',
  },
  goAccount: {
    id: 'accountManagement.components.label.migrationModal.goAccount.text',
    defaultMessage: 'Go to Account',
  },
  upgradeStatement: {
    id: 'accountManagement.components.label.migrationModal.upgradeStatement.text',
    defaultMessage:
      "We've upgraded your plan to the {plan} Plan at no additional cost. You now get {feature} for up to {maxCameras} cameras. Thank you for being a loyal Momentum customer.",
  },
  errorStatement: {
    id: 'accountManagement.components.label.migrationModal.errorStatement.text',
    defaultMessage:
      'Sorry, we are unable to update your subscription automatically. Contact us to receive a free service upgrade. You can continue using your Momentum app without interruption.',
  },
  signOutBtn: {
    id: 'accountManagement.components.label.migrationModal.signOutBtn.text',
    defaultMessage: 'Sign Out',
  },
  contactUsButton: {
    id: 'accountManagement.components.label.migrationModal.contactUsButton.text',
    defaultMessage: 'Contact Us',
  },
  sevenVideoHistory: {
    id: 'accountManagement.components.label.migrationModal.sevenVideoHistory.text',
    defaultMessage: '7 days of video history',
  },
  thirtyVideoHistory: {
    id: 'accountManagement.components.label.migrationModal.thirtyVideoHistory.text',
    defaultMessage: '30 days of video history',
  },
  sixtyVideoHistory: {
    id: 'accountManagement.components.label.migrationModal.sixtyVideoHistory.text',
    defaultMessage: '60 days of video history',
  },
  defaultVideoHistory: {
    id: 'accountManagement.components.label.migrationModal.defaultVideoHistory.text',
    defaultMessage: '7 days of video history',
  },
});
