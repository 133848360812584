import { createSelector } from 'reselect';

import { AppState } from '../../store/rootReducer';

const getAttempting = (state: AppState) => state.deleteAccount.attempting;
const getDeleteSuccess = (state: AppState) => state.deleteAccount.deleteAccountSuccess;
const getDeleteError = (state: AppState) => state.deleteAccount.error;

export const getDeleteAccountAttemptingSelector = createSelector(getAttempting, attempting => attempting);

export const getDeleteAccountSuccessSelector = createSelector(
  getDeleteSuccess,
  cancelDeleteSuccess => cancelDeleteSuccess,
);

export const getDeleteAccountErrorSelector = createSelector(getDeleteError, error => error);
