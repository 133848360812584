import { all, call, put, takeLatest } from 'redux-saga/effects';
import { GET_DELETE_ACCOUNT_ATTEMPT } from './actionTypes';
import { postDataToService } from '../../services/apiGatewayClient';
import { getDeleteSuccess, getDeleteFailure } from './actions';

import { TypedIterableIterator } from '../../modules/helpers';

function* deleteAccountAttempt(): TypedIterableIterator<any> {
  try {
    const req = yield call(postDataToService, 'account/users/deletionRequests', {}, '');

    if (req?.statusCode === 200) {
      yield put(getDeleteSuccess());
    } else {
      throw new Error('Cancel Delete Account Failed');
    }
  } catch (error) {
    yield put(getDeleteFailure(error));
  }
}

function* deleteAccountSaga() {
  yield all([takeLatest(GET_DELETE_ACCOUNT_ATTEMPT, deleteAccountAttempt)]);
}

export default deleteAccountSaga;
