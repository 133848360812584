import React, { Fragment } from 'react';
import { IntlProvider } from 'react-intl';
import { localeFormats, LOCALES } from './constants';
import messages from './messages';
import { useSelector } from 'react-redux';
import { getLocaleSelector } from './selectors';
import { getCurrencyCountrySelector } from '../../containers/BillingInformationView/selectors';

interface Props {
  children: any;
}

const LanguageProvider = (props: Props) => {
  const locale = useSelector(getLocaleSelector);
  const currencyCountry = useSelector(getCurrencyCountrySelector);

  return (
    <IntlProvider
      textComponent={Fragment}
      locale={locale}
      messages={messages[locale]}
      formats={localeFormats[currencyCountry]}
      defaultFormats={localeFormats[LOCALES.ENGLISH]}
      key={locale}
      onError={() => {}}>
      {props.children}
    </IntlProvider>
  );
};

export default LanguageProvider;
