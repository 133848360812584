import { BrandInfoActionTypes } from './types';
import {
  SET_BRAND_INFO,
  SET_BRAND_IMAGES,
  SET_LOCALE,
  SET_CONFIG,
  SET_FLOW,
  SET_BILLING_PROVIDER,
  SET_LOCATION,
} from './actionTypes';

export const setBrand = (name: string, brandName: string): BrandInfoActionTypes => ({
  type: SET_BRAND_INFO,
  name: name,
  brandName: brandName,
});

export const setImages = (images: object): BrandInfoActionTypes => ({
  type: SET_BRAND_IMAGES,
  images: images,
});

export const setLocale = (locale: object): BrandInfoActionTypes => ({
  type: SET_LOCALE,
  locale: locale,
});

export const setLocation = (location: object): BrandInfoActionTypes => ({
  type: SET_LOCATION,
  location: location,
});

export const setConfig = (config: object): BrandInfoActionTypes => ({
  type: SET_CONFIG,
  config: config,
});

export const setFlow = (flow: string): BrandInfoActionTypes => ({
  type: SET_FLOW,
  flow: flow,
});

export const setBillingProvider = (billingProvider: string): BrandInfoActionTypes => ({
  type: SET_BILLING_PROVIDER,
  billingProvider: billingProvider,
});
