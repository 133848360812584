import React from 'react';
import { getBrandNameSelector } from '../../BrandProvider/selectors';
import { useSelector } from 'react-redux';
import { Helmet } from 'react-helmet-async';

interface Props {
  children: any;
  layoutClasses?: string;
}

const EmptyLayout = (props: Props) => {
  const brand = useSelector(getBrandNameSelector);

  return (
    <div className={`${props.layoutClasses} ${brand}-empty-layout`}>
      <Helmet titleTemplate="%s - Account Management" defaultTitle="Account Management">
        <meta name="description" content="Account Management" />
      </Helmet>
      <main role="main">{React.Children.toArray(props.children)}</main>
    </div>
  );
};

export default EmptyLayout;
