import { createSelector } from 'reselect';

import { AppState } from '../../store/rootReducer';

const getAttemptingSubmitSubscription = (state: AppState) => state.orderSummary.subscriptionAttempting;

const getSubscriptionPreview = (state: AppState) => state.orderSummary.subscriptionPreview;

const getRecurlyPreviewResponse = (state: AppState) => state.orderSummary.recurlyPreviewResponse;

const getSubscriptionSuccess = (state: AppState) => state.orderSummary.subscriptionSuccess;

const getSubscriptionResponse = (state: AppState) => state.orderSummary.subscriptionResponse;

const getSubscriptionFailure = (state: AppState) => state.orderSummary.subscriptionFailure;

const getSubscriptionErrorCode = (state: AppState) => state.orderSummary.subscriptionError;

const getSubscriptionPreviewError = (state: AppState) => state.orderSummary.subscriptionPreviewError;

export const getAttemptingSubmitSubscriptionSelector = createSelector(
  getAttemptingSubmitSubscription,
  attempting => attempting,
);

export const getSubscriptionPreviewSelector = createSelector(getSubscriptionPreview, sub => sub);

export const getRecurlyPreviewResponseSelector = createSelector(
  getRecurlyPreviewResponse,
  recurlyPreview => recurlyPreview,
);

export const getSubscriptionSuccessSelector = createSelector(getSubscriptionSuccess, success => success);

export const getSubscriptionResponseSelector = createSelector(getSubscriptionResponse, res => res);

export const getSubscriptionFailureSelector = createSelector(getSubscriptionFailure, err => err);

export const getSubscriptionErrorCodeSelector = createSelector(getSubscriptionErrorCode, errCode => errCode);

export const getSubscriptionPreviewErrorSelector = createSelector(
  getSubscriptionPreviewError,
  subscriptionPreviewError => subscriptionPreviewError,
);
