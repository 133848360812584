import React, { useState, useCallback, useEffect } from 'react';

//material ui
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

//third party lib
import { FormattedMessage, injectIntl } from 'react-intl';
import isUndefined from 'lodash/isUndefined';
import messages from './messages';

//redux
import { useSelector, useDispatch } from 'react-redux';
import {
  getIsValidatingSelector,
  getPromoCodeErrorCodeSelector,
  getPromoCodeValidSelector,
  getPromoCodeValidationResSelector,
  getPromoCodesStatusSelector,
  getPromoCodeSelector,
} from './selectors';
import { validatePromoCodeAttempt, clearPromoCodeState, validatePromoCodesAttempt, removePromoCode } from './actions';
import { logEvent } from '../../utils/analytics/analyticsLogger';
import { AnalyticsEvent } from '../../utils/analytics/events';
import { getProfileSelector } from '../LoginPage/selectors';
import CloseIcon from '@material-ui/icons/Close';
import DoneIcon from '@material-ui/icons/Done';
import Box from '@material-ui/core/Box';

interface Props {
  brand: string;
  intl: any;
}

const useStyles = makeStyles(theme => ({
  root: {
    '& > *': {
      margin: theme.spacing(0),
      width: '25ch',
    },
  },
}));

const PromoCode = (props: Props) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { brand } = props;

  const [promoCode, setPromoCode] = useState('');
  const [lastPromoAttempt, setLastPromoAttempt] = useState('');

  const validatingCoupon = useSelector(getIsValidatingSelector);
  const promoCodeValid = useSelector(getPromoCodeValidSelector);
  const promoCodeErrorCode = useSelector(getPromoCodeErrorCodeSelector);
  const promoCodeResponse = useSelector(getPromoCodeValidationResSelector);
  const promoCodesData = useSelector(getPromoCodesStatusSelector);
  const currentActivePromoCode = useSelector(getPromoCodeSelector);
  const userProfile = useSelector(getProfileSelector);

  useEffect(() => {
    const clearPromoState = () => {
      dispatch(clearPromoCodeState());
      dispatch(validatePromoCodesAttempt());
    };
    clearPromoState();
  }, [dispatch]);

  const handleInputChange = useCallback((e: any) => {
    setPromoCode(e.target.value);
  }, []);

  const getApplyDisableState = () => {
    return promoCode === '';
  };

  const handleRemovePromoCode = useCallback(
    (promoCode: string) => {
      dispatch(removePromoCode(promoCode));
    },
    [dispatch],
  );

  const handleValidatePromoCode = useCallback(() => {
    if (promoCode) {
      setLastPromoAttempt(promoCode);
      dispatch(validatePromoCodeAttempt(promoCode));
    }
  }, [promoCode, dispatch]);

  const getPromoCodeDescription = () => {
    const { formatMessage } = props.intl;
    if (promoCodeResponse && Object.keys(promoCodeResponse).length > 0) {
      if (promoCodeResponse.description) {
        return promoCodeResponse.description + ' promo applied';
      }
      return formatMessage(messages.promoCodeApplied, {
        code: promoCodeResponse.coupon_code,
      });
    }
    return '';
  };

  const getPromoMessage = () => {
    if (!isUndefined(promoCodeValid) && !promoCodeValid && isUndefined(promoCodeErrorCode)) {
      logEvent(AnalyticsEvent.ACCTDASH_PROMO_CODE_ERROR, userProfile);
      return <FormattedMessage {...messages.promoCodeInvalid} values={{ code: lastPromoAttempt }} />;
    } else if (!isUndefined(promoCodeValid) && !promoCodeValid && !isUndefined(promoCodeErrorCode)) {
      logEvent(AnalyticsEvent.ACCTDASH_PROMO_CODE_ERROR, userProfile);
      return <FormattedMessage {...messages.promoCodePlanRestriction} values={{ code: lastPromoAttempt }} />;
    } else if (!isUndefined(promoCodeValid) && promoCodeValid) {
      logEvent(AnalyticsEvent.ACCTDASH_PROMO_CODE, userProfile);
      return getPromoCodeDescription();
    }

    return '';
  };

  return (
    <div>
      <div className={`${brand}-text-primary`} style={{ fontSize: '1.4rem', marginBottom: 10 }}>
        <FormattedMessage {...messages.promoCodeLabel} />
      </div>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
        }}>
        <div
          style={{
            display: 'flex',
            zIndex: 0,
            flexDirection: 'column',
            width: '100%',
          }}>
          <div>
            <form className={classes.root} noValidate autoComplete="off">
              <TextField
                key="promo code"
                style={{ width: '100%' }}
                id="promo-code-textbox"
                label={<FormattedMessage {...messages.promoCodePlaceHolder} />}
                variant="outlined"
                className={`${brand}-text-field`}
                onKeyPress={(e: any) => {
                  if (e.key === 'Enter') {
                    e.preventDefault();
                  }
                }}
                value={promoCode === null || promoCode === undefined ? '' : promoCode}
                onChange={handleInputChange}
              />
            </form>
          </div>
        </div>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {validatingCoupon ? <CircularProgress size={20} className={`${brand}-small-loading-progress`} /> : null}

          <Button
            id="apply-promo-btn"
            className={getApplyDisableState() ? ` ${brand}-btn-text-disabled` : ` ${brand}-btn-text`}
            style={{
              textTransform: 'none',
              marginLeft: 15,
            }}
            disabled={getApplyDisableState()}
            onClick={handleValidatePromoCode}>
            <FormattedMessage {...messages.applyButton} />
          </Button>
        </div>
      </div>
      <Typography
        variant="body2"
        style={{ marginTop: 4 }}
        className={!promoCodeValid ? `${brand}-promo-code-error` : `${brand}-promo-code-valid`}>
        {getPromoMessage()}
      </Typography>
      {/*  Multi Promo section*/}
      <div>
        {/*FIXME: PLAY-15303: We likely won't support multi-promo in this app, but we'd remove filter to enable*/}
        {promoCodesData
          .filter(p => p.promoCode === currentActivePromoCode)
          .map(promo => {
            return (
              <Box key={promo.promoCode} p={1} display="flex" alignItems="center" justifyContent="space-between">
                {promo.attempting === 'pending' ? (
                  <CircularProgress size={20} className={`${brand}-small-loading-progress`} />
                ) : promo.attempting === 'rejected' ? (
                  <CloseIcon color="error" fontSize="large" />
                ) : (
                  <DoneIcon htmlColor="darkgreen" fontSize="large" />
                )}
                <Typography
                  variant="body2"
                  style={{
                    textTransform: 'uppercase',
                  }}
                  className={promo.attempting === 'rejected' ? `${brand}-promo-code-error` : ``}>
                  {promo.promoCode}
                </Typography>
                <Button
                  id="remove-promo-btn"
                  className={`${brand}-btn-text`}
                  style={{
                    textTransform: 'none',
                  }}
                  onClick={() => handleRemovePromoCode(promo.promoCode)}>
                  <FormattedMessage {...messages.removeButton} />
                </Button>
              </Box>
            );
          })}
      </div>
    </div>
  );
};

export default injectIntl(PromoCode);
