import React from 'react';

//material ui
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Tooltip from '@material-ui/core/Tooltip';

//third party lib
import { FormattedMessage, injectIntl } from 'react-intl';
import messages from './messages';

//redux
import { getSelectedSubscriptionSelector } from '../SubscriptionsList/selectors';
import { getBrandImagesSelector } from '../BrandProvider/selectors';
import { getCurrencySelector } from '../BillingInformationView/selectors';
import { useSelector } from 'react-redux';

//components
import CurrencyFormatter from '../../components/Formatters/CurrencyFormatter';
import { planFeatures } from '../../constants/planFeatures';

//icons
import cloudSvg from '../../themes/momentum/images/icon-cloud.svg';
import cameraSvg from '../../themes/momentum/images/icon-camera.svg';
import dotwhackSvg from '../../themes/momentum/images/icon-dotwhack.svg';
import sendHelpSecuritySvg from '../../themes/common/images/icon-send-help.svg';
import snoozeNotificationsSvg from '../../themes/common/images/icon-snooze-notifications.svg';
import smartVisionSvg from '../../themes/common/images/icon-smart-vision.svg';

//constants
import { brandNames } from '../../constants/brandNames';

const useStyles = makeStyles({
  root: {},
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginLeft: 18,
  },
  ribbon: {
    backgroundColor: 'skyblue',
    position: 'relative',
    color: 'white',
    width: 150,
    zIndex: 3,
    textAlign: 'center',
    padding: 5,
    transform: 'rotate(-45deg)',
    marginLeft: -48,
  },
});

interface Props {
  brand: string;
  handleSelectedPlan: any;
  plan: any;
  secondPlan?: any;
  selectedPlan: any;
  currentPlan: any;
  payFrequency: string;
  hasRibbon?: boolean;
  index: number;
  intl: any;
  ineligible?: boolean;
  isUserSignedIn?: boolean;
}

const dayValues: any = {
  lite: '24 Hour',
  '7+month': '7 Day',
  '7+year': '7 Day',
  '30+month': '30 Day',
  '30+year': '30 Day',
  '60+month': '60 Day',
  '60+year': '60 Day',
};

const cameraValues: any = {
  '7+month': '2 Cameras',
  '7+year': '2 Cameras',
  '30+month': '5 Cameras',
  '30+year': '5 Cameras',
  '60+month': '10 Cameras',
  '60+year': '10 Cameras',
};

const PlanCardControl = (props: Props) => {
  const classes = useStyles();
  const images = useSelector(getBrandImagesSelector);
  const currency = useSelector(getCurrencySelector);
  const currSub = useSelector(getSelectedSubscriptionSelector);

  const handleClick = () => {
    let newPlan = getCleanPlan(props.plan);
    props.handleSelectedPlan(newPlan);
  };

  const handleSecondPlanClick = () => {
    props.handleSelectedPlan(props.secondPlan);
  };

  const renderPlanPrice = () => {
    const price: any = props.plan.amountInCents[currency]._;

    if (price !== '0' && price !== 0) {
      return <CurrencyFormatter value={props.plan.amountInCents[currency]._} />;
    }

    return <FormattedMessage {...messages.freePlan}></FormattedMessage>;
  };

  const getCleanPlan = (plan: any) => {
    let cleanPlan = { ...plan };

    if (cleanPlan.secondPlan) {
      delete cleanPlan.secondPlan;
    }

    return cleanPlan;
  };

  const hasPlanPriceTag = () => {
    return props.brand !== brandNames.visi;
  };

  const getActionButton = (plan: any, id: string, btnClass: string, handleFunc: any) => {
    // If Plan is ineligible, disable the button
    if (props.ineligible) {
      return (
        <Button
          className={`${btnClass} ${props.brand}-btn-action-disabled`}
          disabled={true}
          style={{ textTransform: 'none', minWidth: 256, marginLeft: 2 }}
          id={id}>
          <FormattedMessage id={'ineligibleCardButtonText'} />
        </Button>
      );
    }

    if (
      currSub &&
      currSub.pendingSubscription &&
      currSub.pendingSubscription.recurly_plan_code &&
      (currSub.pendingSubscription.recurly_plan_code === plan.id ||
        (plan.external_plan_code && currSub.pendingSubscription.recurly_plan_code === plan.external_plan_code))
    ) {
      return (
        <Button
          className={`${btnClass} ${props.brand}-btn-action-disabled`}
          disabled={true}
          style={{ textTransform: 'none', minWidth: 256, marginLeft: 2 }}
          id={id}>
          <FormattedMessage id={'pendingCardButtonText'} />
        </Button>
      );
    }

    // If plan is pre-selected and user is signed out, prompt them to sign in to continue
    if ((props.selectedPlan === plan || props.selectedPlan?.id === plan.id) && props.isUserSignedIn === false) {
      return (
        <Button
          className={`${btnClass} ${props.brand}-btn`}
          onClick={handleFunc}
          style={{ textTransform: 'none' }}
          id={id}>
          <FormattedMessage id={'signInCardButtonText'} />
        </Button>
      );
    }

    if (props.currentPlan && plan && props.currentPlan.id === plan.id) {
      return (
        <Button
          className={`${btnClass} ${props.brand}-btn-card-selected ${props.brand}-btn`}
          onClick={handleFunc}
          disabled={plan === props.selectedPlan}
          style={{ textTransform: 'none' }}
          id={id}>
          <FormattedMessage
            id={'currentPlanCardButtonText'}
            values={{
              planIcon: <img src={images['selected-plan-icon']} alt="icon" className={`${props.brand}-card-icon`} />,
            }}
          />
        </Button>
      );
    } else if (props.selectedPlan === plan || props.selectedPlan?.id === plan.id) {
      return (
        <Button
          className={`${btnClass} ${props.brand}-btn-card-selected ${props.brand}-btn`}
          onClick={handleFunc}
          disabled={plan === props.selectedPlan}
          style={{ opacity: 0.6, textTransform: 'none' }}
          id={id}>
          <FormattedMessage id={'selectedPlanCardButtonText'} />
        </Button>
      );
    }
    return (
      <Button
        className={`${btnClass} ${props.brand}-btn`}
        onClick={handleFunc}
        disabled={plan === props.selectedPlan}
        style={{ textTransform: 'none' }}
        id={id}>
        {props.brand === brandNames.visi ? (
          <FormattedMessage
            {...messages.btnTextWithPrice}
            values={{
              price: <CurrencyFormatter value={plan.amountInCents[currency]._} />,
              numOfDevices: plan.device_quantity,
            }}
          />
        ) : (
          <FormattedMessage id={'selectPlanCardButtonText'} />
        )}
      </Button>
    );
  };

  return (
    <>
      <Card
        className={
          getCleanPlan(props.plan) === props.selectedPlan ||
          props.secondPlan === props.selectedPlan ||
          (props.selectedPlan && props.plan.id === props.selectedPlan.id)
            ? `${props.brand}-plan-card ${props.brand}-plan-card-selected`
            : `${props.brand}-plan-card`
        }>
        <CardContent>
          {props.hasRibbon ? (
            <div className={`${classes.ribbon} ${props.brand}-ribbon`}>
              <span>
                <FormattedMessage {...messages.ribbon} />
              </span>
            </div>
          ) : null}

          <Typography
            className={
              getCleanPlan(props.plan) === props.selectedPlan ||
              props.secondPlan === props.selectedPlan ||
              (props.selectedPlan && props.plan.id === props.selectedPlan.id)
                ? `${props.brand}-plan-card-title ${props.brand}-plan-card-title-selected`
                : `${props.brand}-plan-card-title`
            }
            align="center"
            style={!props.hasRibbon && props.brand === 'momentum' ? { paddingTop: 27 } : {}}>
            {props.plan.name}
          </Typography>
          <Divider className="card-divider"></Divider>
          {hasPlanPriceTag() ? (
            <div>
              <Typography
                className={`${props.brand}-text-common ${props.brand}-text-colored`}
                align="center"
                variant="h4"
                component="h4">
                {renderPlanPrice()}
              </Typography>
              <div
                style={{
                  minHeight: 17,
                }}>
                <Typography
                  className={`${props.brand}-text-common ${props.brand}-text-colored`}
                  align="center"
                  variant="body2"
                  component="p">
                  {props.payFrequency}
                </Typography>
              </div>
              <Divider className="card-divider"></Divider>
            </div>
          ) : null}

          {planFeatures[props.plan.external_plan_code] &&
            planFeatures[props.plan.external_plan_code].map((feature: any, index: number) => (
              <div
                key={index}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  marginBottom: 5,
                }}>
                <Typography
                  align="left"
                  className={`${classes.pos} ${props.brand}-text-common`}
                  color="textSecondary"
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexWrap: 'nowrap',
                  }}>
                  <FormattedMessage
                    {...feature}
                    values={{
                      boldBeta: (
                        <span
                          style={{ marginLeft: 4, marginBottom: 4, fontSize: 12 }}
                          className={`${props.brand}-text-primary text-bold`}>
                          {' '}
                          BETA
                        </span>
                      ),
                      day: <span>{dayValues[props.plan.external_plan_code]}</span>,
                      cameraCount: <span>{cameraValues[props.plan.external_plan_code]}</span>,
                      checkIcon: (
                        <img
                          style={{ marginRight: 8 }}
                          src={images['check-icon'] ? images['check-icon'] : null}
                          alt="check"
                        />
                      ),
                      cancelIcon: (
                        <img
                          style={{ marginRight: 8 }}
                          src={images['cancel-icon'] ? images['cancel-icon'] : null}
                          alt="cancel"
                        />
                      ),
                      cloudIcon: <img style={{ marginRight: 8 }} src={cloudSvg} alt="Cloud" />,
                      cameraIcon: <img style={{ marginRight: 8 }} src={cameraSvg} alt="Camera" />,
                      dotwhackIcon: <img style={{ marginRight: 8 }} src={dotwhackSvg} alt="Dotwhack" />,
                      sendHelpIcon: <img style={{ marginRight: 8 }} src={sendHelpSecuritySvg} alt="sendHelp" />,
                      snoozeNotificationsIcon: (
                        <img style={{ marginRight: 8 }} src={snoozeNotificationsSvg} alt="snoozeNotifications" />
                      ),
                      smartVisionIcon: <img style={{ marginRight: 8 }} src={smartVisionSvg} alt="smartVision" />,
                    }}
                  />
                </Typography>
                {feature.defaultMessage && feature.defaultMessage.indexOf('First Month') !== -1 && (
                  <Tooltip
                    title={
                      <span style={{ fontSize: '0.8rem' }}>{props.intl.formatMessage(messages.freeTrialOffer)}</span>
                    }
                    arrow>
                    <img
                      src={images['information'] ? images['information'] : null}
                      alt={'tooltip'}
                      style={{ marginLeft: 7 }}
                    />
                  </Tooltip>
                )}
              </div>
            ))}
        </CardContent>
        <CardActions style={{ display: 'flex', flexDirection: 'column' }}>
          {getActionButton(
            getCleanPlan(props.plan),
            'select-plan-btn-full-' + props.index,
            `${props.brand}-btn-card`,
            handleClick,
          )}
          {props.secondPlan
            ? getActionButton(
                getCleanPlan(props.secondPlan),
                'second-select-plan-btn-full-' + props.index,
                `${props.brand}-second-btn-card `,
                handleSecondPlanClick,
              )
            : null}
        </CardActions>
      </Card>
    </>
  );
};

export default injectIntl(PlanCardControl);
