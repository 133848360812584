/*
 * Subscriptions List Messages
 *
 * This contains all the text for the Subscriptions List component.
 */
import { defineMessages } from 'react-intl';

export default defineMessages<string>({
  noPlanFound: {
    id: 'accountManagement.components.label.SubscriptionsList.text.noPlanFound',
    defaultMessage: 'No plan found.',
  },
  noSubscriptions_momentum: {
    id: 'accountManagement.components.label.SubscriptionsList.text.noSubscriptions.momentum',
    defaultMessage:
      'To purchase a Momentum Capture video history subscription, you will first need to log in to the Momentum app and pair a device.',
  },
  noSubscriptions_otis: {
    id: 'accountManagement.components.label.SubscriptionsList.text.noSubscriptions.otis',
    defaultMessage:
      'To purchase a video history plan, you will first need to log in to the Otis app and pair a device.',
  },
  noSubscriptions_pepper: {
    id: 'accountManagement.components.label.SubscriptionsList.text.noSubscriptions.pepper',
    defaultMessage:
      'No plan found. To purchase a video history plan, you will first need to log in to the Pepper app and pair a device.',
  },
  noSubscriptions_visi: {
    id: 'accountManagement.components.label.SubscriptionsList.text.noSubscriptions.visi',
    defaultMessage:
      'To purchase a video history plan, you will first need to log in to the Visi app and pair a device.',
  },
  noSubscriptions_geeni: {
    id: 'accountManagement.components.label.SubscriptionsList.text.noSubscriptions.geeni',
    defaultMessage:
      'To purchase a video history plan, you will first need to log in to the Merkury Smart app and pair a device.',
  },
  noSubscriptions_nightowl: {
    id: 'accountManagement.components.label.SubscriptionsList.text.noSubscriptions.nightowl',
    defaultMessage:
      'To purchase a video history plan, you will first need to log in to the Notion app and pair a device.',
  },
  noSubscriptions_notion: {
    id: 'accountManagement.components.label.SubscriptionsList.text.noSubscriptions.notion',
    defaultMessage:
      'To purchase a video history plan, you will first need to log in to the Notion app and pair a device.',
  },
  expiredAsOf: {
    id: 'accountManagement.components.label.SubscriptionsList.text.expiredAsOf',
    defaultMessage: 'Expired as of',
  },
  pendingSubscription: {
    id: 'accountManagement.components.label.SubscriptionsList.text.pendingSubscription',
    defaultMessage: 'Pending change to {plan} Plan {billingCycle} - {effectiveDate}',
  },
  trialTitle: {
    id: 'accountManagement.components.label.SubscriptionsList.text.trialTitle',
    defaultMessage: 'Free 30 Day Trial',
  },
  trialExpiration: {
    id: 'accountManagement.components.label.SubscriptionsList.text.trialExpiration',
    defaultMessage: 'Free Trial - Expires on {expireDate}',
  },
  migrationChecking: {
    id: 'accountManagement.components.label.SubscriptionsList.text.migrationChecking',
    defaultMessage: 'Loading...',
  },
  migrationCheckingStatement: {
    id: 'accountManagement.components.label.SubscriptionsList.text.migrationCheckingStatement',
    defaultMessage: 'Please wait',
  },
  manageAppleSubscriptions: {
    id: 'accountManagement.components.label.SubscriptionsList.text.manageAppleSubscriptions',
    defaultMessage: 'Open your {brand} app on your iOS device to manage your Apple {pluralSubs}.',
  },
  manageDevices: {
    id: 'accountManagement.components.label.SubscriptionsList.text.manageDevices',
    defaultMessage: 'Manage Devices',
  },
  changePlan: {
    id: 'accountManagement.components.label.SubscriptionsList.text.changePlan',
    defaultMessage: 'Change Plan',
  },
  viewPlans: {
    id: 'accountManagement.components.label.SubscriptionsList.text.viewPlans',
    defaultMessage: 'View Plans',
  },
  cancelPlan: {
    id: 'accountManagement.components.label.SubscriptionsList.text.cancelPlan',
    defaultMessage: 'Cancel Plan',
  },
});
