/*
 * Confirm Cancelation Messages
 *
 * This contains all the text for the confirm Cancelation component.
 */
import { defineMessages } from 'react-intl';

export default defineMessages({
  modalTitle: {
    id: 'accountManagement.components.label.confirmCancel.modalTitle.text',
    defaultMessage: 'Are you sure you want to cancel your plan?',
  },
  confirmCancelStatement: {
    id: 'accountManagement.components.label.confirmCancel.confirmCancelStatement.text',
    defaultMessage:
      "If you cancel now, you will lose the ability to look back at your camera's recorded {retention}-{retentionTimeFrame} video history along with the features listed below at the end of your current billing cycle.",
  },
  features: {
    id: 'accountManagement.components.label.confirmCancel.features.text',
    defaultMessage: 'FEATURES',
  },
  backupFootage: {
    id: 'accountManagement.components.label.confirmCancel.backupFootage.text',
    defaultMessage: 'Remember to back up your saved camera footage before canceling your plan',
  },
  backupFootageTextOtis: {
    id: 'accountManagement.components.label.confirmCancel.backupFootageText.otis.text',
    defaultMessage:
      'Upon plan expiration, your camera(s) will only save the past 2 hours of video history. All recorded videos and photos saved on the cloud beyond 2 hours will be permanently deleted.',
  },
  backupFootageTextVisi: {
    id: 'accountManagement.components.label.confirmCancel.backupFootageText.visi.text',
    defaultMessage:
      'Upon plan expiration, your camera(s) will no longer have any services. All recorded videos and photos saved on the cloud will be permanently deleted.',
  },
  cancelButton: {
    id: 'accountManagement.components.label.confirmCancel.cancelButton.text',
    defaultMessage: 'Keep current plan',
  },
  exitButton: {
    id: 'accountManagement.components.label.confirmCancel.exitButton.text',
    defaultMessage: 'Cancel plan',
  },
  warningAltText: {
    id: 'accountManagement.components.label.confirmCancel.warningAltText.text',
    defaultMessage: 'Warning: Confirm Exit',
  },
  cancelingPlanText: {
    id: 'accountManagement.components.label.confirmCancel.cancelingPlanText.text',
    defaultMessage: 'Canceling plan…',
  },
});
