import { createSelector } from 'reselect';

import { AppState } from '../../store/rootReducer';

const getPaymentInfoAttempt = (state: AppState) => state.paymentInfo.attempting;

const getPaymentInfo = (state: AppState) => state.paymentInfo.billingInfo;

const getPaymentInfoError = (state: AppState) => state.paymentInfo.error;

const getBillingInfoSessionUrlAttempt = (state: AppState) => state.paymentInfo.updateBillingInfoSession?.attempting;

const getBillingInfoSessionUrl = (state: AppState) => state.paymentInfo.updateBillingInfoSession?.updateBillingInfoUrl;

const getBillingInfoSessionUrlError = (state: AppState) => state.paymentInfo.updateBillingInfoSession?.error;

export const getPaymentInfoAttemptSelector = createSelector(getPaymentInfoAttempt, attempting => attempting);

export const getPaymentInfoSelector = createSelector(getPaymentInfo, billingInfo => billingInfo);

export const getPaymentInfoErrorSelector = createSelector(getPaymentInfoError, error => error);

export const getBillingInfoSessionUrlAttemptSelector = createSelector(
  getBillingInfoSessionUrlAttempt,
  attempting => attempting,
);

export const getBillingInfoSessionUrlSelector = createSelector(
  getBillingInfoSessionUrl,
  updateBillingInfoUrl => updateBillingInfoUrl,
);

export const getBillingInfoSessionUrlFailureSelector = createSelector(getBillingInfoSessionUrlError, error => error);
