import { getAcctHistorySuccess, getAcctHistoryFailure } from './actions';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import { GET_ACCT_HISTORY_ATTEMPT } from './actionTypes';
import { TypedIterableIterator } from '../../modules/helpers';
import { getDataFromService } from '../../services/apiGatewayClient';

export function* getAcctHistory(): TypedIterableIterator<any> {
  try {
    const paymentHistory = yield call(getDataFromService, '/paymentHistory', 'subscriptions');
    yield put(getAcctHistorySuccess(paymentHistory));
  } catch (err) {
    yield put(getAcctHistoryFailure(err));
  }
}

function* AccountHistorySaga() {
  yield all([takeLatest(GET_ACCT_HISTORY_ATTEMPT, getAcctHistory)]);
}

export default AccountHistorySaga;
