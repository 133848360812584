import { BrandInfo, BrandInfoActionTypes } from './types';
import {
  SET_BILLING_PROVIDER,
  SET_BRAND_IMAGES,
  SET_BRAND_INFO,
  SET_CONFIG,
  SET_FLOW,
  SET_LOCALE,
  SET_LOCATION,
} from './actionTypes';

export const initialState: BrandInfo = {
  name: '',
  brandName: '',
  images: {},
  locale: null,
  location: null,
  config: {},
  flow: 'default',
  billingProvider: 'recurly',
};

export const BrandInfoReducer = (state = initialState, action: BrandInfoActionTypes): BrandInfo => {
  switch (action.type) {
    case SET_BRAND_INFO:
      return {
        ...state,
        name: action.name,
        brandName: action.brandName,
      };
    case SET_BRAND_IMAGES:
      return {
        ...state,
        images: action.images,
      };
    case SET_LOCALE:
      return {
        ...state,
        locale: action.locale,
      };
    case SET_LOCATION:
      return {
        ...state,
        location: action.location,
      };
    case SET_CONFIG:
      return {
        ...state,
        config: action.config,
      };
    case SET_FLOW:
      return {
        ...state,
        flow: action.flow,
      };
    case SET_BILLING_PROVIDER:
      return {
        ...state,
        billingProvider: action.billingProvider,
      };
    default:
      return state;
  }
};
