export const STORE_BILLING_INFO = 'app/billingInformationView/STORE_BILLING_INFO';
export const SAVING_BILLING_INFO_ATTEMPT = 'app/billingInformationView/SAVING_BILLING_INFO_ATTEMPT';
export const SAVING_BILLING_INFO_SUCCESS = 'app/billingInformationView/SAVING_BILLING_INFO_SUCCESS';
export const SAVING_BILLING_INFO_FAILURE = 'app/billingInformationView/SAVING_BILLING_INFO_FAILURE';
export const STORE_BILLING_INFO_IN_STORE = 'app/billingInformationView/STORE_BILLING_INFO_IN_STORE';
export const LOADING_BILLING_INFO = 'app/billingInformationView/LOADING_BILLING_INFO';
export const BILLING_INFO_LOADED = 'app/billingInformationView/BILLING_INFO_LOADED';
export const LOADING_BILLING_INFO_VIEW = 'app/billingInformationView/LOADING_BILLING_INFO_VIEW';
export const BILLING_INFO_VIEW_LOADED = 'app/billingInformationView/BILLING_INFO_VIEW_LOADED';
export const LOADING_COUNTRY = 'app/billingInformationView/LOADING_COUNTRY';
export const COUNTRY_LOADED = 'app/billingInformationView/COUNTRY_LOADED';
export const VERIFY_BILLING_INFO_ATTEMPT = 'VERIFY_BILLING_INFO_ATTEMPT';
export const VERIFY_BILLING_INFO_SUCCESS = 'VERIFY_BILLING_INFO_SUCCESS';
export const VERIFY_BILLING_INFO_FAILURE = 'VERIFY_BILLING_INFO_FAILURE';
export const SET_THREE_D_TOKEN = 'SET_THREE_D_TOKEN';
export const CHANGE_CURRENCY = 'CHANGE_CURRENCY';
export const CLEAR_STATE = 'CLEAR_STATE';
