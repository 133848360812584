import React from 'react';

//material ui
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';

//third party lib
import { useMediaQuery } from 'react-responsive';

//redux
import { useSelector } from 'react-redux';
import { getBrandImagesSelector } from '../BrandProvider/selectors';

import { getCancelAccountAttemptingSelector } from './selectors';

interface Props {
  brand: string;
  title: any;
  subText: any;
  primaryBtn: any;
  secondaryBtn: any;
  hasError: boolean;
  onSubmit: any;
  onCancel: any;
  onError: any;
}

const DeleteAccount = (props: Props) => {
  const images = useSelector(getBrandImagesSelector);
  const isMobile = useMediaQuery({ query: `(max-width: 600px)` });
  const { brand } = props;
  const loading = useSelector(getCancelAccountAttemptingSelector);

  return (
    <div>
      <Container maxWidth="sm" className={`${brand}-screen-bg`} style={{ display: 'flex', justifyContent: 'center' }}>
        <div style={isMobile ? { marginTop: 25 } : { marginTop: 45 }}>
          <div className={`${brand}-error-status-page`} style={{ padding: 0 }}>
            {loading ? (
              <div className="progress-wrapper">
                <Typography align="center" variant="h5">
                  Submitting request...
                </Typography>
                <CircularProgress size={55} className={`${brand}-button-progress`} />
              </div>
            ) : (
              <div className={`${brand}-error-status-page`}>
                <div>
                  {' '}
                  <img
                    src={images['logo-error'] ? images['logo-error'] : null}
                    alt="error-status-icon"
                    className={`${brand}-confirm-modal-icon`}
                  />
                </div>
                <div style={{ marginBottom: 20 }}>
                  <Typography align="center" variant="h5">
                    {props.title}
                  </Typography>
                </div>
                <div style={{ maxWidth: 350 }}>
                  <Typography align="center" variant="body1">
                    {props.subText}
                  </Typography>
                </div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    marginTop: 100,
                  }}>
                  <Button className={`${brand}-btn-text`} onClick={props.onCancel} style={{ textTransform: 'none' }}>
                    {props.secondaryBtn}
                  </Button>
                  <Button
                    className={`${brand}-btn ${brand}-btn-action `}
                    onClick={props.hasError ? props.onError : props.onSubmit}
                    style={{
                      textTransform: 'none',
                      fontWeight: 'bold',
                      minWidth: 220,
                    }}>
                    {props.primaryBtn}
                  </Button>
                </div>
              </div>
            )}
          </div>
        </div>
      </Container>
    </div>
  );
};

export default DeleteAccount;
