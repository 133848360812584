import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { getIsAuthenticatedSelector } from '../LoginPage/selectors';

interface GuardedRouteProps {
  /** Whether or not the user should be automatically redirected back to this route after authenticating again. */
  resume?: boolean;
}

export const GuardedRoute: React.FC<GuardedRouteProps> = props => {
  const isAuthenticated = useSelector(getIsAuthenticatedSelector);

  if (!isAuthenticated) {
    const path = window.location.pathname;
    const currentSearch = window.location.search;
    const redirectUri = window.encodeURIComponent(`${path}${currentSearch}`);
    const search = props.resume ? `redirectUri=${redirectUri}` : undefined;
    return <Redirect to={{ pathname: '/login', search }} />;
  }

  return <>{props.children}</>;
};
