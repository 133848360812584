import { DeleteActionTypes, IDeleteAccount } from './types';
import {
  GET_DELETE_ACCOUNT_ATTEMPT,
  GET_DELETE_ACCOUNT_FAILURE,
  GET_DELETE_ACCOUNT_SUCCESS,
  CLEAR_STATE,
} from './actionTypes';

export const initialState: IDeleteAccount = {
  attempting: false,
  error: null,
  deleteAccountSuccess: false,
};

export const DeleteAccountReducer = (state = initialState, action: DeleteActionTypes): IDeleteAccount => {
  switch (action.type) {
    case GET_DELETE_ACCOUNT_ATTEMPT:
      return {
        ...state,
        attempting: true,
        deleteAccountSuccess: false,
        error: null,
      };
    case GET_DELETE_ACCOUNT_SUCCESS:
      return {
        ...state,
        error: null,
        attempting: false,
        deleteAccountSuccess: true,
      };
    case GET_DELETE_ACCOUNT_FAILURE:
      return {
        ...state,
        error: action.error,
        attempting: false,
        deleteAccountSuccess: false,
      };
    case CLEAR_STATE:
      return initialState;
    default:
      return state;
  }
};
