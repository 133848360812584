/*
 * Plan Subscription Success Page Messages
 *
 * This contains all the text for the Plan subscription success component.
 */
import { defineMessages } from 'react-intl';

export default defineMessages<string>({
  successStatement: {
    id: 'accountManagement.components.label.plan.subscription.success.statement',
    defaultMessage: 'Thank you for subscribing.',
  },
  planLabel: {
    id: 'accountManagement.components.label.plan.subscription.success.plan',
    defaultMessage: 'plan',
  },
  chargeLabel: {
    id: 'accountManagement.components.label.plan.subscription.success.recurring charge',
    defaultMessage: 'recurring charge',
  },
  nextChargeLabel: {
    id: 'accountManagement.components.label.plan.subscription.success.nextCharge',
    defaultMessage: 'next charge',
  },
  chargedTodayLabel: {
    id: 'accountManagement.components.label.plan.subscription.success.chargedToday',
    defaultMessage: 'amount charged today',
  },
  appliedPromoLabel: {
    id: 'accountManagement.components.label.plan.subscription.success.appliedPromo',
    defaultMessage: 'applied promotion',
  },
  noPromotionMessage: {
    id: 'accountManagement.components.label.subscription.noPromotion.text',
    defaultMessage: 'None',
  },
  billingCycle1: {
    id: 'accountManagement.components.label.summary.perMonth',
    defaultMessage: '/mo',
  },
  billingCycle12: {
    id: 'accountManagement.components.label.summary.perYear',
    defaultMessage: '/yr',
  },
  billingCycle24: {
    id: 'accountManagement.components.label.summary.perTwoYear',
    defaultMessage: ' every 2 years',
  },
  plusTax: {
    id: 'accountManagement.components.label.summary.plusTax',
    defaultMessage: 'plus tax',
  },
  brand_momentum: {
    id: 'accountManagement.components.label.brand.momentum.text',
    defaultMessage: 'Momentum',
  },
});
