import { BillingInfoActionTypes, IBillingInfo } from './types';
import {
  STORE_BILLING_INFO,
  STORE_BILLING_INFO_IN_STORE,
  LOADING_BILLING_INFO,
  BILLING_INFO_LOADED,
  LOADING_BILLING_INFO_VIEW,
  BILLING_INFO_VIEW_LOADED,
  COUNTRY_LOADED,
  SAVING_BILLING_INFO_ATTEMPT,
  SAVING_BILLING_INFO_SUCCESS,
  SAVING_BILLING_INFO_FAILURE,
  LOADING_COUNTRY,
  CHANGE_CURRENCY,
  CLEAR_STATE,
  VERIFY_BILLING_INFO_SUCCESS,
  VERIFY_BILLING_INFO_ATTEMPT,
  VERIFY_BILLING_INFO_FAILURE,
  SET_THREE_D_TOKEN,
} from './actionTypes';

export const initialState: IBillingInfo = {
  selectedBillingInfo: {},
  billingInfo: null,
  currencyCountry: 'en',
  currency: 'USD',
  saving: false,
  savingError: null,
  paymentDialogLoaded: false,
  actionResultTokenId: undefined,
};

export const BillingInfoReducer = (state = initialState, action: BillingInfoActionTypes): IBillingInfo => {
  switch (action.type) {
    case SET_THREE_D_TOKEN:
      return {
        ...state,
        actionResultTokenId: action.actionResultTokenId,
      };
    case STORE_BILLING_INFO:
      return {
        ...state,
        saving: true,
        selectedBillingInfo: action.selectedBillingInfo,
      };
    case STORE_BILLING_INFO_IN_STORE:
      return {
        ...state,
        selectedBillingInfo: action.selectedBillingInfo,
      };
    case LOADING_BILLING_INFO:
      return state;
    case BILLING_INFO_LOADED:
      return {
        ...state,
        billingInfo: action.billingInfo,
        selectedBillingInfo: action.selectedBillingInfo,
      };
    case LOADING_BILLING_INFO_VIEW:
      return {
        ...state,
        paymentDialogLoaded: false,
      };
    case BILLING_INFO_VIEW_LOADED:
      return {
        ...state,
        paymentDialogLoaded: true,
      };
    case LOADING_COUNTRY:
      return state;
    case COUNTRY_LOADED:
      return {
        ...state,
        currencyCountry: action.currencyCountry,
        currency: action.currency,
      };
    case CHANGE_CURRENCY:
      return {
        ...state,
        currencyCountry: action.currencyCountry,
        currency: action.currency,
      };
    case VERIFY_BILLING_INFO_ATTEMPT:
      return {
        ...state,
        saving: true,
        savingError: null,
      };
    case SAVING_BILLING_INFO_ATTEMPT:
      return {
        ...state,
        saving: true,
      };
    case VERIFY_BILLING_INFO_SUCCESS:
      return {
        ...state,
        saving: false,
        savingError: null,
      };
    case SAVING_BILLING_INFO_SUCCESS:
      return {
        ...state,
        saving: false,
      };
    case VERIFY_BILLING_INFO_FAILURE:
      return {
        ...state,
        saving: false,
        savingError: action.error,
      };
    case SAVING_BILLING_INFO_FAILURE:
      return {
        ...state,
        saving: false,
        savingError: action.error,
      };
    case CLEAR_STATE:
      return initialState;
    default:
      return state;
  }
};
