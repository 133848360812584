import { alertDescriptors } from './alert-descriptors';
import store from '../../store';

/**
 * The action to be performed is specified by its type (e.g. 'SHOW_ALERT') and an optional payload.
 */
export interface Action {
  type: string;
  payload?: Record<string, any>;
}

// Tell TypeScript that we expect these properties to be on 'window' so it doesn't flag them.
interface WindowWebViewFeatures {
  ReactNativeWebView?: any; // used when hosted on Android
  webkit?: any; // used when hosted on iOS
}
declare let window: WindowWebViewFeatures;

/**
 * Provides ability to dispatch an action from code running in a webview to the hosting application for it to perform.
 *
 * Code running in a webview on a mobile device does not have the same look and feel as the app. For example, an alert
 * would look more 'natural' on mobile if it takes the form the mobile user is used to, rather than the form it takes in
 * a web app. Instead of approximating the look and feel in the web application, it would look more authentic if the
 * hosting mobile application displayed the alert.
 *
 * In order to do this, the hosted code must be able to delegate the display and operation of the alert to the hosting
 * application. This can be accomplished by having the hosted code send a message to the hosting application that indicates
 * that it should display an alert with certain content. On the web app side, such a message can be sent using the
 * appropriate React Native message handler.  On the mobile app side, the message can be received and processed by a
 * ReactNative WebView component via a function passed to the onMessage property.  See WebViewEventHandler in the
 * utilities package of pepper-react-native.
 *
 * In the case where the code is not running in a webview, there must be a fallback mechanism to display the alert as
 * expected on the web.
 *
 * This idea can be extended to apply to any action that the hosted code would need to delegate to the mobile app.
 */
export class NativeDispatch {
  /**
   * When running in a webview dispatch the given action to be performed by the hosting application.  When not running
   * in a webview, provide a mechanism for performing the action locally.
   *
   * @param action
   * @return when code is hosted in a webview, returns undefined.  Otherwise, returns an action-related descriptor so
   * that the action can be performed locally. The caller may pass the returned value to a setter returned by useState().
   * If there is a descriptor, a render will occur and the caller may consume the returned value in the state variable
   * returned by useState().  After consuming the value, the caller can set the value back to undefined if a subsequent
   * render is desired.
   *
   * For example, to consume a SHOW_ALERT action, the caller would establish a state value and setter with useState():
   *
   *   const [alertDescriptor, setAlertDescriptor] = useState<AlertDescriptor | undefined>(undefined);
   *
   * When the SHOW_ALERT action needs to be performed, the caller will invoke dispatch() and set the returned value:
   *
   *   setAlertDescriptor(NativeDispatch.dispatch({ type: 'SHOW_ALERT', payload: { subtype: 'placeholder' } }))
   *
   * If a value other than undefined is returned, a render will be triggered.
   *
   * The caller would conditionally display the alert when the alertDescriptor state is not undefined, and set the value
   * back to undefined when the user dismisses the alert.  This will cause another render, which make the alert
   * disappear.
   *
   *     return(
   *       ...
   *       {alertDescriptor && (
   *         <Alert
   *           severity={alertDescriptor.severity}
   *           action={
   *             <Button onClick={() => setAlertDescriptor(undefined)}>{translate(alertDescriptor.buttonLabelTx)}</Button>
   *           }>
   *           <AlertTitle>
   *             <FormattedMessage id={alertDescriptor.titleTx} />
   *           </AlertTitle>
   *           {translate(alertDescriptor.messageTx)}
   *         </Alert>
   *       )}
   *       ...
   *     );
   */
  static dispatch(action: Action) {
    try {
      // If there is a redirect source then we are executing in a web view in a mobile app.  Attempt to delegate the
      // action to the mobile app.
      if (store.getState().isolatedSsoRedirect.source) {
        // If invoked from iOS, `window.webkit` will be defined, and we get our postMessage() function from there.
        if (window.webkit?.messageHandlers?.ReactNativeWebView?.postMessage) {
          window.webkit?.messageHandlers?.ReactNativeWebView?.postMessage(JSON.stringify(action));
          return;
          // Otherwise, we'll use this postMessage function.
        } else if (window.ReactNativeWebView?.postMessage) {
          window.ReactNativeWebView?.postMessage(JSON.stringify(action));
          return;
        }
      }

      if (action.type === 'LOG') return;
      // If there was no hosting application, or if we did not delegate the action to the hosting application, then return
      // a value so the action can be handled locally.
      return this._fetchDescriptor(action);
    } catch (e) {
      // no-op
    }
  }

  static _fetchDescriptor(action: Action) {
    switch (action?.type) {
      case 'SHOW_ALERT': {
        const descriptor = alertDescriptors[action.payload?.subtype];
        if (!descriptor) {
          console.warn(
            `[NativeDispatch] unknown or missing SHOW_ALERT subtype: '${action.payload?.subtype}', ignoring event`,
          );
        }
        return descriptor;
      }
      default: {
        console.warn(`[NativeDispatch] unknown or missing event type: '${action.type}', ignoring event`);
        return;
      }
    }
  }
}
