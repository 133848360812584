import { createSelector } from 'reselect';

import { AppState } from '../../store/rootReducer';

const getPlansConfig = (state: AppState) => state.ssoRedirect.planConfigs;
const getSsoToPlanCode = (state: AppState) => state.ssoRedirect.toPlanCode;
const getSsoFromPlanCode = (state: AppState) => state.ssoRedirect.fromPlanCode;
const getSsoNextPath = (state: AppState) => decodeURIComponent(state.ssoRedirect.nextPath);
const getUsedSso = (state: AppState) => state.ssoRedirect.usedSso;

export const getPlansConfigSelector = createSelector(getPlansConfig, planConfigs => planConfigs);

export const getSsoToPlanCodeSelector = createSelector(getSsoToPlanCode, ssoToPlan => ssoToPlan);

export const getSsoFromPlanCodeSelector = createSelector(getSsoFromPlanCode, fromPlanCode => fromPlanCode);

export const getSsoNextPathSelector = createSelector(getSsoNextPath, nextPath => nextPath);

export const getUsedSsoSelector = createSelector(getUsedSso, usedSso => usedSso);
