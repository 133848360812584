import { ReactElement } from 'react';
import { FormattedDate } from 'react-intl';

interface Props {
  date: Date | string | number;
}

export default function DateFormatter(props: Props): ReactElement {
  return <FormattedDate {...props} value={props.date} />;
}
