import React from 'react';
import { getBrandNameSelector } from '../../BrandProvider/selectors';
import { useDispatch, useSelector } from 'react-redux';
import { getSocialConfig } from '../../../env/config_util';
import { socialAuthenticationAttempt } from '../actions';

interface GoogleCredentialResponse {
  clientId: string;
  /** A JWT which is the id token of the user that was authenticated. */
  credential: string;
}

interface LoginGoogleButtonProps {
  redirectUri?: string;
}

/**
 * NOTE: we may need to use a different library for this:
 * The support of Google Sign-In JavaScript platform library for Web is set to be deprecated after March 31, 2023...
 * https://developers.google.com/identity/sign-in/web/build-button
 *
 */

export const LoginGoogleButton: React.FC<LoginGoogleButtonProps> = props => {
  const dispatch = useDispatch();
  const googleButton = React.useRef<HTMLDivElement | null>(null);
  const brand = useSelector(getBrandNameSelector);

  const [loaded, setLoaded] = React.useState(false);

  const socialConfig = React.useMemo(() => {
    return getSocialConfig(brand);
  }, [brand]);

  const handleGoogleCredential = React.useCallback(
    (e: GoogleCredentialResponse) => {
      dispatch(
        socialAuthenticationAttempt({
          socialProvider: 'google',
          socialCredential: { idToken: e.credential },
          redirectUri: props.redirectUri,
        }),
      );
    },
    [dispatch, props.redirectUri],
  );

  React.useEffect(() => {
    const scriptTag = document.createElement('script');
    scriptTag.src = 'https://accounts.google.com/gsi/client';
    scriptTag.async = true;
    scriptTag.defer = true;
    scriptTag.addEventListener('load', () => setLoaded(true));
    document.body.appendChild(scriptTag);
  }, []);

  // Effect that sets up the google social login callback
  React.useEffect(() => {
    if (!loaded) return;

    if (!socialConfig?.google?.clientId) {
      console.debug('Google social login config not defined, will not initialize');
      return;
    }

    google.accounts.id.initialize({
      client_id: socialConfig.google.clientId,
      callback: handleGoogleCredential,
    });

    if (!googleButton.current) {
      console.warn('Could not initialize google sign in button!');
      return;
    }

    try {
      google.accounts.id?.renderButton(googleButton.current, {
        theme: 'outline',
        size: 'large',
        shape: 'pill',
        width: 200,
      });
    } catch (e) {
      console.error("Couldn't render google sign in button", e);
      return;
    }
  }, [loaded, handleGoogleCredential, socialConfig?.google?.clientId]);

  return <div ref={googleButton} />;
};
