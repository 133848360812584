import React from 'react';
import { Link } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import { FormattedMessage } from 'react-intl';

const PrivacyLink = (props: any) => {
  const { intl, brand } = props;

  return (
    <Link
      className={`${brand}-link-text`}
      target="_blank"
      to={intl.formatMessage({ id: `footer.privacyPolicy.link.${brand}` })}
    >
      <FormattedMessage id={'privacyPolicy'} />
    </Link>
  );
};

export default injectIntl(PrivacyLink);
