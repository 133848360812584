import { enFormats } from '../../locale/enFormats';
import { deFormats } from '../../locale/deFormats';
import { frFormats } from '../../locale/frFormats';
import { jaFormats } from '../../locale/jaFormats';
import { auFormats } from '../../locale/auFormats';
import { gbFormats } from '../../locale/gbFormats';
import { caFormats } from '../../locale/caFormats';
import { nzFormats } from '../../locale/nzFormats';
import { itFormats } from '../../locale/itFormats';
import { esFormats } from '../../locale/esFormats';
import { mxFormats } from '../../locale/mxFormats';
import { sgFormats } from '../../locale/sgFormats';

export const LOCALES: any = {
  ENGLISH: 'en-us',
};

//TODO add your new brand formats
export const brandAllowedCurrency: any = {
  momentum: {
    en: enFormats,
    US: enFormats,
  },
  otis: {
    en: enFormats,
    US: enFormats,
  },
  pepper: {
    en: enFormats,
    US: enFormats,
  },
  visi: {
    en: enFormats,
    US: enFormats,
  },
  geeni: {
    en: enFormats,
    US: enFormats,
  },
  nightowl: {
    en: enFormats,
    US: enFormats,
  },
  notion: {
    en: enFormats,
    US: enFormats,
    AU: auFormats,
  }
};

export const localeFormats: any = {
  en: enFormats,
  'en-us': enFormats,
  US: enFormats,
  'EN-US': enFormats,
  de: deFormats,
  fr: frFormats,
  FR: frFormats,
  DE: deFormats,
  AU: auFormats,
  GB: gbFormats,
  CA: caFormats,
  NZ: nzFormats,
  IT: itFormats,
  ES: esFormats,
  MX: mxFormats,
  JP: jaFormats,
  SG: sgFormats,
};
