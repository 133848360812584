import React, { useState } from 'react';

//material ui
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Divider from '@material-ui/core/Divider';

//controls
import { freePlansCodes, isFreePlan } from '../../constants/freePlans';
import { FormattedMessage } from 'react-intl';
import DateFormatter from '../../components/Formatters/DateFormatter';
import DeviceControl from './DeviceControl';
import history from '../../history';

// Analytics
import { AnalyticsEvent } from '../../utils/analytics/events';
import { logEvent } from '../../utils/analytics/analyticsLogger';

//third party lib
import orderBy from 'lodash/orderBy';
import messages from './messages';

//redux
import { brandNames } from '../../constants/brandNames';
import { useSelector } from 'react-redux';
import { getProfileSelector } from '../LoginPage/selectors';

interface Props {
  plan: any;
  brand: string;
  hasPaidPlan: boolean;
  containsAppleSubscriptions: boolean;
  isFreePlanHasNoDevices: boolean;
  threeDotId: string;
  deviceModels?: any[];
}

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
    fontWeight: 'bold',
  },
}));

export const SubscriptionsControl = (props: Props) => {
  const classes = useStyles();
  const brand = props.brand;
  const [anchorEl, setAnchorEl] = useState(null);
  const isMenuOpen = Boolean(anchorEl);
  const plan_config = props.plan.plan_config;
  const name = props.plan.plan_config.name;
  const billing_cycle = props.plan.plan_config.billing_cycle;
  const end_date = props.plan.recurly_period_end_date;
  const devices = props.plan.devices;
  const deviceModels = props.deviceModels;
  const userProfile = useSelector(getProfileSelector);

  const handleProfileMenuOpen = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const getBillingText = (billing_cycle: number): string => {
    if (props.plan && freePlansCodes.indexOf(props.plan.plan_config.external_plan_code) === -1) {
      switch (billing_cycle) {
        case 1: 
          return '(Monthly)';
        case 12: 
          return '(Yearly)';
        case 0: 
          return ''; // free plan (0) is neither yearly nor monthly
        default:
          return '';
      }
    }
    return '';
  };

  const handleChangePlan = () => {
    setAnchorEl(null);
    history.push('/plans');
    logEvent(AnalyticsEvent.ACCTDASH_PLAN_CHANGE, userProfile);
  };

  const handleManageDevices = () => {
    setAnchorEl(null);
    history.push(`/manage-devices?prefillSelections=true`);
    logEvent(AnalyticsEvent.ACCTDASH_MANAGE_DEVICE, userProfile);
  };

  const handleCancelPlan = () => {
    setAnchorEl(null);
    history.push('/cancel-plan');
  };

  const getDevicesText = (numOfDevices: number) => {
    if (props.plan.plan_config.device_quantity > 1 && props.plan.plan_config.device_quantity < 30) {
      return (
        <p>
          {props.plan.devices.length} of {props.plan.plan_config.device_quantity} Devices
        </p>
      );
    }
    return (
      <p>
        {props.plan.devices.length} {props.plan.devices.length === 1 ? 'Device' : 'Devices'}
      </p>
    );
  };

  const menuId = 'primary-search-account-menu';

  const hasCancelPlan = () => {
    if (
      (brand === brandNames.otis ||
        brand === brandNames.visi ||
        brand === brandNames.notion ||
        brand === brandNames.geeni) &&
      plan_config &&
      plan_config.billing_order > 0
    ) {
      if (
        props.plan &&
        (!props.plan.pendingSubscription || !isFreePlan(props.plan.pendingSubscription.recurly_plan_code))
      ) {
        return (
          <div>
            <Divider light />
            <MenuItem onClick={handleCancelPlan} id="cancel-plan-item">
              <FormattedMessage {...messages.cancelPlan} />
            </MenuItem>
          </div>
        );
      }
    }

    return null;
  };

  const hasManageDevices = () => {
    if (
      !props.isFreePlanHasNoDevices &&
      props.hasPaidPlan &&
      props.plan &&
      freePlansCodes.indexOf(props.plan.plan_config.external_plan_code) === -1
    ) {
      return (
        <div>
          <MenuItem onClick={handleManageDevices} id="manage-devices-item">
            <FormattedMessage {...messages.manageDevices} />
          </MenuItem>
          <Divider light />
        </div>
      );
    }
    return null;
  };

  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMenuOpen}
      onClose={handleMenuClose}>
      {hasManageDevices()}
      <MenuItem onClick={handleChangePlan} id="Popover-1">
        <FormattedMessage {...messages.changePlan} />
      </MenuItem>
      {hasCancelPlan()}
    </Menu>
  );

  const isRecurlyFreeTrial = (subscription: any) => {
    if (subscription.recurly_trial_end_date) {
      // Make sure it's a future date...
      const now = new Date().setHours(0, 0, 0, 0);
      const ftEndDate = new Date(subscription.recurly_trial_end_date).setHours(0, 0, 0, 0);
      return ftEndDate >= now;
    }
    return false;
  };

  const getPendingSubscription = (subscription: any) => {
    const effectiveDate = new Date(subscription.pendingSubscription.effectiveDate);
    let billingCycle = 1;

    if (subscription.pendingSubscription.billing_cycle !== null) {
      billingCycle = subscription.pendingSubscription.billing_cycle;
    }

    let billingCycleText = '';

    if (billingCycle) {
      billingCycleText = billingCycle === 1 ? '(Monthly)' : '(Yearly)';
    }

    return (
      <FormattedMessage
        id={'pendingSubscription'}
        values={{
          billingCycle: `${billingCycleText}`,
          plan: subscription.pendingSubscription.pepper_name,
          effectiveDate: <DateFormatter date={effectiveDate} />,
        }}
      />
    );
  };

  const getSubFreeTrial = (subscription: any, recurlyFreeTrial: boolean) => {
    if (
      subscription.devices &&
      subscription.devices.length > 0 &&
      subscription.devices[0].subscription.oz_plan_expriation_date
    ) {
      const device = subscription.devices[0];

      const expirationDate = new Date(device.subscription.oz_plan_expriation_date);

      return (
        <FormattedMessage
          id={'subTrialExpiration'}
          values={{
            expireDate: <DateFormatter date={expirationDate} />,
          }}
        />
      );
    } else if (recurlyFreeTrial) {
      const expirationDate = new Date(subscription.recurly_trial_end_date);
      return (
        <FormattedMessage
          id={'subTrialExpiration'}
          values={{
            expireDate: <DateFormatter date={expirationDate} />,
          }}
        />
      );
    }

    return <FormattedMessage id={'recurring'} />;
  };

  const getSubscriptionSubtext = () => {
    const recurlyFreeTrial = isRecurlyFreeTrial(props.plan);

    if (props.plan.pendingSubscription) {
      return getPendingSubscription(props.plan);
    }

    if (
      props.plan.plan_config &&
      freePlansCodes.indexOf(props.plan.plan_config.external_plan_code) === -1 &&
      recurlyFreeTrial
    ) {
      return getSubFreeTrial(props.plan, recurlyFreeTrial);
    }

    if (props.containsAppleSubscriptions || props.plan.plan_config.provider === 'stripe') {
      let billingCycle = 1;
      if (props.plan.pending_subscription) {
        const effectiveDate = new Date(props.plan.pending_subscription.effective_date);
        if (props.plan.pending_subscription.billing_cycle !== null) {
          billingCycle = props.plan.pending_subscription.billing_cycle;
        }

        return (
          <FormattedMessage
            {...messages.pendingSubscription}
            values={{
              billingCycle: `${billingCycle === 0 ? '' : billingCycle === 1 ? '(Monthly)' : '(Yearly)'}`,
              plan: props.plan.pending_subscription.pepper_name,
              effectiveDate: <DateFormatter date={effectiveDate} />,
            }}
          />
        );
      } else if (props.plan.expiration_date) {
        // Make sure it's a future date...
        const periodExprDate = new Date(props.plan.expiration_date);
        const now = new Date().setHours(0, 0, 0, 0);
        if (periodExprDate.setHours(0, 0, 0, 0) >= now) {
          return (
            <div className="d-flex align-items-center">
              <span className="primary-h5-reg-text mr-1">
                <FormattedMessage id={'renews'} />
              </span>
              <DateFormatter date={periodExprDate} />
            </div>
          );
        }
        // Subscription is expired.. and polling has not changed it yet
        return (
          <div className="d-flex align-items-center">
            <span className="primary-h5-reg-text mr-1">
              <FormattedMessage {...messages.expiredAsOf} />
            </span>
            <DateFormatter date={periodExprDate} />
          </div>
        );
      }
      return null;
    }

    if (
      props.plan.recurly_period_end_date &&
      freePlansCodes.indexOf(props.plan.plan_config.external_plan_code) === -1
    ) {
      return (
        <div className="d-flex align-items-center">
          <span className="primary-h5-reg-text mr-1">
            <FormattedMessage id={'renews'} />
          </span>{' '}
          <DateFormatter date={props.plan.recurly_period_end_date} />
        </div>
      );
    }

    return '';
  };

  const hasThreeDotsButton = () => {
    if (
      props.hasPaidPlan &&
      freePlansCodes.indexOf(props.plan.plan_config.external_plan_code) === -1 &&
      !props.containsAppleSubscriptions
    ) {
      return iconButton();
    }

    if (!props.hasPaidPlan && !props.containsAppleSubscriptions) {
      return iconButton();
    }

    return <div style={{ marginRight: '30px' }}></div>;
  };

  const getHoursText = (): string => {
    if (
      brand === brandNames.otis &&
      props.plan.plan_config.billing_cycle === 0 &&
      isFreePlan(props.plan.plan_config_id)
    ) {
      return '2';
    }

    return '24';
  };

  const OrderedDevices = () => {
    const sortedDevices = orderBy(
      devices,
      [
        (d: any) => {
          return d && d.name ? d.name : false;
        },
      ],
      ['asc'],
    );

    return sortedDevices;
  };

  const getStorageRetention = () => {
    const retention = props.plan.plan_config.features ? props.plan.plan_config.features.retention : null;

    if (retention) {
      if (retention === 0) {
        return <FormattedMessage id={'noStorage'} />;
      } else if (retention === 1) {
        const hours: string = getHoursText();
        return <FormattedMessage id={'hourStorage'} values={{ hours: hours }} />;
      } else {
        return <FormattedMessage id={'dayStorage'} values={{ days: retention }} />;
      }
    }
    return <FormattedMessage id={'noStorage'} />;
  };

  const iconButton = () => {
    return (
      <IconButton
        edge="end"
        aria-label="Show more"
        aria-controls={menuId}
        aria-haspopup="true"
        onClick={handleProfileMenuOpen}
        color="inherit"
        id={props.threeDotId}>
        <MoreVertIcon />
      </IconButton>
    );
  };
  return (
    <>
      <AppBar position="static" className={`${brand}-sub-app-bar`}>
        <Toolbar style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div className={`${brand}-plan-top-bar-text`}>
            <Typography variant="h6" className={classes.title}>
              {name} Plan {getBillingText(billing_cycle)}
            </Typography>
            <div>
              {end_date !== undefined ? (
                <Typography variant="subtitle2" className={`${brand}-text-common`}>
                  {getSubscriptionSubtext()}
                </Typography>
              ) : null}
            </div>
          </div>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Typography variant="body2" className={`${brand}-text-common ${brand}-text-b`} style={{ textAlign: 'end' }}>
              {getStorageRetention()}
            </Typography>
            <div className={`${brand}-device-three-dots`}>{hasThreeDotsButton()}</div>
          </div>
          {renderMenu}
        </Toolbar>
      </AppBar>
      <div className={`${brand}-plan-device-text`}>{getDevicesText(devices.length)}</div>
      {OrderedDevices().map((device: any, i: number) => (
        <div key={i} style={{ marginBottom: -1 }}>
          <DeviceControl
            numOfDevices={devices.length}
            brand={props.brand}
            device={device}
            deviceModels={deviceModels}
          />
        </div>
      ))}
    </>
  );
};
