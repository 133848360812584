import { createSelector } from 'reselect';

import { AppState } from '../rootReducer';

const getCheckoutSessionUrl = (state: AppState) => state.checkoutSession.checkoutSessionUrl;

export const getCheckoutSessionUrlSelector = createSelector(
  getCheckoutSessionUrl,
  checkoutSessionUrl => checkoutSessionUrl,
);

const getCheckoutSessionFailure = (state: AppState) => state.checkoutSession.checkoutSessionFailure;

export const getCheckoutSessionFailureSelector = createSelector(
  getCheckoutSessionFailure,
  checkoutSessionFailure => checkoutSessionFailure,
);

const getCreatingCheckoutSession = (state: AppState) => state.checkoutSession.creatingCheckoutSession;

export const getCreatingCheckoutSessionSelector = createSelector(
  getCreatingCheckoutSession,
  creatingCheckoutSession => creatingCheckoutSession,
);

const getRedirectingToCheckoutSession = (state: AppState) => state.checkoutSession.redirecting;

export const getRedirectingToCheckoutSessionSelector = createSelector(
  getRedirectingToCheckoutSession,
  redirecting => redirecting,
);
