import { createSelector } from 'reselect';

import { AppState } from '../../store/rootReducer';

const getAttempting = (state: AppState) => state.cancelDelete.attempting;
const getCancelSuccess = (state: AppState) => state.cancelDelete.cancelDeleteSuccess;
const getCancelError = (state: AppState) => state.cancelDelete.error;

export const getCancelAccountAttemptingSelector = createSelector(getAttempting, attempting => attempting);

export const getCancelAccountSuccessSelector = createSelector(
  getCancelSuccess,
  cancelDeleteSuccess => cancelDeleteSuccess,
);

export const getCancelAccountErrorSelector = createSelector(getCancelError, error => error);
