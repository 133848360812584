import { all, call, put, takeLatest } from 'redux-saga/effects';
import { getPlansFailure, getPlansSuccess } from './actions';

import { GET_PLANS_ATTEMPT } from './actionTypes';
import { GetPlansAttemptType } from './types';
import { getDataFromService } from '../../services/apiGatewayClient';
import { TypedIterableIterator } from '../../modules/helpers';
import { getManageDevicesSuccess, setAssignedDevicesFailure } from '../ManageDevices/actions';

function* getPlans(action: GetPlansAttemptType): TypedIterableIterator<any> {
  try {
    const plans = yield call(getDataFromService, `/plans`, 'subscriptions', { brand: action.brand }, false);
    yield put(getPlansSuccess(plans.plans));
  } catch (err) {
    yield put(getPlansFailure(err));
  }
}

export function* getAccountDevices(): TypedIterableIterator<any> {
  try {
    const devices = yield call(getDataFromService, '/devices', 'account', {
      excludeExternalSub: false,
    }) ?? [];

    const subscribableDevices = devices.filter((device: any) => device.subscribable !== false);

    yield put(getManageDevicesSuccess(subscribableDevices));
  } catch (err) {
    yield put(setAssignedDevicesFailure(err));
  }
}

function* loadPlansSaga() {
  yield all([takeLatest(GET_PLANS_ATTEMPT, getPlans)]);
  yield all([takeLatest(GET_PLANS_ATTEMPT, getAccountDevices)]);
}

export default loadPlansSaga;
