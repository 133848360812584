import React, { useEffect, useState } from 'react';

//controls
import { SubscriptionsControl } from './SubscriptionsControl';
import { FormattedMessage } from 'react-intl';
import MigrationModal from '../MigrationModal';
import LoadingModel from '../LoadingModel';
import messages from './messages';

//material ui
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import { Alert, AlertTitle } from '@material-ui/lab';
import Button from '@material-ui/core/Button';

//third party
import { withRouter } from 'react-router-dom';

//redux
import { getDeviceModelsAttempt, getSubscriptionPlansAttempt, migrationAttempt } from './actions';
import { freePlansCodes, isFreePlan, isNonSubscribablePlan } from '../../constants/freePlans';
import { useSelector, useDispatch } from 'react-redux';
import { getManageDevicesAttempt } from '../ManageDevices/actions';
import { subscriptionClearState } from '../OrderSummary/actions';
import { getBrandNameSelector, getLongBrandNameSelector } from '../BrandProvider/selectors';
import { getProfileSelector } from '../LoginPage/selectors';
import { getSavingSelector } from '../ManageDevices/selectors';
import {
  getSubscriptionPlansSelector,
  getAttemptingSubscriptionPlansSelector,
  getIsMigrationRequiredSelector,
  getCheckingMigrationSelector,
  getDeviceModelsSelector,
  getDeviceModelsAttemptSelector,
  getSubscriptionsListLoadedSelector,
} from './selectors';
import { logEvent } from '../../utils/analytics/analyticsLogger';
import { AnalyticsEvent } from '../../utils/analytics/events';
import { setPrevPathName } from '../PathName/actions';
import history from '../../history';

const SubscriptionsList = () => {
  const dispatch = useDispatch();

  const [migrationModalVisible, setMigrationModalVisible] = useState(false);
  const [plans, setPlans] = useState<any>([]);

  const isMigrationRequired = useSelector(getIsMigrationRequiredSelector);
  const isCheckingMigration = useSelector(getCheckingMigrationSelector);
  const plansSelector = useSelector(getSubscriptionPlansSelector);
  const userProfile = useSelector(getProfileSelector);
  const loading = useSelector(getAttemptingSubscriptionPlansSelector);
  const loaded = useSelector(getSubscriptionsListLoadedSelector);
  const attemptingDeviceModels = useSelector(getDeviceModelsAttemptSelector);
  const saving = useSelector(getSavingSelector);
  const brand = useSelector(getBrandNameSelector);
  const brandName = useSelector(getLongBrandNameSelector);
  const deviceModels = useSelector(getDeviceModelsSelector);

  useEffect(() => {
    const fetchPlans = () => {
      dispatch(subscriptionClearState());
      dispatch(getSubscriptionPlansAttempt());
      dispatch(getManageDevicesAttempt());
      dispatch(getDeviceModelsAttempt(brand));
    };
    fetchPlans();
  }, [dispatch, brand]);

  useEffect(() => {
    dispatch(setPrevPathName('/subscriptions'));
  }, [dispatch]);

  useEffect(() => {
    // If user has nothing to manage, forward them to the plans list
    if (loaded && plansSelector.length === 0) {
      history.push('/plans');
    }
  }, [loaded, plansSelector]);

  const profile = useSelector(getProfileSelector);
  useEffect(() => {
    logEvent(AnalyticsEvent.ACCTDASH_MY_PLAN_RENDERED, profile);
  }, [profile]);

  useEffect(() => {
    if (plansSelector.length > 0) {
      let newPlans: any = [];

      for (let i = 0; i < plansSelector.length; ++i) {
        let plan = plansSelector[i];

        if (!isNonSubscribablePlan(plan?.plan_config_id)) {
          newPlans.push(plan);
        }
      }

      if (
        newPlans.length > 1 &&
        newPlans[0].plan_config_id === newPlans[1].plan_config_id &&
        isFreePlan(newPlans[0].plan_config_id)
      ) {
        if (newPlans[0].recurly_plan_code) {
          if (newPlans[1].devices.length > 0) {
            for (let i = 0; i < newPlans[1].devices.length; ++i) {
              newPlans[0].devices.push(newPlans[1].devices[i]);
            }
            newPlans[1].devices = [];
          }
        } else {
          if (newPlans[0].devices.length > 0) {
            for (let i = 0; i < newPlans[0].devices.length; ++i) {
              newPlans[1].devices.push(newPlans[0].devices[i]);
            }
            newPlans[0].devices = [];
          }
        }
      }
      setPlans(newPlans);
    }
  }, [plansSelector]);

  useEffect(() => {
    const maybeRunMigration = () => {
      if (
        userProfile &&
        userProfile.pepperUser &&
        userProfile.pepperUser.account &&
        !userProfile.pepperUser.account.migrationInfo
      ) {
        if (isMigrationRequired === null) {
          const timeoutId = setTimeout(() => {
            if (isMigrationRequired) {
              dispatch(migrationAttempt());
              setMigrationModalVisible(true);
            }
            clearTimeout(timeoutId);
          }, 2100);
        } else {
          if (isMigrationRequired) {
            dispatch(migrationAttempt());
            setMigrationModalVisible(true);
          }
        }
      }
    };
    maybeRunMigration();
  }, [dispatch, isMigrationRequired, userProfile]);

  const hasPaidPlan = (): boolean => {
    let paidPlan = false;

    for (let i = plans.length - 1; i >= 0; i--) {
      const subscription: any = plans[i];

      if (
        subscription &&
        subscription.plan_config &&
        freePlansCodes.indexOf(subscription.plan_config.external_plan_code) === -1
      ) {
        paidPlan = true;
        break;
      }

      if (subscription && isFreePlan(subscription.plan_config) && subscription.recurly_plan_code) {
        paidPlan = true;
        break;
      }
    }
    return paidPlan;
  };

  const containsAppleSubscriptions = (): boolean => {
    const appleSubscriptionExists = plans.find((subscription: any) => {
      if (subscription && subscription.plan_config && subscription.plan_config.provider) {
        return subscription.plan_config.provider.toLowerCase() === 'apple';
      }
      return false;
    });

    return appleSubscriptionExists ? true : false;
  };

  const isFreePlanHasNoDevices = (): boolean => {
    for (let i = 0; i < plans.length; ++i) {
      const subscription: any = plans[i];
      if (
        subscription &&
        subscription.plan_config &&
        freePlansCodes.indexOf(subscription.plan_config.external_plan_code) !== -1
      ) {
        if (subscription.devices.length === 0) {
          return true;
        }
        break;
      }
    }

    return false;
  };

  const retTrueIfTheSamePlan = (index: number): boolean => {
    let retVal: boolean = true;

    if (plans && plans.length > 1) {
      if (plans[0].plan_config_id === plans[1].plan_config_id) {
        if (plans[index].recurly_plan_code && plans[index].devices.length === 0) {
          retVal = false;
        }

        if (plans[index].devices.length === 0) {
          retVal = false;
        }
      } else {
        if (
          freePlansCodes.indexOf(plans[index].plan_config.external_plan_code) !== -1 &&
          plans[index].devices.length === 0
        ) {
          retVal = false;
        }
      }
    }

    return retVal;
  };

  const subscriptionPlansLoaded = () => {
    const navigateToPlans = () => {
      history.push('/plans');
      logEvent(AnalyticsEvent.ACCTDASH_PLAN_CHANGE, userProfile);
    };

    if (!hasPaidPlan()) {
      return (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}>
          <div
            style={{
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              marginTop: 12,
              textAlign: 'center',
            }}>
            <Typography variant="h6">
              <FormattedMessage {...messages.noPlanFound} />
            </Typography>
            <Button
              className={`${brand}-btn-card ${brand}-btn`}
              onClick={navigateToPlans}
              style={{ textTransform: 'none' }}
              id={'view-plans'}>
              <FormattedMessage {...messages.viewPlans} />
            </Button>
          </div>
        </div>
      );
    }

    return plans.map((sub: any, i: number) => (
      <div key={i}>
        {retTrueIfTheSamePlan(i) ? (
          <div style={{ marginBottom: '25px' }}>
            <SubscriptionsControl
              plan={sub}
              brand={brand}
              hasPaidPlan={hasPaidPlan()}
              containsAppleSubscriptions={containsAppleSubscriptions()}
              isFreePlanHasNoDevices={isFreePlanHasNoDevices()}
              threeDotId={'menu-' + i}
              deviceModels={deviceModels}
            />
          </div>
        ) : null}
      </div>
    ));
  };

  return (
    <>
      {containsAppleSubscriptions() && (
        <Alert severity="info">
          <AlertTitle>
            <FormattedMessage
              {...messages.manageAppleSubscriptions}
              values={{
                brand: <span className="text-capitalize">{brandName}</span>,
                pluralSubs: 'subscription',
              }}
            />
          </AlertTitle>
        </Alert>
      )}
      <div
        className={
          plans.length > 1 && freePlansCodes.indexOf(plans[0].plan_config.external_plan_code) !== -1
            ? `${brand}-subscriptions-control-swap-order`
            : `${brand}-subscriptions-control`
        }>
        {plans && !loading && !attemptingDeviceModels && !saving ? (
          subscriptionPlansLoaded()
        ) : (
          <div className="progress-wrapper-center">
            <CircularProgress size={55} className={`${brand}-button-progress`} />
          </div>
        )}
        <LoadingModel
          open={isCheckingMigration}
          brand={brand}
          title={<FormattedMessage {...messages.migrationChecking} />}
          subtitle={<FormattedMessage {...messages.migrationCheckingStatement} />}></LoadingModel>
        <MigrationModal
          open={migrationModalVisible}
          brand={brand}
          handleClose={() => setMigrationModalVisible(false)}
          userProfile={userProfile}></MigrationModal>
      </div>
    </>
  );
};

export default withRouter(SubscriptionsList);
