import CircularProgress from '@material-ui/core/CircularProgress';
import { getBrandNameSelector } from '../BrandProvider/selectors';
import { useSelector } from 'react-redux';
import { useEffect, useMemo, useState } from 'react';
import { getAppUrls } from '../../env/config_util';

export const AccountShare = () => {
  const brand = useSelector(getBrandNameSelector);

  const [platform, setPlatform] = useState<'ios' | 'android' | 'other' | ''>('');

  const appUrls = useMemo<{ ios?: string; android?: string } | undefined>(() => {
    const c = getAppUrls(brand);
    return c;
  }, [brand]);

  useEffect(() => {
    // Check the user agent to determine the platform
    const userAgent = window.navigator.userAgent;

    if (/iPhone|iPad|iPod/i.test(userAgent)) {
      setPlatform('ios');
    } else if (/Android/i.test(userAgent)) {
      setPlatform('android');
    } else {
      setPlatform('other');
    }
  }, []);

  useEffect(() => {
    if (platform) {
      const url = platform === 'android' ? appUrls?.android : appUrls?.ios;
      if (url) {
        window.location.href = url;
      } else {
        alert('The app does not exist. If you have access, please consider using the app on the test flight.');
      }
    }
  }, [platform, brand, appUrls]);

  return <CircularProgress size={55} className={`${brand}-button-progress`} />;
};
