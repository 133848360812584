import { Typography, makeStyles } from '@material-ui/core';
import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import ApplePayLogo from '../../themes/common/images/card_apple_pay@2x.png';
import GooglePayLogo from '../../themes/common/images/card_google_pay@2x.png';
import messages from './messages';
import { PaymentMethod } from './types';
import unifiedCheckoutMessages from './messages';

interface PaymentMethodSelectorProps {
  selectedPaymentMethod: PaymentMethod | undefined;
}

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  img: {
    marginRight: theme.spacing(2),
  },
  label: { display: 'inline-flex', alignItems: 'center' },
}));

const PaymentMethodSelector: React.FC<PaymentMethodSelectorProps> = ({ selectedPaymentMethod }) => {
  const intl = useIntl();
  const styles = useStyles();
  const applePayLabel = intl.formatMessage(messages.applePay);
  const googlePayLabel = intl.formatMessage(messages.googlePay);

  const paymentMethodElement = useMemo(() => {
    switch (selectedPaymentMethod?.type) {
      case 'applePay':
        return (
          <img src={ApplePayLogo} alt={applePayLabel} aria-label={applePayLabel} width={64} className={styles.img} />
        );
      case 'googlePay':
        return (
          <img src={GooglePayLogo} alt={googlePayLabel} aria-label={googlePayLabel} width={64} className={styles.img} />
        );
      default:
        return null;
    }
  }, [applePayLabel, selectedPaymentMethod?.type, styles.img, googlePayLabel]);

  const paymentMethodDescription = useMemo(() => {
    switch (selectedPaymentMethod?.type) {
      case 'applePay':
        return intl.formatMessage(unifiedCheckoutMessages.applePay);
      case 'googlePay':
        return intl.formatMessage(unifiedCheckoutMessages.googlePay);
      default:
        return null;
    }
  }, [intl, selectedPaymentMethod?.type]);

  return (
    <div className={styles.container}>
      <Typography variant="h6" className={styles.label}>
        {paymentMethodElement} {paymentMethodDescription}
      </Typography>
      {/* <Button variant="text" disabled> // TODO: Users cannot change payment method in current iteration
        Change
      </Button> */}
    </div>
  );
};

export default PaymentMethodSelector;
