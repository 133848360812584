import {
  ISOLATED_SSO_AUTHENTICATION_ATTEMPT,
  ISOLATED_SSO_AUTHENTICATION_FAILURE,
  ISOLATED_SSO_AUTHENTICATION_SUCCESS,
  SET_SOURCE,
} from './actionTypes';

import { Location } from 'history';
import { SsoSource } from './types';

export const isolatedSsoAuthAttempt = (location: Location) =>
  ({
    type: ISOLATED_SSO_AUTHENTICATION_ATTEMPT,
    location: location,
  } as const);

export const isolatedSsoAuthFailure = (error: any) =>
  ({
    type: ISOLATED_SSO_AUTHENTICATION_FAILURE,
    error: error,
  } as const);

export const isolatedSsoAuthSuccess = (username: string, profile: any, nextPath: string) =>
  ({
    type: ISOLATED_SSO_AUTHENTICATION_SUCCESS,
    username: username,
    profile: profile,
    nextPath: nextPath,
  } as const);

export const setSource = (source: SsoSource) =>
  ({
    type: SET_SOURCE,
    source: source,
  } as const);

export type IsolatedSsoRedirectActionTypes =
  | ReturnType<typeof isolatedSsoAuthAttempt>
  | ReturnType<typeof isolatedSsoAuthFailure>
  | ReturnType<typeof isolatedSsoAuthSuccess>
  | ReturnType<typeof setSource>;
