/*
 * Promo Code Messages
 *
 * This contains all the text for the Promo Code component.
 */
import { defineMessages } from 'react-intl';

export default defineMessages({
  promoCodeLabel: {
    id: 'accountManagement.components.label.PromoCode.text',
    defaultMessage: 'Promo Code',
  },
  promoCodePlaceHolder: {
    id: 'accountManagement.components.label.PromoCode.placeHolder',
    defaultMessage: 'Enter Code',
  },
  promoCodeInvalid: {
    id: 'accountManagement.components.label.PromoCode.invalid',
    defaultMessage: 'Promo code {code} is not valid',
  },
  promoCodeRestriction: {
    id: 'accountManagement.components.label.PromoCode.restriction',
    defaultMessage: 'Promo code {code} is not valid for this device.',
  },
  promoCodePlanRestriction: {
    id: 'accountManagement.components.label.PromoCode.plan.restriction',
    defaultMessage: 'Promo code {code} is not valid for the selected plan.',
  },
  applyButton: {
    id: 'accountManagement.components.button.PromoCode.applyButton',
    defaultMessage: 'Apply',
  },
  promoCodeApplied: {
    id: 'accountManagement.components.label.PromoCode.appliedCode',
    defaultMessage: 'Promo code {code} is applied',
  },
  removeButton: {
    id: 'accountManagement.components.button.PromoCode.removeButton',
    defaultMessage: 'Remove',
  },
});
