const momentumConfig = {
  brand: 'momentum',
  brandName: 'Momentum',
  images: 'themes/momentum/images/',
  css: 'themes/momentum/',
  messages: '',
};

const pepperConfig = {
  brand: 'pepper',
  brandName: 'Pepper',
  images: 'themes/pepper/images/',
  css: 'themes/pepper/',
  messages: '',
};

const otisConfig = {
  brand: 'otis',
  brandName: 'Otis',
  images: 'themes/otis/images/',
  css: 'themes/otis/',
  messages: '',
};

const visiConfig = {
  brand: 'visi',
  brandName: 'Visi',
  images: 'themes/visi/images/',
  css: 'themes/visi/',
  messages: '',
};

const geeniConfig = {
  brand: 'geeni',
  brandName: 'Merkury Smart',
  images: 'themes/geeni/images/',
  css: 'themes/geeni/',
  messages: '',
};

const nightowlConfig = {
  brand: 'nightowl',
  brandName: 'Night Owl',
  images: 'themes/nightowl/images/',
  css: 'themes/nightowl/',
  messages: '',
};

const notionConfig = {
  brand: 'notion',
  brandName: 'Notion',
  images: 'themes/notion/images/',
  css: 'themes/notion/',
  config: {
    footerLinks: [
      {
        nameI18n: 'privacyPolicy',
        linkI18n: 'footer.privacyPolicy.link.notion',
      },
      {
        nameI18n: 'footer.chat.name.notion',
        linkI18n: 'footer.chat.link.notion',
      },
    ],
    footerText: 'Notion, 1530 Blake Street, Suite 240, Denver, CO 80202, United States',
  },
  flow: 'delegatedCheckout',
  messages: '',
  billingProvider: 'stripe',
};

const brandDomain: { [key: string]: any } = {
  localhost: geeniConfig,
  default: geeniConfig,
  momentum: momentumConfig,
  pepper: pepperConfig,
  otis: otisConfig,
  visi: visiConfig,
  geeni: geeniConfig,
  nightowl: nightowlConfig,
  notion: notionConfig,
  'dev.account.momentum-cam.com': momentumConfig,
  'staging.account.momentum-cam.com': momentumConfig,
  'uat.account.momentum-cam.com': momentumConfig,
  'account.momentum-cam.com': momentumConfig,
  'dev.account.pepperos.io': pepperConfig,
  'staging.account.pepperos.io': pepperConfig,
  'uat.account.pepperos.io': pepperConfig,
  'account.pepperos.io': pepperConfig,
  'dev.account.otishome.com': otisConfig,
  'staging.account.otishome.com': otisConfig,
  'uat.account.otishome.com': otisConfig,
  'account.otishome.com': otisConfig,
  'dev.account.getvisi.com': visiConfig,
  'staging.account.getvisi.com': visiConfig,
  'uat.account.getvisi.com': visiConfig,
  'account.getvisi.com': visiConfig,
  'dev.account.mygeeni.com': geeniConfig,
  'staging.account.mygeeni.com': geeniConfig,
  'uat.account.mygeeni.com': geeniConfig,
  'account.mygeeni.com': geeniConfig,
  'dev.account.merkurysmart.com': geeniConfig,
  'staging.account.merkurysmart.com': geeniConfig,
  'uat.account.merkurysmart.com': geeniConfig,
  'account.merkurysmart.com': geeniConfig,
  'dev.portal.getnotion.com': notionConfig,
  'staging.portal.getnotion.com': notionConfig,
  'uat.portal.getnotion.com': notionConfig,
  'portal.getnotion.com': notionConfig,
  'dev.account.nightowlsp.com': nightowlConfig,
  'staging.account.nightowlsp.com': nightowlConfig,
  'uat.account.nightowlsp.com': nightowlConfig,
  'account.nightowlsp.com': nightowlConfig,
};

export { brandDomain };
