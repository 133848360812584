import { IIsolatedSsoRedirect } from './types';
import {
  ISOLATED_SSO_AUTHENTICATION_ATTEMPT,
  ISOLATED_SSO_AUTHENTICATION_FAILURE,
  ISOLATED_SSO_AUTHENTICATION_SUCCESS,
  SET_SOURCE,
} from './actionTypes';
import { IsolatedSsoRedirectActionTypes } from './actions';

export const initialState: IIsolatedSsoRedirect = {
  error: null,
  attempting: false,
  source: undefined,
};

export const IsolatedSsoRedirectReducer = (
  state = initialState,
  action: IsolatedSsoRedirectActionTypes,
): IIsolatedSsoRedirect => {
  switch (action.type) {
    case ISOLATED_SSO_AUTHENTICATION_ATTEMPT:
      return {
        ...state,
        attempting: true,
        error: null,
      };
    case ISOLATED_SSO_AUTHENTICATION_FAILURE:
      return {
        ...state,
        error: action.error,
        attempting: false,
      };
    case ISOLATED_SSO_AUTHENTICATION_SUCCESS:
      return {
        ...state,
        attempting: false,
        error: null,
      };
    case SET_SOURCE:
      return {
        ...state,
        source: action.source,
      };
    default:
      return state;
  }
};
