import createSagaMiddleware from 'redux-saga';
// import logger from 'redux-logger';
import rootReducer from './rootReducer';
import history from '../history';
import storage from 'redux-persist/lib/storage';

import { createStore, applyMiddleware, compose } from 'redux';
import { rootSaga } from './rootSaga';
import { routerMiddleware } from 'react-router-redux';
import { createMigrate, persistReducer } from 'redux-persist';

// Create the saga middleware
const sagaMiddleware = createSagaMiddleware();

const migrations = {
  // PLAY-15303 - might not be necessary, but an example of how this would be set up
  0: (state: any) => {
    return {
      ...state,
      promoCode: {
        ...state.promoCode,
        codesData: {},
        codes: [],
      },
    };
  },
};

export const config = {
  key: 'root',
  version: 0, //New version 0, default/previous version -1
  storage: storage,
  blacklist: [
    'brand',
    'language',
    'resetPassword',
    'verifyEmail',
    'checkoutSession',
    'paymentInfo',
    'unifiedCheckout',
    'promoCode',
    'orderSummary',
  ],
  migrations: createMigrate(migrations, { debug: true }),
};

const persisted = persistReducer(config, rootReducer);

const composeEnhancers = (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store =
  process.env.REACT_APP_BUILD_ENV === 'dev'
    ? createStore(persisted, composeEnhancers(applyMiddleware(sagaMiddleware, routerMiddleware(history))))
    : createStore(persisted, applyMiddleware(sagaMiddleware, routerMiddleware(history)));
sagaMiddleware.run(rootSaga);

export default store;
