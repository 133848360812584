import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';

//material ui
import CircularProgress from '@material-ui/core/CircularProgress';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';

//redux
import { getBillingInformationExistsSelector } from '../BillingInformationView/selectors';
import { useDispatch, useSelector } from 'react-redux';
import { getBrandNameSelector } from '../BrandProvider/selectors';
import { getPrevPathSelector } from '../PathName/selectors';
import { setPrevPathName } from '../PathName/actions';

import {
  clearErrorState,
  clearManageDevicesState,
  getManageDevicesAttempt,
  setDevicesAsAssigned,
  setDevicesAsManaged,
} from './actions';

import {
  getAttemptingGetDevicesForPlanSelector,
  getDevicesToManageSelector,
  getSaveErrorSelector,
  getSaveSuccessSelector,
  getSavingSelector,
} from './selectors';

import { clearCheckoutSessionState, createCheckoutSessionAttempt } from '../../store/CheckoutSession/actions';

import {
  getCheckoutSessionFailureSelector,
  getCheckoutSessionUrlSelector,
  getCreatingCheckoutSessionSelector,
} from '../../store/CheckoutSession/selectors';
import { getPlansListSelector, getSelectedPlanSelector, isNewCheckoutSessionSelector } from '../PlanListPage/selectors';
import { getCurrentSubscription } from '../SubscriptionsList/selectors';

//third party lib
import { FormattedMessage } from 'react-intl';
import messages from './messages';
import orderBy from 'lodash/orderBy';

//components
import PlanLimitReachedModal from '../PlanLimitReachedModal';
import UnableToSaveModal from '../UnableToSaveModal';
import DataLossModal from '../DataLossModal';
import LoadingModel from '../LoadingModel';
import DevicesCard from './DevicesCard';
import history from '../../history';

//styles
import './manageDevices.scss';
import { getDeviceModelsAttemptSelector, getDeviceModelsSelector } from '../SubscriptionsList/selectors';
import { getDeviceModelsAttempt, getSubscriptionPlansAttempt } from '../SubscriptionsList/actions';
import { getPlansAttempt } from '../PlanListPage/actions';

const ManageDevices = () => {
  const { search } = useLocation();
  const prefillSelections = new URLSearchParams(search).get('prefillSelections') ?? false;

  const isAttemptingGetDevices = useSelector(getAttemptingGetDevicesForPlanSelector);
  const brand = useSelector(getBrandNameSelector);
  const notSortedDevices = useSelector(getDevicesToManageSelector);
  const saveError = useSelector(getSaveErrorSelector);
  const saveSuccess = useSelector(getSaveSuccessSelector);
  const saving = useSelector(getSavingSelector);
  const lastPath = useSelector(getPrevPathSelector);
  const billingInfoExists = useSelector(getBillingInformationExistsSelector);
  const currentSubscription = useSelector(getCurrentSubscription);
  const isNewCheckoutSession = useSelector(isNewCheckoutSessionSelector);
  const deviceModels = useSelector(getDeviceModelsSelector);
  const isAttemptingGetDeviceModels = useSelector(getDeviceModelsAttemptSelector);

  const loading = isAttemptingGetDevices && isAttemptingGetDeviceModels;

  const dispatch = useDispatch();

  // If we get here on a redirect after an idle timeout, we'll need plans and subscriptions.
  useEffect(() => {
    dispatch(getPlansAttempt(brand));
  }, [dispatch, brand]);

  useEffect(() => {
    dispatch(getSubscriptionPlansAttempt());
  }, [dispatch]);

  // In all cases except Manage Devices, the user will have selected a plan before arriving here.  In the case of Manage
  // Devices, it will be the plan of the current subscription. Derive which case it is and call it the "pertinent plan".
  const selectedPlan = useSelector(getSelectedPlanSelector);
  const plans = useSelector(getPlansListSelector);
  const pertinentPlan = useMemo(
    () => selectedPlan ?? plans?.find(plan => plan.id === currentSubscription?.plan_config_id),
    [plans, selectedPlan, currentSubscription?.plan_config_id],
  );

  const [devicesSelected, setDevicesSelected] = useState<boolean[]>([]);

  const [planLimitReached, setPlanLimitReached] = React.useState(false);
  const [openDataLoss, setOpenDataLoss] = React.useState(false);
  const [openUnableToSaveModal, setOpenUnableToSaveModal] = React.useState(false);

  useEffect(() => {
    const fetchPlans = () => {
      dispatch(getManageDevicesAttempt());
      // Device models are fetched in the SubscriptionList, but that will not happen if we arrive here via the mobile app.
      dispatch(getDeviceModelsAttempt(brand));
    };

    fetchPlans();
  }, [brand, dispatch]);

  const devices = useMemo(() => {
    if (notSortedDevices && notSortedDevices.length > 0) {
      let sortedDevices = orderBy(
        notSortedDevices,
        [
          (d: any) => {
            return d && d.name ? d.name : false;
          },
        ],
        ['asc'],
      );

      return sortedDevices;
    } else {
      return [];
    }
  }, [notSortedDevices]);

  const initialDevicesSelected = useMemo<boolean[]>(
    () =>
      devices.map(device =>
        prefillSelections ? currentSubscription?.device_ids.includes(device.pepperDeviceId) : false,
      ),
    [devices, prefillSelections, currentSubscription],
  );

  useEffect(() => {
    if (loading || initialDevicesSelected.length === 0) return;
    setDevicesSelected(initialDevicesSelected);
  }, [initialDevicesSelected, loading]);

  useEffect(() => {
    const saveChangesHandle = () => {
      if (saveError) {
        setOpenUnableToSaveModal(true);
        dispatch(clearErrorState());
      }

      if (saveSuccess) {
        dispatch(clearManageDevicesState());
        history.push('/subscriptions');
      }
    };

    saveChangesHandle();
  }, [dispatch, saveError, saveSuccess]);

  const handleClose = () => {
    setPlanLimitReached(false);
  };

  const handleDataLossClose = () => {
    setOpenDataLoss(false);
  };

  const handleUnableToSaveModalClose = () => {
    setOpenUnableToSaveModal(false);
  };

  const goToChangePlan = () => {
    history.push('/plans');
  };

  const confirmDataLoss = () => {
    handleSaveChanges(true);
  };

  const planName = pertinentPlan?.name ?? '';

  const handleCheckboxChange = useCallback(
    (checkboxIndex: number, isSelected: boolean) => {
      setDevicesSelected(prevDevicesSelected => {
        const prevNumberOfDevicesSelected = prevDevicesSelected.filter(Boolean).length;
        if (prevNumberOfDevicesSelected + (isSelected ? 1 : -1) <= (pertinentPlan?.device_quantity ?? 0)) {
          return prevDevicesSelected.map((selected, index) => (index === checkboxIndex ? isSelected : selected));
        } else {
          setPlanLimitReached(true);
          return prevDevicesSelected;
        }
      });
    },
    [pertinentPlan],
  );

  const handleBackClick = () => {
    history.goBack();
    if (
      lastPath === '/manage-devices' ||
      lastPath === '/plans' ||
      lastPath === '/order-summary' ||
      lastPath === '/billing'
    ) {
      dispatch(setPrevPathName('/manage-devices'));
      history.push('/plans');
      return;
    }

    history.push('/subscriptions');
  };

  const handleSaveChanges = (forceSave: boolean = false) => {
    let selectedActualDevices: any[] = [];
    let removedDevices: any[] = getRemovedDevices();

    for (let i = 0; i < devices.length; ++i) {
      if (devicesSelected[i]) {
        selectedActualDevices.push(devices[i]);
      }
    }

    if (forceSave) {
      handleDataLossClose();
      changeDevices(selectedActualDevices, removedDevices);
      return;
    }

    if (removedDevices.length > 0 && (lastPath === '/subscriptions' || lastPath === '/manage-devices')) {
      setOpenDataLoss(true);
    } else {
      changeDevices(selectedActualDevices, removedDevices);
    }
  };

  const changeDevices = (selectedActualDevices: any, removedActualDevices: any) => {
    if (lastPath === '/subscriptions') {
      // user is managing devices for a current subscription
      dispatch(setDevicesAsManaged(selectedActualDevices, removedActualDevices));
      dispatch(setPrevPathName('/manage-devices'));
    } else {
      dispatch(setDevicesAsAssigned(selectedActualDevices, removedActualDevices));
      dispatch(setPrevPathName('/manage-devices'));

      if (isNewCheckoutSession) {
        dispatch(createCheckoutSessionAttempt());
      } else {
        if (billingInfoExists) {
          history.push('/order-summary');
        } else {
          history.push('/billing');
        }
      }
    }
  };

  const getRemovedDevices = (): any[] => {
    let removedDevices: any[] = [];

    for (let i = 0; i < devices.length; ++i) {
      if (devicesSelected[i] === false && initialDevicesSelected[i] === true) {
        removedDevices.push(devices[i]);
      }
    }

    return removedDevices;
  };
  const getNextDisabledState = (): boolean => {
    const a = initialDevicesSelected;
    const b = devicesSelected;

    if (hasNoDevicesChecked(a) && hasNoDevicesChecked(b)) {
      return true;
    }

    let retVal: boolean = true;
    for (let i = 0; i < a.length; i++) {
      if (a[i] !== b[i]) {
        retVal = false;
      }
    }

    if (a.length !== b.length) {
      retVal = false;
    }

    return retVal;
  };

  const hasNoDevicesChecked = (arr: boolean[]): boolean => {
    return arr.every((val: boolean) => val === false);
  };

  const checkoutSessionUrl = useSelector(getCheckoutSessionUrlSelector);
  useEffect(() => {
    if (checkoutSessionUrl) {
      let url = checkoutSessionUrl;
      dispatch(clearCheckoutSessionState());
      window.location.replace(url);
    }
  }, [checkoutSessionUrl, dispatch]);

  const checkoutSessionFailure = useSelector(getCheckoutSessionFailureSelector);
  useEffect(() => {
    if (checkoutSessionFailure) {
      dispatch(clearCheckoutSessionState());
      history.push('/error');
    }
  }, [checkoutSessionFailure, dispatch]);

  let creatingCheckoutSession = useSelector(getCreatingCheckoutSessionSelector);

  // If we are creating a subscription without devices, then forward them through the checkout flow.
  if (!loading && !creatingCheckoutSession && selectedPlan && devices.length === 0) {
    if (billingInfoExists) {
      history.replace('/order-summary');
    } else {
      history.replace('/billing');
    }
  }

  return (
    <div>
      {loading || plans.length === 0 || !currentSubscription || !devices || !deviceModels || creatingCheckoutSession ? (
        <div className="progress-wrapper-center">
          <CircularProgress size={55} className={`${brand}-button-progress`} />
        </div>
      ) : (
        <div>
          <div style={{ marginBottom: 40 }}>
            <Paper
              variant="outlined"
              className={`manage-devices-header ${brand}-manage-devices-header-color `}
              style={{ width: '100%' }}>
              <div className="manage-devices-container">
                <p style={{ fontWeight: 'bolder', marginTop: 0 }} className="manage-devices-title-text">
                  <FormattedMessage {...messages.devicePlanQuestion} />
                </p>
                <div style={{ width: '100%' }}>
                  <div className="manage-devices-sub-text">
                    <span>
                      <FormattedMessage {...messages.assignDeviceToPlan} values={{ planName: planName }} />
                    </span>
                    <span style={{ marginLeft: 4, fontWeight: 'bolder' }}>
                      <FormattedMessage
                        {...messages.selectStatement}
                        values={{
                          maxDevicesForPlan: pertinentPlan?.device_quantity,
                        }}
                      />
                    </span>
                  </div>
                </div>
              </div>
            </Paper>
          </div>
          <div style={{ height: devices.length * 160 }}>
            {devices.map((device: any, index: number) => (
              <div className="manage-devices-card" key={index}>
                <DevicesCard
                  device={device}
                  index={index}
                  brand={brand}
                  isSelected={devicesSelected[index] ? devicesSelected[index] : false}
                  handleChange={handleCheckboxChange}
                  deviceModels={deviceModels}></DevicesCard>
              </div>
            ))}
          </div>
          <Paper variant="outlined" className="manage-devices-footer" style={{ width: '100%' }}>
            <div className="manage-devices-action-buttons">
              <Button
                className={`${brand}-btn ${brand}-btn-text`}
                style={{
                  textTransform: 'none',
                  fontWeight: 'bolder',
                }}
                onClick={() => handleBackClick()}
                id={`${brand}-back-btn`}>
                <FormattedMessage {...messages.backBtn} />
              </Button>

              {(!lastPath || lastPath !== '/subscriptions') && (
                <Button
                  className={
                    getNextDisabledState()
                      ? `${brand}-btn ${brand}-btn-action ${brand}-btn-action-disabled`
                      : `${brand}-btn ${brand}-btn-action`
                  }
                  style={{
                    height: 42,
                    width: 224,
                    textTransform: 'none',
                    fontWeight: 'bolder',
                  }}
                  onClick={() => handleSaveChanges()}
                  disabled={getNextDisabledState()}
                  id="save-changes-btn">
                  <FormattedMessage {...messages.nextBtn} />
                </Button>
              )}

              {lastPath === '/subscriptions' && (
                <Button
                  className={
                    getNextDisabledState()
                      ? `${brand}-btn ${brand}-btn-action ${brand}-btn-action-disabled`
                      : `${brand}-btn ${brand}-btn-action`
                  }
                  style={{
                    height: 42,
                    width: 224,
                    textTransform: 'none',
                    fontWeight: 'bolder',
                  }}
                  onClick={() => handleSaveChanges()}
                  disabled={getNextDisabledState()}
                  id="save-changes-btn">
                  <FormattedMessage {...messages.saveChangesBtn} />
                </Button>
              )}
            </div>
          </Paper>
          <DataLossModal
            open={openDataLoss}
            confirmDataLoss={confirmDataLoss}
            handleClose={handleDataLossClose}
            brand={brand}
            devices={getRemovedDevices()}
            plan={planName}
            external_plan_code={pertinentPlan?.external_plan_code ?? ''}></DataLossModal>
          <PlanLimitReachedModal
            open={planLimitReached}
            goToChangePlan={goToChangePlan}
            handleClose={handleClose}
            brand={brand}
            maxDevices={pertinentPlan?.device_quantity ?? 0}></PlanLimitReachedModal>
          <UnableToSaveModal
            open={openUnableToSaveModal}
            brand={brand}
            handleClose={handleUnableToSaveModalClose}></UnableToSaveModal>
          <LoadingModel
            open={saving}
            brand={brand}
            title={<FormattedMessage {...messages.savingDevices} />}
            subtitle={<FormattedMessage {...messages.savingDevicesStatement} />}></LoadingModel>
        </div>
      )}
    </div>
  );
};

export default ManageDevices;
