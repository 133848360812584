/*
 * Unable To Save Messages
 *
 * This contains all the text for the manage devices component.
 */
import { defineMessages } from 'react-intl';

export default defineMessages({
  modalTitle: {
    id: 'accountManagement.components.label.unableToSave.modalTitle.text',
    defaultMessage: 'Unable To Save',
  },
  okayButton: {
    id: 'accountManagement.components.label.unableToSave.okayButton.text',
    defaultMessage: 'ok',
  },
  unableSaveStatement: {
    id: 'accountManagement.components.label.unableToSave.unableSaveStatement.text',
    defaultMessage: 'We are unable to save your changes at this time. Please try again.',
  },
  warningAltText: {
    id: 'accountManagement.components.label.unableToSave.warningAltText.text',
    defaultMessage: 'Warning: Unable to Save',
  },
});
