import { LanguageProviderTypes, ILanguageProvider } from './types';
import { CHANGE_LOCALE, CLEAR_STATE } from './actionTypes';
import { LOCALES } from './constants';

export const initialState: ILanguageProvider = {
  locale: LOCALES.ENGLISH,
};

export const LanguageProviderReducer = (state = initialState, action: LanguageProviderTypes): ILanguageProvider => {
  switch (action.type) {
    case CHANGE_LOCALE:
      return {
        ...state,
        locale: action.locale,
      };
    case CLEAR_STATE:
      return initialState;
    default:
      return state;
  }
};
