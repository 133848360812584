import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import Typography from '@material-ui/core/Typography';
import messages from './messages';
import IconButton from '@material-ui/core/IconButton';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import DeleteAccountModalRequest from '../../containers/DeleteAccountModalRequest';
import { getLongBrandNameSelector } from '../../containers/BrandProvider/selectors';
import { useSelector } from 'react-redux';
import history from '../../history';

/**
 * User's name and sign out link for when user is signed in.
 *
 * @param props
 * @constructor
 */
const SignOut = (props: any) => {
  const { brand, fullName } = props;
  const [anchorEl, setAnchorEl] = useState(null);
  const [showDeleteAccountModal, setShowDeleteAccountModal] = useState(false);
  const brandName = useSelector(getLongBrandNameSelector);

  const handleProfileMenuOpen = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleDeleteAccount = () => {
    setAnchorEl(null);
    setShowDeleteAccountModal(true);
  };

  const handleSignOut = () => {
    setAnchorEl(null);
    localStorage.clear();
    history.push('/');
  };

  const iconButton = () => {
    return (
      <IconButton
        edge="end"
        aria-label="Show more"
        aria-controls="profile-menu"
        aria-haspopup="true"
        onClick={handleProfileMenuOpen}
        color="inherit"
        style={{ padding: 5 }}>
        <MoreVertIcon />
      </IconButton>
    );
  };

  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id="profile-menu"
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={Boolean(anchorEl)}
      onClose={handleMenuClose}>
      <MenuItem onClick={handleSignOut} id="Popover-1">
        <FormattedMessage {...messages.signOut} />
      </MenuItem>

      <MenuItem onClick={handleDeleteAccount} id="Popover-2">
        <FormattedMessage {...messages.deleteAccount} />
      </MenuItem>
    </Menu>
  );

  return (
    <div className={`${brand}-log-out`}>
      <Typography variant="h6" style={{ fontWeight: 'bold' }}>
        {fullName}
      </Typography>
      <div>{iconButton()}</div>
      {renderMenu}
      <DeleteAccountModalRequest
        open={showDeleteAccountModal}
        brand={brand}
        handleClose={() => setShowDeleteAccountModal(false)}
        brandName={brandName}
      />
    </div>
  );
};

export default SignOut;
