import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { FormattedMessage } from 'react-intl';
import { useMediaQuery } from 'react-responsive';
import CurrencyFormatter from '../../components/Formatters/CurrencyFormatter';
import messages from './messages';

const useStyles = makeStyles({
  table: {
    minWidth: 450,
  },
  mobileTable: {
    minWidth: 250,
  },
  container: {
    marginTop: 6,
  },
  lineItem: {
    display: 'flex',
    justifyContent: 'space-between',
  },
});

interface Props {
  planName: string | undefined;
  subTotal: number | undefined;
  currency: string | undefined;
  planTax: number | undefined;
  total: number | undefined;
}

const PaymentDetails = (props: Props) => {
  const classes = useStyles();
  const isMobile = useMediaQuery({ query: `(max-width: 768px)` });
  const { planName, subTotal, currency, planTax, total } = props;

  return (
    <div className={isMobile ? classes.mobileTable : classes.table}>
      <div className={classes.container}>
        <div className={classes.lineItem}>
          <Typography>
            <FormattedMessage {...messages.paymentDetailsPlan} />:
          </Typography>
          <Typography align="right">{planName ?? '...'}</Typography>
        </div>
        <div className={classes.lineItem}>
          <Typography>
            <FormattedMessage {...messages.paymentDetailsPrice} />:
          </Typography>
          <Typography align="right">
            {typeof subTotal === 'number' ? <CurrencyFormatter value={subTotal} currency={currency} /> : '...'}
          </Typography>
        </div>
        <div className={classes.lineItem}>
          <Typography>
            <FormattedMessage {...messages.tax} />:
          </Typography>
          <Typography align="right">
            {typeof planTax === 'number' ? <CurrencyFormatter value={planTax} currency={currency} /> : '...'}
          </Typography>
        </div>
        <div className={classes.lineItem}>
          <Typography>
            <FormattedMessage {...messages.total} />
          </Typography>
          <Typography align="right">
            {typeof total === 'number' ? <CurrencyFormatter value={total} currency={currency} /> : '...'}
          </Typography>
        </div>
      </div>
    </div>
  );
};

export default PaymentDetails;
