import React from 'react';
import PlanCardControl from './PlanCardControl';
import { brandNames } from '../../constants/brandNames';

//material ui
import Carousel from 'react-material-ui-carousel';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';

//Media Query
import { useMediaQuery } from 'react-responsive';

interface Props {
  brand: string;
  handleSelectedPlan: any;
  plans: any;
  selectedPlan: any;
  currentPlan: any;
  payFrequency: string;
  // If set, this will restrict the plans that can be selected for certain workflows
  eligiblePlans?: string[];
  isUserSignedIn?: boolean;
}

const TabControl = (props: Props) => {
  const isMobile = useMediaQuery({ query: `(max-width: 760px)` });

  const getCardList = () => {
    if (props.brand !== brandNames.visi) {
      return props.plans;
    }

    let newPlans: any = [];
    let index: number = 0;

    for (let i = 0; i < props.plans.length; ++i) {
      if (i > 0 && props.plans[i].name === props.plans[i - 1].name) {
        newPlans[index - 1].secondPlan = props.plans[i];
      } else {
        newPlans.push(props.plans[i]);
        ++index;
      }
    }

    return newPlans;
  };

  const listCards = getCardList().map((planCard: any, index: number) => (
    <PlanCardControl
      key={index}
      brand={props.brand}
      handleSelectedPlan={props.handleSelectedPlan}
      plan={planCard}
      secondPlan={planCard.secondPlan ? planCard.secondPlan : null}
      selectedPlan={props.selectedPlan}
      currentPlan={props.currentPlan}
      payFrequency={props.brand === 'momentum' && planCard.name === 'Lite' ? '' : props.payFrequency}
      hasRibbon={props.brand === 'momentum' && planCard.name === 'Plus'}
      index={index}
      ineligible={props.eligiblePlans && !props.eligiblePlans.includes(planCard.id)}
      isUserSignedIn={props.isUserSignedIn}
    />
  ));

  // Normally this will default to zero, but if we were passed a plan id, it may not be.  Make sure the preselected
  // plan is shown as selected
  const selectedCardIndex = getCardList().findIndex((plan: any) => plan?.id === props?.selectedPlan?.id) ?? 0;

  return (
    <div className={`${props.brand}-plans-control`}>
      <div className={`${props.brand}-plans-control-wrapper`}>
        {!isMobile ? (
          <div className={`${props.brand}-plans-control`}>{listCards}</div>
        ) : (
          <Carousel
            NextIcon={<ArrowForwardIosIcon className="carousel-icon" />}
            PrevIcon={<ArrowBackIosIcon className="carousel-icon" />}
            index={selectedCardIndex}
            autoPlay={false}
            animation={'slide'}
            navButtonsAlwaysVisible={true}
            className="dash-carousel"
            timeout={350}
            navButtonsProps={{
              className: 'indicator-icons',
              style: {
                color: '#666666',
                backgroundColor: 'transparent',
                marginLeft: 0,
                marginRight: -5,
                transition: '1s',
              },
            }}>
            {listCards}
          </Carousel>
        )}
      </div>
    </div>
  );
};
export default TabControl;
