import { ISsoRedirect, SsoRedirectActionTypes } from './types';
import {
  CLEAR_STATE,
  PLAN_CONFIGS_FAILURE,
  PLAN_CONFIGS_SUCCESS,
  SSO_AUTHENTICATION_ATTEMPT,
  SSO_AUTHENTICATION_FAILURE,
  SSO_AUTHENTICATION_SUCCESS,
  REDIRECTED_WITH_SSO,
} from './actionTypes';

export const initialState: ISsoRedirect = {
  token: null,
  toPlanCode: null,
  fromPlanCode: null,
  nextPath: null,
  error: null,
  attempting: false,
  planConfigs: [],
  usedSso: false,
};

export const SsoRedirectReducer = (state = initialState, action: SsoRedirectActionTypes): ISsoRedirect => {
  switch (action.type) {
    case SSO_AUTHENTICATION_ATTEMPT:
      return {
        ...state,
        attempting: true,
        error: null,
        usedSso: false,
      };
    case SSO_AUTHENTICATION_FAILURE:
      return {
        ...state,
        error: action.error,
        attempting: false,
        usedSso: false,
      };
    case SSO_AUTHENTICATION_SUCCESS:
      return {
        ...state,
        token: null,
        attempting: false,
        error: null,
        toPlanCode: action.toPlanCode,
        fromPlanCode: action.fromPlanCode,
        nextPath: action.nextPath,
        usedSso: true,
      };
    case PLAN_CONFIGS_SUCCESS:
      return {
        ...state,
        planConfigs: action.planConfigs,
        error: null,
      };
    case PLAN_CONFIGS_FAILURE:
      return {
        ...state,
        error: action.error,
      };
    case REDIRECTED_WITH_SSO:
      return {
        ...state,
        usedSso: action.usedSso,
      };
    case CLEAR_STATE:
      return initialState;
    default:
      return state;
  }
};
