import React, { useEffect } from 'react';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import YourPlanTableControl from './YourPlanTableControl';
import Container from '@material-ui/core/Container';
import { useMediaQuery } from 'react-responsive';
import { getSelectedBillingInfoSelector } from '../BillingInformationView/selectors';
import { FormattedMessage } from 'react-intl';
import messages from './messages';
import { useSelector, useDispatch } from 'react-redux';
import PromoCode from '../PromoCode';
import { getSelectedPlanSelector, getCurrentPlanSelector } from '../PlanListPage/selectors';

import { getDevicesAddedSelector, getDevicesRemovedSelector } from '../ManageDevices/selectors';
import { freePlansCodes } from '../../constants/freePlans';
import TermsLink from '../../components/Links/TermsLink';
import PrivacyLink from '../../components/Links/PrivacyLink';
import history from '../../history';
import { clearSubscriptionPreviewState } from './actions';
import { setPrevPathName } from '../PathName/actions';
import { getFlowSelector } from '../BrandProvider/selectors';
import { clearBillingInfoState, createBillingInfoUpdateDelegateAttempt } from '../PaymentInformation/actions';
import {
  getBillingInfoSessionUrlAttemptSelector,
  getBillingInfoSessionUrlFailureSelector,
  getBillingInfoSessionUrlSelector,
} from '../PaymentInformation/selectors';
import CircularProgress from '@material-ui/core/CircularProgress';

interface Props {
  brand: string;
  checkboxChangeHandle: Function;
  subPreview: any;
}

const OrderBody = (props: Props) => {
  const { brand, subPreview } = props;
  const isMobile = useMediaQuery({ query: `(max-width: 992px)` });

  const selectedPlan = useSelector(getSelectedPlanSelector);
  const selectedBillingInfo = useSelector(getSelectedBillingInfoSelector);
  const currentPlan = useSelector(getCurrentPlanSelector);
  const selectedDevices = useSelector(getDevicesAddedSelector);
  const removedDevices = useSelector(getDevicesRemovedSelector);
  const linkToTerms = <TermsLink brand={brand} />;
  const linkToPrivacy = <PrivacyLink brand={brand} />;

  const dispatch = useDispatch();

  const handleChange = (e: any) => {
    props.checkboxChangeHandle(!e.target.checked);
  };

  const flow = useSelector(getFlowSelector);
  const paymentMethodChange = () => {
    if (flow === 'delegatedCheckout') {
      dispatch(createBillingInfoUpdateDelegateAttempt());
    } else {
      dispatch(setPrevPathName('/order-summary'));
      history.push('/billing');
    }
  };

  const updateBillingInfoSessionUrlAttempt = useSelector(getBillingInfoSessionUrlAttemptSelector);

  const updateBillingInfoSessionUrl = useSelector(getBillingInfoSessionUrlSelector);

  useEffect(() => {
    if (updateBillingInfoSessionUrl) {
      const url = updateBillingInfoSessionUrl;
      dispatch(clearSubscriptionPreviewState());
      dispatch(clearBillingInfoState());
      window.location.replace(url);
    }
  }, [updateBillingInfoSessionUrl, dispatch]);

  const updateBillingInfoSessionUrlFailure = useSelector(getBillingInfoSessionUrlFailureSelector);

  useEffect(() => {
    if (updateBillingInfoSessionUrlFailure) {
      dispatch(clearSubscriptionPreviewState());
      dispatch(clearBillingInfoState());
      history.push('/error');
    }
  });

  const isOnFreeTrialWithDowngrade = () => {
    let hasFreeTrial: boolean = false;
    if (removedDevices && removedDevices.length > 0) {
      for (let i = removedDevices.length - 1; i >= 0; i--) {
        const device = removedDevices[i];
        if (
          device.subscription &&
          (device.subscription.oz_plan_expriation_date || device.subscription.recurly_trial_end_date)
        ) {
          if (device.subscription.oz_plan_expriation_date) {
            // Make sure the trial end is a future date...
            const now = new Date().setHours(0, 0, 0, 0);
            const ftEndDate = new Date(device.subscription.oz_plan_expriation_date).setHours(0, 0, 0, 0);
            if (ftEndDate >= now) {
              hasFreeTrial = true;
              break;
            }
          } else if (device.subscription.recurly_trial_end_date) {
            // Make sure the trial end is a future date...
            const now = new Date().setHours(0, 0, 0, 0);
            const ftEndDate = new Date(device.subscription.recurly_trial_end_date).setHours(0, 0, 0, 0);
            if (ftEndDate >= now) {
              hasFreeTrial = true;
              break;
            }
          }
        }
      }
    }
    return hasFreeTrial;
  };

  const isOnFreeTrial = () => {
    let hasFreeTrial: boolean = false;
    if (selectedDevices && selectedDevices.length > 0) {
      for (let i = selectedDevices.length - 1; i >= 0; i--) {
        const device = selectedDevices[i];
        if (
          device.subscription &&
          (device.subscription.oz_plan_expriation_date || device.subscription.recurly_trial_end_date)
        ) {
          if (device.subscription.oz_plan_expriation_date) {
            // Make sure the trial end a future date...
            const now = new Date().setHours(0, 0, 0, 0);
            const ftEndDate = new Date(device.subscription.oz_plan_expriation_date).setHours(0, 0, 0, 0);
            if (ftEndDate >= now) {
              hasFreeTrial = true;
              break;
            }
          } else if (device.subscription.recurly_trial_end_date) {
            // Make sure the trial end a future date...
            const now = new Date().setHours(0, 0, 0, 0);
            const ftEndDate = new Date(device.subscription.recurly_trial_end_date).setHours(0, 0, 0, 0);
            if (ftEndDate >= now) {
              hasFreeTrial = true;
              break;
            }
          }
        }
      }
    }
    return hasFreeTrial;
  };

  const getChargeAmountStatement = () => {
    const billingCycle = selectedPlan && selectedPlan.billing_cycle === 12 ? 'year' : 'month';

    if (selectedPlan) {
      if (freePlansCodes.indexOf(selectedPlan.external_plan_code) !== -1) {
        return <FormattedMessage {...messages.downgradeLiteChargeText} />;
      } else if (currentPlan && selectedPlan.billing_order < currentPlan.billing_order) {
        if (isOnFreeTrial() || isOnFreeTrialWithDowngrade()) {
          return <FormattedMessage {...messages.downgradeFreeTrial} values={{ billingCycle: billingCycle }} />;
        }

        return <FormattedMessage {...messages.downgradeNotFreeTrial} values={{ billingCycle: billingCycle }} />;
      } else if (isOnFreeTrial()) {
        return <FormattedMessage {...messages.freeTrialChargeText} values={{ billingCycle: billingCycle }} />;
      } else {
        return <FormattedMessage {...messages.generalRenewChargeText} values={{ billingCycle: billingCycle }} />;
      }
    }
    return <FormattedMessage {...messages.chargeAmountStatement} />;
  };

  return (
    <Container maxWidth="sm">
      {updateBillingInfoSessionUrlAttempt || updateBillingInfoSessionUrl ? (
        <div className="progress-wrapper-center">
          <CircularProgress size={55} className={`${brand}-button-progress`} />
        </div>
      ) : (
        <div className="order-summary-body" style={{ fontSize: '1rem' }}>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <div className={`${brand}-text-primary`} style={{ fontSize: '1.4rem', marginBottom: 10 }}>
              <FormattedMessage {...messages.paymentMethodTitle} />
            </div>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                marginBottom: 25,
              }}>
              {selectedBillingInfo ? (
                <div>
                  &#8226;&#8226;&#8226;&#8226; &#8226;&#8226;&#8226;&#8226; &#8226;&#8226;&#8226;&#8226;{' '}
                  {selectedBillingInfo.cardNumberLast4}
                </div>
              ) : null}

              <div>
                <Button
                  id="change-payment-method-btn"
                  className={`${brand}-btn-text`}
                  style={{
                    textTransform: 'none',
                    marginLeft: 15,
                  }}
                  onClick={paymentMethodChange}>
                  <FormattedMessage {...messages.paymentMethodChange} />
                </Button>
              </div>
            </div>
            {isMobile ? <Divider style={{ marginBottom: 20 }} /> : null}
            <div>
              <PromoCode brand={brand}></PromoCode>
            </div>
          </div>
          {!isMobile ? (
            <Divider orientation="vertical" flexItem style={{ marginLeft: 40, marginRight: 40, height: 500 }} />
          ) : null}
          {isMobile ? <Divider style={{ marginTop: 20 }} /> : null}

          <div className="your-plan-control">
            <div className={`${brand}-text-primary`} style={{ fontSize: '1.4rem', marginBottom: 10 }}>
              <FormattedMessage {...messages.yourPlanTitle} />
            </div>
            <div className="your-plan-table-control">
              <div>
                <YourPlanTableControl
                  selectedPlan={selectedPlan}
                  currency={subPreview.currency}
                  planTax={subPreview.tax}
                  total={subPreview.total}></YourPlanTableControl>
              </div>
              <div>
                <Button
                  id="change-plan-btn"
                  className={`${brand}-btn-text`}
                  style={{
                    textTransform: 'none',
                    marginLeft: 15,
                  }}
                  onClick={() => {
                    dispatch(clearSubscriptionPreviewState());
                    dispatch(clearBillingInfoState());
                    history.push('/plans');
                  }}>
                  <FormattedMessage {...messages.yourPlanChange} />
                </Button>
              </div>
            </div>
            <div style={{ marginTop: 35, maxWidth: 750, lineHeight: 1.2 }}>{getChargeAmountStatement()}</div>
            <div className={`${brand}-text-primary`} style={{ marginTop: 35, fontSize: '1.4rem' }}>
              <FormattedMessage {...messages.termsTitle} />
            </div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}>
              <div>
                <Checkbox id="check-box" className={`${brand}-checkbox`} color="primary" onChange={handleChange} />
              </div>
              <div style={{ marginTop: 15, lineHeight: 1.2 }}>
                <FormattedMessage
                  {...messages['terms_' + brand]}
                  values={{ terms: linkToTerms, privacy: linkToPrivacy }}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </Container>
  );
};

export default OrderBody;
