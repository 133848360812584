/*
 * DataLossModal Messages
 *
 * This contains all the text for the DataLossModal component.
 */
import { defineMessages } from 'react-intl';

export default defineMessages({
  modalTitle: {
    id: 'app.containers.DataLossModal.modalTitle.text',
    defaultMessage: 'Are you sure you want to remove {device} from your plan?',
  },
  dataLossQuestion: {
    id: 'app.containers.DataLossModal.dataLossQuestion.text',
    defaultMessage: 'The {devicesNames} will lose all services including the premium features listed below.',
  },
  pepperDataLossQuestion: {
    id: 'app.containers.DataLossModal.pepperDataLossQuestion.text',
    defaultMessage: 'The {devicesNames} will lose these features.',
  },
  dataLossFeatures: {
    id: 'app.containers.DataLossModal.features.text',
    defaultMessage: 'features',
  },
  dataLossStatement: {
    id: 'app.containers.DataLossModal.dataLossStatement.text',
    defaultMessage:
      'All recorded videos and photos saved on the cloud will be permanently deleted for the removed {device}',
  },
  pepperDataLossStatement: {
    id: 'app.containers.DataLossModal.pepperDataLossStatement.text',
    defaultMessage: 'Removing your {device} will downgrade your {camera} to 2-hour video history.',
  },
  backUp: {
    id: 'app.containers.DataLossModal.backUp.text',
    defaultMessage: 'Back up footage',
  },
  notNowButton: {
    id: 'app.containers.DataLossModal.OkayButton.text',
    defaultMessage: 'Not Now',
  },
  yesImSureButton: {
    id: 'app.containers.DataLossModal.ChangePlanButton.text',
    defaultMessage: "Yes, I'm Sure",
  },
  warningAltText: {
    id: 'app.containers.DataLossModal.warningAltText.text',
    defaultMessage: 'Warning: Data Loss May Occur',
  },
});
