export const SSO_AUTHENTICATION_ATTEMPT = 'SSO_AUTHENTICATION_ATTEMPT';
export const SSO_AUTHENTICATION_FAILURE = 'SSO_AUTHENTICATION_FAILURE';
export const SSO_AUTHENTICATION_SUCCESS = 'SSO_AUTHENTICATION_SUCCESS';
export const PLAN_CONFIGS_FAILURE = 'PLAN_CONFIGS_FAILURE';
export const PLAN_CONFIGS_SUCCESS = 'PLAN_CONFIGS_SUCCESS';
export const SSO_SUBSCRIPTIONS_DEVICES_FAILURE = 'SSO_SUBSCRIPTIONS_DEVICES_FAILURE';
export const SSO_SUBSCRIPTIONS_DEVICES_SUCCESS = 'SSO_SUBSCRIPTIONS_DEVICES_SUCCESS';
export const REDIRECTED_WITH_SSO = 'REDIRECTED_WITH_SSO';

export const CLEAR_STATE = 'CLEAR_STATE';
