import { all, call, put, takeLatest } from 'redux-saga/effects';
import { getManageDevicesSuccess, setAssignedDevicesFailure } from '../ManageDevices/actions';

import { CANCEL_SUBSCRIPTION_ATTEMPT, CANCEL_SUBSCRIPTION_FAILURE, CANCEL_SUBSCRIPTION_SUCCESS } from './actionTypes';

import { getDataFromService, postDataToService } from '../../services/apiGatewayClient';
import { TypedIterableIterator, SelectState } from '../../modules/helpers';
import { getDevicesAddedSelector, getDevicesRemovedSelector } from '../ManageDevices/selectors';

import { getSubscriptionPlansSelector, getSelectedSubscriptionSelector } from '../SubscriptionsList/selectors';

import { getCurrencySelector, getBillingInformationToken } from '../BillingInformationView/selectors';

export function* getAccountDevices(): TypedIterableIterator<any> {
  try {
    const devices = yield call(getDataFromService, '/devices', 'account', {
      excludeExternalSub: false,
    }) ?? [];

    const subscribableDevices = devices.filter((device: any) => device.subscribable !== false)

    yield put(getManageDevicesSuccess(subscribableDevices));
  } catch (err) {
    yield put(setAssignedDevicesFailure(err));
  }
}

function createSubscriptionRequest(
  devicesAdded: any,
  devicesRemoved: any,
  selectedSubscription: any,
  selectedPlan: any,
  billingToken: any,
  promoCode: any,
  currency: any,
) {
  let currSubId = undefined;
  let pepperSubscriptionId = undefined;
  let ozTrialEndDate = undefined;

  if (selectedSubscription) {
    pepperSubscriptionId = selectedSubscription.id;
    currSubId = selectedSubscription.external_subscription_id;
    if (selectedSubscription.devices && selectedSubscription.devices.length > 0) {
      let tempDevice = selectedSubscription.devices[0];
      if (tempDevice.subscription) {
        ozTrialEndDate = tempDevice.subscription.oz_plan_expriation_date;
      }
    }
  }

  return {
    pepperPlanId: selectedPlan.id,
    pepperSubscriptionId: pepperSubscriptionId,
    devices: createDeviceObjectFromList(devicesAdded),
    devicesRemoved: createDeviceObjectFromList(devicesRemoved),
    targetPlanCode: selectedPlan.external_plan_code,
    ozTrialEndDate: ozTrialEndDate,
    currentSubscriptionUuid: currSubId,
    currency: currency,
    billingToken: billingToken,
    couponCode: promoCode,
  };
}

function createDeviceObjectFromList(devices: any) {
  let localDevices = [];
  if (devices) {
    for (let device of devices) {
      let localDevice = {
        deviceId: device.pepperDeviceId,
        externalDeviceId: device.deviceId,
        deviceProvider: device.provider,
        deviceModel: device.model,
      };
      localDevices.push(localDevice);
    }
  }
  return localDevices;
}

function* submitSubscription(): TypedIterableIterator<any> {
  const selectedPlan: any = yield SelectState<any>(getSubscriptionPlansSelector);
  const billingToken: any = yield SelectState<any>(getBillingInformationToken);

  const devicesAdded: any = yield SelectState<any>(getDevicesAddedSelector);
  const devicesRemoved: any = yield SelectState<any>(getDevicesRemovedSelector);
  const selectedSubscription: any = yield SelectState<any>(getSelectedSubscriptionSelector);
  const currency: any = yield SelectState<any>(getCurrencySelector);

  let changeSubscriptionRequest = createSubscriptionRequest(
    devicesAdded,
    devicesRemoved,
    selectedSubscription,
    selectedPlan,
    billingToken,
    undefined,
    currency,
  );

  try {
    let subscriptionResponse = yield call(postDataToService, '/subscribe', changeSubscriptionRequest, 'subscriptions');

    if (subscriptionResponse.Payload) {
      try {
        let subscriptionPayload = JSON.parse(subscriptionResponse.Payload);
        if (subscriptionPayload.FunctionError) {
          const errorPayload = JSON.parse(subscriptionPayload.Payload);
          const err = new Error(errorPayload.errorType);
          yield put({
            type: CANCEL_SUBSCRIPTION_FAILURE,
            err,
            changeSubscriptionRequest,
          });
          return;
        }
      } catch (err) {
        yield put({
          type: CANCEL_SUBSCRIPTION_FAILURE,
          err,
          changeSubscriptionRequest,
        });
        return;
      }
    }
    yield put({ type: CANCEL_SUBSCRIPTION_SUCCESS, subscriptionResponse });
  } catch (err) {
    let error: any = err;
    if (err.response && err.response.data && err.response.data.error) {
      error = new Error(err.response.data.error);
    }
    yield put({
      type: CANCEL_SUBSCRIPTION_FAILURE,
      error,
      changeSubscriptionRequest,
    });
  }
}

function* CancelPlanSaga() {
  yield all([takeLatest(CANCEL_SUBSCRIPTION_ATTEMPT, submitSubscription)]);
}

export default CancelPlanSaga;
