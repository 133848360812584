export const planFeatures: Record<string, any[]> = {
  /**
   * Momentum plans
   */
  lsm: [
    {
      id: 'accountManagement.components.subscription.plan.description.momentum.lsm-1',
      defaultMessage: '- View Live Stream All Day and Night',
    },
    {
      id: 'accountManagement.components.subscription.plan.description.momentum.lsm-2',
      defaultMessage: '- Set Motion Triggered Notifications',
    },
  ],

  lite: [
    {
      id: 'accountManagement.components.subscription.plan.description.momentum.lite-1',
      defaultMessage: '{cloudIcon} {day}\u00a0Video History',
    },
  ],

  '7+month': [
    {
      id: 'accountManagement.components.subscription.plan.description.momentum.7+month-1',
      defaultMessage: '{dotwhackIcon} First Month FREE',
    },
    {
      id: 'accountManagement.components.subscription.plan.description.momentum.7+month-2',
      defaultMessage: '{cloudIcon} {day}\u00a0Video History',
    },
    {
      id: 'accountManagement.components.subscription.plan.description.momentum.7+month-3',
      defaultMessage: '{cameraIcon} Up to\u00a0{cameraCount}',
    },
    {
      id: 'accountManagement.components.subscription.plan.description.momentum.7+month-4',
      defaultMessage: '{snoozeNotificationsIcon} Snooze Notifications',
    },
  ],

  '30+month': [
    {
      id: 'accountManagement.components.subscription.plan.description.momentum.30+month-1',
      defaultMessage: '{dotwhackIcon} First Month FREE',
    },
    {
      id: 'accountManagement.components.subscription.plan.description.momentum.30+month-2',
      defaultMessage: '{cloudIcon} {day}\u00a0Video History',
    },
    {
      id: 'accountManagement.components.subscription.plan.description.momentum.30+month-3',
      defaultMessage: '{cameraIcon} Up to\u00a0{cameraCount}',
    },
    {
      id: 'accountManagement.components.subscription.plan.description.momentum.30+month-4',
      defaultMessage: '{snoozeNotificationsIcon} Snooze Notifications',
    },
  ],

  '60+month': [
    {
      id: 'accountManagement.components.subscription.plan.description.momentum.60+month-1',
      defaultMessage: '{dotwhackIcon} First Month FREE',
    },
    {
      id: 'accountManagement.components.subscription.plan.description.momentum.60+month-2',
      defaultMessage: '{cloudIcon} {day}\u00a0Video History',
    },
    {
      id: 'accountManagement.components.subscription.plan.description.momentum.60+month-3',
      defaultMessage: '{cameraIcon} Up to\u00a0{cameraCount}',
    },
    {
      id: 'accountManagement.components.subscription.plan.description.momentum.60+month-4',
      defaultMessage: '{sendHelpIcon} Send Help Smart Security',
    },
    {
      id: 'accountManagement.components.subscription.plan.description.momentum.60+month-5',
      defaultMessage: '{snoozeNotificationsIcon} Snooze Notifications',
    },
    {
      id: 'accountManagement.components.subscription.plan.description.momentum.60+month-6',
      defaultMessage: '{smartVisionIcon} Smart Detections',
    },
  ],

  '7+year': [
    {
      id: 'accountManagement.components.subscription.plan.description.momentum.7+year-1',
      defaultMessage: '{dotwhackIcon} First Month FREE',
    },
    {
      id: 'accountManagement.components.subscription.plan.description.momentum.7+year-2',
      defaultMessage: '{cloudIcon} {day}\u00a0Video History',
    },
    {
      id: 'accountManagement.components.subscription.plan.description.momentum.7+year-3',
      defaultMessage: '{cameraIcon} Up to\u00a0{cameraCount}',
    },
    {
      id: 'accountManagement.components.subscription.plan.description.momentum.7+year-4',
      defaultMessage: '{snoozeNotificationsIcon} Snooze Notifications',
    },
  ],

  '30+year': [
    {
      id: 'accountManagement.components.subscription.plan.description.momentum.30+year-1',
      defaultMessage: '{dotwhackIcon} First Month FREE',
    },
    {
      id: 'accountManagement.components.subscription.plan.description.momentum.30+year-2',
      defaultMessage: '{cloudIcon} {day}\u00a0Video History',
    },
    {
      id: 'accountManagement.components.subscription.plan.description.momentum.30+year-3',
      defaultMessage: '{cameraIcon} Up to\u00a0{cameraCount}',
    },
    {
      id: 'accountManagement.components.subscription.plan.description.momentum.30+year-4',
      defaultMessage: '{snoozeNotificationsIcon} Snooze Notifications',
    },
  ],

  '60+year': [
    {
      id: 'accountManagement.components.subscription.plan.description.momentum.60+year-1',
      defaultMessage: '{dotwhackIcon} First Month FREE',
    },
    {
      id: 'accountManagement.components.subscription.plan.description.momentum.60+year-2',
      defaultMessage: '{cloudIcon} {day}\u00a0Video History',
    },
    {
      id: 'accountManagement.components.subscription.plan.description.momentum.60+year-3',
      defaultMessage: '{cameraIcon} Up to\u00a0{cameraCount}',
    },
    {
      id: 'accountManagement.components.subscription.plan.description.momentum.60+year-4',
      defaultMessage: '{sendHelpIcon} Send Help Smart Security',
    },
    {
      id: 'accountManagement.components.subscription.plan.description.momentum.60+year-5',
      defaultMessage: '{snoozeNotificationsIcon} Snooze Notifications',
    },
    {
      id: 'accountManagement.components.subscription.plan.description.momentum.60+year-6',
      defaultMessage: '{smartVisionIcon} Smart Detections',
    },
  ],

  '30dean': [
    {
      id: 'accountManagement.components.subscription.plan.description.momentum.30dean-1',
      defaultMessage: '- Save 30 Days of Video History',
    },
    {
      id: 'accountManagement.components.subscription.plan.description.momentum.30dean-2',
      defaultMessage: '- Download and Share Recorded Videos',
    },
    {
      id: 'accountManagement.components.subscription.plan.description.momentum.30dean-3',
      defaultMessage: '- View Live Stream All Day and Night',
    },
    {
      id: 'accountManagement.components.subscription.plan.description.momentum.30dean-4',
      defaultMessage: '- Set Motion Triggered Notifications',
    },
  ],

  '7dem': [
    {
      id: 'accountManagement.components.subscription.plan.description.momentum.7dem-1',
      defaultMessage: '- Save 7 Days of Video History',
    },
    {
      id: 'accountManagement.components.subscription.plan.description.momentum.7dem-2',
      defaultMessage: '- Download and Share Recorded Videos',
    },
    {
      id: 'accountManagement.components.subscription.plan.description.momentum.7dem-3',
      defaultMessage: '- View Live Stream All Day and Night',
    },
    {
      id: 'accountManagement.components.subscription.plan.description.momentum.7dem-4',
      defaultMessage: '- Set Motion Triggered Notifications',
    },
  ],

  '30dem': [
    {
      id: 'accountManagement.components.subscription.plan.description.momentum.30dem-1',
      defaultMessage: '- Save 30 Days of Video History',
    },
    {
      id: 'accountManagement.components.subscription.plan.description.momentum.30dem-2',
      defaultMessage: '- Download and Share Recorded Videos',
    },
    {
      id: 'accountManagement.components.subscription.plan.description.momentum.30dem-3',
      defaultMessage: '- View Live Stream All Day and Night',
    },
    {
      id: 'accountManagement.components.subscription.plan.description.momentum.30dem-4',
      defaultMessage: '- Set Motion Triggered Notifications',
    },
  ],

  '7dean': [
    {
      id: 'accountManagement.components.subscription.plan.description.momentum.7dean-1',
      defaultMessage: '- Save 7 Days of Video History',
    },
    {
      id: 'accountManagement.components.subscription.plan.description.momentum.7dean-2',
      defaultMessage: '- Download and Share Recorded Videos',
    },
    {
      id: 'accountManagement.components.subscription.plan.description.momentum.7dean-3',
      defaultMessage: '- View Live Stream All Day and Night',
    },
    {
      id: 'accountManagement.components.subscription.plan.description.momentum.7dean-4',
      defaultMessage: '- Set Motion Triggered Notifications',
    },
  ],

  /**
   * Otis plans
   */
  es_1month_1cam: [
    {
      id: 'accountManagement.components.subscription.plan.description.otis.es_1month_4cam.free_trial',
      defaultMessage: '{checkIcon} 30-day free trial',
    },
    {
      id: 'accountManagement.components.subscription.plan.description.otis.es_1month_4cam.7_day_history',
      defaultMessage: '{checkIcon} 7-day video history',
    },
    {
      id: 'accountManagement.components.subscription.plan.description.otis.es_1month_4cam.1_camera',
      defaultMessage: '{checkIcon} 1 camera',
    },
    {
      id: 'accountManagement.components.subscription.plan.description.otis.es_1month_4cam.30_day_history',
      defaultMessage: '{cancelIcon} 30-day video history',
    },
    {
      id: 'accountManagement.components.subscription.plan.description.otis.es_1month_4cam.up_to',
      defaultMessage: '{cancelIcon} Up to 5 cameras',
    },
  ],
  pr_1month_allcam: [
    {
      id: 'accountManagement.components.subscription.plan.description.otis.pr_1month_4cam.free_trial',
      defaultMessage: '{checkIcon} 30-day free trial',
    },
    {
      id: 'accountManagement.components.subscription.plan.description.otis.pr_1month_4cam.30_day_history',
      defaultMessage: '{checkIcon} 30-day video history',
    },
    {
      id: 'accountManagement.components.subscription.plan.description.otis.pr_1month_4cam.up_to',
      defaultMessage: '{checkIcon} Up to 5 cameras',
    },
  ],
  es_1year_1cam: [
    {
      id: 'accountManagement.components.subscription.plan.description.otis.es_1year_4cam_1',
      defaultMessage: '{checkIcon} 30-day free trial',
    },
    {
      id: 'accountManagement.components.subscription.plan.description.otis.es_1year_4cam_2',
      defaultMessage: '{checkIcon} 7-day video history',
    },
    {
      id: 'accountManagement.components.subscription.plan.description.otis.es_1year_4cam_3',
      defaultMessage: '{checkIcon} 1 camera',
    },
    {
      id: 'accountManagement.components.subscription.plan.description.otis.es_1year_4cam_4',
      defaultMessage: '{cancelIcon} 30-day video history',
    },
    {
      id: 'accountManagement.components.subscription.plan.description.otis.es_1year_4cam_5',
      defaultMessage: '{cancelIcon} Up to 5 cameras',
    },
  ],
  pr_1year_allcam: [
    {
      id: 'accountManagement.components.subscription.plan.description.otis.pr_1year_4cam_1',
      defaultMessage: '{checkIcon} 30-day free trial',
    },
    {
      id: 'accountManagement.components.subscription.plan.description.otis.pr_1year_4cam_2',
      defaultMessage: '{checkIcon} 30-day video history',
    },
    {
      id: 'accountManagement.components.subscription.plan.description.otis.pr_1year_4cam_3',
      defaultMessage: '{checkIcon} Up to 5 cameras',
    },
  ],

  /**
   * Visi plans
   */
  ba_1month_4cam: [
    {
      id: 'accountManagement.components.subscription.plan.description.visi.ba_1month_4cam_1',
      defaultMessage: '{checkIcon} Watch & download activity',
    },
    {
      id: 'accountManagement.components.subscription.plan.description.visi.ba_1month_4cam_2',
      defaultMessage: '{checkIcon} 24-hour video history',
    },
    {
      id: 'accountManagement.components.subscription.plan.description.visi.ba_1month_4cam_3',
      defaultMessage: '{cancelIcon} Smart Detection',
    },
    {
      id: 'accountManagement.components.subscription.plan.description.visi.ba_1month_4cam_4',
      defaultMessage: '{cancelIcon} Send Help Smart Security',
    },
  ],

  pr_1month_4cam: [
    {
      id: 'accountManagement.components.subscription.plan.description.visi.pr_1month_4cam_1',
      defaultMessage: '{checkIcon} Watch & download activity',
    },
    {
      id: 'accountManagement.components.subscription.plan.description.visi.pr_1month_4cam_2',
      defaultMessage: '{checkIcon} 30-day video history',
    },
    {
      id: 'accountManagement.components.subscription.plan.description.visi.pr_1month_4cam_3',
      defaultMessage: '{checkIcon} Smart Detection',
    },
    {
      id: 'accountManagement.components.subscription.plan.description.visi.pr_1month_4cam_4',
      defaultMessage: '{checkIcon} Send Help Smart Security',
    },
  ],

  ba_1year_4cam: [
    {
      id: 'accountManagement.components.subscription.plan.description.visi.ba_1year_4cam_1',
      defaultMessage: '{checkIcon} Watch & download activity',
    },
    {
      id: 'accountManagement.components.subscription.plan.description.visi.ba_1year_4cam_2',
      defaultMessage: '{checkIcon} 24-hour video history',
    },
    {
      id: 'accountManagement.components.subscription.plan.description.visi.ba_1year_4cam_3',
      defaultMessage: '{cancelIcon} Smart Detection',
    },
    {
      id: 'accountManagement.components.subscription.plan.description.visi.ba_1year_4cam_4',
      defaultMessage: '{cancelIcon} Send Help Smart Security',
    },
  ],

  pr_1year_4cam: [
    {
      id: 'accountManagement.components.subscription.plan.description.visi.pr_1year_4cam_1',
      defaultMessage: '{checkIcon} Watch & download activity',
    },
    {
      id: 'accountManagement.components.subscription.plan.description.visi.pr_1year_4cam_2',
      defaultMessage: '{checkIcon} 30-day video history',
    },
    {
      id: 'accountManagement.components.subscription.plan.description.visi.pr_1year_4cam_3',
      defaultMessage: '{checkIcon} Smart Detection',
    },
    {
      id: 'accountManagement.components.subscription.plan.description.visi.pr_1year_4cam_4',
      defaultMessage: '{checkIcon} Send Help Smart Security',
    },
  ],

  ba_2year_4cam: [
    {
      id: 'accountManagement.components.subscription.plan.description.visi.ba_2year_4cam_1',
      defaultMessage: '{checkIcon} Watch & download activity',
    },
    {
      id: 'accountManagement.components.subscription.plan.description.visi.ba_2year_4cam_2',
      defaultMessage: '{checkIcon} 24-hour video history',
    },
    {
      id: 'accountManagement.components.subscription.plan.description.visi.ba_2year_4cam_3',
      defaultMessage: '{cancelIcon} Smart Detection',
    },
    {
      id: 'accountManagement.components.subscription.plan.description.visi.ba_2year_4cam_4',
      defaultMessage: '{cancelIcon} Send Help Smart Security',
    },
  ],

  pr_2year_4cam: [
    {
      id: 'accountManagement.components.subscription.plan.description.visi.pr_2year_4cam_1',
      defaultMessage: '{checkIcon} Watch & download activity',
    },
    {
      id: 'accountManagement.components.subscription.plan.description.visi.pr_2year_4cam_2',
      defaultMessage: '{checkIcon} 30-day video history',
    },
    {
      id: 'accountManagement.components.subscription.plan.description.visi.pr_2year_4cam_3',
      defaultMessage: '{checkIcon} Smart Detection',
    },
    {
      id: 'accountManagement.components.subscription.plan.description.visi.pr_2year_4cam_4',
      defaultMessage: '{checkIcon} Send Help Smart Security',
    },
  ],

  /**
   * Geeni plans (Merkury Smart)
   */
  pr_1month_basic: [
    {
      id: 'accountManagement.components.subscription.plan.description.geeni.pr_1month_basic_1',
      defaultMessage: '{checkIcon} Watch & download activity',
    },
    {
      id: 'accountManagement.components.subscription.plan.description.geeni.pr_1month_basic_2',
      defaultMessage: '{checkIcon} 2-day video history',
    },
    {
      id: 'accountManagement.components.subscription.plan.description.geeni.pr_1month_basic_3',
      defaultMessage: '{checkIcon} 1 camera',
    },
    {
      id: 'accountManagement.components.subscription.plan.description.geeni.pr_1month_basic_4',
      defaultMessage: '{cancelIcon} Snooze notifications',
    },
    {
      id: 'accountManagement.components.subscription.plan.description.geeni.pr_1month_basic_5',
      defaultMessage: '{cancelIcon} Smart Detection',
    },
    {
      id: 'accountManagement.components.subscription.plan.description.geeni.pr_1month_basic_6',
      defaultMessage: '{cancelIcon} Send Help',
    },
  ],
  pr_1year_basic: [
    {
      id: 'accountManagement.components.subscription.plan.description.geeni.pr_1year_basic_1',
      defaultMessage: '{checkIcon} Watch & download activity',
    },
    {
      id: 'accountManagement.components.subscription.plan.description.geeni.pr_1year_basic_2',
      defaultMessage: '{checkIcon} 2-day video history',
    },
    {
      id: 'accountManagement.components.subscription.plan.description.geeni.pr_1year_basic_3',
      defaultMessage: '{checkIcon} 1 camera',
    },
    {
      id: 'accountManagement.components.subscription.plan.description.geeni.pr_1year_basic_4',
      defaultMessage: '{cancelIcon} Snooze notifications',
    },
    {
      id: 'accountManagement.components.subscription.plan.description.geeni.pr_1year_basic_5',
      defaultMessage: '{cancelIcon} Smart Detection',
    },
    {
      id: 'accountManagement.components.subscription.plan.description.geeni.pr_1year_basic_6',
      defaultMessage: '{cancelIcon} Send Help',
    },
  ],
  pr_1month_plus: [
    {
      id: 'accountManagement.components.subscription.plan.description.geeni.pr_1month_plus_1',
      defaultMessage: '{checkIcon} Watch & download activity',
    },
    {
      id: 'accountManagement.components.subscription.plan.description.geeni.pr_1month_plus_2',
      defaultMessage: '{checkIcon} 14-day video history',
    },
    {
      id: 'accountManagement.components.subscription.plan.description.geeni.pr_1month_plus_3',
      defaultMessage: '{checkIcon} Up to 2 Cameras',
    },
    {
      id: 'accountManagement.components.subscription.plan.description.geeni.pr_1month_plus_4',
      defaultMessage: '{checkIcon} Snooze notifications',
    },
    {
      id: 'accountManagement.components.subscription.plan.description.geeni.pr_1month_plus_5',
      defaultMessage: '{checkIcon} Smart Detection',
    },
    {
      id: 'accountManagement.components.subscription.plan.description.geeni.pr_1month_plus_6',
      defaultMessage: '{cancelIcon} Send Help',
    },
  ],
  pr_1year_plus: [
    {
      id: 'accountManagement.components.subscription.plan.description.geeni.pr_1year_plus_1',
      defaultMessage: '{checkIcon} Watch & download activity',
    },
    {
      id: 'accountManagement.components.subscription.plan.description.geeni.pr_1year_plus_2',
      defaultMessage: '{checkIcon} 14-day video history',
    },
    {
      id: 'accountManagement.components.subscription.plan.description.geeni.pr_1year_plus_3',
      defaultMessage: '{checkIcon} Up to 2 Cameras',
    },
    {
      id: 'accountManagement.components.subscription.plan.description.geeni.pr_1year_plus_4',
      defaultMessage: '{checkIcon} Snooze notifications',
    },
    {
      id: 'accountManagement.components.subscription.plan.description.geeni.pr_1year_plus_5',
      defaultMessage: '{checkIcon} Smart Detection',
    },
    {
      id: 'accountManagement.components.subscription.plan.description.geeni.pr_1year_plus_6',
      defaultMessage: '{cancelIcon} Send Help',
    },
  ],
  pr_1month_premium: [
    {
      id: 'accountManagement.components.subscription.plan.description.geeni.pr_1month_premium_1',
      defaultMessage: '{checkIcon} Watch & download activity',
    },
    {
      id: 'accountManagement.components.subscription.plan.description.geeni.pr_1month_premium_2',
      defaultMessage: '{checkIcon} 30-day video history',
    },
    {
      id: 'accountManagement.components.subscription.plan.description.geeni.pr_1month_premium_3',
      defaultMessage: '{checkIcon} Up to 10 Cameras',
    },
    {
      id: 'accountManagement.components.subscription.plan.description.geeni.pr_1month_premium_4',
      defaultMessage: '{checkIcon} Snooze notifications',
    },
    {
      id: 'accountManagement.components.subscription.plan.description.geeni.pr_1month_premium_5',
      defaultMessage: '{checkIcon} Smart Detection',
    },
    {
      id: 'accountManagement.components.subscription.plan.description.geeni.pr_1month_premium_6',
      defaultMessage: '{checkIcon} Send Help',
    },
  ],
  pr_1year_premium: [
    {
      id: 'accountManagement.components.subscription.plan.description.geeni.pr_1year_premium_1',
      defaultMessage: '{checkIcon} Watch & download activity',
    },
    {
      id: 'accountManagement.components.subscription.plan.description.geeni.pr_1year_premium_2',
      defaultMessage: '{checkIcon} 30-day video history',
    },
    {
      id: 'accountManagement.components.subscription.plan.description.geeni.pr_1year_premium_3',
      defaultMessage: '{checkIcon} Up to 10 Cameras',
    },
    {
      id: 'accountManagement.components.subscription.plan.description.geeni.pr_1year_premium_4',
      defaultMessage: '{checkIcon} Snooze notifications',
    },
    {
      id: 'accountManagement.components.subscription.plan.description.geeni.pr_1year_premium_5',
      defaultMessage: '{checkIcon} Smart Detection',
    },
    {
      id: 'accountManagement.components.subscription.plan.description.geeni.pr_1year_premium_6',
      defaultMessage: '{checkIcon} Send Help',
    },
  ],

  /**
   * Pepper plans
   */
  pepper_es_1mo: [
    {
      id: 'accountManagement.components.subscription.plan.description.pepper.pepper_es_1mo_1',
      defaultMessage: '{checkIcon} 7-day video history',
    },
    {
      id: 'accountManagement.components.subscription.plan.description.pepper.pepper_es_1mo_2',
      defaultMessage: '{checkIcon} 1 camera',
    },
    {
      id: 'accountManagement.components.subscription.plan.description.pepper.pepper_es_1mo_3',
      defaultMessage: '{checkIcon} Watch & download activity',
    },
    {
      id: 'accountManagement.components.subscription.plan.description.pepper.pepper_es_1mo_4',
      defaultMessage: '{checkIcon} Snooze notifications',
    },
    {
      id: 'accountManagement.components.subscription.plan.description.pepper.pepper_es_1mo_5',
      defaultMessage: '{checkIcon} Smart Detection',
    },
    {
      id: 'accountManagement.components.subscription.plan.description.pepper.pepper_es_1mo_6',
      defaultMessage: '{cancelIcon} Send Help Smart Security',
    },
  ],
  pepper_pr_1mo: [
    {
      id: 'accountManagement.components.subscription.plan.description.pepper.pepper_pr_1mo_1',
      defaultMessage: '{checkIcon} 30-day video history',
    },
    {
      id: 'accountManagement.components.subscription.plan.description.pepper.pepper_pr_1mo_2',
      defaultMessage: '{checkIcon} Up to 5 cameras',
    },
    {
      id: 'accountManagement.components.subscription.plan.description.pepper.pepper_pr_1mo_3',
      defaultMessage: '{checkIcon} Watch & download activity',
    },
    {
      id: 'accountManagement.components.subscription.plan.description.pepper.pepper_pr_1mo_4',
      defaultMessage: '{checkIcon} Snooze notifications',
    },
    {
      id: 'accountManagement.components.subscription.plan.description.pepper.pepper_pr_1mo_5',
      defaultMessage: '{checkIcon} Smart Detection',
    },
    {
      id: 'accountManagement.components.subscription.plan.description.pepper.pepper_pr_1mo_6',
      defaultMessage: '{checkIcon} Send Help Smart Security',
    },
  ],

  pepper_es_1year: [
    {
      id: 'accountManagement.components.subscription.plan.description.pepper.pepper_es_1year_1',
      defaultMessage: '{checkIcon} 7-day video history',
    },
    {
      id: 'accountManagement.components.subscription.plan.description.pepper.pepper_es_1year_2',
      defaultMessage: '{checkIcon} 1 camera',
    },
    {
      id: 'accountManagement.components.subscription.plan.description.pepper.pepper_es_1year_3',
      defaultMessage: '{checkIcon} Watch & download activity',
    },
    {
      id: 'accountManagement.components.subscription.plan.description.pepper.pepper_es_1year_4',
      defaultMessage: '{checkIcon} Snooze notifications',
    },
    {
      id: 'accountManagement.components.subscription.plan.description.pepper.pepper_es_1year_5',
      defaultMessage: '{checkIcon} Smart Detection',
    },
    {
      id: 'accountManagement.components.subscription.plan.description.pepper.pepper_es_1year_6',
      defaultMessage: '{cancelIcon} Send Help Smart Security',
    },
  ],
  pepper_pr_1year: [
    {
      id: 'accountManagement.components.subscription.plan.description.pepper.pepper_pr_1year_1',
      defaultMessage: '{checkIcon} 30-day video history',
    },
    {
      id: 'accountManagement.components.subscription.plan.description.pepper.pepper_pr_1year_2',
      defaultMessage: '{checkIcon} Up to 5 cameras',
    },
    {
      id: 'accountManagement.components.subscription.plan.description.pepper.pepper_pr_1year_3',
      defaultMessage: '{checkIcon} Watch & download activity',
    },
    {
      id: 'accountManagement.components.subscription.plan.description.pepper.pepper_pr_1year_4',
      defaultMessage: '{checkIcon} Snooze notifications',
    },
    {
      id: 'accountManagement.components.subscription.plan.description.pepper.pepper_pr_1year_5',
      defaultMessage: '{checkIcon} Smart Detection',
    },
    {
      id: 'accountManagement.components.subscription.plan.description.pepper.pepper_pr_1year_6',
      defaultMessage: '{checkIcon} Send Help Smart Security',
    },
  ],

  /**
   * Night Owl plans
   */
  standard_single_device_monthly: [
    {
      id: 'accountManagement.components.subscription.plan.description.nightowl.standard_single_device_monthly_1',
      defaultMessage: '{checkIcon} 48 hours DVR video history',
    },
    {
      id: 'accountManagement.components.subscription.plan.description.nightowl.standard_single_device_monthly_2',
      defaultMessage: '{checkIcon} 30 days camera video history',
    },
    {
      id: 'accountManagement.components.subscription.plan.description.nightowl.standard_single_device_monthly_3',
      defaultMessage: '{cancelIcon} Send Help',
    },
  ],
  standard_multi_device_monthly: [
    {
      id: 'accountManagement.components.subscription.plan.description.nightowl.standard_multi_device_monthly_1',
      defaultMessage: '{checkIcon} 48 hours DVR video history',
    },
    {
      id: 'accountManagement.components.subscription.plan.description.nightowl.standard_multi_device_monthly_2',
      defaultMessage: '{checkIcon} 30 days camera video history',
    },
    {
      id: 'accountManagement.components.subscription.plan.description.nightowl.standard_multi_device_monthly_3',
      defaultMessage: '{cancelIcon} Send Help',
    },
  ],
  plus_monthly: [
    {
      id: 'accountManagement.components.subscription.plan.description.nightowl.plus_monthly_1',
      defaultMessage: '{checkIcon} 7 days DVR video history',
    },
    {
      id: 'accountManagement.components.subscription.plan.description.nightowl.plus_monthly_2',
      defaultMessage: '{checkIcon} 30 days camera video history',
    },
    {
      id: 'accountManagement.components.subscription.plan.description.nightowl.plus_monthly_3',
      defaultMessage: '{checkIcon} Send Help',
    },
  ],
  premium_monthly: [
    {
      id: 'accountManagement.components.subscription.plan.description.nightowl.premium_monthly_1',
      defaultMessage: '{checkIcon} 14 days DVR video history',
    },
    {
      id: 'accountManagement.components.subscription.plan.description.nightowl.premium_monthly_2',
      defaultMessage: '{checkIcon} 30 days camera video history',
    },
    {
      id: 'accountManagement.components.subscription.plan.description.nightowl.premium_monthly_3',
      defaultMessage: '{checkIcon} Send Help',
    },
  ],
  // YEARLY
  standard_single_device_annual: [
    {
      id: 'accountManagement.components.subscription.plan.description.nightowl.standard_single_device_annual_1',
      defaultMessage: '{checkIcon} 48 hours DVR video history',
    },
    {
      id: 'accountManagement.components.subscription.plan.description.nightowl.standard_single_device_annual_2',
      defaultMessage: '{checkIcon} 30 days camera video history',
    },
    {
      id: 'accountManagement.components.subscription.plan.description.nightowl.standard_single_device_annual_3',
      defaultMessage: '{cancelIcon} Send Help',
    },
  ],
  standard_multi_device_annual: [
    {
      id: 'accountManagement.components.subscription.plan.description.nightowl.standard_multi_device_annual_1',
      defaultMessage: '{checkIcon} 48 hours DVR video history',
    },
    {
      id: 'accountManagement.components.subscription.plan.description.nightowl.standard_multi_device_annual_2',
      defaultMessage: '{checkIcon} 30 days camera video history',
    },
    {
      id: 'accountManagement.components.subscription.plan.description.nightowl.standard_multi_device_annual_3',
      defaultMessage: '{cancelIcon} Send Help',
    },
  ],
  plus_annual: [
    {
      id: 'accountManagement.components.subscription.plan.description.nightowl.plus_annual_1',
      defaultMessage: '{checkIcon} 7 days DVR video history',
    },
    {
      id: 'accountManagement.components.subscription.plan.description.nightowl.plus_annual_2',
      defaultMessage: '{checkIcon} 30 days camera video history',
    },
    {
      id: 'accountManagement.components.subscription.plan.description.nightowl.plus_annual_3',
      defaultMessage: '{checkIcon} Send Help',
    },
  ],
  premium_annual: [
    {
      id: 'accountManagement.components.subscription.plan.description.nightowl.premium_annual_1',
      defaultMessage: '{checkIcon} 14 days DVR video history',
    },
    {
      id: 'accountManagement.components.subscription.plan.description.nightowl.premium_annual_2',
      defaultMessage: '{checkIcon} 30 days camera video history',
    },
    {
      id: 'accountManagement.components.subscription.plan.description.nightowl.premium_annual_3',
      defaultMessage: '{checkIcon} Send Help',
    },
  ],

};

export default planFeatures;
