import React from 'react';
import messages from './messages';
import { FormattedMessage } from 'react-intl';

const NotFoundPage = () => {
  return (
    <article
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: 15,
        fontSize: 'larger',
      }}
    >
      <FormattedMessage {...messages.header} />
    </article>
  );
};

export default NotFoundPage;
