import { CancelDeleteActionTypes, ICancelDeleteAccount } from './types';
import {
  GET_CANCEL_DELETE_ACCOUNT_ATTEMPT,
  GET_CANCEL_DELETE_ACCOUNT_FAILURE,
  GET_CANCEL_DELETE_ACCOUNT_SUCCESS,
  CLEAR_STATE,
} from './actionTypes';

export const initialState: ICancelDeleteAccount = {
  attempting: false,
  error: null,
  cancelDeleteSuccess: false,
};

export const CancelDeleteAccountReducer = (
  state = initialState,
  action: CancelDeleteActionTypes,
): ICancelDeleteAccount => {
  switch (action.type) {
    case GET_CANCEL_DELETE_ACCOUNT_ATTEMPT:
      return {
        ...state,
        attempting: true,
        cancelDeleteSuccess: false,
        error: null,
      };
    case GET_CANCEL_DELETE_ACCOUNT_SUCCESS:
      return {
        ...state,
        error: null,
        attempting: false,
        cancelDeleteSuccess: true,
      };
    case GET_CANCEL_DELETE_ACCOUNT_FAILURE:
      return {
        ...state,
        error: action.error,
        attempting: false,
        cancelDeleteSuccess: false,
      };
    case CLEAR_STATE:
      return initialState;
    default:
      return state;
  }
};
