import { CheckoutSessionActionTypes, ICheckoutSession } from './types';
import {
  CREATE_CHECKOUT_SESSION_SUCCESS,
  CREATE_CHECKOUT_SESSION_FAILURE,
  CLEAR_CHECKOUT_SESSION_STATE,
  CREATE_CHECKOUT_SESSION_ATTEMPT,
} from './actionTypes';

export const initialState: ICheckoutSession = {
  checkoutSessionUrl: '',
  checkoutSessionFailure: '',
  creatingCheckoutSession: false,
  redirecting: false,
};

export const CheckoutSessionReducer = (state = initialState, action: CheckoutSessionActionTypes): ICheckoutSession => {
  switch (action.type) {
    case CREATE_CHECKOUT_SESSION_ATTEMPT:
      return {
        ...state,
        creatingCheckoutSession: true,
        checkoutSessionUrl: '',
      };
    case CREATE_CHECKOUT_SESSION_SUCCESS:
      return {
        ...state,
        checkoutSessionUrl: action.checkoutSessionUrl,
        creatingCheckoutSession: false,
        redirecting: true,
      };
    case CREATE_CHECKOUT_SESSION_FAILURE:
      return {
        ...state,
        checkoutSessionFailure: action.error,
        creatingCheckoutSession: false,
        redirecting: false,
      };
    case CLEAR_CHECKOUT_SESSION_STATE:
      return {
        ...state,
        checkoutSessionUrl: '',
        checkoutSessionFailure: '',
        creatingCheckoutSession: false,
        redirecting: false,
      };
    default:
      return state;
  }
};
