import React from 'react';
import { Link } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import { FormattedMessage } from 'react-intl';

const I18nLink = (props: any) => {
  const { intl, brand, nameI18n, linkI18n } = props;

  return (
    <Link className={`${brand}-link-text`} target="_blank" to={intl.formatMessage({ id: linkI18n })}>
      <FormattedMessage id={nameI18n} />
    </Link>
  );
};

export default injectIntl(I18nLink);
