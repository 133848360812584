import { PaymentInfoActionTypes } from './types';

import {
  CLEAR_BILLING_INFORMATION_STATE,
  CREATE_BILLING_INFORMATION_UPDATE_DELEGATE_FAILURE,
  CREATE_PAYMENT_INFORMATION_UPDATE_DELEGATE_ATTEMPT,
  CREATE_PAYMENT_INFORMATION_UPDATE_DELEGATE_SUCCESS,
  GET_PAYMENT_INFORMATION_ATTEMPT,
  GET_PAYMENT_INFORMATION_FAILURE,
  GET_PAYMENT_INFORMATION_SUCCESS,
} from './actionTypes';

export const getBillingInfoAttempt = (): PaymentInfoActionTypes => ({
  type: GET_PAYMENT_INFORMATION_ATTEMPT,
});

export const getBillingInfoSuccess = (billingInfo: any): PaymentInfoActionTypes => ({
  type: GET_PAYMENT_INFORMATION_SUCCESS,
  billingInfo: billingInfo,
});

export const getBillingInfoFailure = (err: any): PaymentInfoActionTypes => ({
  type: GET_PAYMENT_INFORMATION_FAILURE,
  error: err,
});

export const createBillingInfoUpdateDelegateAttempt = (): PaymentInfoActionTypes => ({
  type: CREATE_PAYMENT_INFORMATION_UPDATE_DELEGATE_ATTEMPT,
});

export const createBillingInfoUpdateDelegateSuccess = (updateBillingInfoSessionUrl: any): PaymentInfoActionTypes => ({
  type: CREATE_PAYMENT_INFORMATION_UPDATE_DELEGATE_SUCCESS,
  updateBillingInfoSessionUrl: updateBillingInfoSessionUrl,
});

export const createBillingInfoUpdateDelegateFailure = (err: any): PaymentInfoActionTypes => ({
  type: CREATE_BILLING_INFORMATION_UPDATE_DELEGATE_FAILURE,
  error: err,
});

export const clearBillingInfoState = (): PaymentInfoActionTypes => ({
  type: CLEAR_BILLING_INFORMATION_STATE,
});
