import { LOCALES } from '../constants';
import unifiedCheckoutMessages from '../../../containers/UnifiedCheckout/messages';
import { MessageDescriptor } from 'react-intl';
import { MessageFormatElement } from 'react-intl';

type Message = string | MessageFormatElement[] | undefined;
const fromDefaultMessages = (msg: Record<string, MessageDescriptor>): Record<string, Message> => {
  return Object.values(msg).reduce<Record<string, Message>>(
    (acc, val) => ({ ...acc, [val.id ?? '']: val.defaultMessage }),
    {},
  );
};

// cSpell:ignore visi, recurly, dotwhack, pepperos

const en = {
  [LOCALES.ENGLISH]: {
    //tabs
    common_first_tab: 'My Plan',
    common_second_tab: 'Payment',
    common_third_tab: 'Account History',
    common_third_tab_mobile: 'History',

    pendingSubscription: 'Pending change to {plan} Plan {billingCycle} - {effectiveDate}',
    noStorage: 'No Storage',
    hourStorage: '{hours}-Hour History',
    dayStorage: '{days}-Day History',
    subTrialExpiration: 'Free Trial - Expires on {expireDate}',
    recurring: 'Recurring',
    renews: 'Renews',
    termsOfUse: 'Terms of Use',
    privacyPolicy: 'Privacy Policy',
    selectedPlanCardButtonText: 'Selected Plan',
    pendingCardButtonText: 'Pending',
    ineligibleCardButtonText: 'Not eligible for selected promo',
    signInCardButtonText: 'Sign in to continue',
    currentPlanCardButtonText: '{planIcon} Current Plan',
    selectPlanCardButtonText: 'Select Plan',
    monthlyTab: 'Monthly',
    yearlyTab: 'Yearly',
    'app.containers.PlansList.tab.yearlyTabBestValue.text': 'yearly (best value)',
    'app.containers.PlansList.tab.twoYearTab.text': '2 Year',
    currency: 'USD',

    // Header
    'accountManagement.components.header.button.signIn.text': 'Sign In',
    'accountManagement.components.header.button.signOut.text': 'Sign Out',

    //Change Plan messages
    'app.containers.PlansList.tab.monthly.text': 'monthly',
    'app.containers.PlansList.tab.yearly.text': 'yearly',
    'app.containers.PlansList.label.perYear.text': 'per year',
    'app.containers.PlansList.label.perMonth.text': 'per month',
    'accountManagement.components.button.subscription.plan.selected.text': 'Selected Plan',
    'accountManagement.components.button.subscription.plan.current.text': 'Current Plan',
    'accountManagement.components.button.subscription.plan.select.text': 'Select Plan',
    'accountManagement.components.PlansList.label.brand.momentum.text': 'Momentum',
    'accountManagement.components.PlansList.button.backBtn.text': 'Back',
    'accountManagement.components.PlansList.button.changePlan.text': 'Change Plan',
    'accountManagement.components.PlansList.button.freePlan.text': 'FREE',
    'accountManagement.components.PlansList.planText.text': 'Plan',
    'app.containers.PlansList.ribbon.text': 'Most Popular',

    'accountManagement.components.popover.freeTrialOffer.text':
      'Offer valid for new subscribers only. Limit one 30-day trial per account. Your account will be charged at the end of your trial period unless you downgrade or cancel.',
    'accountManagement.components.PlansList.btnTextWithPrice.text': '{price} (up to {numOfDevices} devices)',

    /**
     * Momentum plans
     */
    //lsm
    'accountManagement.components.subscription.plan.description.momentum.lsm-1': '- View Live Stream All Day and Night',
    'accountManagement.components.subscription.plan.description.momentum.lsm-2': '- Set Motion Triggered Notifications',

    //lite
    'accountManagement.components.subscription.plan.description.momentum.lite-1':
      '{cloudIcon} {day}\u00a0Video History',

    //7+month
    'accountManagement.components.subscription.plan.description.momentum.7+month-1': '{dotwhackIcon} First Month FREE',
    'accountManagement.components.subscription.plan.description.momentum.7+month-2':
      '{cloudIcon} {day}\u00a0Video History',
    'accountManagement.components.subscription.plan.description.momentum.7+month-3':
      '{cameraIcon} Up to\u00a0{cameraCount}',
    'accountManagement.components.subscription.plan.description.momentum.7+month-4':
      '{snoozeNotificationsIcon} Snooze Notifications',

    //30+month
    'accountManagement.components.subscription.plan.description.momentum.30+month-1': '{dotwhackIcon} First Month FREE',
    'accountManagement.components.subscription.plan.description.momentum.30+month-2':
      '{cloudIcon} {day}\u00a0Video History',
    'accountManagement.components.subscription.plan.description.momentum.30+month-3':
      '{cameraIcon} Up to\u00a0{cameraCount}',
    'accountManagement.components.subscription.plan.description.momentum.30+month-4':
      '{snoozeNotificationsIcon} Snooze Notifications',

    //60+month
    'accountManagement.components.subscription.plan.description.momentum.60+month-1': '{dotwhackIcon} First Month FREE',
    'accountManagement.components.subscription.plan.description.momentum.60+month-2':
      '{cloudIcon} {day}\u00a0Video History',
    'accountManagement.components.subscription.plan.description.momentum.60+month-3':
      '{cameraIcon} Up to\u00a0{cameraCount}',
    'accountManagement.components.subscription.plan.description.momentum.60+month-4':
      '{sendHelpIcon} Send Help Smart Security {boldBeta}',
    'accountManagement.components.subscription.plan.description.momentum.60+month-5':
      '{snoozeNotificationsIcon} Snooze Notifications',
    'accountManagement.components.subscription.plan.description.momentum.60+month-6':
      '{smartVisionIcon} Smart Detections {boldBeta}',

    //7+year
    'accountManagement.components.subscription.plan.description.momentum.7+year-1': '{dotwhackIcon} First Month FREE',
    'accountManagement.components.subscription.plan.description.momentum.7+year-2':
      '{cloudIcon} {day}\u00a0Video History',
    'accountManagement.components.subscription.plan.description.momentum.7+year-3':
      '{cameraIcon} Up to\u00a0{cameraCount}',
    'accountManagement.components.subscription.plan.description.momentum.7+year-4':
      '{snoozeNotificationsIcon} Snooze Notifications',

    //30+year
    'accountManagement.components.subscription.plan.description.momentum.30+year-1': '{dotwhackIcon} First Month FREE',
    'accountManagement.components.subscription.plan.description.momentum.30+year-2':
      '{cloudIcon} {day}\u00a0Video History',
    'accountManagement.components.subscription.plan.description.momentum.30+year-3':
      '{cameraIcon} Up to\u00a0{cameraCount}',
    'accountManagement.components.subscription.plan.description.momentum.30+year-4':
      '{snoozeNotificationsIcon} Snooze Notifications',

    //60+year
    'accountManagement.components.subscription.plan.description.momentum.60+year-1': '{dotwhackIcon} First Month FREE',
    'accountManagement.components.subscription.plan.description.momentum.60+year-2':
      '{cloudIcon} {day}\u00a0Video History',
    'accountManagement.components.subscription.plan.description.momentum.60+year-3':
      '{cameraIcon} Up to\u00a0{cameraCount}',
    'accountManagement.components.subscription.plan.description.momentum.60+year-4':
      '{sendHelpIcon} Send Help Smart Security {boldBeta}',
    'accountManagement.components.subscription.plan.description.momentum.60+year-5':
      '{snoozeNotificationsIcon} Snooze Notifications',
    'accountManagement.components.subscription.plan.description.momentum.60+year-6':
      '{smartVisionIcon} Smart Detections {boldBeta}',

    //30dean
    'accountManagement.components.subscription.plan.description.momentum.30dean-1': '- Save 30 Days of Video History',
    'accountManagement.components.subscription.plan.description.momentum.30dean-2':
      '- Download and Share Recorded Videos',
    'accountManagement.components.subscription.plan.description.momentum.30dean-3':
      '- View Live Stream All Day and Night',
    'accountManagement.components.subscription.plan.description.momentum.30dean-4':
      '- Set Motion Triggered Notifications',

    //7dem
    'accountManagement.components.subscription.plan.description.momentum.7dem-1': '- Save 7 Days of Video History',
    'accountManagement.components.subscription.plan.description.momentum.7dem-2':
      '- Download and Share Recorded Videos',
    'accountManagement.components.subscription.plan.description.momentum.7dem-3':
      '- View Live Stream All Day and Night',
    'accountManagement.components.subscription.plan.description.momentum.7dem-4':
      '- Set Motion Triggered Notifications',

    //30dem
    'accountManagement.components.subscription.plan.description.momentum.30dem-1': '- Save 30 Days of Video History',
    'accountManagement.components.subscription.plan.description.momentum.30dem-2':
      '- Download and Share Recorded Videos',
    'accountManagement.components.subscription.plan.description.momentum.30dem-3':
      '- View Live Stream All Day and Night',
    'accountManagement.components.subscription.plan.description.momentum.30dem-4':
      '- Set Motion Triggered Notifications',

    //7dean
    'accountManagement.components.subscription.plan.description.momentum.7dean-1': '- Save 7 Days of Video History',
    'accountManagement.components.subscription.plan.description.momentum.7dean-2':
      '- Download and Share Recorded Videos',
    'accountManagement.components.subscription.plan.description.momentum.7dean-3':
      '- View Live Stream All Day and Night',
    'accountManagement.components.subscription.plan.description.momentum.7dean-4':
      '- Set Motion Triggered Notifications',

    /**
     * Otis plans
     */
    //es_1month_1cam

    'accountManagement.components.subscription.plan.description.otis.es_1month_4cam.free_trial':
      '{checkIcon} 30-day free trial',
    'accountManagement.components.subscription.plan.description.otis.es_1month_4cam.7_day_history':
      '{checkIcon} 7-day video history',
    'accountManagement.components.subscription.plan.description.otis.es_1month_4cam.1_camera': '{checkIcon} 1 camera',
    'accountManagement.components.subscription.plan.description.otis.es_1month_4cam.30_day_history':
      '{cancelIcon} 30-day video history',
    'accountManagement.components.subscription.plan.description.otis.es_1month_4cam.up_to':
      '{cancelIcon} Up to 5 cameras',

    //pr_1month_all_cam
    'accountManagement.components.subscription.plan.description.otis.pr_1month_4cam.free_trial':
      '{checkIcon} 30-day free trial',
    'accountManagement.components.subscription.plan.description.otis.pr_1month_4cam.30_day_history':
      '{checkIcon} 30-day video history',
    'accountManagement.components.subscription.plan.description.otis.pr_1month_4cam.up_to':
      '{checkIcon} Up to 5 cameras',

    //es_1year_1cam

    'accountManagement.components.subscription.plan.description.otis.es_1year_4cam_1': '{checkIcon} 30-day free trial',
    'accountManagement.components.subscription.plan.description.otis.es_1year_4cam_2':
      '{checkIcon} 7-day video history',
    'accountManagement.components.subscription.plan.description.otis.es_1year_4cam_3': '{checkIcon} 1 camera',
    'accountManagement.components.subscription.plan.description.otis.es_1year_4cam_4':
      '{cancelIcon} 30-day video history',
    'accountManagement.components.subscription.plan.description.otis.es_1year_4cam_5': '{cancelIcon} Up to 5 cameras',

    //pr_1year_all_cam

    'accountManagement.components.subscription.plan.description.otis.pr_1year_4cam_1': '{checkIcon} 30-day free trial',
    'accountManagement.components.subscription.plan.description.otis.pr_1year_4cam_2':
      '{checkIcon} 30-day video history',
    'accountManagement.components.subscription.plan.description.otis.pr_1year_4cam_3': '{checkIcon} Up to 5 cameras',

    /**
     * Visi plans
     */
    'accountManagement.components.subscription.plan.description.visi.ba_1month_4cam_1':
      '{checkIcon} Watch & download activity',
    'accountManagement.components.subscription.plan.description.visi.ba_1month_4cam_2':
      '{checkIcon} 24-hour video history',
    'accountManagement.components.subscription.plan.description.visi.ba_1month_4cam_3': '{cancelIcon} Smart Detection',
    'accountManagement.components.subscription.plan.description.visi.ba_1month_4cam_4':
      '{cancelIcon} Send Help Smart Security',
    'accountManagement.components.subscription.plan.description.visi.pr_1month_4cam_1':
      '{checkIcon} Watch & download activity',
    'accountManagement.components.subscription.plan.description.visi.pr_1month_4cam_2':
      '{checkIcon} 30-day video history',
    'accountManagement.components.subscription.plan.description.visi.pr_1month_4cam_3': '{checkIcon} Smart Detection',
    'accountManagement.components.subscription.plan.description.visi.pr_1month_4cam_4':
      '{checkIcon} Send Help Smart Security',

    'accountManagement.components.subscription.plan.description.visi.ba_1year_4cam_1':
      '{checkIcon} Watch & download activity',
    'accountManagement.components.subscription.plan.description.visi.ba_1year_4cam_2':
      '{checkIcon} 24-hour video history',
    'accountManagement.components.subscription.plan.description.visi.ba_1year_4cam_3': '{cancelIcon} Smart Detection',
    'accountManagement.components.subscription.plan.description.visi.ba_1year_4cam_4':
      '{cancelIcon} Send Help Smart Security',
    'accountManagement.components.subscription.plan.description.visi.pr_1year_4cam_1':
      '{checkIcon} Watch & download activity',
    'accountManagement.components.subscription.plan.description.visi.pr_1year_4cam_2':
      '{checkIcon} 30-day video history',
    'accountManagement.components.subscription.plan.description.visi.pr_1year_4cam_3': '{checkIcon} Smart Detection',
    'accountManagement.components.subscription.plan.description.visi.pr_1year_4cam_4':
      '{checkIcon} Send Help Smart Security',
    'accountManagement.components.subscription.plan.description.visi.ba_2year_4cam_1':
      '{checkIcon} Watch & download activity',
    'accountManagement.components.subscription.plan.description.visi.ba_2year_4cam_2':
      '{checkIcon} 24-hour video history',
    'accountManagement.components.subscription.plan.description.visi.ba_2year_4cam_3': '{cancelIcon} Smart Detection',
    'accountManagement.components.subscription.plan.description.visi.ba_2year_4cam_4':
      '{cancelIcon} Send Help Smart Security',
    'accountManagement.components.subscription.plan.description.visi.pr_2year_4cam_1':
      '{checkIcon} Watch & download activity',
    'accountManagement.components.subscription.plan.description.visi.pr_2year_4cam_2':
      '{checkIcon} 30-day video history',
    'accountManagement.components.subscription.plan.description.visi.pr_2year_4cam_3': '{checkIcon} Smart Detection',
    'accountManagement.components.subscription.plan.description.visi.pr_2year_4cam_4':
      '{checkIcon} Send Help Smart Security',

    //cancel plan page

    'accountManagement.components.cancel.plan.description.otis.es_1month_4cam': '{cancelIcon} 7-day video history',
    'accountManagement.components.cancel.plan.description.otis.pr_1month_4cam': '{cancelIcon} 30-day video history',
    'accountManagement.components.cancel.plan.description.otis.es_1year_4cam': '{cancelIcon} 7-day video history',
    'accountManagement.components.cancel.plan.description.otis.pr_1year_4cam': '{cancelIcon} 30-day video history',

    //visi
    'accountManagement.components.cancel.plan.description.visi.ba_1month_4cam_1':
      '{cancelIcon} Watch & download activity',
    'accountManagement.components.cancel.plan.description.visi.ba_1month_4cam_2': '{cancelIcon} 24-hour video history',
    'accountManagement.components.cancel.plan.description.visi.pr_1month_4cam_1':
      '{cancelIcon} Watch & download activity',
    'accountManagement.components.cancel.plan.description.visi.pr_1month_4cam_2': '{cancelIcon} 30-day video history',
    'accountManagement.components.cancel.plan.description.visi.pr_1month_4cam_3': '{cancelIcon} Smart Detection',
    'accountManagement.components.cancel.plan.description.visi.pr_1month_4cam_4':
      '{cancelIcon} Send Help Smart Security',
    'accountManagement.components.cancel.plan.description.visi.ba_1year_4cam_1':
      '{cancelIcon} Watch & download activity',
    'accountManagement.components.cancel.plan.description.visi.ba_1year_4cam_2': '{cancelIcon} 24-hour video history',
    'accountManagement.components.cancel.plan.description.visi.pr_1year_4cam_1':
      '{cancelIcon} Watch & download activity',
    'accountManagement.components.cancel.plan.description.visi.pr_1year_4cam_2': '{cancelIcon} 30-day video history',
    'accountManagement.components.cancel.plan.description.visi.pr_1year_4cam_3': '{cancelIcon} Smart Detection',
    'accountManagement.components.cancel.plan.description.visi.pr_1year_4cam_4':
      '{cancelIcon} Send Help Smart Security',
    'accountManagement.components.cancel.plan.description.visi.ba_2year_4cam_1':
      '{cancelIcon} Watch & download activity',
    'accountManagement.components.cancel.plan.description.visi.ba_2year_4cam_2': '{cancelIcon} 24-hour video history',
    'accountManagement.components.cancel.plan.description.visi.pr_2year_4cam_1':
      '{cancelIcon} Watch & download activity',
    'accountManagement.components.cancel.plan.description.visi.pr_2year_4cam_2': '{cancelIcon} 30-day video history',
    'accountManagement.components.cancel.plan.description.visi.pr_2year_4cam_3': '{cancelIcon} Smart Detection',
    'accountManagement.components.cancel.plan.description.visi.pr_2year_4cam_4':
      '{cancelIcon} Send Help Smart Security',

    //data loss features
    'accountManagement.components.dataLoss.plan.description.momentum.lsm-1': '- View Live Stream All Day and Night',
    'accountManagement.components.dataLoss.plan.description.momentum.lsm-2': '- Set Motion Triggered Notifications',
    'accountManagement.components.dataLoss.plan.description.momentum.lite-1': '{cloudIcon} {day} Video History',
    'accountManagement.components.dataLoss.plan.description.momentum.7+month-2': '{cancelIcon} 7 Day Video History',
    'accountManagement.components.dataLoss.plan.description.momentum.7+month-3': '{cancelIcon} Up to 2 Cameras',
    'accountManagement.components.dataLoss.plan.description.momentum.30+month-2': '{cancelIcon} 30 Day Video History',
    'accountManagement.components.dataLoss.plan.description.momentum.30+month-3': '{cancelIcon} Up to 5 Cameras',
    'accountManagement.components.dataLoss.plan.description.momentum.60+month-2': '{cancelIcon} 60 Day Video History',
    'accountManagement.components.dataLoss.plan.description.momentum.60+month-3': '{cancelIcon} Up to 10 Cameras',
    'accountManagement.components.dataLoss.plan.description.momentum.7+year-2': '{cancelIcon} 7 Day Video History',
    'accountManagement.components.dataLoss.plan.description.momentum.7+year-3': '{cancelIcon} Up to 2 Cameras',
    'accountManagement.components.dataLoss.plan.description.momentum.30+year-2': '{cancelIcon} 30 Day Video History',
    'accountManagement.components.dataLoss.plan.description.momentum.30+year-3': '{cancelIcon} Up to 5 Cameras',
    'accountManagement.components.dataLoss.plan.description.momentum.60+year-1': '{cancelIcon} 60 Day Video History',
    'accountManagement.components.dataLoss.plan.description.momentum.60+year-2': '{cancelIcon} Up to 10 Cameras',
    'accountManagement.components.dataLoss.plan.description.momentum.30dean-1':
      '{cancelIcon} Save 30 Days of Video History',
    'accountManagement.components.dataLoss.plan.description.momentum.30dean-2':
      '{cancelIcon} Download and Share Recorded Videos',
    'accountManagement.components.dataLoss.plan.description.momentum.30dean-3':
      '{cancelIcon} View Live Stream All Day and Night',
    'accountManagement.components.dataLoss.plan.description.momentum.30dean-4':
      '{cancelIcon} Set Motion Triggered Notifications',
    'accountManagement.components.dataLoss.plan.description.momentum.7dem-1':
      '{cancelIcon} Save 7 Days of Video History',
    'accountManagement.components.dataLoss.plan.description.momentum.7dem-2':
      '{cancelIcon} Download and Share Recorded Videos',
    'accountManagement.components.dataLoss.plan.description.momentum.7dem-3':
      '{cancelIcon} View Live Stream All Day and Night',
    'accountManagement.components.dataLoss.plan.description.momentum.7dem-4':
      '{cancelIcon} Set Motion Triggered Notifications',
    'accountManagement.components.dataLoss.plan.description.momentum.30dem-1':
      '{cancelIcon} Save 30 Days of Video History',
    'accountManagement.components.dataLoss.plan.description.momentum.30dem-2':
      '{cancelIcon} Download and Share Recorded Videos',
    'accountManagement.components.dataLoss.plan.description.momentum.30dem-3':
      '{cancelIcon} View Live Stream All Day and Night',
    'accountManagement.components.dataLoss.plan.description.momentum.30dem-4':
      '{cancelIcon} Set Motion Triggered Notifications',
    'accountManagement.components.dataLoss.plan.description.momentum.7dean-1':
      '{cancelIcon} Save 7 Days of Video History',
    'accountManagement.components.dataLoss.plan.description.momentum.7dean-2':
      '{cancelIcon} Download and Share Recorded Videos',
    'accountManagement.components.dataLoss.plan.description.momentum.7dean-3':
      '{cancelIcon} View Live Stream All Day and Night',
    'accountManagement.components.dataLoss.plan.description.momentum.7dean-4':
      '{cancelIcon} Set Motion Triggered Notifications',
    'accountManagement.components.dataLoss.plan.description.otis.es_1month_4cam.7_day_history':
      '{cancelIcon} 7-day video history',
    'accountManagement.components.dataLoss.plan.description.otis.es_1month_4cam.1_camera': '{cancelIcon} 1 camera',
    'accountManagement.components.dataLoss.plan.description.otis.pr_1month_4cam.30_day_history':
      '{cancelIcon} 30-day video history',
    'accountManagement.components.dataLoss.plan.description.otis.pr_1month_4cam.up_to': '{cancelIcon} Up to 5 cameras',
    'accountManagement.components.dataLoss.plan.description.otis.es_1year_4cam_2': '{cancelIcon} 7-day video history',
    'accountManagement.components.dataLoss.plan.description.otis.es_1year_4cam_3': '{cancelIcon} 1 camera',
    'accountManagement.components.dataLoss.plan.description.otis.pr_1year_4cam_1': '{cancelIcon} 30-day free trial',
    'accountManagement.components.dataLoss.plan.description.otis.pr_1year_4cam_2': '{cancelIcon} 30-day video history',
    'accountManagement.components.dataLoss.plan.description.otis.pr_1year_4cam_3': '{cancelIcon} Up to 5 cameras',
    'accountManagement.components.dataLoss.plan.description.visi.ba_1month_4cam_1':
      '{cancelIcon} Watch & download activity',
    'accountManagement.components.dataLoss.plan.description.visi.ba_1month_4cam_2':
      '{cancelIcon} 24-hour video history',
    'accountManagement.components.dataLoss.plan.description.visi.pr_1month_4cam_1':
      '{cancelIcon} Watch & download activity',
    'accountManagement.components.dataLoss.plan.description.visi.pr_1month_4cam_2': '{cancelIcon} 30-day video history',
    'accountManagement.components.dataLoss.plan.description.visi.pr_1month_4cam_3': '{cancelIcon} Smart Detection',
    'accountManagement.components.dataLoss.plan.description.visi.pr_1month_4cam_4':
      '{cancelIcon} Send Help Smart Security',
    'accountManagement.components.dataLoss.plan.description.visi.ba_1year_4cam_1':
      '{cancelIcon} Watch & download activity',
    'accountManagement.components.dataLoss.plan.description.visi.ba_1year_4cam_2': '{cancelIcon} 24-hour video history',
    'accountManagement.components.dataLoss.plan.description.visi.pr_1year_4cam_1':
      '{cancelIcon} Watch & download activity',
    'accountManagement.components.dataLoss.plan.description.visi.pr_1year_4cam_2': '{cancelIcon} 30-day video history',
    'accountManagement.components.dataLoss.plan.description.visi.pr_1year_4cam_3': '{cancelIcon} Smart Detection',
    'accountManagement.components.dataLoss.plan.description.visi.pr_1year_4cam_4':
      '{cancelIcon} Send Help Smart Security',
    'accountManagement.components.dataLoss.plan.description.visi.ba_2year_4cam_1':
      '{cancelIcon} Watch & download activity',
    'accountManagement.components.dataLoss.plan.description.visi.ba_2year_4cam_2': '{cancelIcon} 24-hour video history',
    'accountManagement.components.dataLoss.plan.description.visi.pr_2year_4cam_1':
      '{cancelIcon} Watch & download activity',
    'accountManagement.components.dataLoss.plan.description.visi.pr_2year_4cam_2': '{cancelIcon} 30-day video history',
    'accountManagement.components.dataLoss.plan.description.visi.pr_2year_4cam_3': '{cancelIcon} Smart Detection',
    'accountManagement.components.dataLoss.plan.description.visi.pr_2year_4cam_4':
      '{cancelIcon} Send Help Smart Security',

    'accountManagement.components.dataLoss.plan.description.pepper.pepper_es_1mo_1': '{cancelIcon} 7-day video history',
    'accountManagement.components.dataLoss.plan.description.pepper.pepper_es_1mo_2':
      '{cancelIcon} Snooze notifications',
    'accountManagement.components.dataLoss.plan.description.pepper.pepper_es_1mo_3': '{cancelIcon} Smart Detection',
    'accountManagement.components.dataLoss.plan.description.pepper.pepper_es_1mo_4':
      '{cancelIcon} Send Help Smart Security',

    'accountManagement.components.dataLoss.plan.description.pepper.pepper_pr_1mo_1':
      '{cancelIcon} 30-day video history',
    'accountManagement.components.dataLoss.plan.description.pepper.pepper_pr_1mo_2':
      '{cancelIcon} Snooze notifications',
    'accountManagement.components.dataLoss.plan.description.pepper.pepper_pr_1mo_3': '{cancelIcon} Smart Detection',
    'accountManagement.components.dataLoss.plan.description.pepper.pepper_pr_1mo_4':
      '{cancelIcon} Send Help Smart Security',

    'accountManagement.components.dataLoss.plan.description.pepper.pepper_es_1year_1':
      '{cancelIcon} 7-day video history',
    'accountManagement.components.dataLoss.plan.description.pepper.pepper_es_1year_2':
      '{cancelIcon} Snooze notifications',
    'accountManagement.components.dataLoss.plan.description.pepper.pepper_es_1year_3': '{cancelIcon} Smart Detection',
    'accountManagement.components.dataLoss.plan.description.pepper.pepper_es_1year_4':
      '{cancelIcon} Send Help Smart Security',

    'accountManagement.components.dataLoss.plan.description.pepper.pepper_pr_1year_1':
      '{cancelIcon} 30-day video history',
    'accountManagement.components.dataLoss.plan.description.pepper.pepper_pr_1year_2':
      '{cancelIcon} Snooze notifications',
    'accountManagement.components.dataLoss.plan.description.pepper.pepper_pr_1year_3': '{cancelIcon} Smart Detection',
    'accountManagement.components.dataLoss.plan.description.pepper.pepper_pr_1year_4':
      '{cancelIcon} Send Help Smart Security',

    /**
     * Pepper plans
     */
    'accountManagement.components.subscription.plan.description.pepper.pepper_es_1mo_1':
      '{checkIcon} 7-day video history',
    'accountManagement.components.subscription.plan.description.pepper.pepper_es_1mo_2': '{checkIcon} 1 camera',
    'accountManagement.components.subscription.plan.description.pepper.pepper_es_1mo_3':
      '{checkIcon} Watch & download activity',
    'accountManagement.components.subscription.plan.description.pepper.pepper_es_1mo_4':
      '{checkIcon} Snooze notifications',
    'accountManagement.components.subscription.plan.description.pepper.pepper_es_1mo_5': '{checkIcon} Smart Detection',
    'accountManagement.components.subscription.plan.description.pepper.pepper_es_1mo_6':
      '{cancelIcon} Send Help Smart Security',

    'accountManagement.components.subscription.plan.description.pepper.pepper_pr_1mo_1':
      '{checkIcon} 30-day video history',
    'accountManagement.components.subscription.plan.description.pepper.pepper_pr_1mo_2': '{checkIcon} Up to 5 cameras',
    'accountManagement.components.subscription.plan.description.pepper.pepper_pr_1mo_3':
      '{checkIcon} Watch & download activity',
    'accountManagement.components.subscription.plan.description.pepper.pepper_pr_1mo_4':
      '{checkIcon} Snooze notifications',
    'accountManagement.components.subscription.plan.description.pepper.pepper_pr_1mo_5': '{checkIcon} Smart Detection',
    'accountManagement.components.subscription.plan.description.pepper.pepper_pr_1mo_6':
      '{checkIcon} Send Help Smart Security',

    'accountManagement.components.subscription.plan.description.pepper.pepper_es_1year_1':
      '{checkIcon} 7-day video history',
    'accountManagement.components.subscription.plan.description.pepper.pepper_es_1year_2': '{checkIcon} 1 camera',
    'accountManagement.components.subscription.plan.description.pepper.pepper_es_1year_3':
      '{checkIcon} Watch & download activity',
    'accountManagement.components.subscription.plan.description.pepper.pepper_es_1year_4':
      '{checkIcon} Snooze notifications',
    'accountManagement.components.subscription.plan.description.pepper.pepper_es_1year_5':
      '{checkIcon} Smart Detection',
    'accountManagement.components.subscription.plan.description.pepper.pepper_es_1year_6':
      '{cancelIcon} Send Help Smart Security',

    'accountManagement.components.subscription.plan.description.pepper.pepper_pr_1year_1':
      '{checkIcon} 30-day video history',
    'accountManagement.components.subscription.plan.description.pepper.pepper_pr_1year_2':
      '{checkIcon} Up to 5 cameras',
    'accountManagement.components.subscription.plan.description.pepper.pepper_pr_1year_3':
      '{checkIcon} Watch & download activity',
    'accountManagement.components.subscription.plan.description.pepper.pepper_pr_1year_4':
      '{checkIcon} Snooze notifications',
    'accountManagement.components.subscription.plan.description.pepper.pepper_pr_1year_5':
      '{checkIcon} Smart Detection',
    'accountManagement.components.subscription.plan.description.pepper.pepper_pr_1year_6':
      '{checkIcon} Send Help Smart Security',

    //ConfirmExitModal

    'accountManagement.components.label.confirmExit.modalTitle.text': 'Are you sure you want to go back?',
    'accountManagement.components.label.confirmExit.confirmExitStatement.text':
      'All changes will be lost unless you finish your purchase.',
    'accountManagement.components.label.confirmExit.cancelButton.text': 'Cancel',
    'accountManagement.components.label.confirmExit.exitButton.text': 'Yes',
    'accountManagement.components.label.confirmExit.warningAltText.text': 'Warning: Confirm Exit',

    //PaymentInfo
    'accountManagement.components.paymentInformation.cardType.text': 'Card Type',

    'accountManagement.components.paymentInformation.cardExpired.text': 'Card Expired',

    'accountManagement.components.paymentInformation.change.text': 'Change',

    'accountManagement.components.paymentInformation.info.networkError.text':
      'Sorry, we are unable to retrieve your information at this time. Please try again later.',

    'accountManagement.components.paymentInformation.info.noCreditCard.text': 'No credit card on file.',

    'accountManagement.components.paymentInformation.label.upcomingPayment.text': 'Upcoming Payment',
    'accountManagement.components.paymentInformation.label.applePayment.text': 'Billed Through iTunes',
    'accountManagement.components.paymentInformation.label.applePayment.message':
      'Go to iTunes to update your payment details.',

    //account history
    'accountManagement.components.accountHistory.columnHeader.date.text': 'Date',
    'accountManagement.components.accountHistory.columnHeader.status.text': 'Status',
    'accountManagement.components.accountHistory.columnHeader.amount.text': 'Amount',
    'accountManagement.components.accountHistory.columnHeader.paymentMethod.text': 'Payment Method',
    'accountManagement.components.accountHistory.columnHeader.device.text': 'Device',
    'accountManagement.components.accountHistory.columnHeader.invoiceNumber.text': 'Invoice #',
    'accountManagement.components.accountHistory.columnText.statusPaid.text': 'Paid',
    'accountManagement.components.accountHistory.columnText.statusPending.text': 'Pending',
    'accountManagement.components.accountHistory.columnText.statusFailed.text': 'Failed',
    'accountManagement.components.accountHistory.columnText.statusPastDue.text': 'Past Due',
    'accountManagement.components.accountHistory.columnText.statusRefund.text': 'Refund',
    'accountManagement.components.accountHistory.showingCount.text': 'Showing last 50 invoices',
    'accountManagement.components.accountHistory.info.noAcctHistory.text': 'You have no payment history.',
    'accountManagement.components.accountHistory.info.networkError.text':
      'Sorry, we are unable to retrieve your information at this time. Please try again later.',
    'accountManagement.components.accountHistory.cardType.text': 'Payment Method',
    'accountManagement.components.accountHistory.appleSubscriptions.text':
      'Go to iTunes to view payment history for your Apple subscriptions.',

    //Plan Limit Reached
    'app.containers.PlanLimitReachedModal.modalTitle.text': 'Plan Limit Reached',
    'app.containers.PlanLimitReachedModal.planLimitStatement.text':
      'The selected plan includes coverage up to {maxDevices} devices. Please select a new plan or uncheck a device to open up a new slot.',
    'app.containers.PlanLimitReachedModal.OkayButton.text': 'OK',
    'app.containers.PlanLimitReachedModal.ChangePlanButton.text': 'Change Plan',
    'app.containers.PlanLimitReachedModal.warningAltText.text': 'Warning: Plan Limit Reached',

    //Manage Devices Messages
    'accountManagement.components.label.manageDevices.title.text': 'Manage Devices',
    'accountManagement.components.label.manageDevices.devicePlanQuestion.text':
      'Which devices would you like to use with your plan?',
    'accountManagement.components.label.manageDevices.assignDeviceToPlan.text':
      'You can assign your {planName} to any of these devices.',
    'accountManagement.components.label.manageDevices.selectStatement.text': 'Select up to {maxDevicesForPlan}.',
    'accountManagement.components.button.manageDevices.backBtn.text': 'Back',
    'accountManagement.components.button.manageDevices.nextBtn.text': 'Next',
    'accountManagement.components.button.manageDevices.saveChangesBtn.text': 'Save Changes',
    'accountManagement.components.label.manageDevices.networkError.text':
      'Sorry, we are unable to retrieve your information at this time. Please try again later.',
    'accountManagement.components.label.manageDevices.savingDevices.text': 'Saving...',
    'accountManagement.components.label.manageDevices.savingDevices.statement.text':
      'Please wait while we update your account.',
    'accountManagement.components.label.manageDevices.momentumDefaultPlan.text': 'Lite Plan',
    'accountManagement.components.label.manageDevices.otisDefaultPlan.text': 'No Plan',

    //BRAND ADD - ADD THE DataLossModal.brand.dataLossStatement && dataLossQuestion
    //data loss model
    'app.containers.DataLossModal.modalTitle.text': 'Are you sure you want to remove {device} from your plan?',
    'app.containers.DataLossModal.dataLossQuestion.text':
      'The {devicesNames} will lose all services including the premium features listed below.',
    'app.containers.DataLossModal.features.text': 'features',
    'app.containers.DataLossModal.dataLossStatement.text':
      'All recorded videos and photos saved on the cloud will be permanently deleted for the removed {device}.',
    'app.containers.DataLossModal.backUp.text': 'Back up footage',
    'app.containers.DataLossModal.OkayButton.text': 'Not Now',
    'app.containers.DataLossModal.ChangePlanButton.text': "Yes, I'm Sure",
    'app.containers.DataLossModal.warningAltText.text': 'Warning: Data Loss May Occur',

    'app.containers.DataLossModal.pepperDataLossQuestion.text': 'The {devicesNames} will lose these features.',
    'app.containers.DataLossModal.pepperDataLossStatement.text':
      'Removing your {device} will downgrade your {camera} to 2-hour video history.',
    // Unable To Save Model Messages

    'accountManagement.components.label.unableToSave.modalTitle.text': 'Unable To Save',
    'accountManagement.components.label.unableToSave.okayButton.text': 'ok',
    'accountManagement.components.label.unableToSave.unableSaveStatement.text':
      'We are unable to save your changes at this time. Please try again.',
    'accountManagement.components.label.unableToSave.warningAltText.text': 'Warning: Unable to Save',

    //Change Subscription Confirmation model Page Messages

    'accountManagement.components.plan.subscriptionChange.confirmation.statement.backUpStatement':
      'Changing your plan will downgrade your {device} to {storage} video history. Older footage will be deleted at the end of the current billing period, {expireDate}',
    'accountManagement.components.plan.subscriptionChange.confirmation.statement.downgrade':
      'When you change your plan you will lose these features.',
    'accountManagement.components.plan.subscriptionChange.confirmation.question':
      'Are you sure you want to change your plan?',
    'accountManagement.components.button.plan.subscriptionChange.confirmation.cancel': 'Cancel',
    'accountManagement.components.button.plan.subscriptionChange.confirmation.confirm': 'Yes, change plan',
    'accountManagement.components.button.plan.subscriptionChange.confirmation.warning.alt.text':
      'Subscription Change Warning',

    //LoginPage Messages
    'accountManagement.components.loginPage.divider.or': 'OR',
    'accountManagement.components.loginPage.Links.ForgotPassword.message': 'Forgot Password?',
    'accountManagement.components.loginPage.fields.username.text': 'Email Address',
    'accountManagement.components.loginPage.fields.password.text': 'Password',
    'accountManagement.components.loginPage.fields.rememberMe.text': 'Remember Me',
    'accountManagement.components.loginPage.button.signIn.text': 'Sign In',
    'accountManagement.components.loginPage.button.appleSignIn.alt': 'Sign in with Apple',
    'accountManagement.components.loginPage.error.signInError.text': 'Sorry there was an error while logging in.',
    'accountManagement.components.loginPage.error.signInVerifyEmailError.text':
      'Email is not verified. Please verify and try again.',
    'accountManagement.components.loginPage.error.signInTooManyResendsError.text':
      'You requested too many verification codes. For security purposes, please wait {EMAIL_VERIFICATION_REQUEST_DISABLED_MINUTES} {EMAIL_VERIFICATION_REQUEST_DISABLED_MINUTES, plural, one {minute} other {minutes}} and try again.',

    // MFA
    'accountManagement.components.multiFactorAuthentication.fields.code.text': '6-digit-code',
    'accountManagement.components.multiFactorAuthentication.button.resendCode.text': 'Resend verification code',
    'accountManagement.components.multiFactorAuthentication.message.resendCode.success': 'Verification code sent.',
    'accountManagement.components.multiFactorAuthentication.message.resendCode.error':
      'Sorry, there was an issue sending your verification code. Please try again later.',
    'accountManagement.components.multiFactorAuthentication.button.verify': 'Verify',
    'accountManagement.components.multiFactorAuthentication.button.okay': 'Okay',
    'accountManagement.components.multiFactorAuthentication.default.title': 'Verify your identity',
    'accountManagement.components.multiFactorAuthentication.default.text':
      "This device isn't recognized. For your security, {brand} wants to make sure it's really you. Please enter the code we sent to {contact}",
    'accountManagement.components.multiFactorAuthentication.error.invalidLimitExceeded.title':
      'You have requested too many codes',
    'accountManagement.components.multiFactorAuthentication.error.invalidLimitExceeded.text':
      'For security purposes, you will need to wait {EMAIL_VERIFICATION_REQUEST_DISABLED_MINUTES} {EMAIL_VERIFICATION_REQUEST_DISABLED_MINUTES, plural, one {minute} other {minutes}} before trying to sign in again.',
    'accountManagement.components.multiFactorAuthentication.error.codeLimitExceeded.title':
      'You have entered too many invalid codes',
    'accountManagement.components.multiFactorAuthentication.error.codeLimitExceeded.text':
      'For security purposes, you will need to wait {EMAIL_VERIFICATION_REQUEST_DISABLED_MINUTES} {EMAIL_VERIFICATION_REQUEST_DISABLED_MINUTES, plural, one {minute} other {minutes}} before trying to sign in again.',
    'accountManagement.components.multiFactorAuthentication.error.invalidToken.title': 'Wrong Verification Code',
    'accountManagement.components.multiFactorAuthentication.error.invalidToken.text':
      'Please confirm the code sent to {contact}. If needed, you can choose to resend a verification code.',
    'accountManagement.components.multiFactorAuthentication.error.expiredToken.title': 'Expired Verification Code',
    'accountManagement.components.multiFactorAuthentication.error.expiredToken.text':
      'Verification codes are only valid for {EMAIL_VERIFICATION_REQUEST_DISABLED_MINUTES} {EMAIL_VERIFICATION_REQUEST_DISABLED_MINUTES, plural, one {minute} other {minutes}} and also expire when you request a new one. If needed, you can choose to resend a verification code',
    'accountManagement.components.multiFactorAuthentication.message.resending.text': 'Resending Code...',
    'accountManagement.components.multiFactorAuthentication.message.verifying.text': 'Verifying Code...',

    // Order Summary Messages

    'accountManagement.components.label.subscription.summary.title.text': 'Order Summary',
    'accountManagement.components.label.subscription.summary.subtitle.text': 'Summary',
    'accountManagement.components.label.subscription.summary.terms.text': 'Terms and Conditions',
    'accountManagement.components.label.subscription.summary.terms.message.momentum':
      'By clicking Submit, I acknowledge I have read and agree to the {terms} and {privacy} of Momentum.',
    'accountManagement.components.label.subscription.summary.terms.message.otis':
      'By clicking Submit, I acknowledge I have read and agree to the {terms} and {privacy} of Otis.',
    'accountManagement.components.label.subscription.summary.terms.message.pepper':
      'By clicking Submit, I acknowledge I have read and agree to the {terms} and {privacy} of Pepper.',

    'accountManagement.components.label.subscription.summary.terms.message.visi':
      'By clicking Submit, I acknowledge I have read and agree to the {terms} and {privacy} of Visi.',
    'accountManagement.components.label.subscription.summary.terms.message.geeni':
      'By clicking Submit, I acknowledge I have read and agree to the {terms} and {privacy} of Merkury Smart.',
    'accountManagement.components.label.brand.momentum.text': 'Momentum',
    'accountManagement.components.label.brand.otis.text': 'Otis',
    'accountManagement.components.label.subscription.summary.terms.join_term_link': 'Terms of Use',
    'accountManagement.components.label.subscription.summary.terms.join_privacy_link': 'Privacy Policies',
    'accountManagement.components.label.subscription.summary.paymentMethod.message': 'Payment Method',
    'accountManagement.components.button.subscription.summary.paymentMethod.change.text': 'Change',
    'accountManagement.components.label.subscription.summary.yourPlan.text': 'Your Plan',
    'accountManagement.components.label.subscription.summary.yourPlan.message':
      'Your account will be charged the total amount shown above each {period} less any applied Promo Code. Your plan will continue until you cancel.',
    'accountManagement.components.label.subscription.summary.yourPlan.freeTrial.message':
      'After your free trial, your account will be charged the total amount shown above each {period} less any applied Promo Code. Your plan will continue until you cancel. If you cancel during your free trial period, you will not be charged.',
    'accountManagement.components.label.subscription.summary.device.text': 'Device',
    'accountManagement.components.button.subscription.summary.yourPlan.change.text': 'Change',
    'accountManagement.components.label.subscription.summary.plan.text': 'Plan',
    'accountManagement.components.label.subscription.summary.price.text': 'Price',
    'accountManagement.components.label.subscription.summary.tax.text': 'Tax',
    'accountManagement.components.label.subscription.summary.total.text': 'Total:',
    'accountManagement.components.button.subscription.summary.back.text': 'Back',
    'accountManagement.components.button.subscription.summary.submit.text': 'Submit',
    'accountManagement.components.button.subscription.summary.genericError.text':
      'There has been an error saving your changes. Please try again',
    change_must_be_immediate: 'You must immediately change the plan in order to redeem a coupon.',
    'accountManagement.components.label.summary.perMonth': '/mo',
    'accountManagement.components.label.summary.perYear': '/yr',
    'accountManagement.components.label.summary.perTwoYear': ' every 2 years',
    'accountManagement.components.label.savingSubscription.title.text': 'Saving...',
    'accountManagement.components.label.savingSubscriptionStatement.text': 'Please wait while we update your account.',
    'accountManagement.components.label.payment.downgradeFreeTrial.text':
      'The plan change will take effect at the end of your free trial period. Your card will be charged the plan price shown above each {billingCycle} less any applied Promo code. Your plan will renew automatically each {billingCycle} unless you downgrade or cancel prior to the renewal date. Please review the devices associated with your new plan once the plan change is in effect.',
    'accountManagement.components.label.payment.downgradeNotFreeTrial.text':
      'The plan change will take effect at the end of your current billing cycle. Your card will be charged the plan price shown above each {billingCycle} less any applied Promo code. Your plan will renew automatically each {billingCycle} unless you downgrade or cancel prior to the renewal date. Please review the devices associated with your new plan once the plan change is in effect.',
    'accountManagement.components.label.payment.chargeAmountStatement.text':
      'Your card will be charged the total amount above each month/year less any applied Promo Code.',
    'accountManagement.components.label.payment.downgradeLiteChargeText.text':
      'Downgrading to the free plan will stop auto-renewing payments. Your existing service will continue until the end of your current billing cycle. Your account will no longer be charged.',
    'accountManagement.components.label.payment.freeTrialChargeText.text':
      'After your free trial period, your card will be charged the plan price shown above each {billingCycle} less any applied Promo Code. Your plan will renew automatically each {billingCycle} unless you downgrade or cancel prior to the renewal date.',
    'accountManagement.components.label.payment.generalRenewChargeText.text':
      'Your card will be charged the plan price shown above each {billingCycle} less any applied Promo Code. Your plan will renew automatically each {billingCycle} unless you downgrade or cancel prior to the renewal date.',
    'accountManagement.components.label.confirmExit.confirmTermsStatement.text':
      'Before submitting, you must select the checkbox accepting the Terms and Conditions found at the bottom of the page.',

    // Unified Checkout messages
    ...fromDefaultMessages(unifiedCheckoutMessages),

    // Promo Code Messages

    'accountManagement.components.label.PromoCode.text': 'Promo Code',
    'accountManagement.components.label.PromoCode.placeHolder': 'Enter Code',
    'accountManagement.components.label.PromoCode.invalid': 'Promo code {code} is not valid',
    'accountManagement.components.label.PromoCode.restriction': 'Promo code {code} is not valid for this device.',
    'accountManagement.components.label.PromoCode.plan.restriction':
      'Promo code {code} is not valid for the selected plan.',
    'accountManagement.components.button.PromoCode.applyButton': 'Apply',
    'accountManagement.components.label.PromoCode.appliedCode': 'Promo code {code} is applied',

    //generic error page
    'accountManagement.components.genericError.title.somethingWentWrong': 'Something went wrong',
    'accountManagement.components.genericError.sorry.message':
      'Sorry for the inconvenience. We are working on getting this fixed as soon as possible.',
    'accountManagement.components.genericError.goToMainPage.button': 'Go to My Plan',

    //Plan Subscription Success Page Messages

    'accountManagement.components.label.plan.subscription.success.statement': 'Thank you for subscribing.',
    'accountManagement.components.label.plan.subscription.success.plan': 'plan',
    'accountManagement.components.label.plan.subscription.success.recurring charge': 'recurring charge',
    'accountManagement.components.label.plan.subscription.success.nextCharge': 'next charge',
    'accountManagement.components.label.plan.subscription.success.chargedToday': 'amount charged today',
    'accountManagement.components.label.plan.subscription.success.appliedPromo': 'applied promotion',
    'accountManagement.components.label.subscription.noPromotion.text': 'None',
    'accountManagement.components.label.summary.plusTax': 'plus tax',

    //Confirm cancellation Messages

    'accountManagement.components.label.confirmCancel.modalTitle.text': 'Are you sure you want to cancel your plan?',
    'accountManagement.components.label.confirmCancel.confirmCancelStatement.text':
      "If you cancel now, you will lose the ability to look back at your camera's recorded {retention}-{retentionTimeFrame} video history along with the features listed below at the end of your current billing cycle.",
    'accountManagement.components.label.confirmCancel.features.text': 'FEATURES',
    'accountManagement.components.label.confirmCancel.backupFootage.text':
      'Remember to back up your saved camera footage before canceling your plan',
    'accountManagement.components.label.confirmCancel.backupFootageText.otis.text':
      'Upon plan expiration, your camera(s) will only save the past 2 hours of video history. All recorded videos and photos saved on the cloud beyond 2 hours will be permanently deleted.',
    'accountManagement.components.label.confirmCancel.backupFootageText.visi.text':
      'Upon plan expiration, your camera(s) will no longer have any services. All recorded videos and photos saved on the cloud will be permanently deleted.',
    'accountManagement.components.label.confirmCancel.backupFootageText.geeni.text':
      'Upon plan expiration, your camera(s) will no longer have any services. All recorded videos and photos saved on the cloud will be permanently deleted.',
    'accountManagement.components.label.confirmCancel.cancelButton.text': 'Keep current plan',
    'accountManagement.components.label.confirmCancel.exitButton.text': 'Cancel plan',
    'accountManagement.components.label.confirmCancel.warningAltText.text': 'Warning: Confirm Exit',
    'accountManagement.components.label.confirmCancel.cancelingPlanText.text': 'Canceling plan…',

    //Subscriptions List Messages// for every brand need to add one

    'accountManagement.components.label.SubscriptionsList.text.noPlanFound': 'No plan found.',
    'accountManagement.components.label.SubscriptionsList.text.noSubscriptions.momentum':
      'To purchase a Momentum Capture video history subscription, you will first need to log in to the Momentum app and pair a device.',
    'accountManagement.components.label.SubscriptionsList.text.noSubscriptions.otis':
      'To purchase a video history plan, you will first need to log in to the Otis app and pair a device.',
    'accountManagement.components.label.SubscriptionsList.text.noSubscriptions.pepper':
      'No plan found. To purchase a video history plan, you will first need to log in to the Pepper app and pair a device.',
    'accountManagement.components.label.SubscriptionsList.text.noSubscriptions.visi':
      'To purchase a video history plan, you will first need to log in to the Visi app and pair a device.',
    'accountManagement.components.label.SubscriptionsList.text.noSubscriptions.geeni':
      'To purchase a video history plan, you will first need to log in to the Merkury Smart app and pair a device.',
    'accountManagement.components.label.SubscriptionsList.text.noSubscriptions.nightowl':
      'To purchase a video history plan, you will first need to log in to the Night Owl app and pair a device.',

    'accountManagement.components.label.SubscriptionsList.text.expiredAsOf': 'Expired as of',
    'accountManagement.components.label.SubscriptionsList.text.pendingSubscription':
      'Pending change to {plan} Plan {billingCycle} - {effectiveDate}',
    'accountManagement.components.label.SubscriptionsList.text.trialTitle': 'Free 30 Day Trial',
    'accountManagement.components.label.SubscriptionsList.text.trialExpiration': 'Free Trial - Expires on {expireDate}',
    'accountManagement.components.label.SubscriptionsList.text.migrationChecking': 'Loading...',
    'accountManagement.components.label.SubscriptionsList.text.migrationCheckingStatement': 'Please wait',
    'accountManagement.components.label.SubscriptionsList.text.manageAppleSubscriptions':
      'Open your {brand} app on your iOS device to manage your Apple {pluralSubs}.',
    'accountManagement.components.label.SubscriptionsList.text.manageDevices': 'Manage Devices',
    'accountManagement.components.label.SubscriptionsList.text.changePlan': 'Change Plan',
    'accountManagement.components.label.SubscriptionsList.text.cancelPlan': 'Cancel Plan',

    //MigrationModal Messages

    'accountManagement.components.label.migrationModal.attemptingModalHeader.text': 'Great things are about to happen!',
    'accountManagement.components.label.migrationModal.migrationStatement.text':
      'You are eligible for a {freeText} service upgrade. Please wait a moment while we update your plan.',
    'accountManagement.components.label.migrationModal.freeText.text': 'free',
    'accountManagement.components.label.migrationModal.warningAltText.text': 'Migration Gift',
    'accountManagement.components.label.migrationModal.goAccount.text': 'Go to Account',
    'accountManagement.components.label.migrationModal.upgradeStatement.text':
      "We've upgraded your plan to the {plan} Plan at no additional cost. You now get {feature} for up to {maxCameras} cameras. Thank you for being a loyal Momentum customer.",
    'accountManagement.components.label.migrationModal.errorStatement.text':
      'Sorry, we are unable to update your subscription automatically. Contact us to receive a free service upgrade. You can continue using your Momentum app without interruption.',
    'accountManagement.components.label.migrationModal.signOutBtn.text': 'Sign Out',
    'accountManagement.components.label.migrationModal.contactUsButton.text': 'Contact Us',
    'accountManagement.components.label.migrationModal.sevenVideoHistory.text': '7 days of video history',
    'accountManagement.components.label.migrationModal.thirtyVideoHistory.text': '30 days of video history',
    'accountManagement.components.label.migrationModal.sixtyVideoHistory.text': '60 days of video history',
    'accountManagement.components.label.migrationModal.defaultVideoHistory.text': '7 days of video history',

    // BillingInformationView Messages

    'app.containers.BillingInformationView.billingTitle': 'Billing Information',
    'app.containers.BillingInformationView.creditCardTitle': 'Credit Card',
    'app.containers.BillingInformationView.firstNameLabel': 'First Name',
    'app.containers.BillingInformationView.firstNameValidationError': 'Please enter a First Name',
    'app.containers.BillingInformationView.lastNameLabel': 'Last Name',
    'app.containers.BillingInformationView.lastNameValidationError': 'Please enter a Last Name',
    'app.containers.BillingInformationView.streetAddressLabel': 'Street Address',
    'app.containers.BillingInformationView.streetValidationError': 'Please enter an Address',
    'app.containers.BillingInformationView.aptLabel': 'Apt / Suite',
    'app.containers.BillingInformationView.cityLabel': 'City / Town',
    'app.containers.BillingInformationView.cityValidationError': 'Please enter a City',
    'app.containers.BillingInformationView.stateLabel': 'State',
    'app.containers.BillingInformationView.stateInputLabel': 'State / Province / Region',
    'app.containers.BillingInformationView.stateSelectBox': 'Select',
    'app.containers.BillingInformationView.stateValidationError': 'Please select a State',
    'app.containers.BillingInformationView.countryLabel': 'Country',
    'app.containers.BillingInformationView.countryValidationError': 'Please select a Country',
    'app.containers.BillingInformationView.zipLabel': 'Zip Code',
    'app.containers.BillingInformationView.postalCodeLabel': 'Postal Code',
    'app.containers.BillingInformationView.zipValidationError': 'Please enter a Code',
    'app.containers.BillingInformationView.creditCardLabel': 'Card Number',
    'app.containers.BillingInformationView.numberValidationError': 'Enter Valid Card Number',
    'app.containers.BillingInformationView.expirationDateLabel': 'Expiration Date',
    'app.containers.BillingInformationView.monthValidationError': 'Enter a valid Month',
    'app.containers.BillingInformationView.monthPlaceHolder': 'MM',
    'app.containers.BillingInformationView.yearLabel': 'Date',
    'app.containers.BillingInformationView.yearPlaceHolder': 'YYYY',
    'app.containers.BillingInformationView.yearValidationError': 'Enter a valid Year',
    'app.containers.BillingInformationView.cvvLabel': 'Security Code',
    'app.containers.BillingInformationView.cvvPlaceHolder': 'CVV',
    'app.containers.BillingInformationView.cvvValidationError': 'Enter a valid CVV',
    'app.containers.BillingInformationView.applyButton': 'Apply',
    'app.containers.BillingInformationView.nextButton': 'Next',
    'app.containers.BillingInformationView.backButton': 'Back',
    'app.containers.BillingInformationView.perMonth': '/mo',
    'app.containers.BillingInformationView.perYear': '/yr',
    'app.containers.BillingInformationView.cvvToolTip': '3 on back, AMEX: 4 on front:',

    //PaymentDialog Messages
    'app.containers.PaymentDialog.title': 'Payment Details',
    'app.containers.PaymentDialog.summary': 'Summary',
    'app.containers.PaymentDialog.nextButton': 'Next',
    'app.containers.PaymentDialog.backButton': 'Back',
    'app.containers.PaymentDialog.cancelButton': 'Cancel',
    'app.containers.PaymentDialog.submitButton': 'Submit',
    'app.containers.PaymentDialog.perMonth': '/mo',
    'app.containers.PaymentDialog.perYear': '/yr',

    //errors
    'error.subscription.card.declined':
      'Unable to process your request. Please use a different card or contact your bank.',
    'error.subscription.card.address.declined':
      'Unable to process your request. Please review your billing and credit card information and try again.',
    'error.subscription.card.expired': 'Your credit card is expired. Please update your card.',
    'error.subscription.card.type': 'Your card type is not accepted. Please try another card.',
    'error.subscription.card.payment': 'Your payment type is not accepted. Please try another card.',
    'error.subscription.card.cvv.declined':
      'The security code you entered does not match. Please update the CVV and try again.',
    'error.subscription.card.number.declined': 'Your card number is not valid. Please update your card number.',
    'error.subscription.token.invalid': 'Payment session timed out. Please re-enter your credit card information.',
    generic_error: 'An unknown error occurred, please try again.',
    'error.subscription': 'There has been an error saving your changes. Please try again.',
    'error.subscription.change_must_be_immediate':
      'You must immediately change the subscription in order to redeem a coupon.',
    'error.subscription.preview.already_subscribed': 'You already have a subscription to this plan.',
    'subscription.coupon_code.not_redeemable':
      'Promo code has reached the maximum redemptions. Please try a different code.',

    //error status page
    'accountManagement.components.flowError.title.somethingWentWrong': 'Oops! Something went wrong.',
    'accountManagement.components.flowError.sorry.message':
      'Sorry, there was an error trying to cancel your plan. Please try again later.',
    'accountManagement.components.flowError.goToMainPage.button': 'Back',
    'accountManagement.components.flowError.contactSupport.button': 'Contact support',
    'accountManagement.components.flowError.sorryGenericMessage.message':
      'Sorry for the inconvenience. Please try again later.',
    'accountManagement.components.flowError.goToMyPlan.button': 'Go to My Plan',

    //delete account
    'accountManagement.components.deleteAccount.title.deleteAccount': 'Pending Delete Account Request',
    'accountManagement.components.deleteAccount.subtext.deleteAccount':
      'There is a pending delete account request on your account. You can’t sign in to your account unless the delete account request is canceled.',
    'accountManagement.components.deleteAccount.PrimaryBtn:.deleteAccount': 'Cancel Request',
    'accountManagement.components.deleteAccount.SecondaryBtn.deleteAccount': 'Keep Request',
    'accountManagement.components.deleteAccountError.title.deleteAccount':
      'Sorry, we are unable to cancel your delete account request at this time.',
    'accountManagement.components.deleteAccountError.subtext.deleteAccount':
      'Please contact customer support for assistance.',
    'accountManagement.components.deleteAccountError.PrimaryBtn:.deleteAccount': 'Email Support',
    'accountManagement.components.deleteAccountError.SecondaryBtn.deleteAccount': 'Close',

    // Verify Email Messages
    'verifyEmail.button.text.openApp': 'Open { brand } App',
    'verifyEmail.modal.confirmOpen.button.cancel': 'Cancel',
    'verifyEmail.modal.confirmOpen.button.open': 'Open',
    'verifyEmail.text.success.brand': 'Return to the mobile app to enjoy using your { brand } products',
    'verifyEmail.text.successTitle': 'Verification Success',
    'verifyEmail.text.failureAlreadyUsedTitle': 'The email verification link has already been used',
    'verifyEmail.text.failureAlreadyUsedSubtitle':
      "Each link can only be used once. You can log in to the { brand } app if you already verified your email address OR use the 'Forgot Password' option",
    'verifyEmail.text.failureInvalidTitle': 'The email verification link is invalid',
    'verifyEmail.text.failureYouCanSubtitle':
      "You can log in to the { brand } app if you have already verified your email address OR use the 'Forgot Password' option.",
    'verifyEmail.text.failureExpiredTitle': 'The email verification link has expired',

    //Reset Password Messages
    'resetPassword.page.title': 'Reset Password',
    'resetPassword.page.subtitle': 'Think of a strong password and enter it below.',
    'resetPassword.input.placeholder': 'New Password',
    'resetPassword.confirm.input.placeholder': 'Confirm Password',
    'resetPassword.input.error.minLength': 'Password must be at least 8 characters',
    'resetPassword.input.error.missingUppercase': 'Password must contain at least one uppercase letter',
    'resetPassword.input.error.missingLowercase': 'Password must contain at least one lowercase letter',
    'resetPassword.input.error.missingNumber': 'Password must contain at least one number',
    'resetPassword.input.error.missingSpecialChar': 'Password must contain at least one special character',
    'resetPassword.input.error.passwordsMatch': 'Entered passwords don’t match',
    'resetPassword.error.invalidToken.title': 'The password reset link is invalid',
    'resetPassword.error.invalidToken.subtitle':
      'The password reset link is malformed. Please go to the { brand } App and submit a new request if you would like to reset your password.',
    'resetPassword.error.linkAlreadyUsed.title': 'The password reset link has already been used',
    'resetPassword.error.linkAlreadyUsed.subtitle':
      'Each link can only be used once. Please go to the { brand } App and submit a new request if you would like to reset your password.',
    'resetPassword.error.passwordsTooSimilar':
      'Your new password is too similar to your old passwords. Please enter a different password.',
    'resetPassword.success.title': 'Congrats',
    'resetPassword.success.subtitle': 'Your password has been successfully reset.',
    'verifyEmail.modal.confirmOpen.text.title': 'Open in "{ brand }"',

    //TermsOfService and PrivacyPolicy
    'footer.termsOfUse.link.otis': '//content.pepperos.io/static/brand/otis/TermsOfService.pdf',
    'footer.privacyPolicy.link.otis': '//content.pepperos.io/static/brand/momentum/PrivacyPolicy.pdf',
    'footer.chat.name.notion': 'Chat With Us',
    'footer.chat.link.notion': '//static.zdassets.com/web_widget/latest/liveChat.html?v=10#key=notion.zendesk.com',
    'footer.privacyPolicy.link.notion': '//getnotion.com/pages/privacy',
    'footer.termsOfUse.link.notion': '//content.pepperos.io/static/brand/notion/TermsOfService.pdf',
    'footer.termsOfUse.link.momentum': '//content.pepperos.io/static/brand/momentum/TermsOfService.pdf',
    'footer.privacyPolicy.link.momentum': '//content.pepperos.io/static/brand/momentum/PrivacyPolicy.pdf',
    'footer.termsOfUse.link.pepper': 'TODO',
    'footer.privacyPolicy.link.pepper': 'TODO',
    'footer.termsOfUse.link.visi': '//content.pepperos.io/static/brand/visi/TermsOfService.pdf',
    'footer.privacyPolicy.link.visi': '//content.pepperos.io/static/brand/visi/PrivacyPolicy.pdf',
    'footer.copyright': 'Pepper',
    'footer.termsOfUse.link.geeni': '//content.pepperos.io/static/brand/geeni/TermsOfService.pdf',
    'footer.privacyPolicy.link.geeni': '//content.pepperos.io/static/brand/geeni/PrivacyPolicy.pdf',

    // WebView
    'webView.redirect.message': 'Please wait while we redirect you...',

    'webViewAction.showAlertSubtype.placeholder.title': 'Placeholder title',
    'webViewAction.showAlertSubtype.placeholder.message': 'Placeholder message',
    'webViewAction.showAlertSubtype.placeholder.buttonLabel': 'Placeholder label',

    //deleteAccountRequest
    'app.containers.DeleteAccountModalRequest.modalTitle.text': 'Are you sure you want to delete your account?',
    'app.containers.DeleteAccountModalRequest.delete.text':
      'When you submit a request to delete your account your {partnerName} products and services will no longer work. The following will occur once you submit your request:',
    'app.containers.DeleteAccountModalRequest.deleteAccountButton.text': 'Delete Account',
    'app.containers.DeleteAccountModalRequest.cancelDeleteAccountButton.text': 'Cancel',
    'app.containers.DeleteAccountModalRequest.deleteAccountBullet1.text':
      'You will be immediately signed out of the app and will not be able to sign back in. ',
    'app.containers.DeleteAccountModalRequest.deleteAccountBullet2.text':
      'Your rules will be turned OFF and you will no longer receive notifications. ',
    'app.containers.DeleteAccountModalRequest.deleteAccountBullet3.text':
      'An email will be sent to you confirming you want to delete your account. ',
    'app.containers.DeleteAccountModalRequest.deleteAccountBullet4.text':
      'If you don’t cancel your delete account request your account including information and data will be deleted from our system. ',
  },
};

export default en;
