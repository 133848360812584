import { PlanListActionTypes, IPlanList } from './types';
import {
  GET_PLANS_ATTEMPT,
  GET_PLANS_SUCCESS,
  GET_PLANS_FAILURE,
  SET_PLANS_SELECTED_PLAN,
  CLEAR_STATE,
  SET_PLANS_CURRENT_PLAN,
  SET_CURRENT_SELECTED_PLANS,
} from './actionTypes';

export const initialState: IPlanList = {
  plans: [],
  errorCode: '',
  attempting: false,
  selectedPlan: undefined,
  currentPlan: undefined,
};

export const PlansListReducer = (state = initialState, action: PlanListActionTypes): IPlanList => {
  switch (action.type) {
    case GET_PLANS_ATTEMPT:
      return {
        ...state,
        attempting: true,
      };
    case GET_PLANS_SUCCESS:
      return {
        ...state,
        errorCode: null,
        plans: action.plans,
        attempting: false,
      };
    case GET_PLANS_FAILURE:
      return {
        ...state,
        errorCode: action.errorCode,
        attempting: false,
      };
    case SET_PLANS_SELECTED_PLAN:
      return {
        ...state,
        selectedPlan: action.selectedPlan,
      };
    case SET_PLANS_CURRENT_PLAN:
      return {
        ...state,
        currentPlan: action.currentPlan,
      };
    case SET_CURRENT_SELECTED_PLANS:
      return {
        ...state,
        currentPlan: action.currentPlan,
        selectedPlan: action.selectedPlan,
      };
    case CLEAR_STATE:
      return initialState;
    default:
      return state;
  }
};
