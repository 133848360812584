export const UNIFIED_CHECKOUT_INITIALIZE_PROVIDER_ATTEMPT = 'UNIFIED_CHECKOUT_INITIALIZE_PROVIDER_ATTEMPT';
export const UNIFIED_CHECKOUT_INITIALIZE_PROVIDER_SUCCESS = 'UNIFIED_CHECKOUT_INITIALIZE_PROVIDER_SUCCESS';
export const UNIFIED_CHECKOUT_INITIALIZE_PROVIDER_FAILURE = 'UNIFIED_CHECKOUT_INITIALIZE_PROVIDER_FAILURE';
export const UNIFIED_CHECKOUT_PAYMENT_METHOD_FAILURE = 'UNIFIED_CHECKOUT_PAYMENT_METHOD_FAILURE';
export const UNIFIED_CHECKOUT_GET_PAYMENT_METHODS_ATTEMPT = 'UNIFIED_CHECKOUT_GET_PAYMENT_METHODS_ATTEMPT';
export const UNIFIED_CHECKOUT_GET_PAYMENT_METHODS_SUCCESS = 'UNIFIED_CHECKOUT_GET_PAYMENT_METHODS_SUCCESS';
export const UNIFIED_CHECKOUT_GET_PAYMENT_METHODS_FAILURE = 'UNIFIED_CHECKOUT_GET_PAYMENT_METHODS_FAILURE';
export const UNIFIED_CHECKOUT_LIST_ACCOUNT_SUBSCRIPTIONS_ATTEMPT =
  'UNIFIED_CHECKOUT_LIST_ACCOUNT_SUBSCRIPTIONS_ATTEMPT';
export const UNIFIED_CHECKOUT_LIST_ACCOUNT_SUBSCRIPTIONS_SUCCESS =
  'UNIFIED_CHECKOUT_LIST_ACCOUNT_SUBSCRIPTIONS_SUCCESS';
export const UNIFIED_CHECKOUT_LIST_ACCOUNT_SUBSCRIPTIONS_FAILURE =
  'UNIFIED_CHECKOUT_LIST_ACCOUNT_SUBSCRIPTIONS_FAILURE';
export const UNIFIED_CHECKOUT_SUBSCRIPTION_PREVIEW_ATTEMPT = 'UNIFIED_CHECKOUT_SUBSCRIPTION_PREVIEW_ATTEMPT';
export const UNIFIED_CHECKOUT_SUBSCRIPTION_PREVIEW_SUCCESS = 'UNIFIED_CHECKOUT_SUBSCRIPTION_PREVIEW_SUCCESS';
export const UNIFIED_CHECKOUT_SUBSCRIPTION_PREVIEW_FAILURE = 'UNIFIED_CHECKOUT_SUBSCRIPTION_PREVIEW_FAILURE';
export const UNIFIED_CHECKOUT_SUBSCRIPTION_CONFIRM_ATTEMPT = 'UNIFIED_CHECKOUT_SUBSCRIPTION_CONFIRM_ATTEMPT';
export const UNIFIED_CHECKOUT_SUBSCRIPTION_CONFIRM_SUCCESS = 'UNIFIED_CHECKOUT_SUBSCRIPTION_CONFIRM_SUCCESS';
export const UNIFIED_CHECKOUT_SUBSCRIPTION_CONFIRM_FAILURE = 'UNIFIED_CHECKOUT_SUBSCRIPTION_CONFIRM_FAILURE';
