import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useSelector } from 'react-redux';
import { getBrandImagesSelector } from '../BrandProvider/selectors';
import messages from './messages';
import { FormattedMessage, injectIntl } from 'react-intl';

interface Props {
  open: boolean;
  handleClose: Function;
  brand: string;
  intl: any;
}

const UnableToSaveModal = (props: Props) => {
  const { open, brand } = props;
  const images = useSelector(getBrandImagesSelector);
  const { formatMessage } = props.intl;

  const handleClose = () => {
    props.handleClose();
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <img
            src={images['icon-alert'] ? images['icon-alert'] : null}
            alt={formatMessage(messages.warningAltText)}
            className={`${brand}-confirm-modal-icon`}
          />
        </DialogTitle>
        <DialogTitle id="alert-dialog-title" style={{ fontWeight: 'bold' }}>
          <FormattedMessage {...messages.modalTitle} />
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <FormattedMessage {...messages.unableSaveStatement} />
          </DialogContentText>
        </DialogContent>
        <DialogActions id="alert-dialog-actions" style={{ justifyContent: 'center' }}>
          <Button
            onClick={handleClose}
            className={`${brand}-btn ${brand}-btn-action `}
            style={{
              width: 165,
              textTransform: 'none',
              height: 42,
              fontWeight: 'bolder',
            }}
            autoFocus
          >
            <FormattedMessage {...messages.okayButton} />
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default injectIntl(UnableToSaveModal);
