import { CheckoutSuccessTypes, ICheckoutSuccess } from './types';

import {
  INVOICE_DETAILS_ATTEMPT,
  INVOICE_DETAILS_CLEAR_STATE,
  INVOICE_DETAILS_FAILURE,
  INVOICE_DETAILS_SUCCESS,
} from './actionTypes';

export const initialState: ICheckoutSuccess = {
  invoiceDetailsAttempting: false,
  invoiceDetailsFailure: null,
  invoiceDetailsSuccess: null,
  invoiceDetailsPreview: null,
  invoiceDetailsResponse: null,
  invoiceDetailsError: null,
};

export const CheckoutSuccessReducer = (state = initialState, action: CheckoutSuccessTypes): ICheckoutSuccess => {
  switch (action.type) {
    case INVOICE_DETAILS_ATTEMPT:
      return {
        ...state,
        invoiceDetailsAttempting: true,
        invoiceDetailsFailure: null,
        invoiceDetailsSuccess: null,
      };
    case INVOICE_DETAILS_FAILURE:
      return {
        ...state,
        invoiceDetailsFailure: true,
        invoiceDetailsAttempting: false,
        invoiceDetailsError: action.error,
      };
    case INVOICE_DETAILS_SUCCESS:
      return {
        ...state,
        invoiceDetailsSuccess: true,
        invoiceDetailsFailure: false,
        invoiceDetailsAttempting: false,
        invoiceDetailsResponse: action.invoiceDetailsResponse,
      };
    case INVOICE_DETAILS_CLEAR_STATE:
      return initialState;
    default:
      return state;
  }
};
