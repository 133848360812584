/*
 * PaymentDialog Messages
 *
 * This contains all the text for the PaymentDialog component.
 */
import { defineMessages } from 'react-intl';

export default defineMessages<string>({
  title: {
    id: 'app.containers.PaymentDialog.title',
    defaultMessage: 'Payment Details',
  },
  summary: {
    id: 'app.containers.PaymentDialog.summary',
    defaultMessage: 'Summary',
  },
  nextButton: {
    id: 'app.containers.PaymentDialog.nextButton',
    defaultMessage: 'Next',
  },
  backButton: {
    id: 'app.containers.PaymentDialog.backButton',
    defaultMessage: 'Back',
  },
  cancelButton: {
    id: 'app.containers.PaymentDialog.cancelButton',
    defaultMessage: 'Cancel',
  },
  submitButton: {
    id: 'app.containers.PaymentDialog.submitButton',
    defaultMessage: 'Submit',
  },
  billingCycle1: {
    id: 'app.containers.PaymentDialog.perMonth',
    defaultMessage: '/mo',
  },
  billingCycle12: {
    id: 'app.containers.PaymentDialog.perYear',
    defaultMessage: '/yr',
  },
});
