import { defineMessages } from 'react-intl';

export default defineMessages<string>({
  monthlyTab: {
    id: 'app.containers.PlansList.tab.monthly.text',
    defaultMessage: 'monthly',
  },
  yearlyTab: {
    id: 'app.containers.PlansList.tab.yearly.text',
    defaultMessage: 'yearly',
  },
  yearlyTabBestValue: {
    id: 'app.containers.PlansList.tab.yearlyTabBestValue.text',
    defaultMessage: 'yearly (best value)',
  },
  twoYearTab: {
    id: 'app.containers.PlansList.tab.twoYearTab.text',
    defaultMessage: '2 Year',
  },
  ribbon: {
    id: 'app.containers.PlansList.ribbon.text',
    defaultMessage: 'Most Popular',
  },
  perYear: {
    id: 'app.containers.PlansList.label.perYear.text',
    defaultMessage: 'per year',
  },
  perMonth: {
    id: 'app.containers.PlansList.label.perMonth.text',
    defaultMessage: 'per month',
  },
  selectedPlan: {
    id: 'accountManagement.components.button.subscription.plan.selected.text',
    defaultMessage: 'Selected Plan',
  },
  currentPlan: {
    id: 'accountManagement.components.button.subscription.plan.current.text',
    defaultMessage: 'Current Plan',
  },
  selectPlan: {
    id: 'accountManagement.components.button.subscription.plan.select.text',
    defaultMessage: 'Select Plan',
  },
  brand_momentum: {
    id: 'accountManagement.components.PlansList.label.brand.momentum.text',
    defaultMessage: 'Momentum',
  },
  backBtn: {
    id: 'accountManagement.components.PlansList.button.backBtn.text',
    defaultMessage: 'Back',
  },
  changePlan: {
    id: 'accountManagement.components.PlansList.button.changePlan.text',
    defaultMessage: 'Change Plan',
  },
  freePlan: {
    id: 'accountManagement.components.PlansList.button.freePlan.text',
    defaultMessage: 'FREE',
  },
  planText: {
    id: 'accountManagement.components.PlansList.planText.text',
    defaultMessage: 'Plan',
  },
  freeTrialOffer: {
    id: 'accountManagement.components.popover.freeTrialOffer.text',
    defaultMessage:
      'Offer valid for new subscribers only. Limit one 30-day trial per account. Your account will be charged at the end of your trial period unless you downgrade or cancel.',
  },
  btnTextWithPrice: {
    id: 'accountManagement.components.PlansList.btnTextWithPrice.text',
    defaultMessage: '{price} (up to {numOfDevices} devices)',
  },
  redirecting: {
    id: 'accountManagement.components.PlansList.redirectingModal.text',
    defaultMessage: 'Loading payment dashboard…',
  },
  noSubscriptions_geeni: {
    id: 'accountManagement.components.label.SubscriptionsList.text.noSubscriptions.geeni',
    defaultMessage:
      'To purchase a video history plan, you will first need to log in to the Merkury Smart app and pair a device.',
  },
});
