import { CheckoutSuccessTypes } from './types';

import {
  INVOICE_DETAILS_ATTEMPT,
  INVOICE_DETAILS_FAILURE,
  INVOICE_DETAILS_SUCCESS,
  INVOICE_DETAILS_CLEAR_STATE,
} from './actionTypes';

export const invoiceDetailsAttempt = (): CheckoutSuccessTypes => ({
  type: INVOICE_DETAILS_ATTEMPT,
});

export const invoiceDetailsFailure = (error: any): CheckoutSuccessTypes => ({
  type: INVOICE_DETAILS_FAILURE,
  error: error,
});

export const invoiceDetailsSuccess = (invoiceDetailsResponse: any): CheckoutSuccessTypes => ({
  type: INVOICE_DETAILS_SUCCESS,
  invoiceDetailsResponse,
});

export const invoiceDetailsClearState = (): CheckoutSuccessTypes => ({
  type: INVOICE_DETAILS_CLEAR_STATE,
});
