import { defineMessages } from 'react-intl';

const unifiedCheckoutMessages = defineMessages({
  checkoutSummary: {
    id: 'accountManagement.unifiedCheckout.checkoutSummary',
    defaultMessage: 'Checkout summary',
  },
  plan: {
    id: 'accountManagement.unifiedCheckout.plan',
    defaultMessage: '{plan} Plan',
  },
  price: {
    id: 'accountManagement.unifiedCheckout.price',
    defaultMessage: '{price} per {period}',
  },
  period: {
    id: 'accountManagement.unifiedCheckout.period',
    defaultMessage: '{period}',
  },
  trialInfo: {
    id: 'accountManagement.unifiedCheckout.trialInfo',
    defaultMessage: 'First {trialDuration} {trialUnit} free, then billed every {billingDuration} {billingUnit}',
  },
  trialEnd: {
    id: 'accountManagement.unifiedCheckout.trialEnd',
    defaultMessage: 'Starting {trialEndDate, date, short}',
  },
  day: {
    id: 'accountManagement.unifiedCheckout.day',
    defaultMessage: '{count, plural, one {day} other {days}}',
  },
  month: {
    id: 'accountManagement.unifiedCheckout.month',
    defaultMessage: '{count, plural, one {month} other {months}}',
  },
  year: {
    id: 'accountManagement.unifiedCheckout.year',
    defaultMessage: '{count, plural, one {year} other {years}}',
  },
  change: {
    id: 'accountManagement.unifiedCheckout.change',
    defaultMessage: 'Change',
  },
  paymentMethod: {
    id: 'accountManagement.unifiedCheckout.paymentMethod',
    defaultMessage: 'Payment method',
  },
  paymentDetails: {
    id: 'accountManagement.unifiedCheckout.paymentDetails',
    defaultMessage: 'Payment details',
  },
  cancel: {
    id: 'accountManagement.unifiedCheckout.cancel',
    defaultMessage: 'Cancel',
  },
  pay: {
    id: 'accountManagement.unifiedCheckout.pay',
    defaultMessage: 'Pay',
  },
  subscribeWithApplePay: {
    id: 'accountManagement.unifiedCheckout.subscribeWithApplePay',
    defaultMessage: 'Subscribe with Apple Pay',
  },
  applePay: {
    id: 'accountManagement.unifiedCheckout.applePay',
    defaultMessage: 'Apple Pay',
  },
  googlePay: {
    id: 'accountManagement.unifiedCheckout.googlePay',
    defaultMessage: 'Google Pay',
  },
  // Mobile pay error messages
  'apple-pay-not-supported': {
    id: 'accountManagement.unifiedCheckout.applePayNotSupported',
    defaultMessage: 'Apple Pay is not supported by this device or browser.',
  },
  'apple-pay-not-available': {
    id: 'accountManagement.unifiedCheckout.applePayNotAvailable',
    defaultMessage: 'Apple Pay is supported by this device, but the customer has not configured Apple Pay.',
  },
  'apple-pay-config-missing': {
    id: 'accountManagement.unifiedCheckout.applePayConfigMissing',
    defaultMessage: 'Missing Apple Pay configuration option',
  },
  'apple-pay-config-invalid': {
    id: 'accountManagement.unifiedCheckout.applePayConfigInvalid',
    defaultMessage: 'Apple Pay configuration option invalid',
  },
  'apple-pay-factory-only': {
    id: 'accountManagement.unifiedCheckout.applePayFactoryOnly',
    defaultMessage: 'Apple Pay must be initialized by calling recurly.ApplePay',
  },
  'google-pay-config-missing': {
    id: 'accountManagement.unifiedCheckout.google',
    defaultMessage: 'Missing Internal Configuration',
  },
  'google-pay-not-configured': {
    id: 'accountManagement.unifiedCheckout.google',
    defaultMessage: 'There is no gateway to support the Payment with Google',
  },
  'google-pay-init-error': {
    id: 'accountManagement.unifiedCheckout.google',
    defaultMessage: 'Failed to load the Google Pay library',
  },
  'google-pay-not-available': {
    id: 'accountManagement.unifiedCheckout.google',
    defaultMessage: 'Your device or browser is not compatible with Google Pay',
  },
  'google-pay-payment-failure': {
    id: 'accountManagement.unifiedCheckout.google',
    defaultMessage: 'Failed to get your Google payment details',
  },
  'api-error': {
    id: 'accountManagement.unifiedCheckout.apiError',
    defaultMessage: 'There was an error communicating with Recurly',
  },
  creditCard: {
    id: 'accountManagement.unifiedCheckout.creditCard',
    defaultMessage: 'Credit Card',
  },
  paymentDetailsPlan: {
    id: 'accountManagement.unifiedCheckout.paymentDetails.plan',
    defaultMessage: 'Plan',
  },
  paymentDetailsPrice: {
    id: 'accountManagement.unifiedCheckout.paymentDetails.price',
    defaultMessage: 'Price',
  },
  tax: {
    id: 'accountManagement.unifiedCheckout.paymentDetails.tax',
    defaultMessage: 'Tax',
  },
  total: {
    id: 'accountManagement.unifiedCheckout.paymentDetails.total',
    defaultMessage: 'Total:',
  },
  selectedPaymentMethod: {
    id: 'accountManagement.unifiedCheckout.selectedPaymentMethod',
    defaultMessage: 'Selected payment method:',
  },
});

export default unifiedCheckoutMessages;
