import React, { useEffect } from 'react';
import Footer from '../../../components/Footer';
import Header from '../../../components/Header';
import TabContainer from '../../../components/Tab';
import { Helmet } from 'react-helmet-async';

import { useSelector } from 'react-redux';
import { getBrandNameSelector } from '../../BrandProvider/selectors';
import { useMediaQuery } from 'react-responsive';
import { getUsedSsoSelector } from '../../SsoRedirect/selectors';
import history from '../../../history';
import { getIsPendingDeleteSelector } from '../../LoginPage/selectors';
import { getCancelAccountSuccessSelector } from '../../DeleteAccountModel/selectors';

interface Props {
  children: any;
}

const MainLayout = (props: Props) => {
  const brand = useSelector(getBrandNameSelector);
  const isMobile = useMediaQuery({ query: `(max-width: 600px)` });
  const usedSso = useSelector(getUsedSsoSelector);
  const isPendingDelete = useSelector(getIsPendingDeleteSelector);
  const cancelDeleteSuccess = useSelector(getCancelAccountSuccessSelector);

  useEffect(() => {
    if (isPendingDelete && isPendingDelete === 'pending_delete' && !cancelDeleteSuccess) {
      history.push('/pending-delete');
    }
  }, [isPendingDelete, cancelDeleteSuccess]);

  return (
    <div className={`body-bg ${brand}-body ${brand}-font`}>
      <div className="main-layout">
        <Helmet titleTemplate="%s - Account Management" defaultTitle="Account Management">
          <meta name="description" content="Account Management" />
        </Helmet>
        <div
          style={{
            display: usedSso && isMobile ? 'none' : 'block',
          }}>
          <Header></Header>
        </div>
        <div style={{ marginTop: isMobile && !usedSso ? 54 : 0 }}>
          <TabContainer></TabContainer>
        </div>
        <main role="main" className="w-100 h-100">
          {React.Children.toArray(props.children)}
        </main>
      </div>
      <Footer></Footer>
    </div>
  );
};

export default MainLayout;
