import { BillingInfoActionTypes } from './types';

import {
  STORE_BILLING_INFO,
  STORE_BILLING_INFO_IN_STORE,
  LOADING_BILLING_INFO,
  BILLING_INFO_LOADED,
  VERIFY_BILLING_INFO_ATTEMPT,
  LOADING_BILLING_INFO_VIEW,
  BILLING_INFO_VIEW_LOADED,
  COUNTRY_LOADED,
  LOADING_COUNTRY,
  SAVING_BILLING_INFO_ATTEMPT,
  SAVING_BILLING_INFO_FAILURE,
  SAVING_BILLING_INFO_SUCCESS,
  CHANGE_CURRENCY,
  SET_THREE_D_TOKEN,
} from './actionTypes';

export const storeBillingInfo = (state: any, callback: any): BillingInfoActionTypes => ({
  type: STORE_BILLING_INFO,
  actionResultTokenId: state.actionResultTokenId,
  selectedBillingInfo: {
    first_name: state.first_name,
    last_name: state.last_name,
    address1: state.address1,
    address2: state.address2,
    city: state.city,
    state: state.state,
    postal_code: state.postal_code,
    country: state.country,
    token: state.token,
    cardNumberLast4: state.cardNumberLast4,
  },
  callback: callback,
  promoCode: state.promoCode,
});

export const verifyBillingInfo = (state: any, callback: any): BillingInfoActionTypes => ({
  type: VERIFY_BILLING_INFO_ATTEMPT,
  actionResultTokenId: state.actionResultTokenId,
  billingInformation: {
    first_name: state.first_name,
    last_name: state.last_name,
    address1: state.address1,
    address2: state.address2,
    city: state.city,
    state: state.state,
    postal_code: state.postal_code,
    country: state.country,
    token: state.token,
    cardNumberLast4: state.cardNumberLast4,
  },
  callback: callback,
});

export const saveBillingInfoInStore = (state: any): BillingInfoActionTypes => ({
  type: STORE_BILLING_INFO_IN_STORE,
  selectedBillingInfo: {
    first_name: state.first_name,
    last_name: state.last_name,
    address1: state.address1,
    address2: state.address2,
    city: state.city,
    state: state.state,
    postal_code: state.postal_code,
    country: state.country,
    token: state.token,
    cardNumberLast4: state.cardNumberLast4,
  },
  promoCode: state.promoCode,
});

export const loadingBillingInfo = (): BillingInfoActionTypes => ({
  type: LOADING_BILLING_INFO,
});

export const setThreeDToken = (actionResultTokenId: any): BillingInfoActionTypes => ({
  type: SET_THREE_D_TOKEN,
  actionResultTokenId: actionResultTokenId,
});

export const billingInfoLoaded = (billingInfo: any, selectedBillingInfo: any): BillingInfoActionTypes => ({
  type: BILLING_INFO_LOADED,
  billingInfo: billingInfo,
  selectedBillingInfo: selectedBillingInfo,
});

export const loadingBillingInfoView = (): BillingInfoActionTypes => ({
  type: LOADING_BILLING_INFO_VIEW,
});

export const billingInfoViewLoaded = (): BillingInfoActionTypes => ({
  type: BILLING_INFO_VIEW_LOADED,
});

export const countryLoaded = (currencyCountry: any, currency: any): BillingInfoActionTypes => ({
  type: COUNTRY_LOADED,
  currencyCountry,
  currency,
});

export const loadingCountry = (): BillingInfoActionTypes => ({
  type: LOADING_COUNTRY,
});

export const savingBillingInfoAttempt = (): BillingInfoActionTypes => ({
  type: SAVING_BILLING_INFO_ATTEMPT,
});

export const savingBillingInfoFailure = (error: any): BillingInfoActionTypes => ({
  type: SAVING_BILLING_INFO_FAILURE,
  error,
});

export const savingBillingInfoSuccess = (): BillingInfoActionTypes => ({
  type: SAVING_BILLING_INFO_SUCCESS,
});

export const changeCurrency = (currencyCountry: any, currency: any): BillingInfoActionTypes => ({
  type: CHANGE_CURRENCY,
  currencyCountry: currencyCountry,
  currency: currency,
});
