import { createSelector } from 'reselect';

import { AppState } from '../../store/rootReducer';

const getAccountHistory = (state: AppState) => state.accountHistory.accountHistoryList;
const getAccountHistoryAttempt = (state: AppState) => state.accountHistory.attempting;
const getAccountHistoryError = (state: AppState) => state.accountHistory.errorCode;

export const getAccountHistorySelector = createSelector(getAccountHistory, accountHistoryList => accountHistoryList);

export const getAccountHistoryAttemptSelector = createSelector(getAccountHistoryAttempt, attempting => attempting);

export const getAccountHistoryErrorSelector = createSelector(getAccountHistoryError, error => error);
