const planFeatures: any = {
  //
  // MOMENTUM
  //
  lsm: [
    // list of features for key
    {
      id: 'accountManagement.components.dataLoss.plan.description.momentum.lsm-1',
      defaultMessage: '- View Live Stream All Day and Night',
    },
    {
      id: 'accountManagement.components.dataLoss.plan.description.momentum.lsm-2',
      defaultMessage: '- Set Motion Triggered Notifications',
    },
  ],

  lite: [
    {
      id: 'accountManagement.components.dataLoss.plan.description.momentum.lite-1',
      defaultMessage: '{cloudIcon} {boldDay} Video History',
    },
  ],

  '7+month': [
    {
      id: 'accountManagement.components.dataLoss.plan.description.momentum.7+month-2',
      defaultMessage: '{cancelIcon} 7 Day Video History',
    },
    {
      id: 'accountManagement.components.dataLoss.plan.description.momentum.7+month-3',
      defaultMessage: '{cancelIcon} Up to 2 Cameras',
    },
  ],

  '30+month': [
    {
      id: 'accountManagement.components.dataLoss.plan.description.momentum.30+month-2',
      defaultMessage: '{cancelIcon} 30 Day Video History',
    },
    {
      id: 'accountManagement.components.dataLoss.plan.description.momentum.30+month-3',
      defaultMessage: '{cancelIcon} Up to 5 Cameras',
    },
  ],

  '60+month': [
    {
      id: 'accountManagement.components.dataLoss.plan.description.momentum.60+month-2',
      defaultMessage: '{cancelIcon} 60 Day Video History',
    },
    {
      id: 'accountManagement.components.dataLoss.plan.description.momentum.60+month-3',
      defaultMessage: '{cancelIcon} Up to 10 Cameras',
    },
  ],

  '7+year': [
    {
      id: 'accountManagement.components.dataLoss.plan.description.momentum.7+year-2',
      defaultMessage: '{cancelIcon} 7 Day Video History',
    },
    {
      id: 'accountManagement.components.dataLoss.plan.description.momentum.7+year-3',
      defaultMessage: '{cancelIcon} Up to 2 Cameras',
    },
  ],

  '30+year': [
    {
      id: 'accountManagement.components.dataLoss.plan.description.momentum.30+year-2',
      defaultMessage: '{cancelIcon} 30 Day Video History',
    },
    {
      id: 'accountManagement.components.dataLoss.plan.description.momentum.30+year-3',
      defaultMessage: '{cancelIcon} Up to 5 Cameras',
    },
  ],

  '60+year': [
    {
      id: 'accountManagement.components.dataLoss.plan.description.momentum.60+year-1',
      defaultMessage: '{cancelIcon} 60 Day Video History',
    },
    {
      id: 'accountManagement.components.dataLoss.plan.description.momentum.60+year-2',
      defaultMessage: '{cancelIcon} Up to 10 Cameras',
    },
  ],

  '30dean': [
    {
      id: 'accountManagement.components.dataLoss.plan.description.momentum.30dean-1',
      defaultMessage: '{cancelIcon} Save 30 Days of Video History',
    },
    {
      id: 'accountManagement.components.dataLoss.plan.description.momentum.30dean-2',
      defaultMessage: '{cancelIcon} Download and Share Recorded Videos',
    },
    {
      id: 'accountManagement.components.dataLoss.plan.description.momentum.30dean-3',
      defaultMessage: '{cancelIcon} View Live Stream All Day and Night',
    },
    {
      id: 'accountManagement.components.dataLoss.plan.description.momentum.30dean-4',
      defaultMessage: '{cancelIcon} Set Motion Triggered Notifications',
    },
  ],

  '7dem': [
    {
      id: 'accountManagement.components.dataLoss.plan.description.momentum.7dem-1',
      defaultMessage: '{cancelIcon} Save 7 Days of Video History',
    },
    {
      id: 'accountManagement.components.dataLoss.plan.description.momentum.7dem-2',
      defaultMessage: '{cancelIcon} Download and Share Recorded Videos',
    },
    {
      id: 'accountManagement.components.dataLoss.plan.description.momentum.7dem-3',
      defaultMessage: '{cancelIcon} View Live Stream All Day and Night',
    },
    {
      id: 'accountManagement.components.dataLoss.plan.description.momentum.7dem-4',
      defaultMessage: '{cancelIcon} Set Motion Triggered Notifications',
    },
  ],

  '30dem': [
    {
      id: 'accountManagement.components.dataLoss.plan.description.momentum.30dem-1',
      defaultMessage: '{cancelIcon} Save 30 Days of Video History',
    },
    {
      id: 'accountManagement.components.dataLoss.plan.description.momentum.30dem-2',
      defaultMessage: '{cancelIcon} Download and Share Recorded Videos',
    },
    {
      id: 'accountManagement.components.dataLoss.plan.description.momentum.30dem-3',
      defaultMessage: '{cancelIcon} View Live Stream All Day and Night',
    },
    {
      id: 'accountManagement.components.dataLoss.plan.description.momentum.30dem-4',
      defaultMessage: '{cancelIcon} Set Motion Triggered Notifications',
    },
  ],

  '7dean': [
    {
      id: 'accountManagement.components.dataLoss.plan.description.momentum.7dean-1',
      defaultMessage: '{cancelIcon} Save 7 Days of Video History',
    },
    {
      id: 'accountManagement.components.dataLoss.plan.description.momentum.7dean-2',
      defaultMessage: '{cancelIcon} Download and Share Recorded Videos',
    },
    {
      id: 'accountManagement.components.dataLoss.plan.description.momentum.7dean-3',
      defaultMessage: '{cancelIcon} View Live Stream All Day and Night',
    },
    {
      id: 'accountManagement.components.dataLoss.plan.description.momentum.7dean-4',
      defaultMessage: '{cancelIcon} Set Motion Triggered Notifications',
    },
  ],

  //otis
  es_1month_1cam: [
    {
      id: 'accountManagement.components.dataLoss.plan.description.otis.es_1month_4cam.7_day_history',
      defaultMessage: '{cancelIcon} 7-day video history',
    },
    {
      id: 'accountManagement.components.dataLoss.plan.description.otis.es_1month_4cam.1_camera',
      defaultMessage: '{cancelIcon} 1 camera',
    },
  ],
  pr_1month_allcam: [
    {
      id: 'accountManagement.components.dataLoss.plan.description.otis.pr_1month_4cam.30_day_history',
      defaultMessage: '{cancelIcon} 30-day video history',
    },
    {
      id: 'accountManagement.components.dataLoss.plan.description.otis.pr_1month_4cam.up_to',
      defaultMessage: '{cancelIcon} Up to 5 cameras',
    },
  ],

  es_1year_1cam: [
    {
      id: 'accountManagement.components.dataLoss.plan.description.otis.es_1year_4cam_2',
      defaultMessage: '{cancelIcon} 7-day video history',
    },
    {
      id: 'accountManagement.components.dataLoss.plan.description.otis.es_1year_4cam_3',
      defaultMessage: '{cancelIcon} 1 camera',
    },
  ],

  pr_1year_allcam: [
    {
      id: 'accountManagement.components.dataLoss.plan.description.otis.pr_1year_4cam_1',
      defaultMessage: '{cancelIcon} 30-day free trial',
    },
    {
      id: 'accountManagement.components.dataLoss.plan.description.otis.pr_1year_4cam_2',
      defaultMessage: '{cancelIcon} 30-day video history',
    },
    {
      id: 'accountManagement.components.dataLoss.plan.description.otis.pr_1year_4cam_3',
      defaultMessage: '{cancelIcon} Up to 5 cameras',
    },
  ],

  //visi

  ba_1month_4cam: [
    {
      id: 'accountManagement.components.dataLoss.plan.description.visi.ba_1month_4cam_1',
      defaultMessage: '{cancelIcon} Watch & download activity',
    },
    {
      id: 'accountManagement.components.dataLoss.plan.description.visi.ba_1month_4cam_2',
      defaultMessage: '{cancelIcon} 24-hour video history',
    },
  ],

  ba_1month_8cam: [
    {
      id: 'accountManagement.components.dataLoss.plan.description.visi.ba_1month_4cam_1',
      defaultMessage: '{cancelIcon} Watch & download activity',
    },
    {
      id: 'accountManagement.components.dataLoss.plan.description.visi.ba_1month_4cam_2',
      defaultMessage: '{cancelIcon} 24-hour video history',
    },
  ],
  pr_1month_4cam: [
    {
      id: 'accountManagement.components.dataLoss.plan.description.visi.pr_1month_4cam_2',
      defaultMessage: '{cancelIcon} 30-day video history',
    },
    {
      id: 'accountManagement.components.dataLoss.plan.description.visi.pr_1month_4cam_3',
      defaultMessage: '{cancelIcon} Smart Detection',
    },
    {
      id: 'accountManagement.components.dataLoss.plan.description.visi.pr_1month_4cam_4',
      defaultMessage: '{cancelIcon} Send Help Smart Security',
    },
  ],
  pr_1month_8cam: [
    {
      id: 'accountManagement.components.dataLoss.plan.description.visi.pr_1month_4cam_2',
      defaultMessage: '{cancelIcon} 30-day video history',
    },
    {
      id: 'accountManagement.components.dataLoss.plan.description.visi.pr_1month_4cam_3',
      defaultMessage: '{cancelIcon} Smart Detection',
    },
    {
      id: 'accountManagement.components.dataLoss.plan.description.visi.pr_1month_4cam_4',
      defaultMessage: '{cancelIcon} Send Help Smart Security',
    },
  ],
  ba_1year_4cam: [
    {
      id: 'accountManagement.components.dataLoss.plan.description.visi.ba_1year_4cam_1',
      defaultMessage: '{cancelIcon} Watch & download activity',
    },
    {
      id: 'accountManagement.components.dataLoss.plan.description.visi.ba_1year_4cam_2',
      defaultMessage: '{cancelIcon} 24-hour video history',
    },
  ],
  ba_1year_8cam: [
    {
      id: 'accountManagement.components.dataLoss.plan.description.visi.ba_1year_4cam_1',
      defaultMessage: '{cancelIcon} Watch & download activity',
    },
    {
      id: 'accountManagement.components.dataLoss.plan.description.visi.ba_1year_4cam_2',
      defaultMessage: '{cancelIcon} 24-hour video history',
    },
  ],
  pr_1year_4cam: [
    {
      id: 'accountManagement.components.dataLoss.plan.description.visi.pr_1year_4cam_2',
      defaultMessage: '{cancelIcon} 30-day video history',
    },
    {
      id: 'accountManagement.components.dataLoss.plan.description.visi.pr_1year_4cam_3',
      defaultMessage: '{cancelIcon} Smart Detection',
    },
    {
      id: 'accountManagement.components.dataLoss.plan.description.visi.pr_1year_4cam_4',
      defaultMessage: '{cancelIcon} Send Help Smart Security',
    },
  ],
  pr_1year_8cam: [
    {
      id: 'accountManagement.components.dataLoss.plan.description.visi.pr_1year_4cam_2',
      defaultMessage: '{cancelIcon} 30-day video history',
    },
    {
      id: 'accountManagement.components.dataLoss.plan.description.visi.pr_1year_4cam_3',
      defaultMessage: '{cancelIcon} Smart Detection',
    },
    {
      id: 'accountManagement.components.dataLoss.plan.description.visi.pr_1year_4cam_4',
      defaultMessage: '{cancelIcon} Send Help Smart Security',
    },
  ],
  ba_2year_4cam: [
    {
      id: 'accountManagement.components.dataLoss.plan.description.visi.ba_2year_4cam_1',
      defaultMessage: '{cancelIcon} Watch & download activity',
    },
    {
      id: 'accountManagement.components.dataLoss.plan.description.visi.ba_2year_4cam_2',
      defaultMessage: '{cancelIcon} 24-hour video history',
    },
  ],
  ba_2year_8cam: [
    {
      id: 'accountManagement.components.dataLoss.plan.description.visi.ba_2year_4cam_1',
      defaultMessage: '{cancelIcon} Watch & download activity',
    },
    {
      id: 'accountManagement.components.dataLoss.plan.description.visi.ba_2year_4cam_2',
      defaultMessage: '{cancelIcon} 24-hour video history',
    },
  ],
  pr_2year_4cam: [
    {
      id: 'accountManagement.components.dataLoss.plan.description.visi.pr_2year_4cam_2',
      defaultMessage: '{cancelIcon} 30-day video history',
    },
    {
      id: 'accountManagement.components.dataLoss.plan.description.visi.pr_2year_4cam_3',
      defaultMessage: '{cancelIcon} Smart Detection',
    },
    {
      id: 'accountManagement.components.dataLoss.plan.description.visi.pr_2year_4cam_4',
      defaultMessage: '{cancelIcon} Send Help Smart Security',
    },
  ],
  pr_2year_8cam: [
    {
      id: 'accountManagement.components.dataLoss.plan.description.visi.pr_2year_4cam_2',
      defaultMessage: '{cancelIcon} 30-day video history',
    },
    {
      id: 'accountManagement.components.dataLoss.plan.description.visi.pr_2year_4cam_3',
      defaultMessage: '{cancelIcon} Smart Detection',
    },
    {
      id: 'accountManagement.components.dataLoss.plan.description.visi.pr_2year_4cam_4',
      defaultMessage: '{cancelIcon} Send Help Smart Security',
    },
  ],

  /**
   * Geeni plans
   */
  pr_1month_basic: [
    {
      id: 'accountManagement.components.subscription.plan.description.geeni.pr_1month_basic_3',
      defaultMessage: '{cancelIcon} 1 camera',
    },
    {
      id: 'accountManagement.components.subscription.plan.description.geeni.pr_1month_basic_4',
      defaultMessage: '{cancelIcon} Snooze notifications',
    },
    {
      id: 'accountManagement.components.subscription.plan.description.geeni.pr_1month_basic_5',
      defaultMessage: '{cancelIcon} Smart Detection',
    },
  ],
  pr_1year_basic: [
    {
      id: 'accountManagement.components.subscription.plan.description.geeni.pr_1year_basic_3',
      defaultMessage: '{cancelIcon} 1 camera',
    },
    {
      id: 'accountManagement.components.subscription.plan.description.geeni.pr_1year_basic_4',
      defaultMessage: '{cancelIcon} Snooze notifications',
    },
    {
      id: 'accountManagement.components.subscription.plan.description.geeni.pr_1year_basic_5',
      defaultMessage: '{cancelIcon} Smart Detection',
    },
  ],
  pr_1month_plus: [
    {
      id: 'accountManagement.components.subscription.plan.description.geeni.pr_1month_plus_3',
      defaultMessage: '{cancelIcon} Up to 2 Cameras',
    },
    {
      id: 'accountManagement.components.subscription.plan.description.geeni.pr_1month_plus_4',
      defaultMessage: '{cancelIcon} Snooze notifications',
    },
    {
      id: 'accountManagement.components.subscription.plan.description.geeni.pr_1month_plus_5',
      defaultMessage: '{cancelIcon} Smart Detection',
    },
  ],
  pr_1year_plus: [
    {
      id: 'accountManagement.components.subscription.plan.description.geeni.pr_1year_plus_3',
      defaultMessage: '{cancelIcon} Up to 2 Cameras',
    },
    {
      id: 'accountManagement.components.subscription.plan.description.geeni.pr_1year_plus_4',
      defaultMessage: '{cancelIcon} Snooze notifications',
    },
    {
      id: 'accountManagement.components.subscription.plan.description.geeni.pr_1year_plus_5',
      defaultMessage: '{cancelIcon} Smart Detection',
    },
  ],
  pr_1month_premium: [
    {
      id: 'accountManagement.components.subscription.plan.description.geeni.pr_1month_premium_3',
      defaultMessage: '{cancelIcon} Up to 10 Cameras',
    },
    {
      id: 'accountManagement.components.subscription.plan.description.geeni.pr_1month_premium_4',
      defaultMessage: '{cancelIcon} Snooze notifications',
    },
    {
      id: 'accountManagement.components.subscription.plan.description.geeni.pr_1month_premium_5',
      defaultMessage: '{cancelIcon} Smart Detection',
    },
  ],
  pr_1year_premium: [
    {
      id: 'accountManagement.components.subscription.plan.description.geeni.pr_1year_premium_3',
      defaultMessage: '{cancelIcon} Up to 10 Cameras',
    },
    {
      id: 'accountManagement.components.subscription.plan.description.geeni.pr_1year_premium_4',
      defaultMessage: '{cancelIcon} Snooze notifications',
    },
    {
      id: 'accountManagement.components.subscription.plan.description.geeni.pr_1year_premium_5',
      defaultMessage: '{cancelIcon} Smart Detection',
    },
  ],

  //pepper
  pepper_es_1mo: [
    {
      id: 'accountManagement.components.dataLoss.plan.description.pepper.pepper_es_1mo_1',
      defaultMessage: '{cancelIcon} 7-day video history',
    },
    {
      id: 'accountManagement.components.dataLoss.plan.description.pepper.pepper_es_1mo_2',
      defaultMessage: '{cancelIcon} Snooze notifications',
    },
    {
      id: 'accountManagement.components.dataLoss.plan.description.pepper.pepper_es_1mo_3',
      defaultMessage: '{cancelIcon} Smart Detection',
    },
    {
      id: 'accountManagement.components.dataLoss.plan.description.pepper.pepper_es_1mo_4',
      defaultMessage: '{cancelIcon} Send Help Smart Security',
    },
  ],
  pepper_pr_1mo: [
    {
      id: 'accountManagement.components.dataLoss.plan.description.pepper.pepper_pr_1mo_1',
      defaultMessage: '{cancelIcon} 30-day video history',
    },
    {
      id: 'accountManagement.components.dataLoss.plan.description.pepper.pepper_pr_1mo_2',
      defaultMessage: '{cancelIcon} Snooze notifications',
    },
    {
      id: 'accountManagement.components.dataLoss.plan.description.pepper.pepper_pr_1mo_3',
      defaultMessage: '{cancelIcon} Smart Detection',
    },
    {
      id: 'accountManagement.components.dataLoss.plan.description.pepper.pepper_pr_1mo_4',
      defaultMessage: '{cancelIcon} Send Help Smart Security',
    },
  ],

  pepper_es_1year: [
    {
      id: 'accountManagement.components.dataLoss.plan.description.pepper.pepper_es_1year_1',
      defaultMessage: '{cancelIcon} 7-day video history',
    },
    {
      id: 'accountManagement.components.dataLoss.plan.description.pepper.pepper_es_1year_2',
      defaultMessage: '{cancelIcon} Snooze notifications',
    },
    {
      id: 'accountManagement.components.dataLoss.plan.description.pepper.pepper_es_1year_3',
      defaultMessage: '{cancelIcon} Smart Detection',
    },
    {
      id: 'accountManagement.components.dataLoss.plan.description.pepper.pepper_es_1year_4',
      defaultMessage: '{cancelIcon} Send Help Smart Security',
    },
  ],
  pepper_pr_1year: [
    {
      id: 'accountManagement.components.dataLoss.plan.description.pepper.pepper_pr_1year_1',
      defaultMessage: '{cancelIcon} 30-day video history',
    },
    {
      id: 'accountManagement.components.dataLoss.plan.description.pepper.pepper_pr_1year_2',
      defaultMessage: '{cancelIcon} Snooze notifications',
    },
    {
      id: 'accountManagement.components.dataLoss.plan.description.pepper.pepper_pr_1year_3',
      defaultMessage: '{cancelIcon} Smart Detection',
    },
    {
      id: 'accountManagement.components.dataLoss.plan.description.pepper.pepper_pr_1year_4',
      defaultMessage: '{cancelIcon} Send Help Smart Security',
    },
  ],
};

export default planFeatures;
