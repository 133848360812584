import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useMediaQuery } from 'react-responsive';
import { Typography } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import messages from './messages';
import CurrencyFormatter from '../../components/Formatters/CurrencyFormatter';

const useStyles = makeStyles({
  table: {
    minWidth: 450,
  },
  mobileTable: {
    minWidth: 250,
  },
});

interface Props {
  selectedPlan: any;
  currency: string;
  planTax: any;
  total: any;
}

const YourPlanTableControl = (props: Props) => {
  const classes = useStyles();
  const isMobile = useMediaQuery({ query: `(max-width: 600px)` });
  const { selectedPlan, currency, planTax, total } = props;

  return (
    <div className={isMobile ? classes.mobileTable : classes.table}>
      {selectedPlan ? (
        <div style={{ marginTop: 6 }}>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography>
              <FormattedMessage {...messages.plan} />:
            </Typography>
            <Typography align="right">{selectedPlan.name}</Typography>
          </div>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography>
              <FormattedMessage {...messages.price} />:
            </Typography>
            <Typography align="right">
              <CurrencyFormatter value={selectedPlan.amountInCents[currency]._} />
            </Typography>
          </div>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography>
              <FormattedMessage {...messages.tax} />:
            </Typography>
            <Typography align="right">
              <CurrencyFormatter value={planTax} />
            </Typography>
          </div>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography>
              <FormattedMessage {...messages.total} />
            </Typography>
            <Typography align="right">
              <CurrencyFormatter value={total} />
            </Typography>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default YourPlanTableControl;
