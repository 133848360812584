import { defineMessages } from 'react-intl';

export default defineMessages({
  somethingWentWrong: {
    id: 'accountManagement.components.flowError.title.somethingWentWrong',
    defaultMessage: 'Oops! Something went wrong.',
  },
  sorryMessage: {
    id: 'accountManagement.components.flowError.sorry.message',
    defaultMessage: 'Sorry, there was an error trying to cancel your plan. Please try again later.',
  },
  sorryGenericMessage: {
    id: 'accountManagement.components.flowError.sorryGenericMessage.message',
    defaultMessage: 'Sorry for the inconvenience. Please try again later.',
  },
  goToMainPage: {
    id: 'accountManagement.components.flowError.goToMainPage.button',
    defaultMessage: 'Back',
  },
  goToMyPlan: {
    id: 'accountManagement.components.flowError.goToMyPlan.button',
    defaultMessage: 'Go to My Plan',
  },
  contactSupport: {
    id: 'accountManagement.components.flowError.contactSupport.button',
    defaultMessage: 'Contact support',
  },
  alreadySubscribed: {
    id: 'error.subscription.preview.already_subscribed',
    defaultMessage: 'You already have a subscription to this plan.',
  },
});
