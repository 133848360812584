function getRecurlyValue(field: any) {
  if (field && field.$ && field.$.nil === 'nil') {
    return '';
  }
  return field;
}

function isDate(date: any) {
  if (date && date === 'Invalid Date') {
    return false;
  }
  return Object.prototype.toString.call(date) === '[object Date]';
}

function isBetweenDate(fromDate: any, toDate: any, checkDate: any) {
  if (!fromDate || !isDate(fromDate)) {
    return false;
  }
  if (!toDate || !isDate(fromDate)) {
    return false;
  }
  if (!checkDate || !isDate(fromDate)) {
    return false;
  }

  if (checkDate.getTime() > fromDate.getTime() && checkDate.getTime() < toDate.getTime()) {
    return true;
  }
  return false;
}

/*
 * description - parse a given url to extract the email query param. Needed to avoid
 * encoding which isn't possible with the natural dependency (query-string node module).
 * Original use case is verifyEmail and resetPassword need to get email from the url and send
 * the un-encoded email to the back end
 *
 * @param {string} str
 *
 * @return {string} email
 */
function parseLocationForEmail(str: any) {
  if (!str) {
    return '';
  }
  const locationParts: any = str.split('email=');

  // in case the email query param doesn't exist
  if (!(locationParts.length > 1)) {
    return '';
  }

  // in case there are additional query params after &email=
  const email = locationParts[1] && locationParts[1].includes('&') ? locationParts[1].split('&')[0] : locationParts[1];

  return email;
}

/**
 * Simple email validator regex
 * @param email - string of email address, ex: 'testuser@pepper.me'
 * @return boolean - true or false, depending on if email is valid
 */
function isValidEmail (email: string): boolean {
  return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)
}

export { getRecurlyValue, isBetweenDate, isDate, isValidEmail, parseLocationForEmail };
