import { Plan } from '../PlanListPage/types';
import { PaymentError, PaymentErrorType, UnifiedCheckoutSubscriptionPreview } from './types';
import { useCallback, useEffect, useMemo, useState } from 'react';
import messages from './messages';

// global recurly var that is in the script
declare var recurly: any;

declare interface RecurlyApplePay {
  initError?: unknown;
  ready: (callback: () => void) => void;
  begin: () => void;
  on: (event: string, ...args: any[]) => void;
  off: (event: string, ...args: any[]) => void;
}

const createApplePaymentRequest = (selectedPlan: Plan, subPreview: UnifiedCheckoutSubscriptionPreview) => {
  const trialBilling = subPreview.trial
    ? {
        label: selectedPlan.name,
        amount: '0.00',
        paymentTiming: 'recurring',
        recurringPaymentEndDate: subPreview.trial.trialEndDate,
      }
    : undefined;

  const amount = subPreview.trial
    ? (subPreview.trial.costAfterTrial * 0.01).toFixed(2)
    : (subPreview.total * 0.01).toFixed(2);
  return {
    countryCode: 'US',
    currencyCode: subPreview.currency,
    total: { label: selectedPlan.name, amount: subPreview.trial ? '0.00' : amount },
    recurringPaymentRequest: {
      paymentDescription: selectedPlan.name,
      regularBilling: {
        type: 'final',
        label: selectedPlan.name,
        amount,
        paymentTiming: 'recurring',
        recurringPaymentStartDate: subPreview.trial?.trialEndDate ?? subPreview.chargeDate,
        recurringPaymentIntervalUnit: 'month',
        recurringPaymentIntervalCount: selectedPlan.billing_cycle,
      },
      trialBilling,
    },
  };
};

const applePayErrorMap: Record<string, { id: string; defaultMessage: string }> = {
  'apple-pay-not-supported': messages['apple-pay-not-supported'],
  'apple-pay-not-available': messages['apple-pay-not-available'],
  'apple-pay-config-missing': messages['apple-pay-config-missing'],
  'apple-pay-config-invalid': messages['apple-pay-config-invalid'],
  'apple-pay-factory-only': messages['apple-pay-factory-only'],
  'api-error': messages['api-error'],
};

export const useApplePayCheckout = (
  selectedPlan: Plan | undefined,
  subPreview: UnifiedCheckoutSubscriptionPreview | undefined,
  onPaymentComplete: (id: string) => void,
) => {
  const applePaymentRequest = useMemo(() => {
    if (!selectedPlan || !subPreview) {
      console.warn(
        `Apple pay doesn't have needed info to initialize. If preview is undefined, this is expected.`,
        selectedPlan,
        subPreview,
      );
      return undefined;
    }
    return createApplePaymentRequest(selectedPlan, subPreview);
  }, [selectedPlan, subPreview]);

  const [isApplePayReady, setIsApplePayReady] = useState(false);
  const [applePayError, setApplePayError] = useState<PaymentError | undefined>(undefined);

  /** The instance of the apple pay controlled from recurly. */
  const recurlyApplePay = useMemo(() => {
    // if we can remove this and put it in a single file
    if (!applePaymentRequest) {
      console.warn('Waiting for Apple payment request to initialize.');
      return undefined;
    }

    const applePayInstance: RecurlyApplePay = recurly.ApplePay({
      paymentRequest: applePaymentRequest,
      callbacks: {},
    });
    return applePayInstance;
  }, [applePaymentRequest]);

  useEffect(() => {
    if (!recurlyApplePay) return;

    if (recurlyApplePay?.initError) {
      setIsApplePayReady(false);
      console.error('Apple pay init error: ', recurlyApplePay.initError);
      setApplePayError({
        type: PaymentErrorType.CHECKOUT_FAILURE,
        code: 'apple-pay-not-available',
        message: applePayErrorMap['apple-pay-not-available'],
      });
      return;
    }

    const inst = recurlyApplePay;
    const handler = (token: { id: string; type: string }) => {
      onPaymentComplete(token.id);
    };
    const errorHandler = (err: any) => {
      // FIXME: Get some error codes from Apple Pay (not documented by Recurly)
      const errMessageToDisplay = applePayErrorMap[err.code];
      console.error('apple pay error:', err, err?.code, errMessageToDisplay, err?.fields?.join(', '));
      setApplePayError({
        type: PaymentErrorType.PAYMENT_FAILURE,
        code: err?.code,
        message: errMessageToDisplay,
      });
    };
    inst.on('token', handler);

    inst.on('error', errorHandler);
    return () => {
      inst.off('token', handler);
      inst.off('error', errorHandler);
    };
  }, [onPaymentComplete, recurlyApplePay]);

  useEffect(() => {
    setIsApplePayReady(false);
    if (!recurlyApplePay) return;
    recurlyApplePay.ready(() => {
      // @ts-ignore
      console.info('Apple Pay Available: ', window?.ApplePaySession);
      setIsApplePayReady(true);
      setApplePayError(undefined);
    });
  }, [recurlyApplePay]);

  const onApplePayCheckout = useCallback(() => {
    if (!recurlyApplePay || !isApplePayReady) return;
    recurlyApplePay.begin();
  }, [recurlyApplePay, isApplePayReady]);

  return { onApplePayCheckout, isApplePayReady, applePayError };
};
