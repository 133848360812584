import { defineMessages } from 'react-intl';

export default defineMessages({
  cardTypeAltText: {
    id: 'accountManagement.components.paymentInformation.cardType.text',
    defaultMessage: 'Card Type',
  },
  cardExpired: {
    id: 'accountManagement.components.paymentInformation.cardExpired.text',
    defaultMessage: 'Card Expired',
  },
  changeText: {
    id: 'accountManagement.components.paymentInformation.change.text',
    defaultMessage: 'Change',
  },
  networkError: {
    id: 'accountManagement.components.paymentInformation.info.networkError.text',
    defaultMessage: 'Sorry, we are unable to retrieve your information at this time. Please try again later.',
  },
  noCreditCard: {
    id: 'accountManagement.components.paymentInformation.info.noCreditCard.text',
    defaultMessage: 'No credit card on file.',
  },
  upcomingPayment: {
    id: 'accountManagement.components.paymentInformation.label.upcomingPayment.text',
    defaultMessage: 'Upcoming Payment',
  },
  applePayment: {
    id: 'accountManagement.components.paymentInformation.label.applePayment.text',
    defaultMessage: 'Billed Through iTunes',
  },
  applePaymentMessage: {
    id: 'accountManagement.components.paymentInformation.label.applePayment.message',
    defaultMessage: 'Go to iTunes to update your payment details.',
  },
});
