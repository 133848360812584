/**
 * The properties needed to display an alert.
 *
 * "Tx" values indicate a string id from en-US.ts, etc.
 */
export interface AlertDescriptor {
  severity: 'info' | 'success' | 'warning' | 'error';
  titleTx: string;
  messageTx: string;
  buttonLabelTx?: string;
}

/**
 * Contains an AlertDescriptor for each possible subtype of SHOW_ALERT.
 */
export const alertDescriptors: Record<string, AlertDescriptor> = {
  placeholder: {
    severity: 'warning',
    titleTx: 'webViewAction.showAlertSubtype.placeholder.title',
    messageTx: 'webViewAction.showAlertSubtype.placeholder.message',
    buttonLabelTx: 'webViewAction.showAlertSubtype.placeholder.buttonLabel',
  },
};
