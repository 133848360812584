import React from 'react';
import ReactDOM from 'react-dom';
import App from './containers/App';
import reportWebVitals from './reportWebVitals';
import BrandProvider from './containers/BrandProvider';

import './utils/log';
//styles
import './themes/momentum/fonts/fonts.css';
import './themes/geeni/fonts/fonts.css';
import './index.css';
import './themes/common/main.scss';
import './themes/common/model.scss';
import './themes/geeni/main.scss';
import './themes/momentum/main.scss';
import './themes/notion/main.scss';
import './themes/otis/main.scss';
import './themes/pepper/main.scss';
import './themes/visi/main.scss';
import './themes/nightowl/main.scss';

//router
import { Router } from 'react-router-dom';
import history from './history';

//Context
import { AppContextProvider } from './components/Context';
import LanguageProvider from './components/i18n/Provider';

//redux
import { Provider } from 'react-redux';
import store from './store';
import { persistStore } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';

//pepper sdk
import PepperSdk from 'pepper-js-sdk';
import { IEnvironment } from 'pepper-js-sdk/types';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';

//react Helmet
import { HelmetProvider } from 'react-helmet-async';

// Import session timeout library
import IdleTimer from 'react-idle-timer';

const persistor = persistStore(store);

PepperSdk.config.update({
  environment: process.env.REACT_APP_BUILD_ENV as IEnvironment,
});

const THEME = createMuiTheme({
  typography: {
    fontFamily: `"Proxima Nova", "Helvetica Neue","Roboto", "Helvetica", "Arial", sans-serif`,
    h2: {
      fontSize: '28px',
      lineHeight: '1.4rem',
    },
    h3: {
      fontSize: '24px',
      lineHeight: '1.2rem',
      fontWeight: 600,
    },
  },
  overrides: {
    MuiButton: {
      text: {
        textTransform: 'lowercase',
      },
    },
  },
});

// Session timeout function used by the IdleTimer component
const sessionIdle = () => {
  store.dispatch({ type: 'CLEAR_STATE' });
  store.dispatch({ type: 'CLEAR_ERROR' });
};

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={THEME}>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <BrandProvider>
            <AppContextProvider>
              <LanguageProvider>
                <Router history={history}>
                  <HelmetProvider>
                    <IdleTimer onIdle={sessionIdle} timeout={1000 * 60 * 12}>
                      <App />
                    </IdleTimer>
                  </HelmetProvider>
                </Router>
              </LanguageProvider>
            </AppContextProvider>
          </BrandProvider>
        </PersistGate>
      </Provider>
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
