import { createSelector } from 'reselect';

import { AppState } from '../../store/rootReducer';
import { getFlowSelector } from '../BrandProvider/selectors';
import { isFreePlan } from '../../constants/freePlans';
import { getCurrentSubscription } from '../SubscriptionsList/selectors';

const getLoadingPlans = (state: AppState) => state.planList.attempting;
const getPlansList = (state: AppState) => state.planList.plans;
const getSelectedPlan = (state: AppState) => state.planList.selectedPlan;

export const getLoadingPlansSelector = createSelector(getLoadingPlans, attempting => attempting);

export const getPlansListSelector = createSelector(getPlansList, plans => plans);

export const getCurrentPlanSelector = createSelector(getPlansList, getCurrentSubscription, (plans, subscription) => {
  if (!plans) return undefined;

  if (subscription) {
    const subscriptionPlan = plans.find((plan: any) => plan.id === subscription.plan_config_id);
    if (subscriptionPlan) return subscriptionPlan;
  }

  return plans.find((plan: any) => plan.default);
});

export const getSelectedPlanSelector = createSelector(getSelectedPlan, selectedPlan => selectedPlan);

/**
 *  Selects whether the current scenario will create a new subscription using the delegated checkout flow
 */
export const isNewCheckoutSessionSelector = createSelector(
  getCurrentPlanSelector,
  getFlowSelector,
  (currentPlan, flow) => {
    // currentPlan may be in the structure of the plan list page model or the plan selection model
    // currentPlan is set when submitting the plan selection, so the logic dependent on the value won't get the proper
    // value unless we correctly interpret this value from the beginning (confirmChangeSub PlanActionControl)
    const currentPlanId = currentPlan?.id ?? currentPlan?.external_plan_code;
    return flow === 'delegatedCheckout' && currentPlanId && isFreePlan(currentPlanId);
  },
);
