import React from 'react';
import { Typography } from '@material-ui/core';
//redux
import { getLongBrandNameSelector, getBrandNameSelector, getConfigSelector } from '../../containers/BrandProvider/selectors';
import { useSelector } from 'react-redux';
import Link from '../Links';


const Footer = (props: any) => {
  const brand = useSelector(getBrandNameSelector);
  const brandName = useSelector(getLongBrandNameSelector);

  const config = useSelector(getConfigSelector);

  const Capitalize = (str: string) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  const FooterLinks = () => {
    const links = config?.footerLinks
      ? config.footerLinks
      : [
          {
            nameI18n: 'termsOfUse',
            linkI18n: `footer.termsOfUse.link.${brand}`,
          },
          {
            nameI18n: 'privacyPolicy',
            linkI18n: `footer.privacyPolicy.link.${brand}`,
          },
        ];

    return links.map((link: { [key: string]: any }, index: number) => {
      return (
        <span key={link.nameI18n}>
          <Link key={link.nameI18n} nameI18n={link.nameI18n} linkI18n={link.linkI18n} brand={brand} />{' '}
          {index + 1 < links.length && ` | `}
        </span>
      );
    });
  };

  const CustomFooterText = () => (config?.footerText ? <span>{config.footerText} | </span> : <></>);

  const Copyright = () => {
    return (
      <Typography variant="body2" align="left">
        &copy;
        {new Date().getFullYear()}
        {' ' + Capitalize(brandName)} | <CustomFooterText /> <FooterLinks />
      </Typography>
    );
  };

  return (
    <footer className={`${brand}-footer`}>
      <div className={`${brand}-footer-text`}>
        <Copyright />
      </div>
    </footer>
  );
};

export default Footer;
