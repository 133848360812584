/*
 * Manage Devices Messages
 *
 * This contains all the text for the manage devices component.
 */
import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'accountManagement.components.label.manageDevices.title.text',
    defaultMessage: 'Manage Devices',
  },
  devicePlanQuestion: {
    id: 'accountManagement.components.label.manageDevices.devicePlanQuestion.text',
    defaultMessage: 'Which devices would you like to use with your plan?',
  },
  assignDeviceToPlan: {
    id: 'accountManagement.components.label.manageDevices.assignDeviceToPlan.text',
    defaultMessage: 'You can assign your {planName} to any of these devices.',
  },
  selectStatement: {
    id: 'accountManagement.components.label.manageDevices.selectStatement.text',
    defaultMessage: 'Select up to {maxDevicesForPlan}.',
  },
  backBtn: {
    id: 'accountManagement.components.button.manageDevices.backBtn.text',
    defaultMessage: 'Back',
  },
  nextBtn: {
    id: 'accountManagement.components.button.manageDevices.nextBtn.text',
    defaultMessage: 'Next',
  },
  saveChangesBtn: {
    id: 'accountManagement.components.button.manageDevices.saveChangesBtn.text',
    defaultMessage: 'Save Changes',
  },
  networkError: {
    id: 'accountManagement.components.label.manageDevices.networkError.text',
    defaultMessage: 'Sorry, we are unable to retrieve your information at this time. Please try again later.',
  },
  savingDevices: {
    id: 'accountManagement.components.label.manageDevices.savingDevices.text',
    defaultMessage: 'Saving...',
  },
  savingDevicesStatement: {
    id: 'accountManagement.components.label.manageDevices.savingDevices.statement.text',
    defaultMessage: 'Please wait while we update your account.',
  },

  momentumDefaultPlan: {
    id: 'accountManagement.components.label.manageDevices.momentumDefaultPlan.text',
    defaultMessage: 'Lite Plan',
  },
  otisDefaultPlan: {
    id: 'accountManagement.components.label.manageDevices.otisDefaultPlan.text',
    defaultMessage: 'No Plan',
  },
});
