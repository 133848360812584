/*
 * BillingInformationView Messages
 *
 * This contains all the text for the BillingInformationView component.
 */
import { defineMessages } from 'react-intl';

export default defineMessages({
  billingTitle: {
    id: 'app.containers.BillingInformationView.billingTitle',
    defaultMessage: 'Billing Information',
  },
  creditCardTitle: {
    id: 'app.containers.BillingInformationView.creditCardTitle',
    defaultMessage: 'Credit Card',
  },
  firstNameLabel: {
    id: 'app.containers.BillingInformationView.firstNameLabel',
    defaultMessage: 'First Name',
  },
  firstNameValidationError: {
    id: 'app.containers.BillingInformationView.firstNameValidationError',
    defaultMessage: 'Please enter a First Name',
  },
  lastNameLabel: {
    id: 'app.containers.BillingInformationView.lastNameLabel',
    defaultMessage: 'Last Name',
  },
  lastNameValidationError: {
    id: 'app.containers.BillingInformationView.lastNameValidationError',
    defaultMessage: 'Please enter a Last Name',
  },
  streetAddressLabel: {
    id: 'app.containers.BillingInformationView.streetAddressLabel',
    defaultMessage: 'Street Address',
  },
  streetValidationError: {
    id: 'app.containers.BillingInformationView.streetValidationError',
    defaultMessage: 'Please enter an Address',
  },
  aptLabel: {
    id: 'app.containers.BillingInformationView.aptLabel',
    defaultMessage: 'Apt / Suite',
  },
  cityLabel: {
    id: 'app.containers.BillingInformationView.cityLabel',
    defaultMessage: 'City / Town',
  },
  cityValidationError: {
    id: 'app.containers.BillingInformationView.cityValidationError',
    defaultMessage: 'Please enter a City',
  },
  stateLabel: {
    id: 'app.containers.BillingInformationView.stateLabel',
    defaultMessage: 'State',
  },
  stateInputLabel: {
    id: 'app.containers.BillingInformationView.stateInputLabel',
    defaultMessage: 'State / Province / Region',
  },
  stateSelectBox: {
    id: 'app.containers.BillingInformationView.stateSelectBox',
    defaultMessage: 'Select',
  },
  stateValidationError: {
    id: 'app.containers.BillingInformationView.stateValidationError',
    defaultMessage: 'Please select a State',
  },
  countryLabel: {
    id: 'app.containers.BillingInformationView.countryLabel',
    defaultMessage: 'Country',
  },
  countryValidationError: {
    id: 'app.containers.BillingInformationView.countryValidationError',
    defaultMessage: 'Please select a Country',
  },
  zipLabel: {
    id: 'app.containers.BillingInformationView.zipLabel',
    defaultMessage: 'Zip Code',
  },
  postalCodeLabel: {
    id: 'app.containers.BillingInformationView.postalCodeLabel',
    defaultMessage: 'Postal Code',
  },
  zipValidationError: {
    id: 'app.containers.BillingInformationView.zipValidationError',
    defaultMessage: 'Please enter a Code',
  },
  creditCardLabel: {
    id: 'app.containers.BillingInformationView.creditCardLabel',
    defaultMessage: 'Card Number',
  },
  numberValidationError: {
    id: 'app.containers.BillingInformationView.numberValidationError',
    defaultMessage: 'Enter Valid Card Number',
  },
  expirationDateLabel: {
    id: 'app.containers.BillingInformationView.expirationDateLabel',
    defaultMessage: 'Expiration Date',
  },
  monthValidationError: {
    id: 'app.containers.BillingInformationView.monthValidationError',
    defaultMessage: 'Enter a valid Month',
  },
  monthPlaceHolder: {
    id: 'app.containers.BillingInformationView.monthPlaceHolder',
    defaultMessage: 'MM',
  },
  yearLabel: {
    id: 'app.containers.BillingInformationView.yearLabel',
    defaultMessage: 'Date',
  },
  yearPlaceHolder: {
    id: 'app.containers.BillingInformationView.yearPlaceHolder',
    defaultMessage: 'YYYY',
  },
  yearValidationError: {
    id: 'app.containers.BillingInformationView.yearValidationError',
    defaultMessage: 'Enter a valid Year',
  },
  cvvLabel: {
    id: 'app.containers.BillingInformationView.cvvLabel',
    defaultMessage: 'Security Code',
  },
  cvvPlaceHolder: {
    id: 'app.containers.BillingInformationView.cvvPlaceHolder',
    defaultMessage: 'CVV',
  },
  cvvValidationError: {
    id: 'app.containers.BillingInformationView.cvvValidationError',
    defaultMessage: 'Enter a valid CVV',
  },
  applyButton: {
    id: 'app.containers.BillingInformationView.applyButton',
    defaultMessage: 'Apply',
  },
  nextButton: {
    id: 'app.containers.BillingInformationView.nextButton',
    defaultMessage: 'Next',
  },
  backButton: {
    id: 'app.containers.BillingInformationView.backButton',
    defaultMessage: 'Back',
  },
  billingCycle1: {
    id: 'app.containers.BillingInformationView.perMonth',
    defaultMessage: '/mo',
  },
  billingCycle12: {
    id: 'app.containers.BillingInformationView.perYear',
    defaultMessage: '/yr',
  },
  cvvToolTip: {
    id: 'app.containers.BillingInformationView.cvvToolTip',
    defaultMessage: '3 on back, AMEX: 4 on front:',
  },
});
