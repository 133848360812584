import { createSelector } from 'reselect';

import { AppState } from '../../store/rootReducer';
import {getCurrentSubscription} from "../SubscriptionsList/selectors";

const getDevicesAdded = (state: AppState) => state.manageDevices.devicesAdded;
const getDevicesRemoved = (state: AppState) => state.manageDevices.devicesRemoved;

const getAttemptingGetDevicesForPlan = (state: AppState) => state.manageDevices.attempting;

const getDevicesToManage = (state: AppState) => state.manageDevices.devices;
const getSaveSuccess = (state: AppState) => state.manageDevices.saveSuccess;
const getSaveError = (state: AppState) => state.manageDevices.saveError;
const getSaving = (state: AppState) => state.manageDevices.saving;

const getPlanListSelected = (state: AppState) => state.planList.selectedPlan;
const getPrevPath = (state: AppState) => state.pathReducer.prevPathName;

export const getDevicesAddedSelector = createSelector(getDevicesAdded, devicesAdded => devicesAdded);

export const getDevicesRemovedSelector = createSelector(getDevicesRemoved, devicesRemoved => devicesRemoved);

export const getAttemptingGetDevicesForPlanSelector = createSelector(
  getAttemptingGetDevicesForPlan,
  attempting => attempting,
);

export const getDevicesToManageSelector = createSelector(getDevicesToManage, devices => devices);

export const getSaveSuccessSelector = createSelector(getSaveSuccess, saveSuccess => saveSuccess);

export const getSaveErrorSelector = createSelector(getSaveError, saveError => saveError);

export const getSavingSelector = createSelector(getSaving, saving => saving);

export const getSelectedPlanSelector = createSelector(
  getPrevPath,
  getCurrentSubscription,
  getPlanListSelected,
  (prevPath, currSub, planListSelected) => {
    if (prevPath !== '/subscriptions') {
      return planListSelected;
    }

    return currSub;
  },
);
