import React, { useEffect } from 'react';

//material ui
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

//redux
import { FormattedMessage, injectIntl } from 'react-intl';
import { useSelector, useDispatch } from 'react-redux';
import { getBrandImagesSelector } from '../BrandProvider/selectors';
import { clearMigrationState } from '../SubscriptionsList/actions';
import { patchProfile } from '../LoginPage/actions';
import messages from './messages';

import {
  getMigrationAttemptSelector,
  getMigrationErrorSelector,
  getWasMigratedSelector,
  getMigratedSubscriptionSelector,
  getMigrationCompleteSelector,
} from '../SubscriptionsList/selectors';

import history from '../../history';

interface Props {
  open: boolean;
  handleClose: Function;
  brand: string;
  userProfile: any;
  intl: any;
}

const DataLossModal = (props: Props) => {
  const { open, brand, userProfile } = props;
  const { formatMessage } = props.intl;

  const images = useSelector(getBrandImagesSelector);
  const isMigrationAttempting = useSelector(getMigrationAttemptSelector);
  const migrationError = useSelector(getMigrationErrorSelector);
  const wasMigrated = useSelector(getWasMigratedSelector);
  const migratedSubscription = useSelector(getMigratedSubscriptionSelector);
  const isMigrationComplete = useSelector(getMigrationCompleteSelector);

  const dispatch = useDispatch();

  useEffect(() => {
    const patchAuthProfile = (patch: any) => {
      if (userProfile && userProfile.pepperUser) {
        const newProfile = Object.assign(userProfile.pepperUser.account, patch);
        userProfile.pepperUser.account = newProfile;
        dispatch(patchProfile(userProfile));
      }
    };

    if (migratedSubscription !== null && wasMigrated === true) {
      const migrationData = {
        migrationInfo: {
          status: 'success',
          error: null,
          date: new Date().toISOString(),
        },
      };
      patchAuthProfile(migrationData);
    } else if (migratedSubscription === null && wasMigrated === false) {
      const migrationData = {
        migrationInfo: {
          status: 'error',
          error: 'Unable to migrate user. Could not find the new subscription.',
          date: new Date().toISOString(),
        },
      };
      patchAuthProfile(migrationData);
    }
  }, [dispatch, migratedSubscription, wasMigrated, userProfile]);

  const handleClose = () => {
    dispatch(clearMigrationState());
    props.handleClose();
  };

  const getEmailBody = (): string => {
    return (
      'Momentum Team,%0D%0A%0D%0A Please apply the free service upgrade to account: ' +
      (userProfile ? userProfile.fullName : '') +
      '%0D%0A%0D%0A [DO NOT CHANGE THE FOLLOWING - FOR INTERNAL USE] %0D%0AAccount username - ' +
      (userProfile ? userProfile.fullName : '')
    );
  };

  const getPlanFeature = (externalPlanCode: any) => {
    switch (externalPlanCode) {
      case '7+month':
      case '7+year':
        return <FormattedMessage {...messages.sevenVideoHistory} />;
      case '30+month':
      case '30+year':
        return <FormattedMessage {...messages.thirtyVideoHistory} />;
      case '60+month':
      case '60+year':
        return <FormattedMessage {...messages.sixtyVideoHistory} />;
      default:
        return <FormattedMessage {...messages.defaultVideoHistory} />;
    }
  };

  const isMigrationAttemptingRender = () => {
    if (isMigrationAttempting) {
      return (
        <div className={`${brand}-migration-model`}>
          <DialogTitle id="alert-dialog-title">
            <img
              src={images['giftbox']}
              alt={formatMessage(messages.warningAltText)}
              className={`${brand}-confirm-modal-icon`}
            />
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description" className={`${brand}-migration-model`}>
              <FormattedMessage {...messages.attemptingModalHeader} />
            </DialogContentText>
            <DialogContentText id="alert-dialog-description" className={`${brand}-migration-model`}>
              <FormattedMessage
                {...messages.migrationStatement}
                values={{
                  freeText: (
                    <span className="primary-h4-bold-text text-uppercase">
                      <FormattedMessage {...messages.freeText} />
                    </span>
                  ),
                }}
              />
            </DialogContentText>
          </DialogContent>
        </div>
      );
    }

    return null;
  };

  const notMigrationAttemptingOrHasError = () => {
    if (!isMigrationAttempting && (migrationError || wasMigrated === false)) {
      return (
        <div>
          <DialogTitle id="alert-dialog-title">
            <img
              src={images['error-outline-icon']}
              alt={formatMessage(messages.warningAltText)}
              className={`${brand}-confirm-modal-icon`}
            />
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <FormattedMessage {...messages.errorStatement} />
            </DialogContentText>
          </DialogContent>
          <DialogActions id="alert-dialog-actions">
            <Button
              onClick={handleClose}
              className={`${brand}-btn-text`}
              style={{ textTransform: 'none' }}
              id="not-now-btn">
              <a
                href={
                  'mailto:subscription@momentum-cam.com?subject=Request for Free Service Upgrade&body=' + getEmailBody()
                }
                target="_top"
                style={{ textDecoration: 'none' }}
                className={`${brand}-btn-text`}>
                <FormattedMessage {...messages.contactUsButton} />
              </a>
            </Button>
            <Button
              onClick={() => history.push('/login')}
              className={`${brand}-btn ${brand}-btn-action `}
              style={{
                width: 165,
                textTransform: 'none',
                height: 42,
                fontWeight: 'bolder',
              }}
              autoFocus
              id="sign-out">
              <FormattedMessage {...messages.signOutBtn} />
            </Button>
          </DialogActions>
        </div>
      );
    }

    return null;
  };

  const hasMigrationSubscription = () => {
    if (!isMigrationAttempting && !migrationError && wasMigrated && migratedSubscription) {
      return (
        <div className={`${brand}-migration-model`}>
          <DialogTitle id="alert-dialog-title">
            <img
              src={images['confetti']}
              alt={formatMessage(messages.warningAltText)}
              className={`${brand}-confirm-modal-icon`}
            />
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description" className={`${brand}-migration-model`}>
              <FormattedMessage
                {...messages.upgradeStatement}
                values={{
                  plan: migratedSubscription ? migratedSubscription.planConfig.name : '',
                  feature: migratedSubscription
                    ? getPlanFeature(migratedSubscription.planConfig.external_plan_code)
                    : '',
                  maxCameras: migratedSubscription ? migratedSubscription.planConfig.device_quantity : 1,
                }}
              />{' '}
            </DialogContentText>
            <DialogContentText id="alert-dialog-description" className={`${brand}-migration-model`}>
              <FormattedMessage
                {...messages.migrationStatement}
                values={{
                  freeText: (
                    <span className="primary-h4-bold-text text-uppercase">
                      <FormattedMessage {...messages.freeText} />
                    </span>
                  ),
                }}
              />
            </DialogContentText>
          </DialogContent>
        </div>
      );
    }

    return null;
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        {isMigrationAttemptingRender()}
        {notMigrationAttemptingOrHasError()}
        {hasMigrationSubscription()}

        {!isMigrationComplete && !migrationError && (
          <div
            className={`${brand}-migration-model`}
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              padding: 10,
            }}>
            <DialogActions>
              <div>
                <CircularProgress size={30} className={`${brand}-migration-model-progress`} />
              </div>
            </DialogActions>
          </div>
        )}
      </Dialog>
    </div>
  );
};

export default injectIntl(DataLossModal);
