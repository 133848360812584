export const SUBSCRIPTION_PREVIEW_ATTEMPT = 'SUBSCRIPTION_PREVIEW_ATTEMPT';
export const SUBSCRIPTION_PREVIEW_SUCCESS = 'SUBSCRIPTION_PREVIEW_SUCCESS';
export const SUBSCRIPTION_PREVIEW_FAILURE = 'SUBSCRIPTION_PREVIEW_FAILURE';
export const SUBSCRIPTION_ATTEMPT = 'SUBSCRIPTION_ATTEMPT';
export const SUBSCRIPTION_SUCCESS = 'SUBSCRIPTION_SUCCESS';
export const SUBSCRIPTION_FAILURE = 'SUBSCRIPTION_FAILURE';
export const SUBSCRIPTION_CLEAR_STATE_ATTEMPT = 'SUBSCRIPTION_CLEAR_STATE_ATTEMPT';
export const SUBSCRIPTION_CLEAR_STATE = 'SUBSCRIPTION_CLEAR_STATE';
export const CLEAR_STATE = 'CLEAR_STATE';
export const CLEAR_SUBSCRIPTION_PREVIEW = 'CLEAR_SUBSCRIPTION_PREVIEW';
