import {all, select, takeLatest} from 'redux-saga/effects';
import {
  SAVING_BILLING_INFO_FAILURE,
  SAVING_BILLING_INFO_SUCCESS,
} from '../../containers/BillingInformationView/actionTypes';
import {TypedIterableIterator} from '../../modules/helpers';
import {logEvent} from './analyticsLogger';
import {AnalyticsEvent} from './events';
import {getProfileSelector} from '../../containers/LoginPage/selectors';

function* logAccountDashBillingInfoSuccessAnalytics(): TypedIterableIterator<any> {
  const userProfile = yield select(getProfileSelector);
  logEvent(AnalyticsEvent.ACCTDASH_SUBMIT_BILLING_INFO_SUCCESS, userProfile);
}

function* logAccountDashBillingInfoFailureAnalytics(): TypedIterableIterator<any> {
  const userProfile = yield select(getProfileSelector);
  logEvent(AnalyticsEvent.ACCTDASH_SUBMIT_BILLING_ADDRESS_ERROR, userProfile);
}

function* AnalyticsSaga() {
  yield all([takeLatest(SAVING_BILLING_INFO_SUCCESS, logAccountDashBillingInfoSuccessAnalytics)]);
  yield all([takeLatest(SAVING_BILLING_INFO_FAILURE, logAccountDashBillingInfoFailureAnalytics)]);
}

export default AnalyticsSaga;
