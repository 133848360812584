import { createSelector } from 'reselect';

import { AppState } from '../../store/rootReducer';

const getSelectedPlan = (state: AppState) => state.planList.selectedPlan;
const getLoadingPlans = (state: AppState) => state.planList.attempting;
const getCurrentPlan = (state: AppState) => state.planList.currentPlan;
const getErrorCode = (state: AppState) => state.planList.errorCode;
const getPlansList = (state: AppState) => state.planList.plans;
const getPlans = (state: AppState) => state.planList.plans;

const getSubscriptionAttempting = (state: AppState) => state.orderSummary.subscriptionAttempting;
const getSubscriptionFailure = (state: AppState) => state.orderSummary.subscriptionFailure;
const getSubscriptionSuccess = (state: AppState) => state.orderSummary.subscriptionSuccess;

const getDevices = (state: AppState) => state.manageDevices.devices;

export const getLoadingPlansSelector = createSelector(getLoadingPlans, attempting => attempting);

export const getPlansListSelector = createSelector(getPlansList, plans => plans);

export const getCurrentPlanSelector = createSelector(getCurrentPlan, currentPlan => currentPlan);

export const getSelectedPlanSelector = createSelector(getSelectedPlan, selectedPlan => selectedPlan);

export const getPlansSelector = createSelector(getPlans, plans => plans);

export const getSubscriptionAttemptingSelector = createSelector(
  getSubscriptionAttempting,
  subscriptionAttempting => subscriptionAttempting,
);

export const getSubscriptionFailureSelector = createSelector(getSubscriptionFailure, failure => failure);

export const getSubscriptionSuccessSelector = createSelector(getSubscriptionSuccess, success => success);

export const getLoadPlansErrorCodeSelector = createSelector(getErrorCode, error => error);

export const getDevicesSelector = createSelector(getDevices, devices => devices);
