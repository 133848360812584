import React, { FC, useEffect } from 'react';

//redux
import { ssoAuthAttempt } from './actions';
import { useDispatch, useSelector } from 'react-redux';

//third party lib
import { withRouter, RouteComponentProps } from 'react-router-dom';
import CircularProgress from '@material-ui/core/CircularProgress';
import { getBrandNameSelector } from '../BrandProvider/selectors';

interface SsoRedirectProps extends RouteComponentProps {}

const SsoRedirect: FC<SsoRedirectProps> = ({ location }) => {
  const dispatch = useDispatch();
  const brand = useSelector(getBrandNameSelector);

  useEffect(() => {
    dispatch(ssoAuthAttempt(location));
  }, [dispatch, location]);

  return (
    <div className="progress-wrapper-center">
      <CircularProgress size={55} className={`${brand}-button-progress`} />
    </div>
  );
};

export default withRouter(SsoRedirect);
