import { TypedIterableIterator } from '../../modules/helpers';

//redux
import { all, put, takeLatest } from 'redux-saga/effects';
import { VERIFY_EMAIL_ATTEMPT, FETCH_APP_URI } from './actionTypes';

import {
  appUriFailureAction,
  appUriSuccessAction,
  initiateFetchUri,
  verifyEmailFailureAction,
  verifyEmailSuccessAction,
} from './actions';

import PepperSdk from 'pepper-js-sdk';
import { getBearerToken } from '../../env/config_util';
import { AnalyticsEvent } from '../../utils/analytics/events';
import { logEvent } from '../../utils/analytics/analyticsLogger';

export function* attemptVerifyEmail(action: any): TypedIterableIterator<any> {
  const { brand, verificationToken, email } = action;

  if (!verificationToken) {
    yield put(verifyEmailFailureAction('Invalid email verification link'));
    return;
  }

  const authService = PepperSdk.Authentication();

  yield put(initiateFetchUri(brand));

  const verifyEmailPromise = new Promise(resolve => {
    authService.verifyEmail(brand, verificationToken, email, (err, result) => {
      if (err) {
        resolve({ err });
      }
      resolve({ result });
    });
  });
  const { err, result } = yield verifyEmailPromise;

  if (result && result.IsPosted) {
    yield put(verifyEmailSuccessAction());
    logEvent(AnalyticsEvent.ACCOUNT_CREATED, { email: email, brand: brand });
  } else {
    const errorDescription =
      err.response && err.response.data ? err.response.data.description : 'Invalid email verification link';
    yield put(verifyEmailFailureAction(errorDescription));
  }
}

const setToken = (token: string) => {
  PepperSdk.config.setBearerToken(token);
};

export function* fetchAppUri(action: any): TypedIterableIterator<any> {
  const { brand } = action;
  const token = getBearerToken(brand) as string;

  setToken(token);
  const configService = PepperSdk.Configuration();

  const appUriPromise = new Promise((resolve, reject) => {
    configService.appUriByBrand(brand, (err, result) => {
      if (err) {
        return reject({ err });
      }
      resolve({ result });
    });
  });
  const { result } = yield appUriPromise;

  if (result && result.brand) {
    yield put(appUriSuccessAction(result));
  } else {
    yield put(appUriFailureAction());
  }
}

function* VerifyEmailSaga() {
  yield all([takeLatest(VERIFY_EMAIL_ATTEMPT, attemptVerifyEmail)]);
  yield all([takeLatest(FETCH_APP_URI, fetchAppUri)]);
}

export default VerifyEmailSaga;
