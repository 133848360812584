import React, { useEffect } from 'react';

//material ui
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';

//third party libs
import { isFreePlan } from '../../constants/freePlans';
import { FormattedMessage } from 'react-intl';
import { useMediaQuery } from 'react-responsive';
import planFeatures from './cancelPlanFeatures';
import messages from './messages';
import history from '../../history';

//redux
import { getSelectedSubscriptionSelector } from '../SubscriptionsList/selectors';
import { useSelector, useDispatch } from 'react-redux';
import { getBrandNameSelector } from '../BrandProvider/selectors';
import {
  getPlansSelector,
  getLoadingPlansSelector,
  getSubscriptionAttemptingSelector,
  getSubscriptionFailureSelector,
  getSubscriptionSuccessSelector,
  getLoadPlansErrorCodeSelector,
  getDevicesSelector,
} from './selectors';

import { getPlansAttempt, setCurrentAndSelectedPlan } from '../PlanListPage/actions';
import { setDevicesAsAssigned } from '../ManageDevices/actions';
import { subscriptionAttempt, subscriptionClearState } from '../OrderSummary/actions';

//icons
import cancelSvg from '../../themes/common/images/cancel-icon.svg';
import { brandNames } from '../../constants/brandNames';
import { logEvent } from '../../utils/analytics/analyticsLogger';
import { AnalyticsEvent } from '../../utils/analytics/events';
import { getProfileSelector } from '../LoginPage/selectors';

const CancelPlan = () => {
  const currentSub: any = useSelector(getSelectedSubscriptionSelector);
  const brand = useSelector(getBrandNameSelector);
  const loading = useSelector(getLoadingPlansSelector);

  const subAttempting = useSelector(getSubscriptionAttemptingSelector);
  const subFailure = useSelector(getSubscriptionFailureSelector);
  const subSuccess = useSelector(getSubscriptionSuccessSelector);
  const loadError = useSelector(getLoadPlansErrorCodeSelector);
  const devices = useSelector(getDevicesSelector);

  const isMobile = useMediaQuery({ query: `(max-width: 600px)` });
  const plans = useSelector(getPlansSelector);
  const userProfile = useSelector(getProfileSelector);
  const dispatch = useDispatch();

  const handleSubmit = () => {
    if (plans) {
      const noPlan = plans.find((plan: any) => isFreePlan(plan.id));

      if (currentSub && currentSub.plan_config_id) {
        const currPlan = plans.find((plan: any) => plan.id === currentSub.plan_config_id);

        dispatch(setCurrentAndSelectedPlan(currPlan, noPlan));
        dispatch(setDevicesAsAssigned(devices, []));
        dispatch(subscriptionAttempt());
        logEvent(AnalyticsEvent.ACCTDASH_CANCEL_PLAN, userProfile);
      }
    }
  };

  useEffect(() => {
    const fetchPlans = async () => {
      await dispatch(getPlansAttempt(brand));
    };

    fetchPlans();
  }, [dispatch, brand]);

  useEffect(() => {
    if (subFailure || loadError) {
      dispatch(subscriptionClearState());
      history.push('/error-status');
    }
  }, [dispatch, subFailure, loadError]);

  useEffect(() => {
    if (subSuccess) {
      dispatch(subscriptionClearState());
      history.push('/subscriptions');
    }
  }, [dispatch, subSuccess]);

  const getConfirmCancelStatement = () => {
    if (currentSub && currentSub.plan_config && currentSub.plan_config.features) {
      return (
        <Typography variant="body2" style={{ lineHeight: '22px' }}>
          <FormattedMessage
            {...messages.confirmCancelStatement}
            values={{
              retention: `${currentSub.plan_config.features.retention}`,
              retentionTimeFrame: currentSub.plan_config.features.retention === 1 ? 'day' : 'days',
            }}
          />
        </Typography>
      );
    }
    return '';
  };

  const getFeaturesText = () => {
    if (currentSub && currentSub.plan_config_id) {
      return (
        <div>
          <ul style={{ listStyleType: 'none', width: '100%', paddingLeft: 10 }}>
            {planFeatures[currentSub.plan_config_id] &&
              planFeatures[currentSub.plan_config_id].map((feature: any, index: any) => {
                return (
                  <div key={index} style={{ textDecoration: 'none', display: 'flex' }}>
                    <div
                      style={{
                        display: 'flex',
                        marginBottom: 15,
                        alignItems: 'center',
                      }}>
                      <FormattedMessage
                        {...feature}
                        values={{
                          cancelIcon: <img style={{ marginRight: 5 }} src={cancelSvg} alt="cancel" />,
                        }}
                      />
                    </div>
                  </div>
                );
              })}
          </ul>
        </div>
      );
    }

    return <p></p>;
  };

  const getCancelPlanButton = () => {
    return (
      <Button
        className={`${brand}-btn ${brand}-btn-action`}
        style={
          isMobile
            ? {
                height: 42,
                width: 150,
                minWidth: 150,
                textTransform: 'none',
                fontWeight: 'bolder',
              }
            : {
                height: 42,
                width: 224,
                minWidth: 200,
                textTransform: 'none',
                fontWeight: 'bolder',
              }
        }
        onClick={handleSubmit}
        id="cancel-btn">
        <FormattedMessage {...messages.exitButton} />
      </Button>
    );
  };

  const getKeepCurrentButton = () => {
    return (
      <Button
        className={`${brand}-btn ${brand}-btn-text`}
        style={{
          textTransform: 'none',
          fontWeight: 'bolder',
          height: 42,
        }}
        id={`${brand}-keep-current-btn`}
        onClick={() => history.goBack()}>
        <FormattedMessage {...messages.cancelButton} />
      </Button>
    );
  };

  return (
    <div>
      {loading || subAttempting ? (
        <div className="progress-wrapper-center">
          <CircularProgress size={55} className={`${brand}-button-progress`} />
        </div>
      ) : (
        <div>
          <div className={`${brand}-cancel-plan-page`}>
            <div className={`${brand}-cancel-plan-header`}>
              <div className={`${brand}-cancel-plan-title`} style={{ marginBottom: 15, paddingTop: isMobile ? 15 : 0 }}>
                <Typography variant="h6">
                  <FormattedMessage {...messages.modalTitle} />
                </Typography>
              </div>
              <div className={`${brand}-cancel-plan-description`} style={{ marginTop: 15 }}>
                {getConfirmCancelStatement()}
              </div>
            </div>
            <div className={`${brand}-cancel-plan-backup`}>
              <div className={`${brand}-cancel-plan-features`} style={{ marginTop: 15 }}>
                <Typography variant="h6">
                  <FormattedMessage {...messages.features} />
                </Typography>
              </div>
              <div className={`${brand}-cancel-plan-features-list`} style={{ marginTop: 15 }}>
                {getFeaturesText()}
              </div>
              <div style={{ marginTop: 15 }}>
                <Typography variant="subtitle2" style={{ fontSize: '1.2rem' }}>
                  <FormattedMessage {...messages.backupFootage} />{' '}
                </Typography>
              </div>
              <div style={{ marginTop: 15 }}>
                <Typography variant="body2">
                  {brand === brandNames.otis ? <FormattedMessage {...messages.backupFootageTextOtis} /> : null}
                  {brand === brandNames.visi ? <FormattedMessage {...messages.backupFootageTextVisi} /> : null}
                </Typography>
              </div>
            </div>
          </div>

          <Paper variant="outlined" className="summary-order-footer" style={{ width: '100%' }}>
            <div className="summary-order-footer-action-buttons">
              {getKeepCurrentButton()}

              <div>{getCancelPlanButton()}</div>
            </div>
          </Paper>
        </div>
      )}
    </div>
  );
};

export default CancelPlan;
