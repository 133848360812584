import { DeviceListActionTypes, IDeviceList } from './types';
import {
  CLEAR_STATE,
  GET_DEVICE_LIST_ATTEMPT,
  GET_DEVICE_LIST_SUCCESS,
  GET_DEVICE_LIST_FAILURE,
  // REFRESH_DEVICE_LIST_ATTEMPT,
  // REFRESH_DEVICE_LIST_SUCCESS,
  // REFRESH_DEVICE_LIST_FAILURE,
  // SET_SELECTED_DEVICE,
} from './actionTypes';

export const initialState: IDeviceList = {
  deviceList: [],
  errorCode: null,
  attempting: false,
};

export const DeviceListReducer = (state = initialState, action: DeviceListActionTypes): IDeviceList => {
  switch (action.type) {
    case GET_DEVICE_LIST_ATTEMPT:
      return {
        ...state,
        attempting: true,
      };
    case GET_DEVICE_LIST_SUCCESS:
      return {
        ...state,
        deviceList: action.deviceList,
        errorCode: null,
        attempting: false,
      };
    case GET_DEVICE_LIST_FAILURE:
      return {
        ...state,
        errorCode: action.errorCode,
        attempting: false,
      };
    case CLEAR_STATE:
      return initialState;
    default:
      return state;
  }
};
