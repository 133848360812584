/*
 * Order Summary Messages
 *
 * This contains all the text for the order summary component.
 */
import { defineMessages } from 'react-intl';

export default defineMessages<string>({
  title: {
    id: 'accountManagement.components.label.subscription.summary.title.text',
    defaultMessage: 'Order Summary',
  },
  subtitle: {
    id: 'accountManagement.components.label.subscription.summary.subtitle.text',
    defaultMessage: 'Summary',
  },
  termsTitle: {
    id: 'accountManagement.components.label.subscription.summary.terms.text',
    defaultMessage: 'Terms and Conditions',
  },
  terms_momentum: {
    id: 'accountManagement.components.label.subscription.summary.terms.message.momentum',
    defaultMessage: 'By clicking Submit, I acknowledge I have read and agree to the {terms} and {privacy} of Momentum.',
  },
  terms_otis: {
    id: 'accountManagement.components.label.subscription.summary.terms.message.otis',
    defaultMessage: 'By clicking Submit, I acknowledge I have read and agree to the {terms} and {privacy} of Otis.',
  },
  terms_pepper: {
    id: 'accountManagement.components.label.subscription.summary.terms.message.pepper',
    defaultMessage: 'By clicking Submit, I acknowledge I have read and agree to the {terms} and {privacy} of Pepper.',
  },
  terms_visi: {
    id: 'accountManagement.components.label.subscription.summary.terms.message.visi',
    defaultMessage: 'By clicking Submit, I acknowledge I have read and agree to the {terms} and {privacy} of Visi.',
  },
  terms_notion: {
    id: 'accountManagement.components.label.subscription.summary.terms.message.notion',
    defaultMessage: 'By clicking Submit, I acknowledge I have read and agree to the {terms} and {privacy} of Notion.',
  },
  terms_geeni: {
    id: 'accountManagement.components.label.subscription.summary.terms.message.geeni',
    defaultMessage: 'By clicking Submit, I acknowledge I have read and agree to the {terms} and {privacy} of Merkury Smart.',
  },
  join_term_link: {
    id: 'accountManagement.components.label.subscription.summary.terms.join_term_link',
    defaultMessage: 'Terms of Use',
  },
  join_privacy_link: {
    id: 'accountManagement.components.label.subscription.summary.terms.join_privacy_link',
    defaultMessage: 'Privacy Policies',
  },
  paymentMethodTitle: {
    id: 'accountManagement.components.label.subscription.summary.paymentMethod.message',
    defaultMessage: 'Payment Method',
  },
  paymentMethodChange: {
    id: 'accountManagement.components.button.subscription.summary.paymentMethod.change.text',
    defaultMessage: 'Change',
  },
  yourPlanTitle: {
    id: 'accountManagement.components.label.subscription.summary.yourPlan.text',
    defaultMessage: 'Your Plan',
  },
  yourPlanMessage: {
    id: 'accountManagement.components.label.subscription.summary.yourPlan.message',
    defaultMessage:
      'Your account will be charged the total amount shown above each {period} less any applied Promo Code. Your plan will continue until you cancel.',
  },
  yourPlanMessageFreeTrial: {
    id: 'accountManagement.components.label.subscription.summary.yourPlan.freeTrial.message',
    defaultMessage:
      'After your free trial, your account will be charged the total amount shown above each {period} less any applied Promo Code. Your plan will continue until you cancel. If you cancel during your free trial period, you will not be charged.',
  },
  device: {
    id: 'accountManagement.components.label.subscription.summary.device.text',
    defaultMessage: 'Device',
  },
  yourPlanChange: {
    id: 'accountManagement.components.button.subscription.summary.yourPlan.change.text',
    defaultMessage: 'Change',
  },
  plan: {
    id: 'accountManagement.components.label.subscription.summary.plan.text',
    defaultMessage: 'Plan',
  },
  price: {
    id: 'accountManagement.components.label.subscription.summary.price.text',
    defaultMessage: 'Price',
  },
  tax: {
    id: 'accountManagement.components.label.subscription.summary.tax.text',
    defaultMessage: 'Tax',
  },
  total: {
    id: 'accountManagement.components.label.subscription.summary.total.text',
    defaultMessage: 'Total:',
  },
  back: {
    id: 'accountManagement.components.button.subscription.summary.back.text',
    defaultMessage: 'Back',
  },
  submit: {
    id: 'accountManagement.components.button.subscription.summary.submit.text',
    defaultMessage: 'Submit',
  },
  genericError: {
    id: 'accountManagement.components.button.subscription.summary.genericError.text',
    defaultMessage: 'There has been an error saving your changes. Please try again',
  },
  change_must_be_immediate: {
    id: 'change_must_be_immediate',
    defaultMessage: 'You must immediately change the plan in order to redeem a coupon.',
  },
  billingCycle1: {
    id: 'accountManagement.components.label.summary.perMonth',
    defaultMessage: '/mo',
  },
  billingCycle12: {
    id: 'accountManagement.components.label.summary.perYear',
    defaultMessage: '/yr',
  },
  billingCycle24: {
    id: 'accountManagement.components.label.summary.perTwoYear',
    defaultMessage: ' every 2 years',
  },
  savingSubscription: {
    id: 'accountManagement.components.label.savingSubscription.title.text',
    defaultMessage: 'Saving...',
  },
  savingSubscriptionStatement: {
    id: 'accountManagement.components.label.savingSubscriptionStatement.text',
    defaultMessage: 'Please wait while we update your account.',
  },
  downgradeFreeTrial: {
    id: 'accountManagement.components.label.payment.downgradeFreeTrial.text',
    defaultMessage:
      'The plan change will take effect at the end of your free trial period. Your card will be charged the plan price shown above each {billingCycle} less any applied Promo code. Your plan will renew automatically each {billingCycle} unless you downgrade or cancel prior to the renewal date. Please review the devices associated with your new plan once the plan change is in effect.',
  },
  downgradeNotFreeTrial: {
    id: 'accountManagement.components.label.payment.downgradeNotFreeTrial.text',
    defaultMessage:
      'The plan change will take effect at the end of your current billing cycle. Your card will be charged the plan price shown above each {billingCycle} less any applied Promo code. Your plan will renew automatically each {billingCycle} unless you downgrade or cancel prior to the renewal date. Please review the devices associated with your new plan once the plan change is in effect.',
  },
  chargeAmountStatement: {
    id: 'accountManagement.components.label.payment.chargeAmountStatement.text',
    defaultMessage: 'Your card will be charged the total amount above each month/year less any applied Promo Code.',
  },
  downgradeLiteChargeText: {
    id: 'accountManagement.components.label.payment.downgradeLiteChargeText.text',
    defaultMessage:
      'Downgrading to the free plan will stop auto-renewing payments. Your existing service will continue until the end of your current billing cycle. Your account will no longer be charged.',
  },
  freeTrialChargeText: {
    id: 'accountManagement.components.label.payment.freeTrialChargeText.text',
    defaultMessage:
      'After your free trial period, your card will be charged the plan price shown above each {billingCycle} less any applied Promo Code. Your plan will renew automatically each {billingCycle} unless you downgrade or cancel prior to the renewal date.',
  },
  generalRenewChargeText: {
    id: 'accountManagement.components.label.payment.generalRenewChargeText.text',
    defaultMessage:
      'Your card will be charged the plan price shown above each {billingCycle} less any applied Promo Code. Your plan will renew automatically each {billingCycle} unless you downgrade or cancel prior to the renewal date.',
  },
  confirmTermsStatement: {
    id: 'accountManagement.components.label.confirmExit.confirmTermsStatement.text',
    defaultMessage:
      'Before submitting, you must select the checkbox accepting the Terms and Conditions found at the bottom of the page.',
  },
});
