
// If we want to learn how the sagas work with their breadcrumb trail of actions, selectors, messages, reducers etc, we
// can do that and then refactor the below code to implement that system. Since this is a web app with a visible EOL on 
// the horizon, I'm implementing the network calls in this file to save developer time + sanity.
import { loadConfig } from '../../env/config_util';

const config = loadConfig();

export async function postResetPassword(brand: string, data = {}) {
  const { base_url } = config.config_module[brand];
  const fullUrl = `${base_url}/authentication/forgotPassword/${brand}`;
  const response = await fetch(fullUrl, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data)
  });
  
  return response.json();
}
