import { createSelector } from 'reselect';

import { AppState } from '../../store/rootReducer';

const getAppUriObj = (state: AppState) => state.verifyEmail.appUriObj;
const getAttemptVerify = (state: AppState) => state.verifyEmail.attempting;
const getVerifyEmailAttempting = (state: AppState) => state.verifyEmail.attempting;
const getVerifyEmailError = (state: AppState) => state.verifyEmail.error;
const getVerifyEmailFailed = (state: AppState) => state.verifyEmail.failed;
const getVerifyEmailSuccess = (state: AppState) => state.verifyEmail.success;

export const getAppUriObjSelector = createSelector(getAppUriObj, appUriObj => appUriObj);

export const getAttemptVerifySelector = createSelector(getAttemptVerify, attemptVerify => attemptVerify);

export const getVerifyEmailAttemptingSelector = createSelector(getVerifyEmailAttempting, attempting => attempting);

export const getVerifyEmailErrorSelector = createSelector(getVerifyEmailError, error => error);

export const getVerifyEmailFailedSelector = createSelector(getVerifyEmailFailed, failed => failed);

export const getVerifyEmailSuccessSelector = createSelector(getVerifyEmailSuccess, success => success);
