import React, { useEffect, useState } from 'react';

//third party lib
import { FormattedMessage } from 'react-intl';
import { Dictionary } from '../../modules/helpers';
import messages from './messages';
import history from '../../history';

//redux
import { useSelector, useDispatch } from 'react-redux';
import { getBrandNameSelector } from '../BrandProvider/selectors';

//models
import DeleteAccount from './DeleteAccount';
import { getCancelDeleteAttempt, clearState } from './actions';
import { getCancelAccountErrorSelector, getCancelAccountSuccessSelector } from './selectors';
import { getProfileSelector } from '../LoginPage/selectors';
import { updateAccountStatus } from '../LoginPage/actions';

const brandEmail: Dictionary<string> = {
  otis: 'info@otishome.com',
  visi: 'information@getvisi.com',
  momentum: 'help@momentumcam.com',
  pepper: 'demo@pepper.me',
};

const DeleteAccountModel = () => {
  const brand = useSelector(getBrandNameSelector);
  const dispatch = useDispatch();

  const userProfile = useSelector(getProfileSelector);

  const cancelDeleteSuccess = useSelector(getCancelAccountSuccessSelector);
  const cancelDeleteFailure = useSelector(getCancelAccountErrorSelector);

  const [modelTitle, setModelTitle] = useState<any>(<FormattedMessage {...messages.deleteAccountTitle} />);
  const [modelSubtext, setModelSubtext] = useState<any>(<FormattedMessage {...messages.deleteAccountSubtext} />);

  const [modelPrimaryBtnText, setModelPrimaryBtnText] = useState<any>(
    <FormattedMessage {...messages.deleteAccountPrimaryBtn} />,
  );
  const [modelSecondaryBtnText, setModelSecondaryBtnText] = useState<any>(
    <FormattedMessage {...messages.deleteAccountSecondaryBtn} />,
  );

  const onCancelRequest = () => {
    setModelTitle('');
    setModelSubtext('');
    setModelPrimaryBtnText('');
    setModelSecondaryBtnText('');

    dispatch(getCancelDeleteAttempt());
  };

  const onErrorCancelRequest = () => {
    window.location.href = 'mailto:' + brandEmail[brand];
  };

  useEffect(() => {
    if (!userProfile) {
      history.push('/login');
    }
  }, [userProfile]);

  useEffect(() => {
    if (cancelDeleteSuccess) {
      dispatch(updateAccountStatus('active'));
      history.push('/subscriptions');
    }

    if (cancelDeleteFailure) {
      setModelTitle(<FormattedMessage {...messages.deleteAccountErrorTitle} />);
      setModelSubtext(<FormattedMessage {...messages.deleteAccountErrorSubtext} />);
      setModelPrimaryBtnText(<FormattedMessage {...messages.deleteAccountPrimaryErrorBtn} />);
      setModelSecondaryBtnText(<FormattedMessage {...messages.deleteAccountSecondaryErrorBtn} />);
    }
  }, [cancelDeleteFailure, cancelDeleteSuccess, dispatch]);

  const onKeepRequest = () => {
    dispatch(clearState());
    history.push('/login');
  };

  return (
    <div>
      <DeleteAccount
        title={modelTitle}
        subText={modelSubtext}
        secondaryBtn={modelSecondaryBtnText}
        primaryBtn={modelPrimaryBtnText}
        onCancel={onKeepRequest}
        onSubmit={onCancelRequest}
        hasError={cancelDeleteFailure}
        onError={onErrorCancelRequest}
        brand={brand}></DeleteAccount>
    </div>
  );
};

export default DeleteAccountModel;
