import { all, call, put, takeLatest } from 'redux-saga/effects';
import {
  getDeviceModelsSuccess,
  getDeviceModelsFailure,
  getSubscriptionPlansSuccess,
  getSubscriptionPlansFailure,
  checkMigrationComplete,
  migrationComplete,
  migrationFailure,
} from './actions';

import {
  GET_SUBSCRIPTION_PLANS_ATTEMPT,
  CHECK_MIGRATION_REQUIRED,
  MIGRATION_ATTEMPT,
  GET_DEVICE_MODELS_ATTEMPT,
} from './actionTypes';

import { getDataArrayFromService, getDataFromService, postDataToService } from '../../services/apiGatewayClient';

interface TypedIterableIterator<T, N = any> {
  next(value: N): T;
}

function* getSubscriptionList(): TypedIterableIterator<any> {
  try {
    const subscriptionPlans = yield call(getDataFromService, '/subscriptions', 'account');
    yield put(getSubscriptionPlansSuccess(subscriptionPlans));
  } catch (err) {
    yield put(getSubscriptionPlansFailure(err));
  }
}

function* getDeviceModels(action: any): TypedIterableIterator<any> {
  try {
    const deviceModels = yield call(getDataArrayFromService, `/deviceModels/${action.brand}`, 'config', {}, false);
    yield put(getDeviceModelsSuccess(deviceModels));
  } catch (err) {
    yield put(getDeviceModelsFailure(err));
  }
}

export function* checkMigrationRequired(): TypedIterableIterator<any> {
  try {
    const isMigrationRequired = yield call(getDataFromService, '/migration/required', 'subscriptions');
    yield put(checkMigrationComplete(isMigrationRequired));
  } catch (err) {
    yield put(checkMigrationComplete(false));
  }
}

export function* attemptMigration(): TypedIterableIterator<any> {
  try {
    const runMigration = yield call(postDataToService, '/migrateSubscriptions', {}, 'subscriptions');
    yield put(migrationComplete(runMigration, true));
  } catch (err) {
    yield put(migrationFailure(err));
  }
}

function* loadSubscriptionPlansSaga() {
  yield all([takeLatest(GET_SUBSCRIPTION_PLANS_ATTEMPT, getSubscriptionList),
                    takeLatest(GET_DEVICE_MODELS_ATTEMPT, getDeviceModels)]);
  yield all([takeLatest(CHECK_MIGRATION_REQUIRED, checkMigrationRequired)]);
  yield all([takeLatest(MIGRATION_ATTEMPT, attemptMigration)]);
}

export default loadSubscriptionPlansSaga;
