import { SubscriptionTypes } from './types';

import {
  GET_SUBSCRIPTION_PLANS_ATTEMPT,
  GET_SUBSCRIPTION_PLANS_SUCCESS,
  GET_SUBSCRIPTION_PLANS_FAILURE,
  CHECK_MIGRATION_REQUIRED,
  CHECK_MIGRATION_COMPLETE,
  MIGRATION_ATTEMPT,
  MIGRATION_FAILURE,
  MIGRATION_COMPLETE,
  CLEAR_MIGRATION_STATE, 
  GET_DEVICE_MODELS_FAILURE, 
  GET_DEVICE_MODELS_SUCCESS, 
  GET_DEVICE_MODELS_ATTEMPT,
} from './actionTypes';

export const getSubscriptionPlansAttempt = (): SubscriptionTypes => ({
  type: GET_SUBSCRIPTION_PLANS_ATTEMPT,
});

export const getSubscriptionPlansSuccess = (plans: Array<any>): SubscriptionTypes => ({
  type: GET_SUBSCRIPTION_PLANS_SUCCESS,
  subscriptionPlans: plans,
});

export const getSubscriptionPlansFailure = (error: any): SubscriptionTypes => ({
  type: GET_SUBSCRIPTION_PLANS_FAILURE,
  error: error,
});

export const clearMigrationState = (): SubscriptionTypes => ({
  type: CLEAR_MIGRATION_STATE,
});

export const checkMigration = (): SubscriptionTypes => ({
  type: CHECK_MIGRATION_REQUIRED,
});

export const checkMigrationComplete = (isMigrationRequired: boolean): SubscriptionTypes => ({
  type: CHECK_MIGRATION_COMPLETE,
  isMigrationRequired: isMigrationRequired,
});

export const migrationAttempt = (): SubscriptionTypes => ({
  type: MIGRATION_ATTEMPT,
});

export const migrationComplete = (subsData: any, migrated: any): SubscriptionTypes => ({
  type: MIGRATION_COMPLETE,
  subsData,
  migrated,
});

export const migrationFailure = (error: any): SubscriptionTypes => ({
  type: MIGRATION_FAILURE,
  error: error,
});

export const getDeviceModelsAttempt = (brand: string): SubscriptionTypes => ({
  type: GET_DEVICE_MODELS_ATTEMPT,
  brand: brand,
});

export const getDeviceModelsSuccess= (deviceModels: any): SubscriptionTypes => ({
  type: GET_DEVICE_MODELS_SUCCESS,
  deviceModels: deviceModels,
});

export const getDeviceModelsFailure = (error: any): SubscriptionTypes => ({
  type: GET_DEVICE_MODELS_FAILURE,
  error: error,
});
