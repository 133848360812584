import { AccountHistoryActionTypes, IAccountHistoryList } from './types';
import {
  GET_ACCT_HISTORY_ATTEMPT,
  GET_ACCT_HISTORY_SUCCESS,
  GET_ACCT_HISTORY_FAILURE,
  CLEAR_STATE,
} from './actionTypes';

export const initialState: IAccountHistoryList = {
  accountHistoryList: [],
  errorCode: null,
  attempting: false,
};

export const AccountHistoryReducer = (state = initialState, action: AccountHistoryActionTypes): IAccountHistoryList => {
  switch (action.type) {
    case GET_ACCT_HISTORY_ATTEMPT:
      return {
        ...state,
        attempting: true,
      };
    case GET_ACCT_HISTORY_SUCCESS:
      return {
        ...state,
        accountHistoryList: action.accountHistoryList,
        errorCode: null,
        attempting: false,
      };
    case GET_ACCT_HISTORY_FAILURE:
      return {
        ...state,
        errorCode: action.errorCode,
        attempting: false,
      };
    case CLEAR_STATE:
      return initialState;
    default:
      return state;
  }
};
