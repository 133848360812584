export function loadConfig() {
  return require('../env/' + process.env.REACT_APP_BUILD_ENV + '_config');
}

export function getOldSubscriptionUrl(brand) {
  let url = null;
  if (process.env.OLD_SUBSCRIPTION && process.env.OLD_SUBSCRIPTION === 'true') {
    let config = loadConfig();
    url = config.config_module[brand].old_sub_url;
  }
  return url;
}

export function getRecurlyPublicKey(brand) {
  let config = loadConfig();
  return config.config_module[brand].recurlyPublicKey;
}

export function getRecurlyUrl(brand) {
  let config = loadConfig();
  return config.config_module[brand].recurlyUrl;
}

export function getRecurlyGooglePay(brand) {
  let config = loadConfig();
  return config.config_module[brand]?.recurlyGooglePay;
}

export function getBearerToken(brand) {
  let config = loadConfig();
  return config.config_module[brand].configToken;
}

/**
 * Return the analytics info object from the environment config file.  Used to
 * initialize the analytics package.
 *
 * @param brand
 *
 * 'provider' is the analytics provider (e.g. clevertap) and the 'config'
 * contains the initialization parameters for that provider for the given brand
 * in the given environment.  Note that the structure of 'config' will likely
 * be different for each analytics provider.
 */
export function getAnalyticsInfo(brand) {
  let config = loadConfig();
  return config?.config_module?.[brand]?.analytics;
}

export function getSocialConfig(brand) {
  const config = loadConfig();
  return config?.config_module?.[brand]?.social;
}

export function getAppUrls(brand) {
  const config = loadConfig();
  return config?.config_module?.[brand]?.appStoreUrl;
}
