import { OrderSummaryTypes } from './types';

import {
  SUBSCRIPTION_ATTEMPT,
  SUBSCRIPTION_FAILURE,
  SUBSCRIPTION_SUCCESS,
  SUBSCRIPTION_PREVIEW_ATTEMPT,
  SUBSCRIPTION_PREVIEW_SUCCESS,
  SUBSCRIPTION_PREVIEW_FAILURE,
  SUBSCRIPTION_CLEAR_STATE_ATTEMPT,
  SUBSCRIPTION_CLEAR_STATE,
  CLEAR_SUBSCRIPTION_PREVIEW,
} from './actionTypes';

export const subscriptionPreviewAttempt = (): OrderSummaryTypes => ({
  type: SUBSCRIPTION_PREVIEW_ATTEMPT,
});

export const subscriptionPreviewSuccess = (
  subscriptionPreview: any,
  recurlyPreviewResponse: any,
): OrderSummaryTypes => ({
  type: SUBSCRIPTION_PREVIEW_SUCCESS,
  subscriptionPreview,
  recurlyPreviewResponse,
});

export const subscriptionPreviewFailure = (err: any, recurlyPreviewResponse: any): OrderSummaryTypes => ({
  type: SUBSCRIPTION_PREVIEW_FAILURE,
  err: err,
  recurlyPreviewResponse: recurlyPreviewResponse,
});

export const subscriptionAttempt = (): OrderSummaryTypes => ({
  type: SUBSCRIPTION_ATTEMPT,
});

export const subscriptionFailure = (error: any, selectedSubscriptionRequest: any): OrderSummaryTypes => ({
  type: SUBSCRIPTION_FAILURE,
  error: error,
  selectedSubscriptionRequest: selectedSubscriptionRequest,
});

export const subscriptionSuccess = (subscriptionResponse: any): OrderSummaryTypes => ({
  type: SUBSCRIPTION_SUCCESS,
  subscriptionResponse,
});

export const clearStateAttempt = (): OrderSummaryTypes => ({
  type: SUBSCRIPTION_CLEAR_STATE_ATTEMPT,
});

export const subscriptionClearState = (): OrderSummaryTypes => ({
  type: SUBSCRIPTION_CLEAR_STATE,
});

export const clearSubscriptionPreviewState = (): OrderSummaryTypes => ({
  type: CLEAR_SUBSCRIPTION_PREVIEW,
});
