import React from 'react';
import { PaymentInformation } from '../PaymentInformation';
import PaymentDetailsActions from './PaymentDetailsActions';
import { useSelector } from 'react-redux';
import { getBillingInformationExistsSelector } from '../BillingInformationView/selectors';
import history from '../../history';

interface Props {
  lastPath: string;
  brand: string;
}

const PaymentDetailsStripe = ({ brand, lastPath }: Props) => {
  const submitDisabled = useSelector(getBillingInformationExistsSelector);

  const handleSubmit = () => {
    history.push('/order-summary');
  };

  return (
    <>
      <PaymentInformation />

      <PaymentDetailsActions
        brand={brand}
        lastPath={lastPath}
        handleSubmit={handleSubmit}
        submitDisabled={submitDisabled}
      ></PaymentDetailsActions>
    </>
  );
};

export default PaymentDetailsStripe;
