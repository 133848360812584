import clevertap from 'clevertap-web-sdk';
import { AnalyticsConfigInfo, AnalyticsWrapper } from './AnalyticsWrapper';

/**
 * CleverTap web SDK requires CleverTap account id to initialize.
 */
interface CleverTapConfigInfo extends AnalyticsConfigInfo {
  analytics: { accountId: string };
}

/**
 * Implementation of Analytics Wrapper interface for CleverTap.
 */
export class CleverTapWrapper implements AnalyticsWrapper {
  private analyticsInfo: any;

  /**
   * Create the AnalyticsWrapper for CleverTap.
   *
   * @param analyticsInfo
   */
  constructor(analyticsInfo: CleverTapConfigInfo) {
    this.analyticsInfo = analyticsInfo;
  }

  /**
   * Invoke CleverTap init method with configured account id.
   */
  init() {
    const accountId = this.analyticsInfo?.config?.accountId;
    if (accountId) {
      return clevertap.init(accountId);
    } else {
      console.error('No clevertap account id configured. Analytics will not be reported.');
    }
  }

  /**
   * Logs the given event with the given data.
   *
   * @param eventName
   * @param eventData
   */
  logEvent(eventName: string, eventData: any) {
    clevertap.event.push(eventName, eventData);
  }

  /**
   * Identifies the logged-in user.
   *
   * @param identity
   * @param emailAddress
   */
  identifyUser(identity: string, emailAddress: string) {
    clevertap.onUserLogin.push({
      Site: {
        Identity: identity,
        Email: emailAddress,
      },
    });
  }
}
