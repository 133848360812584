import React from 'react';

import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import { getDeviceImage } from '../../utils/deviceImageUtils';
import { DeviceModel } from 'pepper-js-sdk/types';

interface Props {
  device: any;
  brand: string;
  isSelected: boolean;
  handleChange: Function;
  index: number;
  deviceModels?: DeviceModel[];
}

const DevicesCard = (props: Props) => {
  const { brand, device, isSelected, index, deviceModels } = props;

  const getPlanName = (): string => {
    if (device.subscription && device.subscription.planConfig && device.subscription.planConfig.name) {
      return device.subscription.planConfig.name;
    }
    return '';
  };

  const handleChange = (event: any) => {
    props.handleChange(index, !isSelected);
  };

  return (
    <Paper
      variant="outlined"
      className={isSelected ? `${brand}-device-card-selected` : `${brand}-device-card`}
      style={{ height: 84, width: '100%' }}
    >
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          height: '100%',
          justifyContent: 'space-between',
          marginLeft: 15,
          marginRight: 15,
        }}
      >
        <div style={{ display: 'flex', alignItems: 'center', height: '100%' }}>
          <div
            style={{
              marginRight: 15,
            }}
          >
            <img src={getDeviceImage(device, deviceModels)} alt="device-img" style={{ width: '50px', height: '50px' }} />
          </div>
          <div style={{ lineHeight: 1.5 }}>
            <div style={{ fontWeight: 'bolder' }}>{device.name}</div>
            <div className={`${brand}-text-common ${brand}-text-b`}>
              {getPlanName()}
              {' Plan'}
            </div>
          </div>
        </div>
        <div>
          <Checkbox
            className={`${brand}-checkbox`}
            checked={isSelected}
            color="primary"
            onChange={handleChange}
            id={props.index + '-checkbox'}
          />
        </div>
      </div>
    </Paper>
  );
};

export default DevicesCard;
