import { all, call, put, takeLatest } from 'redux-saga/effects';
import { GET_CANCEL_DELETE_ACCOUNT_ATTEMPT } from './actionTypes';
import { putDataToService } from '../../services/apiGatewayClient';
import { getCancelDeleteSuccess, getCancelDeleteFailure } from './actions';

import { TypedIterableIterator } from '../../modules/helpers';

function* cancelDeleteAccountAttempt(): TypedIterableIterator<any> {
  try {
    const req = yield call(putDataToService, 'account/users/deletionRequests', {}, '');

    if (req?.statusCode === 200) {
      yield put(getCancelDeleteSuccess());
    } else {
      throw new Error('Cancel Delete Account Failed');
    }
  } catch (error) {
    yield put(getCancelDeleteFailure(error));
  }
}

function* cancelDeleteAccountSaga() {
  yield all([takeLatest(GET_CANCEL_DELETE_ACCOUNT_ATTEMPT, cancelDeleteAccountAttempt)]);
}

export default cancelDeleteAccountSaga;
