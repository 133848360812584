import { createSelector } from 'reselect';
import { AppState } from '../../store/rootReducer';
import { getLoadingPlansSelector, getPlansListSelector } from '../PlanListPage/selectors';
import { Subscription } from './types';
import { Plan } from '../PlanListPage/types';

const getUnifiedCheckoutState = (state: AppState) => state.unifiedCheckout;

export const getUnifiedCheckoutSubscriptionPreviewSelector = createSelector(
  getUnifiedCheckoutState,
  state => state.subscriptionPreview,
);

export const getUnifiedCheckoutLoadingSelector = createSelector(
  getUnifiedCheckoutState,
  getLoadingPlansSelector,
  (state, isLoadingPlans) =>
    state.isPreviewLoading ||
    state.isPaymentMethodLoading ||
    state.isProviderLoading ||
    isLoadingPlans ||
    state.isSubscribeLoading ||
    state.isListSubscriptionsLoading,
);

export const getUnifiedCheckoutCurrencySelector = createSelector(getUnifiedCheckoutState, state => state.currency);

export const getUnifiedCheckoutPaymentMethodsSelector = createSelector(
  getUnifiedCheckoutState,
  state => state.paymentMethods,
);

export const getUnifiedCheckoutSubscriptionsSelector = createSelector(
  getUnifiedCheckoutState,
  state => state.subscriptions,
);

const getUnifiedCheckoutTrialPlanCodesSelector = createSelector(getUnifiedCheckoutState, state => state.trialPlanCodes);

export const createUnifiedCheckoutTrialEligibilitySelector = (planId?: string) =>
  createSelector(getUnifiedCheckoutTrialPlanCodesSelector, planCodes => (planId ? planCodes.includes(planId) : false));

export const getUnifiedCheckoutActiveSubscriptionSelector = createSelector(
  getUnifiedCheckoutSubscriptionsSelector,
  getPlansListSelector,
  (subscriptions, plans) => {
    let defaultSubscription: Subscription | undefined;
    for (const sub of subscriptions) {
      const plan = plans.find(i => i.external_plan_code === sub.plan_code);
      if (!plan?.visible) continue;
      if (!plan.default) return sub;
      else defaultSubscription = sub;
    }
    return defaultSubscription;
  },
);

export const getUnifiedCheckoutSubscriptionDeviceIdsSelector = createSelector(
  getUnifiedCheckoutSubscriptionsSelector,
  getPlansListSelector,
  (subscriptions, plans) => {
    const subscriptionPlans = subscriptions
      .map(sub => ({
        sub,
        plan: plans.find(i => i.external_plan_code === sub.plan_code),
      }))
      .filter((i): i is { sub: Subscription; plan: Plan } => i.plan?.visible === true);
    const sortedSubscriptions = subscriptionPlans
      .sort((a, b) => b.plan.billing_order - a.plan.billing_order)
      .map(i => i.sub);
    return sortedSubscriptions.flatMap(i => i.device_ids);
  },
);
