import { useEffect, useMemo, useState } from 'react';
import { Plan } from '../PlanListPage/types';
import { PaymentError, PaymentErrorType, UnifiedCheckoutSubscriptionPreview } from './types';
import { getRecurlyGooglePay } from '../../env/config_util';
import messages from './messages';

// global recurly var that is in the script
declare var recurly: any;

declare interface RecurlyGooglePay {
  initError?: unknown;
  ready: (callback: () => void) => void;
  begin: () => void;
  on: (event: string, ...args: any[]) => void;
  off: (event: string, ...args: any[]) => void;
}

const googlePayErrorMap: Record<string, { id: string; defaultMessage: string }> = {
  'google-pay-config-missing': messages['google-pay-config-missing'],
  'google-pay-not-configured': messages['google-pay-not-configured'],
  'google-pay-init-error': messages['google-pay-init-error'],
  'google-pay-not-available': messages['google-pay-not-available'],
  'google-pay-payment-failure': messages['google-pay-payment-failure'],
  'api-error': messages['api-error'],
};

const createGooglePaymentRequest = (selectedPlan: Plan, subPreview: UnifiedCheckoutSubscriptionPreview) => {
  if (!selectedPlan || !subPreview) {
    console.warn(`Google pay doesn't have needed info to initialize.`);
    return undefined;
  }

  const amount = subPreview.trial
    ? (subPreview.trial.costAfterTrial * 0.01).toFixed(2)
    : (subPreview.total * 0.01).toFixed(2);
  const result = {
    country: 'US',
    currency: subPreview.currency,
    total: amount,
    billingAddressRequired: true,
    //  If we want to add more granular detail, we can utilize the payment info object
    //   paymentInfo: {
    //     transactionInfo: {},
    //     merchantInfo: {},
    // }
  };
  // This is using the most basic form of the payment request, we may need to add more fields later
  return result;
};

export const useGooglePayCheckout = (
  brand: string,
  selectedPlan: Plan | undefined,
  subPreview: UnifiedCheckoutSubscriptionPreview | undefined,
  onPaymentComplete: (id: string) => void,
  googleButtonRef: HTMLDivElement | null,
) => {
  const googlePaymentRequest = useMemo(() => {
    if (!selectedPlan || !subPreview) {
      console.warn(
        `Google pay doesn't have needed info to initialize. If preview is undefined, this is expected.`,
        selectedPlan,
        subPreview,
      );
      return undefined;
    }
    return createGooglePaymentRequest(selectedPlan, subPreview);
  }, [selectedPlan, subPreview]);

  const [isGooglePayReady, setIsGooglePayReady] = useState(false);
  const [button, setButton] = useState<HTMLElement | undefined>(undefined);
  const [googlePayError, setGooglePayError] = useState<PaymentError | undefined>(undefined);

  /** The instance of the Google pay controlled from recurly. */
  const recurlyGooglePay = useMemo(() => {
    if (!googlePaymentRequest) {
      console.warn('Waiting for Google payment request to initialize.');
      return undefined;
    }

    const googlePay = getRecurlyGooglePay(brand);

    if (!googlePay.merchantId) {
      console.warn(`Google payment merchant ID not defined`);
      setGooglePayError({
        type: PaymentErrorType.CHECKOUT_FAILURE,
        code: 'google-pay-config-missing',
        message: googlePayErrorMap['google-pay-config-missing'],
      });
      return undefined;
    }

    const googlePayInstance: RecurlyGooglePay = recurly.GooglePay({
      ...googlePay,
      ...googlePaymentRequest,
    });
    return googlePayInstance;
  }, [googlePaymentRequest, brand]);

  useEffect(() => {
    if (!recurlyGooglePay) return;

    if (recurlyGooglePay?.initError) {
      setIsGooglePayReady(false);
      console.error('Google pay init error: ', recurlyGooglePay.initError);
      setGooglePayError({
        type: PaymentErrorType.CHECKOUT_FAILURE,
        code: 'google-pay-not-available',
        message: googlePayErrorMap['google-pay-not-available'],
      });
      return;
    }

    const inst = recurlyGooglePay;
    const handler = (token: { id: string; type: string }) => {
      onPaymentComplete(token.id);
    };
    const errorHandler = (err: any) => {
      const errMessageToDisplay = googlePayErrorMap[err.code];
      console.error('google pay error:', err, err?.code, errMessageToDisplay, err?.fields?.join(', '));
      setGooglePayError({
        type: PaymentErrorType.PAYMENT_FAILURE,
        code: err.code,
        message: errMessageToDisplay,
      });
    };
    const readyHandler = (googlePayButton: any) => {
      console.log('Google pay is ready');
      setGooglePayError(undefined);
      setIsGooglePayReady(true);
      setButton(googlePayButton);
    };
    inst.on('ready', readyHandler);
    inst.on('token', handler);
    inst.on('error', errorHandler);
    return () => {
      inst.off('token', handler);
      inst.off('error', errorHandler);
    };
  }, [onPaymentComplete, recurlyGooglePay]);

  useEffect(() => {
    if (!isGooglePayReady || !button) return;
    googleButtonRef?.appendChild(button);
  }, [isGooglePayReady, button, googleButtonRef]);

  return { isGooglePayReady, googlePayError };
};
