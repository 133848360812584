import { CheckoutSessionActionTypes } from './types';

import {
  CREATE_CHECKOUT_SESSION_ATTEMPT,
  CREATE_CHECKOUT_SESSION_SUCCESS,
  CREATE_CHECKOUT_SESSION_FAILURE,
  CLEAR_CHECKOUT_SESSION_STATE,
} from './actionTypes';

export const createCheckoutSessionAttempt = (): CheckoutSessionActionTypes => ({
  type: CREATE_CHECKOUT_SESSION_ATTEMPT,
});

export const createCheckoutSessionSuccess = (checkoutSessionUrl: string): CheckoutSessionActionTypes => ({
  type: CREATE_CHECKOUT_SESSION_SUCCESS,
  checkoutSessionUrl: checkoutSessionUrl,
});

export const createCheckoutSessionFailure = (error: any): CheckoutSessionActionTypes => ({
  type: CREATE_CHECKOUT_SESSION_FAILURE,
  error: error,
});

export const clearCheckoutSessionState = (): CheckoutSessionActionTypes => ({
  type: CLEAR_CHECKOUT_SESSION_STATE,
});
