/*
 * DeleteAccountModalRequest Messages
 *
 * This contains all the text for the DeleteAccountModalRequest component.
 */
import { defineMessages } from 'react-intl';

export default defineMessages({
  modalTitle: {
    id: 'app.containers.DeleteAccountModalRequest.modalTitle.text',
    defaultMessage: 'Are you sure you want to delete your account?',
  },
  deleteAccountStatement: {
    id: 'app.containers.DeleteAccountModalRequest.delete.text',
    defaultMessage:
      'When you submit a request to delete your account your {partnerName} products and services will no longer work. The following will occur once you submit your request:',
  },
  deleteAccountButton: {
    id: 'app.containers.DeleteAccountModalRequest.deleteAccountButton.text',
    defaultMessage: 'Delete Account',
  },
  cancelDeleteAccountButton: {
    id: 'app.containers.DeleteAccountModalRequest.cancelDeleteAccountButton.text',
    defaultMessage: 'Cancel',
  },
  deleteAccountBullet1: {
    id: 'app.containers.DeleteAccountModalRequest.deleteAccountBullet1.text',
    defaultMessage: 'You will be immediately signed out of the app and will not be able to sign back in. ',
  },
  deleteAccountBullet2: {
    id: 'app.containers.DeleteAccountModalRequest.deleteAccountBullet2.text',
    defaultMessage: 'Your rules will be turned OFF and you will no longer receive notifications. ',
  },
  deleteAccountBullet3: {
    id: 'app.containers.DeleteAccountModalRequest.deleteAccountBullet3.text',
    defaultMessage: 'An email will be sent to you confirming you want to delete your account. ',
  },
  deleteAccountBullet4: {
    id: 'app.containers.DeleteAccountModalRequest.deleteAccountBullet4.text',
    defaultMessage:
      'If you don’t cancel your delete account request your account including information and data will be deleted from our system. ',
  },
});
