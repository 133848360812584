import React from 'react';
import { FormattedNumber } from 'react-intl';

const CurrencyFormatter = ({ value, style = 'currency', currency }: any) => {
  const convertCents = (value: number) => {
    let results = 0;

    if (value) {
      results = value / 100;
    }
    return results;
  };

  return <FormattedNumber value={convertCents(value)} style={style} currency={currency ?? 'USD'} />;
};

export default CurrencyFormatter;
