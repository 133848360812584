import React, { useEffect } from 'react';

//material ui
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

//third party lib
import { injectIntl, FormattedMessage } from 'react-intl';
import { withRouter } from 'react-router';
import messages from './messages';
import queryString from 'query-string';
import Container from '@material-ui/core/Container';

//utils
import { parseLocationForEmail } from '../../utils/pepperUtils';

//redux
import { useSelector, useDispatch } from 'react-redux';
import { verifyEmailAttemptAction } from './actions';
import { getBrandNameSelector, getLongBrandNameSelector, getBrandImagesSelector } from '../BrandProvider/selectors';
import {
  getVerifyEmailFailedSelector,
  getVerifyEmailSuccessSelector,
  getAppUriObjSelector,
  getVerifyEmailErrorSelector,
} from './selectors';

//images
import redFailureImage from '../../themes/common/images/alert-circle-red.svg';

interface Props {
  location: any;
  history: any;
  match: any;
  intl: any;
}

const brandCapFirst = (brand: string) => {
  return brand.charAt(0).toUpperCase() + brand.slice(1);
};

const VerifyEmail = (props: Props) => {
  const dispatch = useDispatch();

  const verifyEmailFailed = useSelector(getVerifyEmailFailedSelector);
  const verifyEmailSuccess = useSelector(getVerifyEmailSuccessSelector);
  const verifyEmailError = useSelector(getVerifyEmailErrorSelector);
  const appUriObj = useSelector(getAppUriObjSelector);
  const brand = useSelector(getBrandNameSelector);
  const brandName = useSelector(getLongBrandNameSelector);
  const images = useSelector(getBrandImagesSelector);

  const isiOS = (): boolean => {
    return (
      window.navigator.userAgent.includes('iOS') ||
      window.navigator.userAgent.includes('iPhone') ||
      window.navigator.userAgent.includes('iPad')
    );
  };

  const isAndroid = () => {
    return window.navigator.userAgent.includes('Android');
  };

  const confirmOpenApp = () => {
    if (appUriObj) {
      const appUri = appUriObj.appUri;
      const uriScheme = appUriObj.uriScheme;
      if (isiOS()) {
        window.location.href = `${uriScheme}://`;
      } else {
        window.location.href = `intent:${appUri}/#Intent;scheme=${uriScheme};package=${appUri};launchFlags=268435456;end;`;
      }
    }
  };

  const determineResponse = () => {
    if (verifyEmailFailed) {
      return isFailure();
    } else if (verifyEmailSuccess) {
      return isSuccess();
    }
    return (
      <div className={`${brand}-progress-wrapper`}>
        <CircularProgress size={55} className={`${brand}-button-progress`} />
      </div>
    );
  };

  const isFailure = () => {
    if (!verifyEmailFailed) {
      return false;
    }

    const mapErrorToMessage: any = {
      'The email verification link has already been used': {
        primary: messages.verificationFailureAlreadyUsedTitle,
        secondary: messages.verificationFailureAlreadyUsedSubtitle,
      },
      'Invalid email verification link': {
        primary: messages.verificationFailureInvalidTitle,
        secondary: messages.verificationFailureYouCanSubtitle,
      },
      'Email verification link has expired': {
        primary: messages.verificationFailureExpiredTitle,
        secondary: messages.verificationFailureYouCanSubtitle,
      },
    };

    const errorMessageObject =
      mapErrorToMessage[verifyEmailError] === undefined
        ? mapErrorToMessage['Invalid email verification link']
        : mapErrorToMessage[verifyEmailError];
    let src = redFailureImage;

    return (
      <div style={{ margin: 50 }}>
        <img src={src} alt="exclamation-point" />
        <Typography variant="h5" style={{ fontWeight: 'bolder', marginTop: 20 }}>
          {<FormattedMessage {...errorMessageObject.primary} />}
        </Typography>
        <Typography variant="body1" style={{ marginTop: 20 }}>
          {<FormattedMessage {...errorMessageObject.secondary} values={{ brand: brandCapFirst(brandName) }} />}
        </Typography>
      </div>
    );
  };

  const isMobile = () => {
    if (!isAndroid() && !isiOS()) {
      return false;
    }
    return (
      <div>
        <Button
          onClick={confirmOpenApp}
          className={`${brand}-btn ${brand}-btn-action `}
          style={{
            width: 265,
            minWidth: 200,
            textTransform: 'none',
            height: 42,
            fontWeight: 'bolder',
          }}
          id="open-app"
        >
          <FormattedMessage {...messages.verificationOpenAppBrand} values={{ brand: brandCapFirst(brandName) }} />
        </Button>
      </div>
    );
  };

  const isSuccess = () => {
    if (!verifyEmailSuccess) {
      return false;
    }

    return (
      <div style={{ margin: 50 }}>
        <img src={images['complete']} alt="check-mark" />
        <Typography variant="h5" style={{ fontWeight: 'bolder', marginTop: 20 }}>
          {<FormattedMessage {...messages.verificationSuccessTitle} />}
        </Typography>
        <Typography variant="body1" style={{ marginTop: 20 }}>
          {
            <FormattedMessage
              {...messages.verificationSuccessEnjoyUsingBrand}
              values={{ brand: brandCapFirst(brandName) }}
            />
          }
        </Typography>
      </div>
    );
  };

  useEffect(() => {
    if (verifyEmailFailed || verifyEmailSuccess) {
      console.log(
        `Will not attempt to verify - we have already called to verify. Failed: ${verifyEmailFailed}, Succeeded: ${verifyEmailSuccess}`,
      );
      return;
    }

    const verificationToken = queryString.parse(props.location.search).vtoken;
    const maybeEmail = parseLocationForEmail(props.location.search);
    dispatch(verifyEmailAttemptAction(brand, verificationToken, maybeEmail));
  }, [props, dispatch, brand, verifyEmailFailed, verifyEmailSuccess]);

  return (
    <Container
      maxWidth="sm"
      style={{
        textAlign: 'center',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <div style={{ justifyContent: 'center', alignItems: 'center' }}>{determineResponse()}</div>

      <div className="white-background-page-split">{isMobile()}</div>
    </Container>
  );
};

export default injectIntl(withRouter(VerifyEmail));
