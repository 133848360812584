/*
 * Reset Password Messages
 */
import { defineMessages } from 'react-intl';

export default defineMessages({
  resetPasswordPageTitle: {
    id: 'resetPassword.page.title',
    defaultMessage: 'Reset Password',
  },
  resetPasswordPageSubtitle: {
    id: 'resetPassword.page.subtitle',
    defaultMessage: 'Think of a strong password and enter it below.',
  },
  resetPasswordInputPlaceholder: {
    id: 'resetPassword.input.placeholder',
    defaultMessage: 'New Password',
  },
  resetPasswordConfirmInputPlaceholder: {
    id: 'resetPassword.confirm.input.placeholder',
    defaultMessage: 'Confirm Password',
  },
  resetPasswordInputErrorMinLength: {
    id: 'resetPassword.input.error.minLength',
    defaultMessage: 'Password must be at least 8 characters',
  },
  resetPasswordInputErrorMissingUppercase: {
    id: 'resetPassword.input.error.missingUppercase',
    defaultMessage: 'Password must contain at least one uppercase letter',
  },
  resetPasswordInputErrorMissingLowercase: {
    id: 'resetPassword.input.error.missingLowercase',
    defaultMessage: 'Password must contain at least one lowercase letter',
  },
  resetPasswordInputErrorMissingNumber: {
    id: 'resetPassword.input.error.missingNumber',
    defaultMessage: 'Password must contain at least one number',
  },
  resetPasswordInputErrorMissingSpecialChar: {
    id: 'resetPassword.input.error.missingSpecialChar',
    defaultMessage: 'Password must contain at least one special character',
  },
  resetPasswordInputErrorPasswordsMatch: {
    id: 'resetPassword.input.error.passwordsMatch',
    defaultMessage: 'Entered passwords don’t match',
  },
  resetPasswordErrorInvalidTokenTitle: {
    id: 'resetPassword.error.invalidToken.title',
    defaultMessage: 'The password reset link is invalid',
  },
  resetPasswordErrorInvalidTokenSubtitle: {
    id: 'resetPassword.error.invalidToken.subtitle',
    defaultMessage:
      'The password reset link is malformed. Please go to the { brand } App and submit a new request if you would like to reset your password.',
  },
  resetPasswordErrorLinkAlreadyUsedTitle: {
    id: 'resetPassword.error.linkAlreadyUsed.title',
    defaultMessage: 'The password reset link has already been used',
  },
  resetPasswordErrorLinkAlreadyUsedSubtitle: {
    id: 'resetPassword.error.linkAlreadyUsed.subtitle',
    defaultMessage:
      'Each link can only be used once. Please go to the { brand } App and submit a new request if you would like to reset your password.',
  },
  resetPasswordErrorPasswordsTooSimilar: {
    id: 'resetPassword.error.passwordsTooSimilar',
    defaultMessage: 'Your new password is too similar to your old passwords. Please enter a different password.',
  },
  resetPasswordSuccessTitle: {
    id: 'resetPassword.success.title',
    defaultMessage: 'Congrats',
  },
  resetPasswordSuccessSubtitle: {
    id: 'resetPassword.success.subtitle',
    defaultMessage: 'Your password has been successfully reset.',
  },
  openAppBrand: {
    id: 'verifyEmail.button.text.openApp',
    defaultMessage: 'Open { brand } App',
  },
  confirmModalTitle: {
    id: 'verifyEmail.modal.confirmOpen.text.title',
    defaultMessage: 'Open in "{ brand }"',
  },
  confirmModalCancelButton: {
    id: 'verifyEmail.modal.confirmOpen.button.cancel',
    defaultMessage: 'Cancel',
  },
  confirmModalOpenButton: {
    id: 'verifyEmail.modal.confirmOpen.button.open',
    defaultMessage: 'Open',
  },
});
