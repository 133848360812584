/*
 * Verify Email Messages
 */
import { defineMessages } from 'react-intl';

export default defineMessages({
  verificationOpenAppBrand: {
    id: 'verifyEmail.button.text.openApp',
    defaultMessage: 'Open { brand } App',
  },
  verificationConfirmModalCancelButton: {
    id: 'verifyEmail.modal.confirmOpen.button.cancel',
    defaultMessage: 'Cancel',
  },
  verificationConfirmModalOpenButton: {
    id: 'verifyEmail.modal.confirmOpen.button.open',
    defaultMessage: 'Open',
  },
  verificationSuccessEnjoyUsingBrand: {
    id: 'verifyEmail.text.success.brand',
    defaultMessage: 'Return to the mobile app to enjoy using your { brand } products',
  },
  verificationSuccessTitle: {
    id: 'verifyEmail.text.successTitle',
    defaultMessage: 'Verification Success',
  },
  verificationFailureAlreadyUsedTitle: {
    id: 'verifyEmail.text.failureAlreadyUsedTitle',
    defaultMessage: 'The email verification link has already been used',
  },
  verificationFailureAlreadyUsedSubtitle: {
    id: 'verifyEmail.text.failureAlreadyUsedSubtitle',
    defaultMessage:
      "Each link can only be used once. You can log in to the { brand } app if you already verified your email address OR use the 'Forgot Password' option",
  },
  verificationFailureInvalidTitle: {
    id: 'verifyEmail.text.failureInvalidTitle',
    defaultMessage: 'The email verification link is invalid',
  },
  verificationFailureYouCanSubtitle: {
    id: 'verifyEmail.text.failureYouCanSubtitle',
    defaultMessage:
      "You can log in to the { brand } app if you have already verified your email address OR use the 'Forgot Password' option.",
  },
  verificationFailureExpiredTitle: {
    id: 'verifyEmail.text.failureExpiredTitle',
    defaultMessage: 'The email verification link has expired',
  },
});
