import { all, call, put, takeLatest } from 'redux-saga/effects';
import { push } from 'react-router-redux';
import {
  SSO_AUTHENTICATION_ATTEMPT,
  SSO_AUTHENTICATION_SUCCESS,
  SSO_SUBSCRIPTIONS_DEVICES_SUCCESS,
  PLAN_CONFIGS_SUCCESS,
  CLEAR_STATE,
} from './actionTypes';
import {
  ssoAuthSuccess,
  ssoAuthFailure,
  getPlanConfigSuccess,
  getPlanConfigFailure,
  subscriptionsWithDevicesFailure,
  subscriptionsWithDevicesSuccess,
} from './actions';
import { TypedIterableIterator } from '../../modules/helpers';
import { SelectState } from '../../modules/helpers';
import { getDataFromService } from '../../services/apiGatewayClient';
import { createHttpClient } from '../../services/httpRequest';
import { setSelectedPlan, setCurrentPlan } from '../PlanListPage/actions';
import { setDevicesAsAssigned } from '../ManageDevices/actions';

import {
  getPlansConfigSelector,
  getSsoFromPlanCodeSelector,
  getSsoToPlanCodeSelector,
  getSsoNextPathSelector,
} from './selectors';
import { getBrandNameSelector } from '../BrandProvider/selectors';
import { getBillingInformationExistsSelector } from '../BillingInformationView/selectors';
import { getSubscriptionPlansSelector } from '../SubscriptionsList/selectors';

import AWS from 'aws-sdk';
import { SsoAuthAttemptType } from './types';
import { SET_PREV_PATH } from '../PathName/actionTypes';
import queryString from 'query-string';

AWS.config.update({ region: 'us-east-2' });

function createAuthorizationHeader(token: any, brand: string) {
  return 'Basic ' + Buffer.from(`${brand}:${token}`).toString('base64');
}

function callEndpoint(token: any, brand: string) {
  const basicAuthHeader = createAuthorizationHeader(token, brand);

  const httpClient = createHttpClient(brand, 'authentication');
  return httpClient
    .post(
      '/byToken',
      {},
      {
        headers: { Authorization: basicAuthHeader },
      },
    )
    .then(function (res) {
      if (!res || !res.data || res.data.errorMessage) {
        throw new Error('invalid login');
      }
      return res.data;
    })
    .catch(function (error) {
      throw error;
    });
}

function* attemptSsoAuth(payload: SsoAuthAttemptType): TypedIterableIterator<any> {
  const paramsQuery = queryString.parse(payload.location.search);
  const { token, toPlanCode, fromPlanCode, nextPath } = paramsQuery;

  yield put({ type: CLEAR_STATE });

  try {
    if (token) {
      yield put({ type: SET_PREV_PATH, prevPathName: '/sso-redirect' });
      const brand: string = yield SelectState<string>(getBrandNameSelector);
      const results = yield call(callEndpoint, token, brand);
      yield put(ssoAuthSuccess(null, results, toPlanCode, fromPlanCode, nextPath));
    } else {
      yield put(push('/login'));
    }
  } catch (err) {
    yield put(ssoAuthFailure(err));
    yield put(push('/login'));
  }
}

function* attemptPlanConfigs(): TypedIterableIterator<any> {
  try {
    const result = yield call(getDataFromService, '/listAvailableSubscriptions', 'subscriptions');
    yield put(getPlanConfigSuccess(result));
  } catch (err) {
    yield put(getPlanConfigFailure(err));
    yield put(push('/login'));
  }
}

function* getSubscriptionList(): TypedIterableIterator<any> {
  try {
    const subscriptionPlans = yield call(getDataFromService, '/subscriptions', 'account');
    yield put(subscriptionsWithDevicesSuccess(subscriptionPlans));
  } catch (err) {
    yield put(subscriptionsWithDevicesFailure(err));
    yield put(push('/login'));
  }
}

function* determineSsoRedirect(): TypedIterableIterator<any> {
  const nextPath: any = yield SelectState<any>(getSsoNextPathSelector);

  if (nextPath && nextPath !== 'undefined') {
    console.log('Routing to next path: ', nextPath);
    yield put(push(nextPath.charAt(0) === '/' ? nextPath : `/${nextPath}`));
  } else {
    yield ssoRedirectSubscriptionFlow();
  }
}

function* ssoRedirectSubscriptionFlow(): TypedIterableIterator<any> {
  const planConfigs: any = yield SelectState<any>(getPlansConfigSelector);
  const toPlanCode: any = yield SelectState<any>(getSsoToPlanCodeSelector);
  const fromPlanCode: any = yield SelectState<any>(getSsoFromPlanCodeSelector);
  const billingInfoExists: any = yield SelectState<any>(getBillingInformationExistsSelector);

  const userSubscriptions: any = yield SelectState<any>(getSubscriptionPlansSelector);

  const toPlanConfig = planConfigs?.find((planConfig: any) => {
    return planConfig.external_plan_code === toPlanCode;
  });

  const fromPlanSubscription = userSubscriptions?.find((subscription: any) => {
    return subscription.plan_config && subscription.plan_config.external_plan_code === fromPlanCode;
  });

  if (!toPlanConfig || !fromPlanSubscription) {
    console.log('Cannot find subscriptions for the to/from plan codes provided');
    yield put(push('/subscriptions'));
    return;
  }

  // set data for other flow pages
  yield put(setSelectedPlan(toPlanConfig));
  yield put(setCurrentPlan(fromPlanSubscription.plan_config));

  const accountDevices = userSubscriptions.reduce((devices: any, subscription: any) => {
    devices.push(...subscription.devices);
    return devices;
  }, []);

  if (accountDevices.length > toPlanConfig.device_quantity) {
    yield put(push('/manage-devices'));
  } else if (accountDevices.length <= toPlanConfig.device_quantity) {
    yield put(setDevicesAsAssigned(accountDevices, []));
    if (billingInfoExists) {
      yield put(push('/order-summary'));
    } else {
      yield put(push('/billing'));
    }
  } else {
    yield put(push('/subscriptions'));
  }
}

function* ssoAuthenticationRedirectSaga() {
  yield all([takeLatest(SSO_AUTHENTICATION_ATTEMPT, attemptSsoAuth)]);
  yield all([takeLatest(SSO_SUBSCRIPTIONS_DEVICES_SUCCESS, attemptPlanConfigs)]);
  yield all([takeLatest(SSO_AUTHENTICATION_SUCCESS, getSubscriptionList)]);
  yield all([takeLatest(PLAN_CONFIGS_SUCCESS, determineSsoRedirect)]);
}

export default ssoAuthenticationRedirectSaga;
