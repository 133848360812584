import React, { useEffect } from 'react';

//redux
import { useSelector, useDispatch } from 'react-redux';
import { getBillingInfoAttempt } from './actions';
import { getPaymentInfoAttemptSelector, getPaymentInfoSelector, getPaymentInfoErrorSelector } from './selectors';

//material ui
import CircularProgress from '@material-ui/core/CircularProgress';
import { getBrandNameSelector } from '../BrandProvider/selectors';
import PaymentInfoControl from './PaymentInfoControl';
import { getSubscriptionPlansSelector } from '../SubscriptionsList/selectors';
import history from '../../history';

export const PaymentInformation = () => {
  const dispatch = useDispatch();
  const loading = useSelector(getPaymentInfoAttemptSelector);
  const paymentInfo = useSelector(getPaymentInfoSelector);
  const brand = useSelector(getBrandNameSelector);
  const subscriptions = useSelector(getSubscriptionPlansSelector);
  const paymentInfoError = useSelector(getPaymentInfoErrorSelector);

  useEffect(() => {
    const fetchPlans = async () => {
      await dispatch(getBillingInfoAttempt());
    };

    fetchPlans();
  }, [dispatch]);

  useEffect(() => {
    const infoError = async () => {
      if (paymentInfoError) {
        history.push('./error');
      }
    };

    infoError();
  }, [paymentInfoError]);

  return (
    <>
      {loading || paymentInfo === null ? (
        <div className="progress-wrapper-center">
          <CircularProgress size={55} className={`${brand}-button-progress`} />
        </div>
      ) : (
        <PaymentInfoControl
          brand={brand}
          paymentInfo={paymentInfo}
          subscriptions={subscriptions}
          paymentInfoError={paymentInfoError}></PaymentInfoControl>
      )}
    </>
  );
};
