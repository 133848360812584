import { VerifyEmailTypes, IVerifyEmail } from './types';

import {
  APP_URI_FAILURE,
  APP_URI_SUCCESS,
  VERIFY_EMAIL_ATTEMPT,
  VERIFY_EMAIL_FAILURE,
  VERIFY_EMAIL_SUCCESS,
  CLEAR_STATE,
} from './actionTypes';

export const initialState: IVerifyEmail = {
  appUriObj: null,
  attempting: false,
  error: null,
  failed: false,
  success: false,
};

export const VerifyEmailReducer = (state = initialState, action: VerifyEmailTypes): IVerifyEmail => {
  switch (action.type) {
    case APP_URI_FAILURE:
      return {
        ...state,
        appUriObj: null,
      };
    case APP_URI_SUCCESS:
      return {
        ...state,
        appUriObj: action.appUriObj,
      };
    case VERIFY_EMAIL_ATTEMPT:
      return {
        ...state,
        error: null,
        failed: false,
        success: false,
        attempting: true,
      };
    case VERIFY_EMAIL_FAILURE:
      return {
        ...state,
        error: action.error,
        failed: true,
        success: false,
        attempting: false,
      };
    case VERIFY_EMAIL_SUCCESS:
      return {
        ...state,
        error: null,
        failed: false,
        success: true,
        attempting: false,
      };
    case CLEAR_STATE:
      return initialState;
    default:
      return state;
  }
};
