import React from 'react';
import Typography from '@material-ui/core/Typography';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { getMfaErrorSelector, getMfaMessageBody, getMfaMessageTitle } from '../selectors';
import { makeStyles } from '@material-ui/core/styles';
import { EMAIL_VERIFICATION_REQUEST_DISABLED_MINUTES } from '../../../constants/authentication';

interface MfaMessagesProps {
  brand: string;
  contact: string;
  wrapperClass?: string;
}

const useStyles = makeStyles(theme => ({
  title: {
    marginBottom: theme.spacing(2),
  },
  errorTitle: {
    color: theme.palette.warning.main,
  },
}));
export const MfaMessages: React.FC<MfaMessagesProps> = ({ brand, contact, wrapperClass }) => {
  let messageTitle = useSelector(getMfaMessageTitle);
  let messageBody = useSelector(getMfaMessageBody);
  let error = useSelector(getMfaErrorSelector);

  const classes = useStyles();

  const bodyValues = {
    contact: contact,
    brand: brand,
    EMAIL_VERIFICATION_REQUEST_DISABLED_MINUTES,
  };

  return (
    <div className={wrapperClass}>
      <Typography className={`${classes.title} ${!error || classes.errorTitle}`} align="center" variant="h5">
        <FormattedMessage {...messageTitle} />
      </Typography>
      <Typography align="center" variant="body1">
        <FormattedMessage {...messageBody} values={bodyValues} />
      </Typography>
    </div>
  );
};
