import { createSelector } from 'reselect';

import { AppState } from '../../store/rootReducer';
import mfaMessages from './multiFactorAuthentication/messages';
import messages from './messages';
import {
  isRequestLimitError,
  isAccountLockedError,
  isLimitExceededError,
  isMFAExpiredError,
  isUnverifiedError,
  isOAuthMissingParamsError,
} from './errorUtils';

const getUserName = (state: AppState) => state.authentication.username;
const getPassword = (state: AppState) => state.authentication.password;
const getRememberMe = (state: AppState) => state.authentication.rememberMe;
const getIsAttempting = (state: AppState) => state.authentication.attempting;
const getProfile = (state: AppState) => state.authentication.profile;
const getErrorCode = (state: AppState) => state.authentication.errorCode;
const getSsoToken = (state: AppState) => state.authentication.token;
const getAttemptingSso = (state: AppState) => state.authentication.ssoAttempting;
const getMfaData = (state: AppState) => state.authentication.mfaData;
const getUserMFASettings = (state: AppState) => state.authentication.userMFASettings;
const getMfaResendingData = (state: AppState) => state.authentication.mfaResendData;
const getOAuthParameters = (state: AppState) => state.authentication.oauthParameters;
const getAuthCode = (state: AppState) => state.authentication.authCode;
const getIsOAuthLogin = (state: AppState) => state.authentication.isOAuthLogin;
const getAccountLinkingPartner = (state: AppState) => state.authentication.accountLinkingPartner;

export const getIsAuthenticatedSelector = (state: AppState) => state.authentication.authenticated;

export const getUserNameSelector = createSelector(getUserName, name => name);

export const getIsPendingDeleteSelector = createSelector(getProfile, profile => profile?.pepperUser?.account?.status);

export const getPasswordSelector = createSelector(getPassword, password => password);

export const getRememberMeSelector = createSelector(getRememberMe, rememberMe => rememberMe);

export const getIsAttemptingSelector = createSelector(getIsAttempting, attempting => attempting);

export const getProfileSelector = createSelector(getProfile, profile => profile);
export const getAccountIdSelector = createSelector(getProfileSelector, profile => {
  return profile?.pepperUser?.account_id;
});

export const getErrorSelector = createSelector(getErrorCode, err => err);

export const getMfaErrorSelector = createSelector(
  getErrorSelector,
  getMfaResendingData,
  (error, mfaResend) => error ?? mfaResend?.errorCode,
);

export const getErrorStatusCode = createSelector(getErrorSelector, error => error?.data?.statusCode || error);

export const getMfaErrorStatusCode = createSelector(getMfaErrorSelector, error => error?.data?.statusCode || error);

export const getLoginErrorMessage = createSelector(getErrorStatusCode, errorCode => {
  if (isUnverifiedError(errorCode)) {
    return messages.signInVerifyEmailError;
  } else if (isLimitExceededError(errorCode)) {
    return messages.signInTooManyResendsError;
  } else if (isOAuthMissingParamsError(errorCode)) {
    return messages.oauthMissingParametersError;
  }
  return messages.signInError;
});

export const getIsRequestLimitExceeded = createSelector(getMfaErrorStatusCode, isRequestLimitError);

export const getMfaMessageTitle = createSelector(getMfaErrorStatusCode, errorCode => {
  if (!errorCode) return mfaMessages.defaultMessageTitle;
  if (isAccountLockedError(errorCode)) {
    return mfaMessages.errorCodeLimitExceededTitle;
  } else if (isLimitExceededError(errorCode)) {
    return mfaMessages.errorInvalidLimitExceededTitle;
  } else if (isMFAExpiredError(errorCode)) {
    return mfaMessages.errorExpiredTokenTitle;
  }
  return mfaMessages.errorInvalidTokenTitle;
});

export const getMfaMessageBody = createSelector(getMfaErrorStatusCode, errorCode => {
  if (!errorCode) return mfaMessages.defaultMessageBody;
  if (isAccountLockedError(errorCode)) {
    return mfaMessages.errorCodeLimitExceededBody;
  } else if (isLimitExceededError(errorCode)) {
    return mfaMessages.errorInvalidLimitExceededBody;
  } else if (isMFAExpiredError(errorCode)) {
    return mfaMessages.errorExpiredTokenBody;
  }
  return mfaMessages.errorInvalidTokenBody;
});

export const getSsoTokenSelector = createSelector(getSsoToken, token => token);

export const getAttemptingSsoSelector = createSelector(getAttemptingSso, ssoAttempting => ssoAttempting);

export const getMfaDataSelector = createSelector(getMfaData, mfaData => mfaData);

export const getUserMFASettingsSelector = createSelector(getUserMFASettings, userMFASettings => userMFASettings);
export const getResendingSelector = createSelector(getMfaResendingData, mfaResend => mfaResend?.loading);
export const getResendSuccessSelector = createSelector(getMfaResendingData, mfaResend => mfaResend?.resent);
export const getResendErrorCodeSelector = createSelector(getMfaResendingData, mfaResend => mfaResend?.errorCode);

export const getResendMessageSelector = createSelector(getMfaResendingData, mfaResend => {
  if (mfaResend?.resent && !mfaResend?.errorCode && !mfaResend?.loading) {
    return mfaMessages.resendCodeSuccess;
  } else if (!!mfaResend?.errorCode && !mfaResend?.resent && !mfaResend?.loading) {
    return mfaMessages.resendCodeError;
  }
  return undefined;
});

export const getMfaContactDisplay = createSelector(getMfaData, mfaData => {
  if (mfaData?.contactType === 'EMAIL') {
    return obfuscateEmail(mfaData.email || '');
  } else if (mfaData?.contactType === 'PHONE') {
    return obfuscatePhone(mfaData.phoneNumber || '');
  }
  return '';
});

const obfuscateEmail = (email: string) => {
  const obfRegex = /(\w)(.+?)(@)(\w)(.+?)(.\w+$)/i;
  let regexArray = obfRegex.exec(email);
  if (!regexArray) return '';
  const [, uStart, , , pStart, , com] = regexArray;
  return `${uStart}*****@${pStart}*****${com}`;
};

const obfuscatePhone = (phone: string) => {
  let last2 = phone.slice(-2);
  if (!last2) return '';
  // US Format
  return `(xxx) xxx-xx${last2}`;
};

export const getProfileCountry = createSelector(getProfile, profile => profile.countryCode);

// OAuth Selectors
export const getIsOAuthLoginSelector = createSelector(getIsOAuthLogin, isOAuthLogin => isOAuthLogin);
export const getAuthCodeSelector = createSelector(getAuthCode, authCode => authCode);
export const getOAuthParametersSelector = createSelector(getOAuthParameters, oauthParameters => oauthParameters);
export const getAccountLinkingPartnerSelector = createSelector(getAccountLinkingPartner, partner => partner);
