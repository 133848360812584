import { call, put, takeEvery, takeLatest } from 'redux-saga/effects';
import { getRecurlyPublicKey, getRecurlyUrl } from '../../env/config_util';
import { SelectState, TypedIterableIterator } from '../../modules/helpers';
import { getDataFromService, postDataToService } from '../../services/apiGatewayClient';
import { NativeDispatch } from '../../utils/webViewEvents/nativeDispatch';
import {
  UNIFIED_CHECKOUT_GET_PAYMENT_METHODS_ATTEMPT,
  UNIFIED_CHECKOUT_GET_PAYMENT_METHODS_FAILURE,
  UNIFIED_CHECKOUT_INITIALIZE_PROVIDER_ATTEMPT,
  UNIFIED_CHECKOUT_INITIALIZE_PROVIDER_FAILURE,
  UNIFIED_CHECKOUT_LIST_ACCOUNT_SUBSCRIPTIONS_ATTEMPT,
  UNIFIED_CHECKOUT_LIST_ACCOUNT_SUBSCRIPTIONS_FAILURE,
  UNIFIED_CHECKOUT_SUBSCRIPTION_CONFIRM_ATTEMPT,
  UNIFIED_CHECKOUT_SUBSCRIPTION_CONFIRM_FAILURE,
  UNIFIED_CHECKOUT_SUBSCRIPTION_PREVIEW_ATTEMPT,
  UNIFIED_CHECKOUT_SUBSCRIPTION_PREVIEW_FAILURE,
} from './actionTypes';
import {
  unifiedCheckoutGetPaymentMethodsAttempt,
  unifiedCheckoutGetPaymentMethodsSuccess,
  unifiedCheckoutInitializeProviderAttempt,
  unifiedCheckoutInitializeProviderSuccess,
  unifiedCheckoutListAccountSubscriptionsAttempt,
  unifiedCheckoutListAccountSubscriptionsFailure,
  unifiedCheckoutListAccountSubscriptionsSuccess,
  unifiedCheckoutSubscriptionConfirmAttempt,
  unifiedCheckoutSubscriptionConfirmFailure,
  unifiedCheckoutSubscriptionConfirmSuccess,
  unifiedCheckoutSubscriptionPreviewAttempt,
  unifiedCheckoutSubscriptionPreviewFailure,
  unifiedCheckoutSubscriptionPreviewSuccess,
} from './actions';
import { PaymentMethod, Subscription } from './types';
import { getSsoSourceSelector } from '../SsoIsolated/selectors';

const $script = require('scriptjs');
declare var recurly: any;

function createPreviewRequestBody(
  externalPlanCode: string,
  selectedBillingInfo:
    | {
        address1: string;
        address2: string;
        city: string;
        state: string;
        postal_code: string;
        country: string;
      }
    | undefined,
  currency: string,
) {
  let previewRequest = {
    plan_code: externalPlanCode,
    currency: currency,
    account: {
      account_code: '',
      billing_info: selectedBillingInfo ?? {},
    },
  };

  return previewRequest;
}

function mapSubscriptionPreview(subscriptionResponse: {
  currency?: string | null;
  subTotal?: string | number | null;
  tax?: string | number | null;
  total?: string | number | null;
  chargeDate?: string | null;
  trial?: {
    trialEndDate?: string | null;
    costAfterTrial?: string | number | null;
  } | null;
}) {
  return {
    currency: subscriptionResponse.currency ?? 'USD',
    subTotal:
      typeof subscriptionResponse.subTotal === 'string'
        ? parseFloat(subscriptionResponse.subTotal)
        : subscriptionResponse.subTotal || 0,
    tax:
      typeof subscriptionResponse.tax === 'string'
        ? parseFloat(subscriptionResponse.tax)
        : subscriptionResponse.tax || 0,
    total:
      typeof subscriptionResponse.total === 'string'
        ? parseFloat(subscriptionResponse.total)
        : subscriptionResponse.total || 0,
    chargeDate: subscriptionResponse.chargeDate ?? new Date().toISOString(),
    trial: subscriptionResponse.trial?.trialEndDate
      ? {
          trialEndDate: subscriptionResponse.trial.trialEndDate,
          costAfterTrial:
            typeof subscriptionResponse.trial.costAfterTrial === 'string'
              ? parseFloat(subscriptionResponse.trial.costAfterTrial)
              : subscriptionResponse.trial.costAfterTrial || 0,
        }
      : undefined,
  };
}

function* getRecurlyPreview(
  action: ReturnType<typeof unifiedCheckoutSubscriptionPreviewAttempt>,
): TypedIterableIterator<any> {
  const recurlySubscriptionPreviewRequest = createPreviewRequestBody(
    action.payload.externalPlanCode,
    undefined,
    action.payload.currency,
  );

  try {
    const subscriptionResponse = yield call(
      postDataToService,
      '/preview',
      recurlySubscriptionPreviewRequest,
      'subscriptions',
    );
    if (!subscriptionResponse) {
      yield put(unifiedCheckoutSubscriptionPreviewFailure());
    } else {
      const subscriptionPreview = mapSubscriptionPreview(subscriptionResponse);
      yield put(unifiedCheckoutSubscriptionPreviewSuccess(subscriptionPreview));
    }
  } catch (err) {
    yield put(unifiedCheckoutSubscriptionPreviewFailure());
  }
}

function* initializeProvider(action: ReturnType<typeof unifiedCheckoutInitializeProviderAttempt>) {
  const recurlyUrl = getRecurlyUrl(action.payload.brand);
  if (recurlyUrl) {
    $script(recurlyUrl, 'recurly');
    yield new Promise(res => $script.ready('recurly', res));
    recurly.configure(getRecurlyPublicKey(action.payload.brand));
  }
  yield put(unifiedCheckoutInitializeProviderSuccess());
}

function* getPaymentMethods(action: ReturnType<typeof unifiedCheckoutGetPaymentMethodsAttempt>) {
  const source: string | undefined = yield SelectState(getSsoSourceSelector);
  const result: PaymentMethod[] = [];
  if (source === 'android' || source === 'ios') {
    const recurlyUrl = getRecurlyUrl(action.payload.brand);
    if (recurlyUrl) {
      yield new Promise(res => $script.ready('recurly', res));
      /**
       * TODO: PLAY-15305: allow creditCard
       *
       * is this where we should feature flag iOS credit card?
       *
       * Google pay is available on any browser, but Apple Pay is only available on Safari, so we can enable Google Pay
       * in any environment, but we need to explicitly set up Apple Pay when on iOS/MacOS.
       */
      const type = source === 'android' ? 'googlePay' : 'applePay';
      result.push({ type });
    }
  }
  yield put(unifiedCheckoutGetPaymentMethodsSuccess(result));
}

const createConfirmSubscriptionBody = (
  planId: string,
  externalPlanCode: string,
  currency: string,
  token: string,
  subscriptionId: string | undefined,
  externalSubscriptionId: string | undefined,
  deviceIds: string[],
) => {
  return {
    pepperPlanId: planId,
    currency: currency,
    billingToken: token,
    targetPlanCode: externalPlanCode,
    pepperSubscriptionId: subscriptionId,
    currentSubscriptionUuid: externalSubscriptionId,
    devices: deviceIds.map(i => ({ deviceId: i })),
    devicesRemoved: [],
  };
};

function* confirmSubscription(
  action: ReturnType<typeof unifiedCheckoutSubscriptionConfirmAttempt>,
): TypedIterableIterator<any> {
  try {
    const changeSubscriptionRequest = createConfirmSubscriptionBody(
      action.payload.planId,
      action.payload.externalPlanCode,
      action.payload.currency,
      action.payload.token,
      action.payload.subscriptionId,
      action.payload.externalSubscriptionId,
      action.payload.deviceIds,
    );
    const subscriptionResponse = yield call(
      postDataToService,
      '/subscribe',
      changeSubscriptionRequest,
      'subscriptions',
    );
    console.log('payment success', subscriptionResponse);
    yield put(unifiedCheckoutSubscriptionConfirmSuccess());
    NativeDispatch.dispatch({ type: 'SHOW_ALERT', payload: { subtype: 'CHECKOUT_SUCCESS' } });
  } catch (e) {
    console.error('payment failure', e);
    yield put(unifiedCheckoutSubscriptionConfirmFailure());
  }
}

function* listAccountSubscriptions(
  action: ReturnType<typeof unifiedCheckoutListAccountSubscriptionsAttempt>,
): TypedIterableIterator<any> {
  try {
    const subscriptionResponse = yield call(
      getDataFromService,
      `/subscriptions/${action.payload.accountId}`,
      'subscriptions',
    );
    const subscriptions: Subscription[] = subscriptionResponse.subscriptions.map((sub: any) => ({
      id: sub.id,
      device_ids: sub.device_ids,
      external_subscription_id: sub.external_subscription_id ?? undefined,
      plan_code: sub.plan_code,
      provider: sub.provider,
      renewal_date: sub.renewal_date ?? undefined,
      status: sub.status,
      trial_end_date: sub.trial_end_date ?? undefined,
      pending_changes: sub.pending_changes ?? undefined,
    }));
    const trialPlanCodes: string[] = subscriptionResponse.trialEligibility.plans;
    yield put(unifiedCheckoutListAccountSubscriptionsSuccess(subscriptions, trialPlanCodes));
  } catch (e) {
    console.error('list subscriptions failure', e);
    yield put(unifiedCheckoutListAccountSubscriptionsFailure());
  }
}

// eslint-disable-next-line require-yield
function* handleFatalFailure() {
  NativeDispatch.dispatch({ type: 'SHOW_ALERT', payload: { subtype: 'CHECKOUT_FAILURE' } });
}

function* UnifiedCheckoutSaga() {
  yield takeLatest(UNIFIED_CHECKOUT_SUBSCRIPTION_PREVIEW_ATTEMPT, getRecurlyPreview);
  yield takeLatest(UNIFIED_CHECKOUT_GET_PAYMENT_METHODS_ATTEMPT, getPaymentMethods);
  yield takeLatest(UNIFIED_CHECKOUT_INITIALIZE_PROVIDER_ATTEMPT, initializeProvider);
  yield takeLatest(UNIFIED_CHECKOUT_SUBSCRIPTION_CONFIRM_ATTEMPT, confirmSubscription);
  yield takeLatest(UNIFIED_CHECKOUT_LIST_ACCOUNT_SUBSCRIPTIONS_ATTEMPT, listAccountSubscriptions);
  yield takeEvery(
    [
      UNIFIED_CHECKOUT_SUBSCRIPTION_PREVIEW_FAILURE,
      UNIFIED_CHECKOUT_GET_PAYMENT_METHODS_FAILURE,
      UNIFIED_CHECKOUT_INITIALIZE_PROVIDER_FAILURE,
      UNIFIED_CHECKOUT_SUBSCRIPTION_CONFIRM_FAILURE,
      UNIFIED_CHECKOUT_LIST_ACCOUNT_SUBSCRIPTIONS_FAILURE,
    ],
    handleFatalFailure,
  );
}

export default UnifiedCheckoutSaga;
