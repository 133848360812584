export const freePlansCodes = [
  'lsm',
  'lite',
  'basic',
  'sprint_basic_month',
  'no_plan',
  'visi_no_plan',
  'otis_essential_4_m',
  'pepper_lite',
  'notion_lite',
  'not_a_plan_geeni',
];

export const nonSubscribablePlanCodes = [
  'non-subscribable',
  'non-subscribable_geeni'
]

export const otisFreePlan = 'no_plan';
export const visiFreePlan = 'visi_no_plan';
export const notionFreePlan = 'notion_lite';
export const geeniFreePlan = 'not_a_plan_geeni';

export const brandFreePlanCodes = [otisFreePlan, visiFreePlan, notionFreePlan, geeniFreePlan];

/**
 * 2022-07-05
 * Returns whether the specified plan config ID is one that should be treated as a 'free' plan. This serves the
 * purpose to replace existing 'visi' and 'otis' specific checks in the application
 * @param planId
 */
export function isFreePlan(planId: string): boolean {
  return brandFreePlanCodes.includes(planId);
}

/**
 * 2022-07-05
 * Returns whether the specified plan config ID is one that should be treated as a 'non-subscribable' plan.
 * @param planId
 */
export function isNonSubscribablePlan(planId: string): boolean {
  return nonSubscribablePlanCodes.includes(planId);
}
