export interface UnifiedCheckoutSubscriptionPreview {
  /** The currency the preview was generated for. */
  currency: string;
  /** The subtotal line item, in cents (e.g. 599 = $5.99). */
  subTotal: number;
  /** The tax line item, in cents (e.g. 599 = $5.99). */
  tax: number;
  /** The total amount, in cents (e.g. 599 = $5.99). */
  total: number;
  /** The date the subscription will begin. */
  chargeDate: string;
  /** Information about the subscription's free trial, if one is available. */
  trial?: {
    /** The date the free trial will end. */
    trialEndDate: string;
    /** The total amount the user will be charged after the trial ends, in cents (e.g. 599 = $5.99). */
    costAfterTrial: number;
  };
}

/** Describes the state of the checkout feature. */
export interface UnifiedCheckoutState {
  /** The user's selected currency. */
  currency: string;
  /** Information about the payment totals the user is expected to pay for the subscription. */
  subscriptionPreview?: UnifiedCheckoutSubscriptionPreview;
  /** The list of payment methods available to the user. */
  paymentMethods: PaymentMethod[];
  /** The list of the user's current subscriptions. */
  subscriptions: Subscription[];
  /** A list of plan codes for which the user is trial eligible. */
  trialPlanCodes: string[];
  /** Whether or not the subscription preview is currently loading. */
  isPreviewLoading: boolean;
  /** Whether or not the available payment methods are currently being initialized. */
  isPaymentMethodLoading: boolean;
  /** Whether or not the payment provider initialization is currently in progress. */
  isProviderLoading: boolean;
  /** Whether or not the request to fetch subscriptions for the current account is in progress. */
  isListSubscriptionsLoading: boolean;
  /** Whether or not the subscription confirmation request is currently in progress. */
  isSubscribeLoading: boolean;
}

/** Describes a subscription to a plan. */
export interface Subscription {
  /** The unique id of the subscription in the pepper system. */
  id: string;
  /** The list of devices associated with the subscription. */
  device_ids: string[];
  /** The unique id of the subscription in the provider system. */
  external_subscription_id?: string;
  /** The id of the plan that the subscription is for. */
  plan_code: string;
  /** The provider managing the subscription. Note that 'apple' is for in-app purchases, Apple Pay goes through recurly. */
  provider: 'recurly' | 'apple';
  /** The date the subscription will be renewed. */
  renewal_date?: string;
  /** The current status of the subscription. */
  status: 'active' | 'canceled' | 'future' | 'expired' | 'paused' | 'terminated';
  /** The date the free trial will end. If undefined, the subscription is not on a free trial. */
  trial_end_date?: string;
  /** If defined, describes pending changes that will apply to the subscription on its renewal date. */
  pending_changes?: {
    /** The plan code of the plan this subscription will change to. */
    plan_code: string;
  };
}

export interface PaymentMethodCard {
  type: 'creditCard';
  // stub implementation for future
  // lastFour: number
  // address: {...}
}

export interface PaymentMethodApplePay {
  type: 'applePay';
}

export interface PaymentMethodGooglePay {
  type: 'googlePay';
}

export enum PaymentErrorType {
  /** This generic error produces a contact support message, indicates an environment or initialization error */
  CHECKOUT_FAILURE = 'CHECKOUT_FAILURE',
  /** An expected error when the payment is declined for a reason we'll convey to the user */
  PAYMENT_FAILURE = 'PAYMENT_FAILURE',
}

export interface PaymentError {
  /** The type of error that occurred. */
  type: PaymentErrorType;
  /** The error code. */
  code: string;
  /** A translation message describing the error. */
  message: { id: string; defaultMessage: string };
}

/** Describes information that a user can use to make a payment. */
export type PaymentMethod = PaymentMethodCard | PaymentMethodApplePay | PaymentMethodGooglePay;

/** The types of all possible payment methods. */
export type PaymentType = PaymentMethod['type'];
