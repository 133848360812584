import { OrderSummaryTypes, IOrderSummary } from './types';

import {
  SUBSCRIPTION_PREVIEW_ATTEMPT,
  SUBSCRIPTION_PREVIEW_SUCCESS,
  SUBSCRIPTION_PREVIEW_FAILURE,
  SUBSCRIPTION_ATTEMPT,
  SUBSCRIPTION_SUCCESS,
  SUBSCRIPTION_FAILURE,
  SUBSCRIPTION_CLEAR_STATE_ATTEMPT,
  SUBSCRIPTION_CLEAR_STATE,
  CLEAR_SUBSCRIPTION_PREVIEW,
  CLEAR_STATE,
} from './actionTypes';

export const initialState: IOrderSummary = {
  attempting: false,
  subscriptionAttempting: false,
  subscriptionClearState: false,
  subscriptionFailure: null,
  subscriptionSuccess: null,
  subscriptionPreview: null,
  recurlyPreviewResponse: null,
  subscriptionPreviewError: null,
  subscriptionError: null,
  selectedSubscriptionRequest: null,
  subscriptionResponse: null,
};

export const OrderSummaryReducer = (state = initialState, action: OrderSummaryTypes): IOrderSummary => {
  switch (action.type) {
    case SUBSCRIPTION_PREVIEW_ATTEMPT:
      return {
        ...state,
        attempting: true,
        subscriptionAttempting: false,
        subscriptionFailure: null,
        subscriptionSuccess: null,
      };
    case SUBSCRIPTION_PREVIEW_SUCCESS:
      return {
        ...state,
        attempting: false,
        subscriptionPreview: action.subscriptionPreview,
        recurlyPreviewResponse: action.recurlyPreviewResponse,
      };
    case SUBSCRIPTION_PREVIEW_FAILURE:
      return {
        ...state,
        attempting: false,
        subscriptionPreviewError: action.err,
        recurlyPreviewResponse: action.recurlyPreviewResponse,
      };
    case SUBSCRIPTION_ATTEMPT:
      return {
        ...state,
        subscriptionAttempting: true,
        subscriptionFailure: null,
        subscriptionSuccess: null,
      };
    case SUBSCRIPTION_FAILURE:
      return {
        ...state,
        subscriptionFailure: true,
        subscriptionAttempting: false,
        subscriptionError: action.error,
        selectedSubscriptionRequest: action.selectedSubscriptionRequest,
      };
    case SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        subscriptionSuccess: true,
        subscriptionFailure: false,
        subscriptionAttempting: false,
        subscriptionResponse: action.subscriptionResponse,
      };
    case SUBSCRIPTION_CLEAR_STATE_ATTEMPT:
      return {
        ...state,
        subscriptionClearState: true,
      };
    case SUBSCRIPTION_CLEAR_STATE:
      return {
        ...state,
        subscriptionSuccess: null,
        subscriptionFailure: null,
        subscriptionAttempting: false,
        subscriptionClearState: false,
      };
    case CLEAR_SUBSCRIPTION_PREVIEW:
      return {
        ...state,
        attempting: false,
        subscriptionAttempting: false,
        subscriptionClearState: false,
        subscriptionFailure: null,
        subscriptionSuccess: null,
        subscriptionPreview: null,
        recurlyPreviewResponse: null,
        subscriptionPreviewError: null,
      };
    case CLEAR_STATE:
      return initialState;
    default:
      return state;
  }
};
