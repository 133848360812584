/**
 * Analytics events logged by this application.
 */
export enum AnalyticsEvent {
  ACCTDASH_BACK_CONFIRM = 'AcctDashBackConfirm',
  ACCTDASH_CANCEL_PLAN = 'AcctDashCancelPlan',
  ACCTDASH_EXIT_PAYMENT = 'AcctDashExitPayment',
  ACCTDASH_HISTORY = 'AcctDashHistory',
  ACCTDASH_MANAGE_DEVICE = 'AcctDashManageDevice',
  ACCTDASH_MY_PLAN_RENDERED = 'AcctDash-MyPlanRendered',
  ACCTDASH_PAYMENT = 'AcctDash-Payment',
  ACCTDASH_PLAN_CHANGE = 'AcctDashPlanChange',
  ACCTDASH_PROMO_CODE = 'AcctDashPromoCode',
  ACCTDASH_PROMO_CODE_ERROR = 'AcctDashPromoCodeError',
  ACCTDASH_SUBMIT_BILLING_INFO_SUCCESS = 'AcctDashSubmitBillingInfoSuccess',
  ACCTDASH_SUBMIT_BILLING_ADDRESS_ERROR = 'AcctDashSubmitBillingAddressError',
  ACCOUNT_CREATED = 'AccountCreated',
  ACCOUNT_SIGN_OUT = 'SignedOut',
  ACCOUNT_LOGIN_FAILED = 'LoginFailed',
  SIGNIN_USERNAME = 'SigninUsername',
  VOICE_ASSISTANT_LINKED = 'VoiceAssistantLinked',
}