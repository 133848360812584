import { DeviceListActionTypes } from './types';

import {
  GET_DEVICE_LIST_ATTEMPT,
  GET_DEVICE_LIST_SUCCESS,
  GET_DEVICE_LIST_FAILURE,
  // REFRESH_DEVICE_LIST_ATTEMPT,
  // REFRESH_DEVICE_LIST_SUCCESS,
  // REFRESH_DEVICE_LIST_FAILURE,
  // SET_SELECTED_DEVICE,
} from './actionTypes';

export const getDeviceListAttempt = (): DeviceListActionTypes => ({
  type: GET_DEVICE_LIST_ATTEMPT,
});

export const getDeviceListSuccess = (devices: any): DeviceListActionTypes => ({
  type: GET_DEVICE_LIST_SUCCESS,
  deviceList: devices,
});

export const getDeviceListFailure = (err: any): DeviceListActionTypes => ({
  type: GET_DEVICE_LIST_FAILURE,
  errorCode: err,
});
