import { all, call, delay, put, takeLatest } from 'redux-saga/effects';
import {
  authenticationFailure,
  authenticationNeedsMfa,
  authenticationSuccess,
  mfaResendVerificationFailure,
  mfaResendVerificationSuccess,
  mfaVerificationFailure,
  mfaVerificationSuccess,
  socialAuthenticationFailure,
  socialAuthenticationSuccess,
  ssoFailure,
  ssoSuccess,
} from './actions';
import { push } from 'react-router-redux';

import {
  AUTHENTICATION_ATTEMPT,
  CLEAR_STATE,
  MFA_RESEND_VERIFICATION_ATTEMPT,
  MFA_VERIFICATION_ATTEMPT,
  SOCIAL_AUTHENTICATION_ATTEMPT,
  SSO_ATTEMPT,
} from './actionTypes';
import {
  getAccountLinkingPartnerSelector,
  getIsOAuthLoginSelector,
  getOAuthParametersSelector,
  getPasswordSelector,
  getSsoTokenSelector,
  getUserMFASettingsSelector,
  getUserNameSelector,
} from './selectors';

import { getBrandNameSelector } from '../BrandProvider/selectors';
import { SelectState } from '../../modules/helpers';
import { createHttpClient, createOAuthHttpClient } from '../../services/httpRequest';
import { logEvent } from '../../utils/analytics/analyticsLogger';
import { AnalyticsEvent } from '../../utils/analytics/events';

import AWS from 'aws-sdk';
import {
  AuthenticationAttemptType,
  MfaData,
  MfaVerificationAttemptType,
  OAuthParameters,
  OAuthResponse,
  SocialAuthenticationAttemptType,
  UserMFASettings,
} from './types';
import { isRequestLimitError } from './errorUtils';

AWS.config.update({
  region: 'us-east-2',
});

interface TypedIterableIterator<T, N = any> {
  next(value: N): T;
}

const callEndpoint = (
  lambdaFunction: any,
  username: string | undefined,
  password: string | undefined,
  token: any,
  brand: string,
) => {
  const basicValue = createAuthorizationHeader(username, password, token, brand);
  var httpClient = createHttpClient(brand, 'authentication');

  return httpClient
    .post(
      lambdaFunction,
      {},
      {
        headers: { Authorization: basicValue },
      },
    )
    .then(function (response) {
      if (!response || !response.data || response.data.errorMessage) {
        throw new Error('invalid login');
      }
      return response.data;
    })
    .catch(function (error) {
      throw error;
    });
};

const createOAuthAuthorizationHeader = (username: string, password: string, brand: string) => {
  const encodedCredentials = new Buffer(username + ':' + password + ':' + brand).toString('base64');

  return 'Basic ' + encodedCredentials;
};

const createAuthorizationHeader = (
  username: string | undefined,
  password: string | undefined,
  token: any,
  brand: string,
) => {
  let encodedCredentials = null;

  if (username) {
    encodedCredentials = new Buffer(brand + ':' + username + ':' + password).toString('base64');
  } else if (token) {
    encodedCredentials = new Buffer(brand + ':' + token).toString('base64');
  }
  return 'Basic ' + encodedCredentials;
};

type MfaResult = { email?: string; phoneNumber?: string; secondFactorAuthenticationToken: string };

const isMFA = (results: MfaResult | undefined): results is MfaResult => {
  return (results as MfaResult)?.secondFactorAuthenticationToken !== undefined;
};

/**
 * Constructs the MfaData type that correctly fits the information received.
 *
 * NOTE: With login radius the MFA settings should only contain a phone number if SMS is already configured and verified;
 * if email MFA is turned on then the users settings will always include the email even if SMS is the preference used.
 * For that reason we check phone first, assuming that email is being used if phone is not present.
 * @param results
 */
const transcribeMfaData = (results: MfaResult): MfaData | undefined => {
  if (!!results?.phoneNumber) {
    return {
      contactType: 'PHONE',
      phoneNumber: results.phoneNumber,
      secondFactorAuthenticationToken: results?.secondFactorAuthenticationToken,
    };
  } else if (!!results?.email) {
    return {
      contactType: 'EMAIL',
      email: results.email,
      secondFactorAuthenticationToken: results?.secondFactorAuthenticationToken,
    };
  }
};

function* authenticateUser(action: AuthenticationAttemptType): TypedIterableIterator<any> {
  const isOAuthLogin: boolean = yield SelectState<boolean>(getIsOAuthLoginSelector);
  if (isOAuthLogin) {
    yield call(authenticateUserOAuth);
  } else {
    yield call(authenticateUserLogin, action);
  }
}

function* authenticateUserOAuth(): TypedIterableIterator<any> {
  // Select username and password from store
  const username: string = yield SelectState<string | undefined>(getUserNameSelector);

  const password: string = yield SelectState<string | undefined>(getPasswordSelector);

  const oauthParameters: OAuthParameters = yield SelectState<OAuthParameters | undefined>(getOAuthParametersSelector);

  const brand: string = yield SelectState<string>(getBrandNameSelector);

  const accountLinkingPartner: string = yield SelectState<string | undefined>(getAccountLinkingPartnerSelector);

  yield delay(400);

  try {
    const results = yield call(
      callOAuthEndpoint,
      '/authcode',
      username,
      password,
      brand,
      oauthParameters.clientId,
      oauthParameters.redirectUri,
    ) as OAuthResponse;

    if (results.authCode) {
      logEvent(
        AnalyticsEvent.VOICE_ASSISTANT_LINKED,
        { brand, email: username },
        { voiceAssistant: accountLinkingPartner },
      );
      const authCode = results.authCode.authorizationCode;
      window.location.href = `${oauthParameters.redirectUri}?code=${authCode}&state=${oauthParameters.state}`;
    }

    if (results.mfa) {
      yield put(authenticationNeedsMfa(results.mfa));
      yield put(push('/mfaLogin'));
    }
  } catch (err: any) {
    yield put(authenticationFailure(401));
  }
}

function* authenticateUserLogin(action: AuthenticationAttemptType): TypedIterableIterator<any> {
  const { username, password, rememberMe } = action.userInfo;
  const brand: string = yield SelectState<string>(getBrandNameSelector);

  yield delay(400);

  try {
    const results = yield call(callEndpoint, '/byEmail', username, password, rememberMe, brand);
    if (isMFA(results?.mfaLoginInfo)) {
      const mfaData = transcribeMfaData(results?.mfaLoginInfo);
      if (!!mfaData) {
        yield put(authenticationNeedsMfa(mfaData, results.mfaLoginInfo));
        let mfaPath = `/mfaLogin`;
        if (action.payload.redirectUri) {
          mfaPath += `?redirectUri=${action.payload.redirectUri}`;
        }
        yield put(push(mfaPath));
      } else {
        // Return value misconfigured
        yield put(authenticationFailure(401));
      }
    } else {
      // Will need to do this on MFA success
      yield put(authenticationSuccess(username, results));

      if (action.payload.redirectUri) {
        yield put(push(action.payload.redirectUri));
      } else {
        yield put(push('/subscriptions'));
      }
      logEvent(AnalyticsEvent.SIGNIN_USERNAME, results);
    }
  } catch (err: any) {
    yield put(authenticationFailure(err.response || err));
    // If the user is locked out, redirect to MFA view so they can see the lockout error message
    if (isRequestLimitError(err.response?.data?.statusCode)) {
      yield put(push('/mfaLogin'));
    }
    logEvent(AnalyticsEvent.ACCOUNT_LOGIN_FAILED, { email: username, brand: brand });
  }
}

function* verifyMfa(action: MfaVerificationAttemptType): TypedIterableIterator<any> {
  const userMFASettings = yield SelectState<UserMFASettings | undefined>(getUserMFASettingsSelector);
  const oneTimePasscode = action.code;
  const username: string = yield SelectState<string | undefined>(getUserNameSelector);
  const brand: string = yield SelectState<string>(getBrandNameSelector);

  try {
    const isOAuthLogin: boolean = yield SelectState<boolean>(getIsOAuthLoginSelector);
    if (isOAuthLogin) {
      const oauthParameters: OAuthParameters = yield SelectState<OAuthParameters | undefined>(
        getOAuthParametersSelector,
      );
      const accountLinkingPartner: string = yield SelectState<string | undefined>(getAccountLinkingPartnerSelector);
      const results = yield call(
        callOAuthMFAEmailEndpoint,
        brand,
        username,
        userMFASettings.secondFactorAuthenticationToken,
        oneTimePasscode,
        oauthParameters.clientId,
        oauthParameters.redirectUri,
      );
      logEvent(
        AnalyticsEvent.VOICE_ASSISTANT_LINKED,
        { brand, email: username },
        { voiceAssistant: accountLinkingPartner },
      );
      const authCode = results.authCode.authorizationCode;
      window.location.href = `${oauthParameters.redirectUri}?code=${authCode}&state=${oauthParameters.state}`;
    } else {
      const httpClient = createHttpClient(brand, 'authentication');
      const results = yield httpClient
        .put(`mfaValidateLoginOTP`, { brand, secondFactorAuthenticationToken: userMFASettings.secondFactorAuthenticationToken, oneTimePasscode, userMFASettings, email: username })
        .then(function (response) {
          if (!response || !response.data || response.data.errorMessage) {
            throw new Error('invalid mfa verification');
          }
          return response.data;
        });
      yield put(authenticationSuccess(username, results));
      yield put(mfaVerificationSuccess(username, results));
      if (action.payload.redirectUri) {
        yield put(push(action.payload.redirectUri));
      } else {
        yield put(push('/subscriptions'));
      }
      logEvent(AnalyticsEvent.SIGNIN_USERNAME, results);
    }
  } catch (err: any) {
    yield put(mfaVerificationFailure(err.response || err)); // TODO: can I get the right code from this
  }
}

function* resendMfa(): TypedIterableIterator<any> {
  const userMFASettings = yield SelectState<UserMFASettings | undefined>(getUserMFASettingsSelector);
  const username: string = yield SelectState<string | undefined>(getUserNameSelector);
  const brand: string = yield SelectState<string>(getBrandNameSelector);

  try {
    const httpClient = createHttpClient(brand, 'authentication');
    const results = yield httpClient
      .put(`/mfaResendOTP`, { brand, secondFactorAuthenticationToken: userMFASettings.secondFactorAuthenticationToken, email: username, userMFASettings })
      .then(function (response) {
        if (!response || !response.data || response.data.errorMessage) {
          throw new Error('invalid mfa verification');
        }
        return response.data;
      });
    yield put(mfaResendVerificationSuccess(results));
  } catch (err: any) {
    yield put(mfaResendVerificationFailure(err.response || err));
  }
}

function* ssoUser(): TypedIterableIterator<any> {
  yield put({ type: CLEAR_STATE });

  const token: any = yield SelectState<any>(getSsoTokenSelector);
  const brand: string = yield SelectState<string>(getBrandNameSelector);

  try {
    const results = yield call(callEndpoint, '/byToken', undefined, undefined, token, brand);

    yield put(ssoSuccess(undefined, results));
    yield put(push('/subscriptions'));
  } catch (err) {
    yield put(ssoFailure(err));
    yield put(push('/login'));
  }
}

function* socialAuthentication(action: SocialAuthenticationAttemptType): TypedIterableIterator<any> {
  const isOAuthLogin: boolean = yield SelectState<boolean>(getIsOAuthLoginSelector);
  if (isOAuthLogin) {
    yield call(socialAuthenticationOAuth, action);
  } else {
    yield call(socialAuthenticationLogin, action);
  }
}

function* socialAuthenticationOAuth(action: SocialAuthenticationAttemptType): TypedIterableIterator<any> {
  const brand: string = yield SelectState<string>(getBrandNameSelector);
  const oauthParameters: OAuthParameters = yield SelectState<OAuthParameters | undefined>(getOAuthParametersSelector);

  const httpClient = createOAuthHttpClient(brand);
  let url = '';
  if (action.payload.socialProvider === 'google') {
    url = `/authcode/google?clientId=${oauthParameters.clientId}&redirectUri=${oauthParameters.redirectUri}&state=${oauthParameters.state}&idToken=${action.payload.socialCredential.idToken}`;
  } else {
    url = `/authcode/apple?clientId=${oauthParameters.clientId}&redirectUri=${oauthParameters.redirectUri}&state=${oauthParameters.state}&code=${action.payload.socialCredential.authCode}`;
  }

  try {
    const results = yield call(httpClient.get, url, {});
    const authCode = results.data.authorizationCode;
    window.location.href = `${oauthParameters.redirectUri}?code=${authCode}&state=${oauthParameters.state}`;
  } catch (err: any) {
    yield put(authenticationFailure(401));
  }
}

function* socialAuthenticationLogin(action: SocialAuthenticationAttemptType): TypedIterableIterator<any> {
  try {
    const brand: string = yield SelectState<string>(getBrandNameSelector);
    const httpClient = createHttpClient(brand, 'authentication');
    const response = yield call(
      httpClient.post,
      `/authenticateViaSocial/${brand}`,
      {
        ...action.payload,
      },
      {},
    );
    if (!response?.data?.token)
      throw new Error(`Failed to log in, response object was not a profile. Got: ${JSON.stringify(response.data)}`);
    yield put(socialAuthenticationSuccess({ profile: response.data }));

    if (action.payload.redirectUri) {
      yield put(push(action.payload.redirectUri));
    } else {
      yield put(push('/subscriptions'));
    }
  } catch (e) {
    console.error(e);
    yield put(socialAuthenticationFailure());
  }
}

const callOAuthMFAEmailEndpoint = (
  brand: string,
  email: string,
  secondFactorAuthenticationToken: string,
  otp: string,
  clientId: string,
  redirectUri: string,
) => {
  const httpClient = createOAuthHttpClient(brand);
  return httpClient
    .get('/authcode/mfa', {
      params: {
        email,
        secondFactorAuthenticationToken,
        otp,
        clientId,
        redirectUri,
      },
    })
    .then(function (response) {
      if (!response || !response.data || response.data.errorMessage) {
        throw new Error('invalid login');
      }
      return response.data;
    })
    .catch(function (error) {
      throw error;
    });
};

const callOAuthEndpoint = (
  endpoint: any,
  username: string,
  password: string,
  brand: string,
  clientId: string,
  redirectUri: string,
) => {
  const basicValue = createOAuthAuthorizationHeader(username, password, brand);
  const httpClient = createOAuthHttpClient(brand);

  return httpClient
    .post(
      endpoint,
      {
        client_id: clientId,
        redirect_uri: redirectUri,
      },
      {
        headers: { Authorization: basicValue },
      },
    )
    .then(function (response) {
      if (!response || !response.data || response.data.errorMessage) {
        throw new Error('invalid login');
      }
      return response.data;
    })
    .catch(function (error) {
      throw error;
    });
};


function* authenticateSaga() {
  yield all([takeLatest(AUTHENTICATION_ATTEMPT, authenticateUser)]);
  yield all([takeLatest(SSO_ATTEMPT, ssoUser)]);
  yield all([takeLatest(MFA_VERIFICATION_ATTEMPT, verifyMfa)]);
  yield all([takeLatest(MFA_RESEND_VERIFICATION_ATTEMPT, resendMfa)]);
  yield all([takeLatest(SOCIAL_AUTHENTICATION_ATTEMPT, socialAuthentication)]);
}

export default authenticateSaga;
