import { defineMessages } from 'react-intl';

export default defineMessages({
  modalTitle: {
    id: 'accountManagement.components.label.confirmExit.modalTitle.text',
    defaultMessage: 'Are you sure you want to go back?',
  },
  confirmExitStatement: {
    id: 'accountManagement.components.label.confirmExit.confirmExitStatement.text',
    defaultMessage: 'All changes will be lost unless you finish your purchase.',
  },
  cancelButton: {
    id: 'accountManagement.components.label.confirmExit.cancelButton.text',
    defaultMessage: 'Cancel',
  },
  exitButton: {
    id: 'accountManagement.components.label.confirmExit.exitButton.text',
    defaultMessage: 'Yes',
  },
  warningAltText: {
    id: 'accountManagement.components.label.confirmExit.warningAltText.text',
    defaultMessage: 'Warning: Confirm Exit',
  },
});
