/*
 * AccountHistory Messages
 *
 * This contains all the text for the AccountHistory component.
 */
import { defineMessages } from 'react-intl';

export default defineMessages({
  // Column Headers
  columnDate: {
    id: 'accountManagement.components.accountHistory.columnHeader.date.text',
    defaultMessage: 'Date',
  },
  columnStatus: {
    id: 'accountManagement.components.accountHistory.columnHeader.status.text',
    defaultMessage: 'Status',
  },
  columnAmount: {
    id: 'accountManagement.components.accountHistory.columnHeader.amount.text',
    defaultMessage: 'Amount',
  },
  columnPaymentMethod: {
    id: 'accountManagement.components.accountHistory.columnHeader.paymentMethod.text',
    defaultMessage: 'Payment Method',
  },
  columnDevice: {
    id: 'accountManagement.components.accountHistory.columnHeader.device.text',
    defaultMessage: 'Device',
  },
  columnInvoiceNum: {
    id: 'accountManagement.components.accountHistory.columnHeader.invoiceNumber.text',
    defaultMessage: 'Invoice #',
  },

  // Status Column
  statusPaid: {
    id: 'accountManagement.components.accountHistory.columnText.statusPaid.text',
    defaultMessage: 'Paid',
  },
  statusPending: {
    id: 'accountManagement.components.accountHistory.columnText.statusPending.text',
    defaultMessage: 'Pending',
  },
  statusFailed: {
    id: 'accountManagement.components.accountHistory.columnText.statusFailed.text',
    defaultMessage: 'Failed',
  },
  statusPastDue: {
    id: 'accountManagement.components.accountHistory.columnText.statusPastDue.text',
    defaultMessage: 'Past Due',
  },
  statusRefund: {
    id: 'accountManagement.components.accountHistory.columnText.statusRefund.text',
    defaultMessage: 'Refund',
  },

  // General
  showingCount: {
    id: 'accountManagement.components.accountHistory.showingCount.text',
    defaultMessage: 'Showing last 50 invoices',
  },
  noAcctHistory: {
    id: 'accountManagement.components.accountHistory.info.noAcctHistory.text',
    defaultMessage: 'You have no payment history.',
  },
  networkError: {
    id: 'accountManagement.components.accountHistory.info.networkError.text',
    defaultMessage: 'Sorry, we are unable to retrieve your information at this time. Please try again later.',
  },
  cardTypeAltText: {
    id: 'accountManagement.components.accountHistory.cardType.text',
    defaultMessage: 'Payment Method',
  },

  appleSubscriptions: {
    id: 'accountManagement.components.accountHistory.appleSubscriptions.text',
    defaultMessage: 'Go to iTunes to view payment history for your Apple subscriptions.',
  },
});
