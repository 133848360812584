import { createSelector } from 'reselect';

import { AppState } from '../../store/rootReducer';

const getCurrency = (state: AppState) => state.billingInfo.currency;
const getCurrencyCountry = (state: AppState) => state.billingInfo.currencyCountry;
const getBillingInfo = (state: AppState) => state.billingInfo.billingInfo;
const getSelectedBillingInfo = (state: AppState) => state.billingInfo.selectedBillingInfo;

const getSavingBillingInfo = (state: AppState) => state.billingInfo.saving;
const getThreeDToken = (state: AppState) => state.billingInfo.actionResultTokenId;

export const getThreeDTokenSelector = createSelector(getThreeDToken, token => token);

export const getCurrencySelector = createSelector(getCurrency, currency => currency);

export const getBillingInfoSelector = createSelector(getBillingInfo, billingInfo => billingInfo);

export const getSavingBillingInfoSelector = createSelector(getSavingBillingInfo, saving => saving);

export const getSelectedBillingInfoSelector = createSelector(
  getSelectedBillingInfo,
  selectedBillingInfo => selectedBillingInfo,
);

export const getBillingCountrySelector = createSelector(
  getSelectedBillingInfo,
  selectedBillingInfo => selectedBillingInfo.country,
);

export const getBillingInformationExistsSelector = createSelector(getBillingInfo, billingInfo => {
  return !!(billingInfo && billingInfo.last_four);
});

export const getBillingInformationToken = createSelector(getSelectedBillingInfo, billingInfo => {
  if (billingInfo && billingInfo.token) {
    return billingInfo.token;
  }
  return undefined;
});

export const getCurrencyCountrySelector = createSelector(getCurrencyCountry, currencyCountry => {
  if (currencyCountry) {
    return currencyCountry;
  }
  return 'en';
});
