import { SelectState, TypedIterableIterator } from '../../modules/helpers';
import { postDataToService } from '../../services/apiGatewayClient';

//redux
import { all, call, put, takeLatest } from 'redux-saga/effects';
import { PROMO_CODE_PREDEFINED_CODES, PROMO_CODE_REFRESH_ATTEMPT, PROMO_CODE_VALIDATION_ATTEMPT } from './actionTypes';

import { validatePromoCodeSuccess, validatePromoCodeFailure, setPromoCodeInvalid } from './actions';

import { getPromoCodeSelector, getPromoCodesSelector } from './selectors';
import { getSelectedPlanSelector } from '../PlanListPage/selectors';
import { getDevicesAddedSelector } from '../ManageDevices/selectors';

function isPromoCodeValidForPlan(promoCodeResponse: any, selectedPlan: any) {
  if (!promoCodeResponse.plan_codes.plan_code) {
    return true;
  }

  let allowedPlanCodes = Array.isArray(promoCodeResponse.plan_codes.plan_code)
    ? promoCodeResponse.plan_codes.plan_code
    : [promoCodeResponse.plan_codes.plan_code];

  for (let allowedPlanCode of allowedPlanCodes) {
    if (allowedPlanCode === selectedPlan.external_plan_code) {
      return true;
    }
  }
  return false;
}

function* validateCouponCode(promoCode: string, devicesAdded: any, selectedPlan: any): TypedIterableIterator<any> {
  try {
    const deviceModels = devicesAdded.map((device: any) => device.model);
    const promoCodeResponse = yield call(
      postDataToService,
      `/validateCoupon/${promoCode.toLowerCase()}`,
      { device_models: deviceModels },
      'subscriptions',
    );

    if (!promoCodeResponse || !promoCodeResponse.coupon_code) {
      yield put(setPromoCodeInvalid(promoCode, promoCodeResponse));
    } else {
      if (isPromoCodeValidForPlan(promoCodeResponse, selectedPlan)) {
        yield put(validatePromoCodeSuccess(promoCode, promoCodeResponse));
      } else {
        let errorCode = 'promoCodePlanRestriction';
        yield put(validatePromoCodeFailure(promoCode, errorCode));
      }
    }
  } catch (err) {
    let errorCode = 'unknown';
    if (err.response && err.response.data && err.response.data.error) {
      errorCode = 'promoCodeRestriction';
    }
    yield put(validatePromoCodeFailure(promoCode, errorCode));
  }
}

function* validateCoupon(): TypedIterableIterator<any> {
  const promoCode: any = yield SelectState<any>(getPromoCodeSelector);
  const devicesAdded: any = yield SelectState<any>(getDevicesAddedSelector);
  const selectedPlan: any = yield SelectState<any>(getSelectedPlanSelector);

  yield call(validateCouponCode, promoCode, devicesAdded, selectedPlan);
}

function* validateCoupons(): TypedIterableIterator<any> {
  const promoCodes: any = yield SelectState<any>(getPromoCodesSelector);
  const devicesAdded: any = yield SelectState<any>(getDevicesAddedSelector);
  const selectedPlan: any = yield SelectState<any>(getSelectedPlanSelector);

  for (let promoCode of promoCodes) {
    if (!!promoCode) {
      yield call(validateCouponCode, promoCode, devicesAdded, selectedPlan);
    }
  }
}

function* PromoCodeSaga() {
  yield all([
    takeLatest(PROMO_CODE_VALIDATION_ATTEMPT, validateCoupon),
    takeLatest(PROMO_CODE_PREDEFINED_CODES, validateCoupons),
    takeLatest(PROMO_CODE_REFRESH_ATTEMPT, validateCoupons),
  ]);
}

export default PromoCodeSaga;
