export const GET_MANAGE_DEVICES_ATTEMPT = 'GET_MANAGE_DEVICES_ATTEMPT';
export const GET_ASSIGN_DEVICES_SUCCESS = 'GET_ASSIGN_DEVICES_SUCCESS';
export const GET_DEVICES_MANAGED_SUCCESS = 'GET_DEVICES_MANAGED_SUCCESS';
export const GET_MANAGE_DEVICES_FAILURE = 'GET_MANAGE_DEVICES_FAILURE';
export const GET_ASSIGN_DEVICES_FAILURE = 'GET_ASSIGN_DEVICES_FAILURE';
export const SET_DEVICES_MANAGED = 'SET_DEVICES_MANAGED';
export const SET_DEVICES_ASSIGNED = 'SET_DEVICES_ASSIGNED';
export const CLEAR_MANAGE_DEVICES_STATE = 'CLEAR_MANAGE_DEVICES_STATE';
export const CLEAR_STATE = 'CLEAR_STATE';
export const CLEAR_ERROR_STATE = 'CLEAR_ERROR_STATE';
