import { DeleteActionTypes } from './types';

import {
  GET_DELETE_ACCOUNT_ATTEMPT,
  GET_DELETE_ACCOUNT_FAILURE,
  GET_DELETE_ACCOUNT_SUCCESS,
  CLEAR_STATE,
} from './actionTypes';

export const getDeleteAttempt = (): DeleteActionTypes => ({
  type: GET_DELETE_ACCOUNT_ATTEMPT,
});

export const getDeleteSuccess = (): DeleteActionTypes => ({
  type: GET_DELETE_ACCOUNT_SUCCESS,
});

export const getDeleteFailure = (error: any): DeleteActionTypes => ({
  type: GET_DELETE_ACCOUNT_FAILURE,
  error: error,
});

export const clearState = (): DeleteActionTypes => ({
  type: CLEAR_STATE,
});
