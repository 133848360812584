/*
 * Change Subscription Confirmation Page Messages
 *
 * This contains all the text for the change subscription confirmation page messages.
 */
import { defineMessages } from 'react-intl';

export default defineMessages({
  backUpStatement: {
    id: 'accountManagement.components.plan.subscriptionChange.confirmation.statement.backUpStatement',
    defaultMessage:
      'Changing your plan will downgrade your {device} to {storage} video history. Older footage will be deleted at the end of the current billing period, {expireDate}',
  },
  statementDowngrade: {
    id: 'accountManagement.components.plan.subscriptionChange.confirmation.statement.downgrade',
    defaultMessage: 'When you change your plan you will lose these features.',
  },
  question: {
    id: 'accountManagement.components.plan.subscriptionChange.confirmation.question',
    defaultMessage: 'Are you sure you want to change your plan?',
  },
  cancel: {
    id: 'accountManagement.components.button.plan.subscriptionChange.confirmation.cancel',
    defaultMessage: 'Cancel',
  },
  confirm: {
    id: 'accountManagement.components.button.plan.subscriptionChange.confirmation.confirm',
    defaultMessage: 'Yes, change plan',
  },
  warningAltText: {
    id: 'accountManagement.components.button.plan.subscriptionChange.confirmation.warning.alt.text',
    defaultMessage: 'Subscription Change Warning',
  },
  dataLossFeatures: {
    id: 'app.containers.DataLossModal.features.text',
    defaultMessage: 'features',
  },
  backUp: {
    id: 'app.containers.DataLossModal.backUp.text',
    defaultMessage: 'Back up footage',
  },
});
