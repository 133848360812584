import { ResetPasswordTypes } from './types';

import {
  APP_URI_FAILURE,
  APP_URI_SUCCESS,
  FETCH_APP_URI,
  RESET_PASSWORD_ATTEMPT,
  RESET_PASSWORD_FAILURE,
  RESET_PASSWORD_SUCCESS,
} from './actionTypes';

export const appUriFailureAction = (): ResetPasswordTypes => ({
  type: APP_URI_FAILURE,
});

export const appUriSuccessAction = (appUriObj: any): ResetPasswordTypes => ({
  type: APP_URI_SUCCESS,
  appUriObj: appUriObj,
});

export const initiateFetchUri = (brand: string): ResetPasswordTypes => ({
  type: FETCH_APP_URI,
  brand: brand,
});

export const resetPasswordAttemptAction = (
  brand: string,
  resetToken: any,
  password: string,
  email: string,
): ResetPasswordTypes => ({
  type: RESET_PASSWORD_ATTEMPT,
  brand: brand,
  resetToken: resetToken,
  password: password,
  email: email,
});

export const resetPasswordFailureAction = (error: any): ResetPasswordTypes => ({
  type: RESET_PASSWORD_FAILURE,
  error: error,
});

export const resetPasswordSuccessAction = (): ResetPasswordTypes => ({
  type: RESET_PASSWORD_SUCCESS,
});
