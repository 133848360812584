import { CancelDeleteActionTypes } from './types';

import {
  GET_CANCEL_DELETE_ACCOUNT_ATTEMPT,
  GET_CANCEL_DELETE_ACCOUNT_FAILURE,
  GET_CANCEL_DELETE_ACCOUNT_SUCCESS,
  CLEAR_STATE,
} from './actionTypes';

export const getCancelDeleteAttempt = (): CancelDeleteActionTypes => ({
  type: GET_CANCEL_DELETE_ACCOUNT_ATTEMPT,
});

export const getCancelDeleteSuccess = (): CancelDeleteActionTypes => ({
  type: GET_CANCEL_DELETE_ACCOUNT_SUCCESS,
});

export const getCancelDeleteFailure = (error: any): CancelDeleteActionTypes => ({
  type: GET_CANCEL_DELETE_ACCOUNT_FAILURE,
  error: error,
});

export const clearState = (): CancelDeleteActionTypes => ({
  type: CLEAR_STATE,
});
