import { all, call, put, takeLatest } from 'redux-saga/effects';
import { INVOICE_DETAILS_ATTEMPT } from './actionTypes';

import { TypedIterableIterator } from '../../modules/helpers';
import { getDataFromService } from '../../services/apiGatewayClient';
import { invoiceDetailsFailure, invoiceDetailsSuccess } from './actions';

function* getInvoiceDetails(): TypedIterableIterator<any> {
  try {
    const invoiceDetails = yield call(getDataFromService, '/invoiceDetails', 'subscriptions');

    yield put(invoiceDetailsSuccess(invoiceDetails));
  } catch (err) {
    yield put(invoiceDetailsFailure(err));
  }
}

function* CheckoutSuccessSaga() {
  yield all([takeLatest(INVOICE_DETAILS_ATTEMPT, getInvoiceDetails)]);
}

export default CheckoutSuccessSaga;
