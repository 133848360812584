import {
  UNIFIED_CHECKOUT_GET_PAYMENT_METHODS_ATTEMPT,
  UNIFIED_CHECKOUT_GET_PAYMENT_METHODS_FAILURE,
  UNIFIED_CHECKOUT_GET_PAYMENT_METHODS_SUCCESS,
  UNIFIED_CHECKOUT_INITIALIZE_PROVIDER_ATTEMPT,
  UNIFIED_CHECKOUT_INITIALIZE_PROVIDER_FAILURE,
  UNIFIED_CHECKOUT_INITIALIZE_PROVIDER_SUCCESS,
  UNIFIED_CHECKOUT_LIST_ACCOUNT_SUBSCRIPTIONS_ATTEMPT,
  UNIFIED_CHECKOUT_LIST_ACCOUNT_SUBSCRIPTIONS_FAILURE,
  UNIFIED_CHECKOUT_LIST_ACCOUNT_SUBSCRIPTIONS_SUCCESS,
  UNIFIED_CHECKOUT_PAYMENT_METHOD_FAILURE,
  UNIFIED_CHECKOUT_SUBSCRIPTION_CONFIRM_ATTEMPT,
  UNIFIED_CHECKOUT_SUBSCRIPTION_CONFIRM_FAILURE,
  UNIFIED_CHECKOUT_SUBSCRIPTION_CONFIRM_SUCCESS,
  UNIFIED_CHECKOUT_SUBSCRIPTION_PREVIEW_ATTEMPT,
  UNIFIED_CHECKOUT_SUBSCRIPTION_PREVIEW_FAILURE,
  UNIFIED_CHECKOUT_SUBSCRIPTION_PREVIEW_SUCCESS,
} from './actionTypes';
import type { UnifiedCheckoutActionTypes } from './actions';
import type { UnifiedCheckoutState } from './types';
import _ from 'lodash';

export const initialState: UnifiedCheckoutState = {
  currency: 'USD',
  subscriptionPreview: undefined,
  paymentMethods: [],
  subscriptions: [],
  trialPlanCodes: [],
  isPreviewLoading: false,
  isPaymentMethodLoading: false,
  isProviderLoading: true,
  isListSubscriptionsLoading: false,
  isSubscribeLoading: false,
};

export const UnifiedCheckoutReducer = (
  state = initialState,
  action: UnifiedCheckoutActionTypes,
): UnifiedCheckoutState => {
  switch (action.type) {
    case UNIFIED_CHECKOUT_INITIALIZE_PROVIDER_ATTEMPT:
      return { ...state, isProviderLoading: true };
    case UNIFIED_CHECKOUT_INITIALIZE_PROVIDER_FAILURE:
      return { ...state, isProviderLoading: false };
    case UNIFIED_CHECKOUT_INITIALIZE_PROVIDER_SUCCESS:
      return { ...state, isProviderLoading: false };
    case UNIFIED_CHECKOUT_SUBSCRIPTION_PREVIEW_ATTEMPT:
      return { ...state, isPreviewLoading: true };
    case UNIFIED_CHECKOUT_SUBSCRIPTION_PREVIEW_FAILURE:
      return { ...state, isPreviewLoading: false };
    case UNIFIED_CHECKOUT_SUBSCRIPTION_PREVIEW_SUCCESS:
      return { ...state, isPreviewLoading: false, subscriptionPreview: action.payload.subscriptionPreview };
    case UNIFIED_CHECKOUT_SUBSCRIPTION_CONFIRM_ATTEMPT:
      return { ...state, isSubscribeLoading: true };
    case UNIFIED_CHECKOUT_SUBSCRIPTION_CONFIRM_FAILURE:
      return { ...state, isSubscribeLoading: false };
    case UNIFIED_CHECKOUT_SUBSCRIPTION_CONFIRM_SUCCESS:
      return { ...state, isSubscribeLoading: false };
    case UNIFIED_CHECKOUT_LIST_ACCOUNT_SUBSCRIPTIONS_ATTEMPT:
      return { ...state, isListSubscriptionsLoading: true };
    case UNIFIED_CHECKOUT_LIST_ACCOUNT_SUBSCRIPTIONS_FAILURE:
      return { ...state, isListSubscriptionsLoading: false };
    case UNIFIED_CHECKOUT_LIST_ACCOUNT_SUBSCRIPTIONS_SUCCESS:
      return {
        ...state,
        isListSubscriptionsLoading: false,
        subscriptions: action.payload.subscriptions,
        trialPlanCodes: action.payload.trialPlanCodes,
      };
    case UNIFIED_CHECKOUT_GET_PAYMENT_METHODS_ATTEMPT:
      return { ...state, isPaymentMethodLoading: true };
    case UNIFIED_CHECKOUT_GET_PAYMENT_METHODS_FAILURE:
      return { ...state, isPaymentMethodLoading: false };
    case UNIFIED_CHECKOUT_GET_PAYMENT_METHODS_SUCCESS:
      return { ...state, isPaymentMethodLoading: false, paymentMethods: action.payload.paymentMethods };
    case UNIFIED_CHECKOUT_PAYMENT_METHOD_FAILURE:
      return {
        ...state,
        paymentMethods: state.paymentMethods.filter(i => !_.isEqual(i, action.payload.paymentMethod)),
      };
    default:
      return state;
  }
};
