import { ManageDevicesActionTypes } from './types';

import {
  GET_MANAGE_DEVICES_ATTEMPT,
  GET_ASSIGN_DEVICES_SUCCESS,
  GET_DEVICES_MANAGED_SUCCESS,
  GET_MANAGE_DEVICES_FAILURE,
  GET_ASSIGN_DEVICES_FAILURE,
  CLEAR_MANAGE_DEVICES_STATE,
  SET_DEVICES_MANAGED,
  SET_DEVICES_ASSIGNED,
  CLEAR_ERROR_STATE,
} from './actionTypes';

export const getManageDevicesAttempt = (): ManageDevicesActionTypes => ({
  type: GET_MANAGE_DEVICES_ATTEMPT,
});

export const clearErrorState = (): ManageDevicesActionTypes => ({
  type: CLEAR_ERROR_STATE,
});

export const getManageDevicesSuccess = (devices: any): ManageDevicesActionTypes => ({
  type: GET_ASSIGN_DEVICES_SUCCESS,
  devices: devices,
});

export const getDevicesManagedSuccess = (): ManageDevicesActionTypes => ({
  type: GET_DEVICES_MANAGED_SUCCESS,
});

export const setAssignedDevicesFailure = (error: any): ManageDevicesActionTypes => ({
  type: GET_ASSIGN_DEVICES_FAILURE,
  error: error,
});

export const setManagedDevicesFailure = (error: any): ManageDevicesActionTypes => ({
  type: GET_MANAGE_DEVICES_FAILURE,
  error: error,
});

export const setDevicesAsManaged = (devicesAdded: any, devicesRemoved: any): ManageDevicesActionTypes => ({
  type: SET_DEVICES_MANAGED,
  devicesAdded: devicesAdded,
  devicesRemoved: devicesRemoved,
});

export const setDevicesAsAssigned = (devicesAdded: any, devicesRemoved: any): ManageDevicesActionTypes => ({
  type: SET_DEVICES_ASSIGNED,
  devicesAdded: devicesAdded,
  devicesRemoved: devicesRemoved,
});

export const clearManageDevicesState = (): ManageDevicesActionTypes => ({
  type: CLEAR_MANAGE_DEVICES_STATE,
});
