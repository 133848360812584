import { CancelPlanActionTypes, ICancelPlan } from './types';

import {
  CANCEL_SUBSCRIPTION_ATTEMPT,
  CANCEL_SUBSCRIPTION_CLEAR,
  CANCEL_SUBSCRIPTION_FAILURE,
  CANCEL_SUBSCRIPTION_SUCCESS,
  CLEAR_STATE,
} from './actionTypes';

export const initialState: ICancelPlan = {
  subscription_attempting: false,
  subscription_failure: null,
  subscription_success: null,
  subscription_error: null,
  subscription_response: null,
};

export const CancelPlanReducer = (state = initialState, action: CancelPlanActionTypes): ICancelPlan => {
  switch (action.type) {
    case CANCEL_SUBSCRIPTION_ATTEMPT:
      return {
        ...state,
        subscription_attempting: true,
        subscription_failure: null,
        subscription_success: null,
      };
    case CANCEL_SUBSCRIPTION_FAILURE:
      return {
        ...state,
        subscription_failure: true,
        subscription_attempting: false,
        subscription_error: action.error,
        subscription_success: null,
      };
    case CANCEL_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        subscription_failure: null,
        subscription_success: true,
        subscription_attempting: false,
        subscription_response: action.res,
      };
    case CLEAR_STATE:
    case CANCEL_SUBSCRIPTION_CLEAR:
      return initialState;
    default:
      return state;
  }
};
