import { PromoCodeTypes } from './types';

import {
  PROMO_CODE_VALIDATION_ATTEMPT,
  PROMO_CODE_VALIDATION_SUCCESS,
  PROMO_CODE_VALIDATION_FAILURE,
  PROMO_CODE_INVALID,
  PROMO_CODE_VALIDATION_CLEAR_STATE,
  PROMO_CODE_PREDEFINED_CODES,
  PROMO_CODE_REFRESH_ATTEMPT,
  PROMO_CODE_REMOVE_CODE,
} from './actionTypes';

export const validatePromoCodeAttempt = (code: string): PromoCodeTypes => ({
  type: PROMO_CODE_VALIDATION_ATTEMPT,
  code: code.toLowerCase(),
});

export const validatePromoCodeSuccess = (code: string, message: string): PromoCodeTypes => ({
  type: PROMO_CODE_VALIDATION_SUCCESS,
  code: code.toLowerCase(),
  message,
});

export const validatePromoCodeFailure = (code: string, errorCode: any): PromoCodeTypes => ({
  type: PROMO_CODE_VALIDATION_FAILURE,
  code: code.toLowerCase(),
  errorCode,
});

export const setPromoCodeInvalid = (code: string, message: string): PromoCodeTypes => ({
  type: PROMO_CODE_INVALID,
  code: code.toLowerCase(),
  message,
});

export const setPredefinedPromoCodes = (codes: string[]): PromoCodeTypes => ({
  type: PROMO_CODE_PREDEFINED_CODES,
  codes: codes.map(code => code.toLowerCase()),
});

export const validatePromoCodesAttempt = (): PromoCodeTypes => ({
  type: PROMO_CODE_REFRESH_ATTEMPT,
});

export const removePromoCode = (code: string): PromoCodeTypes => ({
  type: PROMO_CODE_REMOVE_CODE,
  code: code.toLowerCase(),
});

export const clearPromoCodeState = (): PromoCodeTypes => ({
  type: PROMO_CODE_VALIDATION_CLEAR_STATE,
});
